'use strict';

/**
 * @function
 * @description Initializes input float labels
 */
exports.init = function () {

    function checkInputs() {
        $('.form-row.has-float-label').each(function () {
            var label = $(this).find('label'),
                input = $(this).find('input, select, textarea, .select-styled'),
                isCustomDropdown = input.hasClass('select-styled');

            //Use the label as a placeholder, this adds (optional) if not required
            if (!$(this).find('input').attr('placeholder')) {
                $(this).find('input').attr('placeholder', $(this).find('input').siblings('label').text());
            }

            //Use the label as a placeholder for select box, if no value is selected
            if ($(this).find('select').length && $(this).find('select').val() == null) {
                $(this).find('select').prepend("<option value='' disabled hidden selected>" + label.text() + "</option>").val('');
            }

            //Determines if we should add or remove the float class.
            //If the input has class 'always-float' it will never have it's float-label removed.
            //If the input has class 'no-val-no-float' it will check the value of the input for every event (focus, blur, etc.)
            function floatLabelIfValue(valueIsRequired) {
                valueIsRequired = typeof(valueIsRequired) === 'boolean' ? valueIsRequired : true; //default to true if not passed in
                if (!input.val() && (valueIsRequired || input.hasClass('no-val-no-float')) && !input.hasClass('always-float')) {
                    label.removeClass('float-label');
                    input.removeClass('float-label');
                } else {
                    if(input.is('select')) {
                        var labelText = label.text(),
                            inputVal = input.find(":selected").text();
                        
                        if(labelText != inputVal) {
                            label.addClass('float-label');
                            input.addClass('float-label');  
                        } 
                    } else {
                        label.addClass('float-label');
                        input.addClass('float-label');  
                    }
                }
            }

            //Add event listener to input
            input.on('keyup drop', function () {
                floatLabelIfValue(false);
            }).on('focus', function () {
                if (input.attr('placeholder')) {
                    input.data('placeholder', input.attr('placeholder')); //store placeholder as data attribute
                    input.attr('placeholder', ''); //remove placeholder attribute
                }
                floatLabelIfValue(false);
            }).on('blur', function () {
                if (input.data('placeholder')) {
                    input.attr('placeholder', input.data('placeholder')); //restore placeholder text
                }
                floatLabelIfValue();
            }).on('change', floatLabelIfValue);

            //Init float label classes on load
            floatLabelIfValue();
        });
    }
    checkInputs();

    //Check inputs when dialog opened
    $(document).on("dialogopen", ".ui-dialog", function (event, ui) {
        checkInputs();
    });
};
