'use strict';
var util = require('./util');

var stickyfooter = {
    init: function () {
        initializeDOM();
        initializeFooterObservers();
    },
    /**
     * @function
     * @description Make a jQuery element sticky to footer on scroll-up
     * @param {String} selector the target as a selector string
     * @param {String} mediaQuery if defined, stickiness should only activate when media query matches viewport
     * @example stickyfooter.makeSticky('.class-name');
    */
    makeSticky: function (selector, mediaQuery) {
        var targets = document.querySelectorAll(selector);
        targets.forEach(function (elem) {
            elem.classList.add('sticky-footer');
            elem.dataset.mediaquery = elem.dataset.mediaquery || mediaQuery || '(min-width: 0px)';
        });
        initializeDOM();
        initializeFooterObservers();
    }
};

function initializeDOM () {
    var targets = document.querySelectorAll('.sticky-footer');
    targets.forEach(function (elem) {
        var sentinel;
        if (elem.previousElementSibling && elem.previousElementSibling.id === 'sticky-footer-sentinel') {
            sentinel = elem.previousElementSibling;
        } else {
            sentinel = document.createElement('div');
            sentinel.setAttribute('id', 'sticky-footer-sentinel');
            elem.insertAdjacentElement('beforebegin', sentinel);
        }
        sentinel.style.height = elem.getBoundingClientRect().height + 'px';
        sentinel.style.position = 'absolute';
        sentinel.style.left = '0';
        sentinel.style.width = '100%';
        sentinel.style.display = 'none';
        elem.dataset.mediaquery = elem.dataset.mediaquery || '(min-width: 0px)';
    });
}

function initializeFooterObservers() {
    /*eslint-disable */
    //Linter does not know observers.
    const sentinelElem = document.getElementById('sticky-footer-sentinel');
    if (sentinelElem === null) {
        return;
    }
    const stickyFooterElem = sentinelElem.nextElementSibling && sentinelElem.nextElementSibling.className === 'sticky-footer' ? sentinelElem.nextElementSibling : document.querySelector('.sticky-footer');
    const mediaQuery = 'mediaquery' in stickyFooterElem.dataset ? stickyFooterElem.dataset.mediaquery : '(min-width: 0px)';

    function _stick () {
        stickyFooterElem.classList.add('stuck');
        sentinelElem.style.position = 'static';
        $('body').find('.back-top').fadeOut();
    }

    function _unstick () {
        stickyFooterElem.classList.remove('stuck');
        sentinelElem.style.position = 'absolute';
        $('body').find('.back-top').fadeIn();
    }

    const config = {
        threshold: 0.95 // somehow, setting this to 1.0 caused problems--intersection was never detected in some instances.  But 0.95 seems to work ¯\_(ツ)_/¯
    };
    const observerStickyFooter = new IntersectionObserver (function(entries, self) {
        entries.forEach(function(entry) {
            if (entry.isIntersecting === true) { // intersection
                _unstick();
            } else { // no intersection
                if (entry.boundingClientRect.top > 0) { // element original seat is BELOW the viewport (i.e. user has not scrolled down past it)
                    _stick();
                }
            }
        });
    }, config);

    //Call observer, responsively
    if (window.matchMedia(mediaQuery).matches) {
        observerStickyFooter.observe(sentinelElem);
        sentinelElem.style.display = 'block';
    }
    util.smartResize(function () {
        if (window.matchMedia(mediaQuery).matches == false) {
            observerStickyFooter.disconnect();
            sentinelElem.style.display = 'none';
            _unstick();
        } else {
            observerStickyFooter.observe(sentinelElem);
            sentinelElem.style.display = 'block';
        }
    });
    /*eslint-enable */
}

module.exports = stickyfooter;