'use strict';

var tealium = require('./tealium');
var floatlabels = require('./float-labels');
var progress = require('./progress');

var subscribeemail = {
    init : function () {
        initializeCache();
        initializeDom();
        initializeEvents();
    }
};

var $cache = {};

function initializeEvents() {
    floatlabels.init();
    $cache.subscribeEmail.on('input propertychange', function(event) {
        event.preventDefault();
        if (validateEmail(jQuery(this).val())) {
            $cache.subscribeButton.removeAttr('disabled');
        } else {
            $cache.subscribeButton.attr('disabled', 'disabled');
        }
    });

    $cache.subscribeButton.on('click', function(event) {
        event.preventDefault();
        if (!jQuery(this).attr('disabled')) {
            var action = jQuery(this).attr('href');
            var email = $cache.subscribeEmail.val();
            progress.show($('#hf-footer'));
            $.ajax({
                dataType : "json",
                url: action,
                data: {'email':email}
            })
            .done(function (response) {
                var success = response.success;
                var message = response.message;
                var newClass = success && success !== 'failed' ? 'success' : 'failed';

                $cache.subscribeMessage.removeClass().addClass(newClass).show().text(response.message);
                if (success && success !== 'failed') {
                    tealium.emailSignup(email);
                    $cache.subscribeEmail.attr('aria-invalid', 'false');
                    $cache.subscribeMessage.focus();
                } else {
                    $cache.subscribeEmail.attr('aria-invalid', 'true');
                    window.setTimeout(function () {
                        $cache.subscribeEmail.focus();
                    }, 400);
                }
                progress.hide($('#hf-footer'));
            })
            .fail(function (xhr, textStatus) {
                // failed
                if(textStatus === "parsererror") {
                    window.alert(window.Resources.BAD_RESPONSE);
                } else {
                    window.alert(window.Resources.SERVER_CONNECTION_ERROR);
                }
                progress.hide();
            });
        }
    });
}

function initializeDom() {
    $cache.subscribeButton.attr('disabled', 'disabled');
}

function initializeCache() {
    $cache = {
        subscribe: $("#hf-email-alert-signup")
    };
    $cache.subscribeEmail = $cache.subscribe.find("#email");
    $cache.subscribeButton = $cache.subscribe.find(".btn").first();
    $cache.subscribeMessage = $cache.subscribe.find("#emailsignup-message");
}

function validateEmail(email) {
    var filter = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    if (filter.test(email)) {
         return true;
     } else {
        return false;
    }
}

module.exports = subscribeemail;
