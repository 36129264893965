'use strict';

var checkboxes = $('.required-checkbox');
var checkbox_names = $.map(checkboxes, function(e, i) {
    return $(e).attr("name");
}).join(" ");

var naPhone = /^[1-9]?[\-\. ]?\(?([2-9][0-8][0-9])\)?[\-\. ]?([2-9][0-9]{2})[\-\. ]?([0-9]{4})(\s*x[0-9]+)?$/,
    regex = {
        phone : {
            us : naPhone,
            ca : naPhone
        },
        postal : {
            us : /^\d{5}(-\d{4})?$/,
            ca : /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$/,
            gb : /^GIR?0AA|[A-PR-UWYZ]([0-9]{1,2}|([A-HK-Y][0-9]|[A-HK-Y][0-9]([0-9]|[ABEHMNPRV-Y]))|[0-9][A-HJKS-UW])?[0-9][ABD-HJLNP-UW-Z]{2}$/
        },
        postalCustom : /^(\d{5}([-\s]?\d{4})?|[A-Za-z]\d[A-Za-z][-\s]?\d[A-Za-z]\d)$/,
        email : /^[\w.%+\-]+@[\w.\-]+\.[\w]{2,6}$/,
        namefield : /^[a-zA-Z0-9 ]*$/
    },
    settings = {
        // global form validator settings
        errorClass : 'error',
        errorElement : 'span',
        errorPlacement: function (error, $element) {
            if ($element.attr('id') && $element.attr('id').length > 0) {
                error.attr('id', $element.attr('id') + '_aria');
            }
            if ($element.hasClass('error-source')) {
                error.appendTo($element.parents('.append-error-target'));
            } else {
                error.insertAfter($element);
            }
        },
        groups: {
            checkboxgroup: checkbox_names
        },
        highlight: function (element, errorClass) {
            var $element = $(element);
            if ($element.hasClass('custom-dropdown')) {
                $element.siblings('.select-styled').addClass(errorClass);
            } else if ($element.hasClass('error-source')){
                $element.parents('.append-error-target').find('.error-source').addClass(errorClass);
            }
            $element.addClass(errorClass);
            if ($element.attr('id') && $element.attr('id').length > 0) {
                $element.attr('aria-describedby', $element.attr('id') + '_aria');
                $element.attr('aria-invalid', 'true');
            }
        },
        unhighlight: function (element, errorClass) {
            var $element = $(element);
            if ($element.hasClass('custom-dropdown')) {
                $element.siblings('.select-styled').removeClass(errorClass);
            } else if ($element.hasClass('error-source')){
                $element.parents('.append-error-target').find('.error-source').removeClass(errorClass);
            }
            if (['dwfrm_cart_couponCode', 'dwfrm_billing_couponCode', 'dwfrm_billing_giftCertCode'].indexOf($element.attr('id')) < 0 && !$element.data('character-limit')) {
                $element.removeClass(errorClass);
                $element.removeAttr('aria-describedby');
                $element.removeAttr('aria-invalid');
            }
        },
        onkeyup : false,
        onfocusout : function (element) {
            if(!this.checkable(element)) {
                this.element(element);
            }
        }
    };

/**
 * @function
 * @description Validates if a group of checkbox has an option selected.
 * Each checkbox in the group must have the same name attribute.
 * @param {String} value The email which will be validated
 * @param {String} el The input field
 */
function validateCheckboxGroupHasOptionSelected(value, el) {
    var boxes = $(el).parents('.required-checkbox-group').find('.required-checkbox');
    var hasOptionSelected = false;
    for (var i = 0; i < boxes.length; i++) {
        if (boxes[i].checked) {
            hasOptionSelected = true;
            break;
        }
    }
    return hasOptionSelected;
}


/**
 * @function
 * @description Validates if a group of radio buttons has an option selected.
 * Each radio button in the group must have the same name attribute.
 * @param {String} value The email which will be validated
 * @param {String} el The input field
 */
function validateShippingRestriction(id, dropdown) {
    var $dropdown = $(dropdown);
    var $selectedAddress = $dropdown.find(":selected").last();
    var address = typeof($selectedAddress) !== "undefined" ? $selectedAddress.data('address') : undefined;
    $dropdown.removeClass('restriction-found');

    if(typeof(address) === "undefined") {
        return false;
    }
    var requiredFormFields = ["firstName", "lastName", "address1", "stateCode", "postalCode", "countryCode", "phone"];
    for(var i = 0; i < requiredFormFields.length; i++) {
        if(typeof(address[requiredFormFields[i]]) !== "string" || address[requiredFormFields[i]] == '') {
            return false;
        }
    }

    //if not military, city field must be validated
    if (typeof(address.custom) !== "undefined") {
        if(address.custom.addressType != "military" && ( typeof(address.city) !== "string" || address.city == '' ) ) {
            return false;
        }
        if (address.custom.hasShippingRestriction) {
            $dropdown.addClass('restriction-found');
            return false;
        }
    }

    return true;
}


/**
 * @function
 * @description Validates a given phone number against the countries phone regex
 * @param {String} value The phone number which will be validated
 * @param {String} el The input field
 */
function validatePhone(value, el) {
    var country = $(el).closest("form").find(".country");
    var rgx;
    if(country.length === 0 || country.val().length === 0 || !regex.phone[country.val().toLowerCase()]) {
        rgx = naPhone;
    } else {
        rgx = regex.phone[country.val().toLowerCase()];
    }

    var isOptional = this.optional(el);
    var isValid = rgx.test($.trim(value));

    return isOptional || isValid;
}

/**
 * @function
 * @description Validates a given email
 * @param {String} value The email which will be validated
 * @param {String} el The input field
 */
function validateEmail(value, el) {
    var isOptional = this.optional(el);
    var isValid = regex.email.test($.trim(value));
    return isOptional || isValid;
}

function validateZipCode(value, el) {
    var isOptional = this.optional(el);
    var isValid = regex.postalCustom.test($.trim(value));
    return isOptional || isValid;
}

/* Add custom zipcode validation method to jQuery validation plugin. */
$.validator.addMethod("zipcode", validateZipCode, window.Resources.INVALID_ZIP);


function validateName(value, el) {
    var isOptional = this.optional(el);
    var isValid = regex.namefield.test($.trim(value));
    return isOptional || isValid;
}

/* Add custom name field validation method to jQuery validation plugin. */
$.validator.addMethod("namefield", validateName, window.Resources.INVALID_TEXT);

/**
 * Add phone validation method to jQuery validation plugin.
 * Text fields must have 'phone' css class to be validated as phone
 */
$.validator.addMethod("phone", validatePhone, window.Resources.INVALID_PHONE);



/**
 * Add email validation method to jQuery validation plugin.
 * Text fields must have 'email' css class to be validated as email
 */
$.validator.addMethod("email", validateEmail, window.Resources.INVALID_EMAIL);

/* Add custom checkbox validation method to jQuery validation plugin. */
$.validator.addMethod("required-checkbox", validateCheckboxGroupHasOptionSelected, window.Resources.INVALID_RADIO);

/* Add custom validation for shipping restrictions to jQuery validation plugin. */
$.validator.addMethod("select-address", validateShippingRestriction, window.Resources.INVALID_RADIO);

/**
 * Add gift cert amount validation method to jQuery validation plugin.
 * Text fields must have 'gift-cert-amont' css class to be validated
 */
$.validator.addMethod("gift-cert-amount", function(value, el) {
    var isOptional = this.optional(el);
    var isValid = (!isNaN(value)) && (parseFloat(value) >= 5) && (parseFloat(value) <= 5000);
    return isOptional || isValid;
}, window.Resources.GIFT_CERT_AMOUNT_INVALID);

/**
 * Add positive number validation method to jQuery validation plugin.
 * Text fields must have 'positivenumber' css class to be validated as positivenumber
 */
$.validator.addMethod("positivenumber", function (value, element) {
    if($.trim(value).length === 0) { return true; }
    return (!isNaN(value) && Number(value) >= 0);
}, window.Resources.QTY_ERROR);
// "" should be replaced with error message if needed

//State, province, and region use the same input field but the validation is dynamic and based on class
$.validator.addMethod('state', function(value, element) {
    if (/^(?:AL|AK|AS|AZ|AR|CA|CO|CT|DE|DC|FL|GA|GU|HI|ID|IL|IN|IA|KS|KY|LA|ME|MD|MA|MI|MN|MS|MO|MT|NE|NV|NH|NJ|NM|NY|NC|ND|OH|OK|OR|PA|PR|RI|SC|SD|TN|TX|UT|VT|VI|VA|WA|WV|WI|WY|AA|AE|AP|AB|BC|MB|NB|NL|NT|NS|NU|ON|PE|QC|SK|YT|OTHER)$/.test($.trim(value))) {
        return true;
    } else {
        return false;
    }
}, window.Resources.INVALID_STATE);


$.validator.addMethod('province', function(value, element) {
    if (/^(?:AB|BC|MB|NB|NL|NT|NS|NU|ON|PE|QC|SK|YT)$/.test($.trim(value))) {
        return true;
    } else {
        return false;
    }
}, window.Resources.INVALID_PROVINCE);

$.validator.addMethod('region', function(value, element) {
    if (/^(?:AE|AA|AP)$/.test($.trim(value))) {
        return true;
    } else {
        return false;
    }
}, window.Resources.INVALID_REGION);

$.validator.addClassRules('state', {
    required: false, //prevent the default messaging (functionality still prevents an empty field)
    state: true
});

$.validator.addClassRules('province', {
    required: false,
    province: true
});

$.validator.addClassRules('region', {
    required: false,
    region: true
});

$.validator.messages.required = function ($1, ele, $3) {
    var requiredText = $(ele).parents('.form-row').attr('data-required-text');
    return requiredText || "";
};

var validator = {
    regex : regex,
    settings : settings,
    init : function () {
        $("form:not(.suppress)").each(function () {
            $(this).validate(validator.settings);
        });
    },
    initForm : function(f) {
        $(f).validate(validator.settings);
    }
};

module.exports = validator;
