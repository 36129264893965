'use strict';

var floatlabels = require('../../float-labels');
var util = require('../../util');
var dialog = require('../../dialog');
var page = require('../../page');
var quickview = require('../../quickview');
var bonusproductsview = require('../../bonusproductsview');
var tealium = require('../../tealium');
var ajax = require('../../ajax');
var validator = require('../../validator');
var progress = require('../../progress');
var shipping = require('./shipping');
var localStorageAddress = require('../../localStorageAddress');
var addressupdate = require('../address');

var $cache = {};

var multiship = {
        handleRestrictions: function () {
            util.handleRestrictions();
        },
        /**
         * @function
         * @description shows gift message box in multiship, and if the page is the multi shipping address page it will call initmultishipshipaddress() to initialize the form
         */
        initAddressPage: function() {
            initializeAddressPageCache();
            localStorageAddress.multiShip.restore();
            $(document).on('click', '.suggested-products-modal a.checkout-select', function () {
                localStorageAddress.multiShip.save();
            });
            $(document).on('click', 'button[name$="confirmRemoval_save"]', function () {
                localStorageAddress.multiShip.save();
            });
            var $baseAddressForm = $('form[id$="multishipping_addressSelection"]'),
            $addressDropdown = $baseAddressForm.find('.item-list select[name$=_addressList]'),
            $billingAddressInputs = $('input[name$="_useAsBillingAddress"]'),
            $continueButton = $('[name$="addressSelection_save"]');

            $baseAddressForm.find('.hf-use-billing input').attr('checked','checked');

            util.formCustomValidation($('.checkout-multi-shipping'));
            checkContinueButton($addressDropdown);

            $baseAddressForm.on('keydown', 'input', function(event){
                if (event.key == "Enter") {
                    //submit the form based on which CTA is visible and enabled
                    $baseAddressForm.find('.multi-address-cta[type="submit"]:visible:enabled').first().trigger('click');
                    return; //prevent default behavior
                }
            });

            $("input[name$='_address1']").attr('autocomplete', 'address-line1');


            $('body').on('click', '.multi-address-cta', function(e) {
                var $addressList = $('.inc-address-list select[name$="_addressList"]');

                var isValid = util.formCustomValidation($('.checkout-multi-shipping'));
                if (!isValid) {
                    e.preventDefault();
                }
                if ($addressList.val() === '') {
                    e.preventDefault();

                    $addressList.addClass('error');
                    var $styledSelect = $addressList.parent().find('.select-styled');
                    $styledSelect.addClass('error');
                }
            });

            // handle page load initialization of the error blocks
            $addressDropdown.each(function() {
                var currentDropdown = $(this);
                var currentDropdownName = currentDropdown.attr('name');
                var currentLineItem = currentDropdown.parent().parent();
                var selectedOption = currentDropdown.find('option:selected').last();

                var shipmentId = currentLineItem.data('shipment-id');
                var productLineItemId = currentLineItem.data('line-item-id');
                var productId = currentLineItem.find('td.item-details div.sku span.value').text().replace('#', '');

                // grab the selected address
                var selectedOptionAddress = selectedOption.data('address') || '';
                var addressObject = (selectedOptionAddress != '') ? createAddressObject(selectedOptionAddress) : '';

                var isBillingAddress = currentDropdown.parent().find('input[name=useAsBillingAddress]:checked').length > 0;
                setBillingAddress(null);
            });

            $('.ship-to-row').find('select.select-address').each(function () {
                var currentSelect = $(this);
                var selectedOption = currentSelect.find('option:selected').last();
                var selectedOptionAddress = selectedOption.data('address') || '';
                if (selectedOptionAddress && selectedOptionAddress != '') {
                    var isValid = util.addressObjectValidation(selectedOptionAddress);
                    if (!isValid) {
                        currentSelect.addClass('error');
                        util.initCustomDropdowns(true);
                        currentSelect.parent().find('.select-styled').addClass('error');
                    }
                }
            });

            $addressDropdown.on('change', function(event) {
                event.preventDefault();

                var currentDropdown = $(this);
                var currentDropdownName = currentDropdown.attr('name');
                var currentLineItem = currentDropdown.parent().parent();
                var selectedOption = currentDropdown.find('option:selected').last();
                var $checkoutRow = currentDropdown.parents('.checkout-row');
                var shipmentId = currentLineItem.data('shipment-id');
                var productLineItemId = currentLineItem.data('line-item-id');
                var productId = currentLineItem.find('td.item-details div.sku span.value').text().replace('#', '');

                // grab the selected address
                var selectedOptionAddress = selectedOption.data('address') || '';
                var addressObject = (selectedOptionAddress != '') ? createAddressObject(selectedOptionAddress) : '';
                var addressId = currentDropdown.val();
                var dropdownIsValid = currentDropdown.valid();

                $checkoutRow.removeClass('shipping-restriction');

                if (addressId !== '' && addressObject !== '' && dropdownIsValid) {
                    // hide any possible dropdown error messages
                    var errmsg = currentDropdownName + "_errormsg";
                    $baseAddressForm.find("[name='" + errmsg + "']").addClass('error-hide');
                    $baseAddressForm.find("[name='" + currentDropdownName + "']").removeClass('error');

                    checkContinueButton($addressDropdown);
                } else {
                    //check if invalid dropdown is because of a shipping restriction
                    if (currentDropdown.hasClass('restriction-found')) {
                        $checkoutRow.addClass('shipping-restriction');
                    }
                    checkContinueButton($addressDropdown);
                }

                var isBillingAddress = currentDropdown.parent().find('input[name=useAsBillingAddress]:checked').length > 0;
                if (isBillingAddress && dropdownIsValid) {
                    setBillingAddress(selectedOption.data('address'));
                }else{
                    setBillingAddress(null);
                }
            });

            $('input[name=useAsBillingAddress]').click(function(event) {
                var $radio = $(this);

                // if this was previously checked
                if ($radio.data('waschecked') == true) {
                    $radio.prop('checked', false);
                    $radio.data('waschecked', false);
                }else{
                    $radio.data('waschecked', true);
                }
                // remove was checked from other radios
                $baseAddressForm.find('input[name=useAsBillingAddress]').not($radio).data('waschecked', false);

                var isBillingAddress = $(this).parent().find('input[name=useAsBillingAddress]:checked').length > 0;
                if (isBillingAddress) {
                    var selectedOption = $(this).parent().siblings('select[name$=_addressList]').find('option:selected');
                    if (selectedOption.length > 0) {
                        setBillingAddress(selectedOption.data('address'));
                    }
                }else{
                    setBillingAddress(null);
                }
            });

            //Set the floating label based on selected address and determine if the user selected "Add A New Address" in the dropdown menu
            $('.ship-to-row select.select-address').change(function() {
                var $selectedOption = $(this).find('option:selected');
                var address = $selectedOption.data('address');
                var labelText = '';
                if ($selectedOption && $selectedOption.data('addaddress')) {
                    //Set dropdown to "select an address"
                    //Set styled drodown to "select an address"
                    $(this).siblings('.select-options').children().first().trigger('click');
                    //Trigger add/edit address modal (defaults to add address since no address is selected)
                    $(this).parents('.ship-to-row').find('.edit-address a').trigger('click');
                } else if (address && address.ID) {
                    labelText = address.ID;
                }
                //Label is replaced by the currently selected address in this dropdown
                $('label[for="' + $(this).attr('id')  + '"] span').text(labelText);
            });

            $('.edit-address').on('click', 'a', function (e) {
                e.preventDefault();
                var addrTypeCheckboxState = getAddressTypeCheckboxState();
                if (addrTypeCheckboxState === 'single') {
                    addressTypeCheckboxHandler('multi');
                }
                $.ajax({
                    dataType: 'json',
                    url: window.Urls.validateSession,
                    async: false
                }).always(function (data) {
                    if (data && !data.success && data.redirectURL) {
                        window.location.href = data.redirectURL;
                    }
                });
                var $button = $(this).parents('.ship-to-row').find('.edit');
                var address = $(this).parents('.ship-to-row').find('.select-address option:selected');
                if (address.length) {
                    address = address.data('address');
                } else {
                    return;
                }
                var addressDialogOptions = {
                    width: 659,
                    draggable: false,
                    resizable: false,
                    openInCallback: true,
                    dialogClass: 'multishipping-edit-address',
                    close: function () {
                        $(this).remove();
                        $button.focus();
                    },
                    open: function () {
                        $('html, body').animate({
                            scrollTop: $(this).parent().offset().top
                        }, 10);
                        $(this).parent().attr('role', 'dialog').attr('aria-modal', 'true');
                        var title = $('.multishipping-edit-address h1').remove();
                        $('.ui-dialog-title').html(title);
                        $(this).find('.hf-select-address').hide();
                        var editAddressUUID = null;
                        util.handleAddressTypeChange('#edit-address-form');
                        addEditAddress(e.target);
                        //insert timestamp into hidden AddressID field - rest of Avalara functionality requires an addressID
                        $(".multishipping-edit-address #address input[name*='addressid']").val(new Date().getTime());

                        if(address) {
                            $('.ui-dialog-title').html('<h1>Edit Address</h1>');
                            //Selects the already defined address in the dropdown of the edit address modal
                            $(this).find('.select-address select').val(address['UUID']).trigger('change');
                            editAddressUUID = address['UUID'];
                            $(this).find("select[name$='_addressType']").val(address.custom['addressType']).trigger('change');
                            //Business
                            if(address.custom['addressType'] == "business" && address.companyName != null) {
                                $(this).find("input[name$='_companyName']").val(address.companyName);
                            }
                            $(this).find("select[name$='_country']").val(address['countryCode']).trigger('change');
                            //Military
                            if(address['stateCode'] == "AE" || address['stateCode'] == "AA" || address['stateCode'] == "AP") {
                                $(this).find("select[name$='_addressType']").val('military').trigger('change');
                                $(this).find("select[name$='_postOffce']").val(address['city']).trigger('change');
                                util.updateStateOptions($(this).find("select[name$='_country']")[0], true);
                            } else {
                                util.updateStateOptions($(this).find("select[name$='_country']")[0], false);
                            }
                            $(this).find("input[name$='_addressid']").val(address.ID).parent().hide();
                            $(this).find("input[name$='_addToAddressBook']").val(true).parent().remove();
                            $(this).find("input[name$='_firstName']").attr('value',address['firstName']);
                            $(this).find("input[name$='_lastName']").attr('value',address['lastName']);
                            $(this).find("input[name$='_address1']").attr('value',address['address1']);
                            $(this).find("input[name$='_address2']").attr('value',address['address2']);
                            $(this).find("input[name$='_city']").attr('value',address['city']);
                            $(this).find("select[name$='_state']").val(address['stateCode']).trigger('change');
                            $(this).find("input[name$='_zip']").attr('value',address['postalCode']);
                            $(this).find("input[name$='_phone']").attr('value',address['phone']);
                            $('button[name$="_editAddress_save"]').text('Update Address');
                        } else {
							$(this).find("select").val('');
						}
                        var $dialogContainer = $(this);

                        var formFieldName = $('#edit-address-form').prop('name');
                        $('#edit-address-form').prop('name', formFieldName + '_disableautocomplete');
                        floatlabels.init();
                        window.setTimeout(function () {
                            $("input[name$='_address1']").attr('autocomplete', 'address-line1');
                        }, 500);

                        $('button[name$="_editAddress_save"]').on('click', function (e) {
                            e.preventDefault();
                            util.formCustomValidation($('#edit-address-form'));
                            var formFieldName = $('#edit-address-form').prop('name');
                            $('#edit-address-form').prop('name', formFieldName.replace('_disableautocomplete', ''));
                            var $addressForm = $dialogContainer.find('form');
                            if (!$addressForm.valid()) {
                                return;
                            }
                            var onSuccess = function () {
                                util.formCustomValidation($('#edit-address-form'));
                                if ($('#edit-address-form').valid()) {
                                    $addressForm.submit();
                                }
                                $dialogContainer.dialog('close');
                            };

                            var onEdit = function () {
                                shipping.fillInDataFromAVSEdit($addressForm, $dialogContainer.find('.newAddress'));
                            };

                            var onError = function () {
                                // close modal but don't trigger button
                                $dialogContainer.dialog('close');
                            };
                            var url = window.Urls.avalaraValidateMultiShippingAddress + '?format=ajax';
                            if (editAddressUUID) {
                                url = url + '&editAddressUUID=' + editAddressUUID;
                            }
                            $.ajax({
                                type: 'POST',
                                data: $addressForm.serialize(),
                                url: url
                            }).done(function (response) {
                                if (typeof response === 'string') {
                                    $dialogContainer.prop('id', 'address-verification').parent().addClass('address-verification');
                                    $addressForm.hide();
                                    $dialogContainer.find('.empty-validation-container').html(response);
                                    $dialogContainer.dialog({
                                        position: {my: 'center', at: 'center', of: window}
                                    });
                                    shipping.initializeAvalaraAddressForm($dialogContainer.find('.empty-validation-container'), onSuccess, onError, onEdit, 'multishipping');
                                } else if (response.success && response.address) {
                                    shipping.acceptAndSubmit(response.address);
                                    onSuccess();
                                } else if (response.success) {
                                    onSuccess();
                                } else {
                                    onError();
                                }
                            });
                        });
                    }
                };
                $cache.basedialog = dialog.open({url: this.attributes.href.value, options: addressDialogOptions});
            });

            $billingAddressInputs.on('change', function() {
                $billingAddressInputs.not(this).removeAttr('checked');
            });

            multiship.initShippingRestrictions();
            util.initCustomDropdowns(true);
            util.restoreScrollPosition();

            var radioGroup = $('.ship-to-multiple-block');
            var radioButtons = radioGroup.find('.input-checkbox');

            radioButtons.each(function() {
                $(this).on('click', function(e) {
                    selectRadioButton(e.target, true);
                });
            });

            radioGroup.on('keyup', function(e) {
                switch(e.key) {
                    case 'ArrowUp':
                    case 'ArrowLeft':
                        e.preventDefault();
                        selectPreviousRadioButton(e.target);
                        break;

                    case 'ArrowDown':
                    case 'ArrowRight':
                        e.preventDefault();
                        selectNextRadioButton(e.target);
                        break;

                    case ' ':
                        selectRadioButton(e.target, true);
                        break;
                }
            });

            function selectPreviousRadioButton(radioButton) {
                var index = radioButtons.index($(radioButton));

                if (index > 0) {
                    selectRadioButton(radioButtons[index - 1]);
                } else {
                    selectRadioButton(radioButtons[radioButtons.length - 1]);
                }
            }

            function selectNextRadioButton(radioButton) {
                var index = radioButtons.index($(radioButton));

                if (index < radioButtons.length - 1) {
                    selectRadioButton(radioButtons[index + 1]);
                } else {
                    selectRadioButton(radioButtons[0]);
                }
            }

            function selectRadioButton(radioButton, avoidClick) {
                // Deselect all other radio buttons
                radioButtons.each(function() {
                    $(this).attr('tabindex', -1);
                });

                // Select the provided radio button
                $(radioButton).attr('tabindex', 0);
                if (!avoidClick) {
                    $(radioButton).trigger('click');
                }
                $(radioButton).focus();
            }

            //toggle content based on ship to single/multi shipping address selection
            var $shipToBlock = $('.ship-to-multiple-block');
            $shipToBlock.find('input[type="checkbox"]').on('click', function(e){
                //make these checkboxes behave like radio buttons, cannot be unchecked
                if (!this.checked){
                    e.preventDefault();
                    e.stopPropagation();
               }
            }).on('change', function(e, initialLoad) {
                var $singleShipWrapper = $('.ship-to-single-wrapper');
                var $addressDropdowns = $('.shippingaddress .form-row');
                var $quantityFields = $('.qty-col input');
                var $addressEditLinks = $('.ship-to-row .edit-address');
                var $removeAddress = $('.ship-to-row .remove-address-wrapper');
                var $additionalAddress = $('.shippingaddress .ship-to-another-address-wrapper');
                var $shipToColHeader = $('.section-header.ship-to-col');

                var $checkbox = $(this);

                if (!this.checked) {
                    return;
                }

                //Clear all checkboxes (behave like radio buttons)
                $shipToBlock.find('input[type="checkbox"]').prop('checked', false);
                this.checked = true; //set this checkbox back to true

                var isSingleShipFlow = $checkbox.attr('id').indexOf('shipToSingleAddress') != -1;
                if (isSingleShipFlow && !initialLoad) {
                    $singleShipWrapper.show();
                    $addressDropdowns.hide();
                    $addressEditLinks.hide();
                    $removeAddress.hide();
                    $additionalAddress.hide();
                    $shipToColHeader.hide();
                } else {
                    $addressDropdowns.each(function () {
                       var $el = $(this);
                       var $addrSelect = $el.find('.select-address');

                       if ($addrSelect.val() === '' && $addrSelect.hasClass('error')) {
                            $addrSelect.removeClass('error');
                            $addrSelect.parent().find('.select-styled').removeClass('error');
                       }
                    });
                    $singleShipWrapper.hide();
                    $addressDropdowns.show();
                    if (isSingleShipFlow && initialLoad) {
                        //Trigger change in case there was a shipping restricted selected
                        $addressDropdowns.find('select').trigger('change');
                        var nonRestrictedDropdowns = $('.checkout-row:not(.shipping-restriction) .shippingaddress .form-row');
                        nonRestrictedDropdowns.addClass('completed');
                        $quantityFields.addClass('completed');
                    } else {
                        $addressDropdowns.removeClass('completed');
                        $quantityFields.removeClass('completed');
                        $addressEditLinks.show();
                        $shipToColHeader.show();
                        $removeAddress.show();
                        $additionalAddress.show();
                    }
                }
            });

            //Triggers hide/show of content based on the saved form data
            $shipToBlock.find('input[type="checkbox"]').trigger('change', [true]);

           $('.ship-to-another-address-link, .remove-address-dropdown').on('click', function(){
               var $this = $(this);
               $('.hidden-selectadddressid').val($this.attr('data-selectedaddressid')); //use attr() because null must be grabbed as a string
               $('.hidden-pliuuid').val($this.data('pliuuid'));
               $('.scroll-position').val($(window).scrollTop());
            });

            $(".checkoutmultishipping").on("click", ".item-edit-details a", function (e) {
                e.preventDefault();
                quickview.show({
                    url : e.target.href,
                    source : "cart"
                });
            })
            .on("click", ".bonus-item-actions a", function (e) {
                e.preventDefault();
                bonusproductsview.show(this.href);
            });

            $('.checkoutmultishipping').on("click", ".shipping-restrictions-msg a.multiaddr-product-edit", function (e) {
                e.preventDefault();
                show({
                    url : e.target.href,
                    source : "multiaddress",
                    lastActive: $(this)
                });
            });

            $('.checkoutmultishipping').on("click", ".shipping-restrictions-msg .button-replace", function (e) {
                e.preventDefault();
                if ($('#ShippingRestrictionViewDialog').is(':visible')) {
                    return;
                }
                var $el = $(this);
                show({
                    url : e.target.href,
                    source : "multiaddress",
                    lastActive: $el
                });
                $('.tealium-clicked').removeClass('tealium-clicked');
                $el.parent().addClass('tealium-clicked');
            });

            // launch confirm product removal dialog
            $('.checkoutmultishipping').on("click", ".shipping-restrictions-msg a.multiaddr-product-removal", function (e) {
                e.preventDefault();
                showConfirmRemovalDialog(e.target.href);
            });

            // launch confirm product removal dialog
            $('.checkoutmultishipping').on("click", ".shipping-restrictions-msg .button-remove", function (e) {
                e.preventDefault();
                showConfirmRemovalDialog(e.target.href);
                $('tealium-clicked').removeClass('tealium-clicked');
                $(this).parent().addClass('tealium-clicked');
            });

            // check for addresses in dropdowns before allowing a continue
            $baseAddressForm.on("click", "[name$=_addressSelection_save]", function (e) {
                e.preventDefault();
                var returntype = true;
                var name = '';
                var errname = '';
                var isFirstEl = false;
                var $body = $('body');
                var headerHeight = window.isMobileUserAgent ? $('#hf-header').height() * 1.7 : $('.full-header-wrapper').height();
                var $shipToMultiple = $('.ship-to-multiple-block');
                var $shipmentCheckboxes = $shipToMultiple.find('input[type="checkbox"]');
                var $shipmentsError = $shipToMultiple.find('.ship-multiple-error');
                var $emailInput = $('input[name*="_email_emailAddress"]');
                var $singleShipForm = $('.ship-to-single-wrapper');

                if (!$shipmentCheckboxes.is(':checked')) {
                    $shipmentCheckboxes.addClass('error');
                    $shipmentsError.removeClass('d-none');

                    $('html, body').animate({
                        scrollTop: $shipToMultiple.offset().top - $body.offset().top - headerHeight
                    }, 500);

                    returntype = false;
                    isFirstEl = true;
                } else {
                    $shipmentCheckboxes.removeClass('error');
                    $shipmentsError.addClass('d-none');
                }

                if ($('.input-checkbox[name*="_shipToSingleAddress"]').is(':checked')) {
                    if ($singleShipForm.is(':visible')) {
                        $singleShipForm.find('.addr-form-error').removeClass('d-none');

                        if (returntype) {
                            $('html, body').animate({
                                scrollTop: $singleShipForm.find('.addr-form-error').offset().top - $body.offset().top - headerHeight
                            }, 500);
                            isFirstEl = true;
                        }

                        returntype = false;
                    }
                }

                if ($emailInput.val() === '') {
                    $singleShipForm.find('.multi-address-cta').click();
                    $emailInput.valid();
                    if (returntype) {
                        $('html, body').animate({
                            scrollTop: $emailInput.offset().top - $body.offset().top - headerHeight
                        }, 500);
                        isFirstEl = true;
                    }

                    returntype = false;
                }

                $addressDropdown.each( function () {
                    var $addrEl = $(this);
                    var $styledSelect = $addrEl.parent().find('.select-styled');

                    if ($addressDropdown.hasClass('error')) {
                        if(!isFirstEl) {
                            $('html, body').animate({
                                scrollTop: $styledSelect.offset().top - $body.offset().top - headerHeight
                            }, 500);
                            isFirstEl = true;
                        }

                        returntype = false;
                    }

                    name = $addrEl.attr("name");
                    errname = name + "_errormsg";
                    if ($addrEl.val() === '') {
                        var errmsg = $baseAddressForm.find("[name='" + errname + "']");
                        errmsg.removeClass('error-hide');
                        $addrEl.addClass('error');

                        $styledSelect.addClass('error');

                        if (!isFirstEl) {
                            $('html, body').animate({
                                scrollTop: $styledSelect.offset().top - $body.offset().top - headerHeight
                            }, 500);
                            isFirstEl = true;
                        }

                        returntype = false;
                    }
                });

                if (returntype && $('.shipping-restriction').length > 0) {
                    var elem = $('.shipping-restriction').first();
                    $('html, body').animate({
                        scrollTop: elem.offset().top - $body.offset().top - headerHeight
                    }, 500);
                    returntype = false;
                }

                if (returntype) {
                    $baseAddressForm.append('<input type="hidden" name="dwfrm_multishipping_addressSelection_save"/>');
                    $baseAddressForm.submit();
                }
            });

            function checkContinueButton($addressFields) {
                multiship.handleRestrictions();

                var $continueButton = $('[name$="addressSelection_save"]')[0];
                if (!isAnyAddressFieldEmpty($addressFields) && !isAnyAddressRestricted()) {
                    $continueButton.disabled = false;
                } else {
                    $continueButton.disabled = true;
                }

            }

            function isAnyAddressFieldEmpty(addressFields) {
                var addresses = addressFields.map(function() {
                    return $(this).val();
                });
                return $.inArray('', addresses) >= 0;
            }

            function isAnyAddressRestricted() {
                var isAddressRestricted = $('.checkout-row.shipping-restriction').length > 0;
                return isAddressRestricted;
            }

            function createAddressObject (dropdownAddressObject) {
                var addressObject = {};
                if (dropdownAddressObject &&
                        dropdownAddressObject.address1 &&
                        dropdownAddressObject.city &&
                        dropdownAddressObject.stateCode &&
                        dropdownAddressObject.countryCode) {
                    addressObject.address1 = dropdownAddressObject.address1.trim();
                    if(typeof(dropdownAddressObject.address2) === "string") {
                        // address includes address 2 value
                        addressObject.address2 = dropdownAddressObject.address2.trim();
                    }else{
                        addressObject.address2 = '';
                    }
                    addressObject.city = dropdownAddressObject.city.trim();
                    addressObject.stateCode = dropdownAddressObject.stateCode.trim();
                    addressObject.countryCode = dropdownAddressObject.countryCode.trim();
                } else {
                    // no address selected or unrecognized format, use an empty default to call check shipping
                    console.warn('Warning - unrecognized format address format in createAddressObject()');
                    addressObject.address1 = '';
                    addressObject.address2 = '';
                    addressObject.city = '';
                    addressObject.stateCode = 'CA';
                    addressObject.countryCode = 'US';
                }
                return addressObject;
            }

            function setBillingAddress(address) {
                $.ajax({
                    type : 'POST',
                    dataType : 'json',
                    cache   : false,
                    contentType : 'application/json',
                    url : window.Urls.setBillingAddress,
                    data: JSON.stringify({
                        addressObject: address ? {
                            firstName: address.firstName,
                            lastName: address.lastName,
                            phone: address.phone,
                            address1: address.address1,
                            address2: address.address2,
                            city: address.city,
                            stateCode: address.stateCode,
                            postalCode: address.postalCode,
                            countryCode: address.countryCode,
                            custom: {
                                addressType: address.custom.addressType
                            }
                        } : null
                    })
                });
            }

            /**
             * @function
             * @description Opens the bonus product quick view dialog
             */
            function showConfirmRemovalDialog (url) {
                // add element to cache if it does not already exist
                var dialogTarget = initproductdialog();

                // create the dialog
                $cache.confirmRemovalDialog = dialog.create({
                    target : dialogTarget,
                    options : {
                        width: 500,
                        dialogClass : 'removeConf',
                        title : ''
                    }
                });

                // load the products then show
                ajax.load({
                    target : dialogTarget,
                    url : url,
                    callback : function () {
                        $cache.confirmRemovalDialog.dialog('open');
                        $('.removeConf').find('h1').remove();
                        bonusproductsview.initializeGrid();
                        $cache.confirmRemovalDialog.find('button[name$="confirmRemoval_save"]').focus();
                        $cache.confirmRemovalDialog.find('button[name$="confirmRemoval_cancel"]').one('click', function(e){
                            e.preventDefault();
                            $cache.confirmRemovalDialog.dialog('close');
                        });
                    }
                });

            }

            function show (options) {
                options.target = initproductdialog();
                options.callback = function () {
                    recommendedProductsCarousel();
                    shipping.suggestedProductsCheckout();
                    $cache.shippingrestrictiondialogview.dialog('open');
                    var $closeBar = $('.suggested-products-dialog a.ui-dialog-titlebar-close');
                    $closeBar.on('keydown', function (e) {
                        e.stopImmediatePropagation();
                        e.preventDefault();

                        if (e.which === 9) {
                            $('.suggested-products-modal h2').focus();
                        }
                    });

                    $closeBar.on('keyup', function (e) {
                        e.stopImmediatePropagation();
                        e.preventDefault();

                        if (e.which === 13) {
                            $('#ShippingRestrictionViewDialog').dialog('close');

                            if (options.lastActive) {
                                options.lastActive.focus();
                            }
                        }
                    });
                };
                quickview.getProductView(options);
                return $cache.shippingrestrictiondialogview;
            }

            function initproductdialog () {
                if (($cache.shippingrestrictiondialogview) && ($cache.shippingrestrictiondialogview.length > 0)) {
                    return $cache.shippingrestrictiondialogview;
                }

                $cache.shippingrestrictiondialogview = $("<div/>").attr("id", "ShippingRestrictionViewDialog").appendTo(document.body);

                return $cache.shippingrestrictiondialogview;

            }

            /**
             * @function
             * @description Initializes the recommended product carousel on the checkout page
             */
            function recommendedProductsCarousel() {
                var timeout = 0;
                window.setTimeout(function () {
                    var carouselBlock;
                    if ($("[id^='cq_recomm_slot']").length > 0) {
                        carouselBlock = $("#hf-pdpMain [id^='cq_recomm_slot'] #hf-cfcarousel");
                    } else {
                        carouselBlock = $("#hf-pdpMain #hf-cfcarousel");
                    }

                    carouselBlock.slick({
                        lazyLoad: 'ondemand',
                        slidesToShow: 2,
                        infinite: false,
                        responsive: [{
                            breakpoint: 959,
                            settings: {
                                slidesToShow: 2
                            }
                        },
                        {
                            breakpoint: 735,
                            settings: {
                                slidesToShow: 1,
                                dots: true,
                                arrows: true,
                                appendDots: $('#hf-pdpMain .hf-pdp-recommendations-slider-nav'),
                                appendArrows: $('#hf-pdpMain .hf-pdp-recommendations-slider-nav')
                            }
                        }]
                    });
                }, timeout);
            }

            $(window).on('load', function() {
                var restrictionMsg = $('.shipping-restrictions-msg:visible').last();
                if(restrictionMsg.length) {
                    $('html, body').animate({
                        scrollTop: (restrictionMsg.offset().top + 25) - ( $(window).height() - restrictionMsg.height() )
                    }, 10);
                }
            });

            $('.checkoutmultishipping').on("click", ".shipping-restrictions-msg .button-replace", function (e) {
                e.preventDefault();
                if($('.checkoutmultishipping').length) {
                    $('body').on('click', '#confirm-removal-form button[name$="confirmRemoval_save"]', function () {
                        tealium.cartRemove($('.tealium-clicked').data('tealium'), ["1.00"]);
                    });

                    $('body').on('click', '.select-button', function () {
                        tealium.cartAdd($(this).data('tealium-replacement'), ["1.00"]);
                    });
                }
                $('tealium-clicked').removeClass('tealium-clicked');
                $(this).parent().addClass('tealium-clicked');
            });

            $('.checkoutmultishipping').on("click", ".shipping-restrictions-msg .button-remove", function (e) {
                e.preventDefault();
                if($('.checkoutmultishipping').length) {
                    $('body').on('click', '#confirm-removal-form button[name$="confirmRemoval_save"]', function () {
                        tealium.cartRemove($('.tealium-clicked').data('tealium'), ["1.00"]);
                    });
                }
                $('tealium-clicked').removeClass('tealium-clicked');
                $(this).parent().addClass('tealium-clicked');
            });

            restoreFormState();

            shipping.initForm();
            shipping.initializeAvalaraFunctionality('multisingleship');

            var addressTypeCheckboxes = $('.ship-to-multiple-block').find('input[type="checkbox"]');
            addressTypeCheckboxes.on('change', function () {
                $('.ship-multiple-error').hide();
                addressTypeCheckboxes.removeClass('error');
            });
            return null;
        },

        /**
         * @function
         * @description shows gift message box in multiship, and if the page is the multi shipping address page it will call initmultishipshipaddress() to initialize the form
         */
        initShippingMethodsPage: function() {
            initializeShippingMethodsCache();
            multiship.initShippingRestrictions();
            applyShippingDiscounts();
        },

        initShippingRestrictions: function() {
            $('.shipping-restrictions').on('click', function() {
                var $restrictionsLink = $(this);
                var container = $restrictionsLink.parent();
                var $shippingDialog = $restrictionsLink.children('.shipping-modal').dialog({
                    height: 500,
                    width: 500,
                    modal: true,
                    draggable: false,
                    resizable: false,
                    dialogClass: 'shippingModal',
                    title: 'Shipping Policy',
                        appendTo: container
                    });

                    $restrictionsLink.click(function() {
                        $shippingDialog.dialog();
                    });
            });
        }
};

/*          Private functions           */
//Multishipping Address Page only
function initializeAddressPageCache() {
    //Get cache that is shared between single/multishipping
    $cache = shipping.getCheckoutCache();

    //Add Multishipping Address Page only selectors here
}

function restoreFormState() {
    if ($('#selectedAddressType').length > 0) {
        var addrType = $('#selectedAddressType').val();
        addressTypeCheckboxHandler(addrType);
    }
    localStorageAddress.shippingType.restore();
}

function addressTypeCheckboxHandler(addrType) {
    if (addrType === 'single') {
        // if checkbox is already checked - reset checked state and trigger
        // click which will set checked state and will open address form
        if ($('input[name$="_shipToSingleAddress"]').is(':checked')) {
            $('input[name$="_shipToSingleAddress"]').trigger('click');
        }
        $('input[name$="_shipToSingleAddress"]').trigger('click');
    } else if (addrType === 'multi') {
        // if checkbox is already checked - reset checked state and trigger
        // click which will set checked state and will open address form
        if ($('input[name$="_shipToMultipleAddresses"]').is(':checked')) {
            $('input[name$="_shipToMultipleAddresses"]').trigger('click');
        }
        $('input[name$="_shipToMultipleAddresses"]').trigger('click');
    }
}

function getAddressTypeCheckboxState() {
    var addrType = null;
    if ($('input[name$="_shipToSingleAddress"]').is(':checked')) {
        addrType = 'single';
    } else if ($('input[name$="_shipToMultipleAddresses"]').is(':checked')) {
        addrType = 'multi';
    }
    return addrType;
}

//Multishipping Shipping Methods Page only
function initializeShippingMethodsCache() {
    //Get cache that is shared between single/multishipping
    $cache = shipping.getCheckoutCache();

    //Add Multishipping Shipping Methods Page only selectors here
    $cache.multishippingMethods = $(".shipping-method-list");
}

function updateAddressSelect(newOptionInnerHtml, pliAddress, $addrSelect, $targetselect) {
    const isMultiShipSelect = $addrSelect.find('option[value="add-address"]').length > 0;
    const $editOption = $addrSelect.find('option[value="' + pliAddress.UUID + '"]');

    if ($editOption.length < 1) {
        var dataaddress = "'" + JSON.stringify(pliAddress) + "'";
        var newOption = '<option value="' + pliAddress.UUID + '" data-address=' + dataaddress + '>' + newOptionInnerHtml + '</option>';
        if (isMultiShipSelect) {
            // multi ship adress select
            $(newOption).insertBefore($addrSelect.find('option[value="add-address"]'));
            if ($addrSelect.data('qliid') == $targetselect.data('qliid')) {
                $addrSelect.find('option[value="' + pliAddress.UUID + '"]').prop('select', true);
                $addrSelect.trigger('change');
            }
        } else {
            // single ship address select
            $(newOption).insertBefore($addrSelect.find('option[value="newAddress"]'));
        }

        $('.no-address').hide();
    } else {
        //If we are Editting an address, we replace the data-address and innerHTML of the option
        //This will effect all the address dropdowns in multiship
        if (isMultiShipSelect) {
            $addrSelect.find('option[value="' + pliAddress.UUID + '"]').data('address', pliAddress).text(newOptionInnerHtml).trigger('change');
        } else {
            $addrSelect.find('option[value="' + pliAddress.UUID + '"]').data('address', pliAddress).text(newOptionInnerHtml);
        }

    }
}

/**
 * @function
 * @description capture add edit adddress form events
 */
function addEditAddress(target) {
    var $addressForm = $('form[name$="multishipping_editAddress"]'),
    $addressDropdown = $addressForm.find('select[name$=_addressList]'),
    $addressList = $addressForm.find('.address-list'),
    add = true,
    originalUUID,
    resetOptionValue = false,
    selectedAddressUUID = $(target).parent().siblings('.select-address').val();

    $addressDropdown.on('change', function (e) {
        e.preventDefault();

        var selectedAddress = $addressList.find('select').val();

        if (selectedAddress !== 'newAddress') {
            selectedAddress = $.grep($addressList.data('addresses'), function (add) {
                return add.UUID === selectedAddress;
            })[0];
            add = false;
            resetOptionValue = false;
            // proceed to fill the form with the selected address
            util.fillAddressFields(selectedAddress, $addressForm);
        } else if  (selectedAddress === 'newAddress') {
            add = true;
            resetOptionValue = true;
            $addressForm.find('.input-text, .input-select').val('');
        } else {
            //reset the form if the value of the option is not a UUID
            $addressForm.find('.input-text, .input-select').val('');
        }
    });

    $addressForm.on('click', '.cancel', function (e) {
        e.preventDefault();
        $cache.basedialog.dialog("close");
    });

    $addressForm.on('submit', function (e) {
        e.preventDefault();
        if (!$addressForm.valid()) {
            return false;
        }

        // TODO: the endpoint should learn about postOffce
        if(!$('#dwfrm_multishipping_editAddress_addressFields_city').val()) {
            $('#dwfrm_multishipping_editAddress_addressFields_city').val(
                $('#dwfrm_singleshipping_shippingAddress_addressFields_postOffce').val()
            );
        }


        $.getJSON(window.Urls.addEditAddress, $addressForm.serialize(), function (response) {
            $('#edit-address-form').remove();
            if (typeof(response) === 'undefined' || !response.success) {
                $('#multiaddresserror').html(window.Resources.COULD_NOT_SAVE_ADDRESS);
                return;
            }
            $('#multiaddresserror').toggleClass('hidden', response.success);

            if(response.duplicateAddress) {
                return;
            }

            var addressMap = response.addressMap;
            var address = addressMap.addresses[0],
                $shippingAddress = $(target).closest('.shippingaddress'),
                $select = $shippingAddress.find('.select-address'),
                $selected = $select.find('option:selected'),
                address2 = (address.address2 == null ? '' : address.address2 + ', ');

            var newOptionInnerHtml = (address.firstName + ' ' + address.lastName) + ', ' +
                address.address1 + ', ' + address2 + address.city + ', ' + address.stateCode + ', ' + address.postalCode + ', ' + address.countryCode;

            $('#address-verification').remove();

            //Insert each address, based on the productLineItem UUID (shipping restrictions will vary based on the product)
            for (var i = 0; i < addressMap.qliids.length; i++) {
                var pliAddress = addressMap.addresses[i];
                var $qliSelect = $('select[data-qliid="' + addressMap.qliids[i] + '"]');
                updateAddressSelect(newOptionInnerHtml, pliAddress, $qliSelect, $select);
            }

            // update single address select
            updateAddressSelect(newOptionInnerHtml, addressMap.addresses[0], $('.inc-address-list select[name*="_addressList"]'));

            // select the newly editted/added option for this dropdown
            var $clickedDropdowNewOption = $select.find('option[value="' + address.UUID + '"]');
            var newAddress = $clickedDropdowNewOption.data('address');

            if (newAddress) {
                $clickedDropdowNewOption.prop('selected', 'selected');
            }

            // unhide empty selects
            $('.select-address.empty').each(function() {
                if ($(this).children().length > 1) {
                    $(this).removeClass('empty');
                }
            });

            //Recreate the custom dropdowns
            $('.shippingaddress .select-styled, .shippingaddress .select-options, .inc-address-list .select-styled, .inc-address-list .select-options').remove();
            util.initCustomDropdowns(true);
            floatlabels.init();
            $clickedDropdowNewOption.trigger('change');
        });
    });

    //preserve the uuid of the option for the hop up form
    if (selectedAddressUUID) {
        //update the form with selected address
        $addressList.find('option').each(function () {
        //check the values of the options
        if ($(this).attr('value') === selectedAddressUUID) {
            $(this).prop('selected', 'selected');
            $addressDropdown.trigger('change');
            }
        });
        originalUUID = selectedAddressUUID;
    }

    validator.init();
}

function initShippingMethodsEvents() {
    // set up shipping methods selection
    $cache.multishippingMethods.each(function () {
        var $shippingMethodsAvailable = $(this).find('.form-row');
        var selectedShip = $(this).find('.form-row.shipping-boxes').not('.border');
        var FODisSet = false;
        if ($(this).find('.select-shipping-container.border').length) {
            FODisSet = true;
        }

        var $shippingCheckboxes = $(this).find('input.input-radio');

        $(selectedShip).each(function () {
            var $this = $(this);
            var selectedShipInput = $this.children().children('input');
            var checkedInput = $this.find('input:checked');
            var initialShippingId = checkedInput.attr('value');

            if ($this.children().children('input:checked')) {
                var $formRow = checkedInput.parents().parents('.form-row');
                var latestArrival = $formRow.find('.estimated-arrival').data('latestarrival');
                $formRow.addClass('border');
                $formRow.closest('fieldset').find('input[name$="latestArrival"]').val(latestArrival);
            }

            //On load, If it is a 'specific' day shipping method (ex: delivery by Christmas)
            if (!FODisSet || typeof (initialShippingId) !== 'undefined' && initialShippingId.indexOf('64M') == -1) {
                selectedShip.parents('.hf-shipment').find('.multi-shipdate-delay-message').show();
            }

            $this.on('click', function (e) {
                $this.find('input:radio').prop('checked', 'checked');
                $this.find('input:radio').trigger('change');
            });
        });


        var selectedCheckbox = $(this).find('input.input-radio:checked');
        var lastSelectedCheckbox = selectedCheckbox;

        $shippingCheckboxes.off().on('change', function (e) {
            if (selectedCheckbox.val() === $(this).val()) {
                return;
            }

            selectedCheckbox = $(this);
            var $shippingWrapper = $(this).closest('.shipping-boxes');
            var shippingId = $(this).val();
            var $addressDataInput = $(this).closest('.hf-shipment-wrapper').find('.hf-shipment-address-data');

            if (shippingId !== '64S' && $addressDataInput.length) {
                var shipmentId = $addressDataInput.data('shipmentid');
                var address1 = $addressDataInput.data('addressaddress1');
                var address2 = $addressDataInput.data('addressaddress2');
                var city = $addressDataInput.data('addresscity');
                var postalCode = $addressDataInput.data('addresspostalcode');
                var countryCode = $addressDataInput.data('addresscountrycode');
                var stateCode = $addressDataInput.data('addressstatecode');
                var addressType = $addressDataInput.data('addresstype');
                var shipOnDate = $shippingWrapper.find('.estimated-arrival').data('shipondate') || '';
                shipping.selectCustomShippingMethod(shipOnDate, shipmentId, shippingId, address1, address2, city, postalCode, countryCode, stateCode, addressType, $shippingWrapper);
            } else {
                lastSelectedCheckbox.prop('checked', 'checked');
                lastSelectedCheckbox.focus();
                lastSelectedCheckbox.trigger('change');
                return false;
            }

            //If it is a 'specific' day shipping method (ex: delivery by Christmas)
            if (shippingId.indexOf('64M') != -1) {
                selectedShip.parents('.hf-shipment').find('.multi-shipdate-delay-message').hide();
            } else {
                selectedShip.parents('.hf-shipment').find('.multi-shipdate-delay-message').show();
            }
            $(this).closest('.shipping-method-list').find('.shipping-boxes.border, .select-shipping-container.border').removeClass('border');
            var latestArrival = $shippingWrapper.find('.estimated-arrival').data('latestarrival');
            $shippingWrapper.addClass('border');
            $shippingWrapper.closest('fieldset').find('input[name$="latestArrival"]').val(latestArrival);

            lastSelectedCheckbox = $(this);
        });

        if ($shippingMethodsAvailable.length == 1) {
            //If there is only one shipping method available, select it
            $shippingMethodsAvailable.click();
        }
    });
}

/**
 * @function
 * @description Initializes gift message box for multiship shipping, the message box starts off as hidden and this will display it if the radio button is checked to yes, also added event handler to listen for when a radio button is pressed to display the message box
 */
function initGiftMessageBox() {
    $.each($('.item-list'), function () {
        var $this = $(this);
        var $giftMessage = $this.find('.gift-message-text');
        var $shipment = $this.parents('.hf-shipment-wrapper');
        var shipmentID = $shipment.data('shipmentid');
        //set event listeners
        $this.on('click', '.shipment-is-gift input[type="checkbox"]', function (e) {
            shipping.giftMessageCheckboxClick(e, false, shipmentID);
        });

    });

    //Only check the box if we've saved a message (clicked the cta of the gift message modal)
    $('body').on('click', '.gift-message-cta', function (e) {
        e.preventDefault();
        var $this = $(this);
        var shipmentID = $this.data('shipmentid');
        var $shipment = $('[data-shipmentid="' + shipmentID + '"]');
        var $checkbox = $shipment.find('.shipment-is-gift input[type="checkbox"]');
        var textBoxHasValue = $shipment.find('.gift-message-text textarea').val() != "";
        if ($checkbox && $checkbox.prop('checked') == false && textBoxHasValue) {
            $checkbox.prop('checked', true);
        }
    });
}

/**
 * @function
 * @description Update the shipping methods to display any shipping discounts that may be applied.
 */
function applyShippingDiscounts() {
    var $target = $('div.hf-multiship-addresses');
    progress.show($target);
    ajax.load({
        url: window.Urls.getDiscountedShipments,
        callback: function(response) {
            if (response) {
                $target.html(response);
                initializeShippingMethodsCache();
                initShippingMethodsEvents();
                multiship.initShippingRestrictions();
                initGiftMessageBox();
            }
            progress.hide();
        }
    });
}

module.exports = multiship;
