'use strict';

var util = require('../util');
var ajax = require('../ajax');
var minicart = require('../minicart');
var progress = require('../progress');
var lazyload = require('../lazyload');
var dialog = require('../dialog');
var stickyfooter = require('../stickyfooter');
var validator = require('../validator');
var tealium = require('../tealium');

var $cache = {
    main : $('#hf-primary'),
    items : $('#gift-snap-grid')
};

var giftsnap = {
    init : function () {
        initiatlizeDOMStructure();
        initializePageEvents();
        initializeGridEvents();
    }
};

/**
 * @private
 * @function
 * @description Initialize DOM restructuring as nec
*/
function initiatlizeDOMStructure () {
    if (document.querySelector('.gift-snap-redeem-form') === null) {
        return;
    }
    var moveFormIntoSlotDiv = function () {
        // Move redeem form inside the slot markup (to support comps layout). Use vanilla js (not jquery) so there's no delay on page load.
        var fragment = document.createDocumentFragment();
        fragment.appendChild(document.querySelector('.gift-snap-redeem-form'));
        document.getElementById('redeemformplaceholder').appendChild(fragment);
    };

    $cache.windowWidth = window.innerWidth;
    if (!util.mediaBreakpointUp(737)) {
        moveFormIntoSlotDiv();
    }

    util.smartResize(function() {
        if (window.innerWidth === $cache.windowWidth) { return; }
        $cache.windowWidth = window.innerWidth;
        if (!util.mediaBreakpointUp(737)) {
            moveFormIntoSlotDiv();
        } else {
            // Move redeem form OUTSIDE the slot markup. Use vanilla js (not jquery) for performance.
            var fragment = document.createDocumentFragment();
            fragment.appendChild(document.querySelector('.gift-snap-redeem-form'));
            document.querySelector('.gift-snap-redeem-banner').after(fragment);
        }
    });
}

/**
 * @private
 * @function
 * @description Initialize event handlers
*/
function initializePageEvents () {
    $('#chooseacollection').on('change', function (e) {
        const url = $(this).find('option:selected').data('url');
        updateGrid(url, e);
    });

    if ($('.gift-snap-redeem-banner').hasClass('redeem-welcome-modal-page')) {
        $('body').addClass('redeem-welcome-modal-page');
    }

    // listen for tab click or enter or space
    $('.hf-collections-display-tab').on('click keydown', function (e) {
        if (e.type === 'keydown' && e.key !== 'Enter' && e.key !== ' ') { return; }
        e.preventDefault();
        const url = $(this).data('url');
        updateGrid(url, e);
    });

    $('.hf-collections-display-tab').on('keydown', function (e) {
        var $selected;
        if (e.key === 'ArrowLeft') {$selected = $(this).prev();}
        if (e.key === 'ArrowRight') {$selected = $(this).next();}
        if (e.key === 'Home') {$selected = $(this).siblings(':last');}
        if (e.key === 'End') {$selected = $(this).siblings(':first');}
        if ($selected) {$selected.focus();}
    });

    $('.gift-snap-next-collection').on('click', function (e) {
        const url = $(this).data('url');
        updateGrid(url, e);
    });

    $('body').on('click', '.collection-modal-page-close', function () {
        closeViewDetailsModalPage();
    });

    $('body').on('click', '.selectcollection-cta', function (e) {
        openRecipientsModal();
    });

    $('body').on('click', '.selectgift-cta', function (e) {
        addToBasket(e, $(this).data('pid') || $(this).closest('.hf-collections-display-grid').find('.slick-current .hf-product-tile').data('itemid'));
    });

    $('.chooseacollection-cta').on('click', function () {
        openRecipientsModal();
    });

    $('body').on('submit', '#recipientform', function (e) {
        handleRecipientFormSubmit(e, $(this).closest('form'));
    });

    $('body').on('submit', '#redeemform', function (e) {
        handleRedeemFormSubmit(e, $(this).closest('form'));
    });

    $(document).on('click', '.giftsnap-purchase .hfgiftsnapviewbutton', function (e) {
        openPurchaseViewDetailsModalPage(e);
    });

    $(document).on('click', '.giftsnap-redeem .hfgiftsnapviewbutton', function (e) {
        openRedeemViewDetailsModalPage(e);
    });

    $(document).on('click', '.giftsnap-purchase .hf-thumb-link-giftsnap', function (e) {
        openPurchaseViewDetailsModalPage(e);
    });

    $(document).on('click', '.giftsnap-redeem .hf-thumb-link-giftsnap', function (e) {
        openRedeemViewDetailsModalPage(e);
    });

    $(document).on('click', '.giftsnap-purchase .hf-name-link-giftsnap', function (e) {
        openPurchaseViewDetailsModalPage(e);
    });

    $(document).on('click', '.giftsnap-redeem .hf-name-link-giftsnap', function (e) {
        openRedeemViewDetailsModalPage(e);
    });
}

/**
 * @private
 * @function
 * @description Initialize/re-initialize event handlers in the AJAX-refreshable grid area
*/
function initializeGridEvents () {
    if ($('body').hasClass('collection-modal-page')) {
        openPurchaseViewDetailsModalPage();
    } else {
        activateCarousel({unslick: 737});
    }
}

/**
 * @private
 * @function
 * @description replaces product listing with ajax and puts a loading indicator over the product listing
 * @param {String} url the url of the AJAX request that will return a new product grid for giftsnap
 * @param {Event} e optional event from which this grid update call originated.
 */
function updateGrid (url, e) {
    if (!url) { return; }
    
    var $panel = $cache.items.find('.hf-collections-display-tabpanel[data-url="' + url + '"]');
    if ($panel.length === 0) { return; }

    if ($panel.attr('data-populated') === 'false') {
        progress.show($cache.items);
        $.ajax({url: url, success: function(result) {
            deactivateCarousel();
            $panel.html(result);
            $panel.attr('data-populated', 'true');
            $panel.addClass('selected').siblings().removeClass('selected');
            lazyload.reinit();
            initializeGridEvents();
            progress.hide();
        },
        error: function () {
            window.alert('invalid request');
        }});
    } else {
        var $selected = $('.hf-collections-display-tabpanel.selected');
        $selected.removeClass('selected');
        $panel.addClass('selected -transition-in');
        deactivateCarousel();
        lazyload.reinit();
        initializeGridEvents();
        window.setTimeout(function () {
            $panel.removeClass('-transition-in');
        }, 1);
    }

    // update the hidden input data
    $('input.collection-data-cache').attr('data-active', false);
    $('input.collection-data-cache[data-url="' + url + '"]').attr('data-active', true);

    // set the correct Tab to 'selected' and focusable
    $('.hf-collections-display-tab.selected').removeClass('selected').attr('aria-selected', false).attr('tabindex', '-1');
    $('.hf-collections-display-tab[data-url="' + url + '"]').addClass('selected').attr('aria-selected', true).attr('tabindex', '0');

    // set the correct Modal Nav to 'selected'
    $('.gift-snap-next-collection.selected').removeClass('selected');
    $('.gift-snap-next-collection[data-url="' + url + '"]').next('.gift-snap-next-collection').addClass('selected');

    // set the correct subcategorey banner to 'selected'
    $('.gift-snap-subcategory-banner-slot.selected').removeClass('selected');
    $('.gift-snap-subcategory-banner-slot[data-url="' + url + '"]').addClass('selected');

    // set the correct Select Dropdown Option to 'selected'
    if (!e || e.type !== 'change') {
        $('#chooseacollection option:selected').removeAttr('selected').prop('selected', false);
        $('#chooseacollection option[data-url="' + url + '"]').prop('selected', true).attr('selected','selected');
    }
    
    // set the collection display name of the recipients modal
    var newDisplayName = $('input.collection-data-cache[data-active="true"]').attr('data-displayname');
    $('.gift-snap-recipients-modal').find('.gift-snap-collection-name').text(newDisplayName);

    // set the collection ID in the recipients modal
    var collectionId = $('input.collection-data-cache[data-active="true"]').attr('data-tier');
    $('.gift-snap-recipients-modal').find('.gift-snap-collection-id').val(collectionId);

    // set tier value to recipients modal form
    $('#dwfrm_giftsnap_purchase_giftsnaptier').attr('value', collectionId);
}

/**
 * @private
 * @function
 * @description destroy a slick carousel by target
 * @param {String} target (optional) the target to try and unslick, ex: '.products'
 */
function deactivateCarousel (target) {
    var $target = target ? $(target) : $('.hf-collections-display-tabpanel.selected .hf-search-result-items');
    if ($target.hasClass('slick-initialized')) { $target.slick('unslick'); }
    $target.removeClass('slick-initialized slick-slider').removeAttr('role').removeAttr('aria-label');
    $target.find('.slick-sr-only').remove();
}

/**
 * @private
 * @function
 * @description initializes the slick carousel for mobile/tablet viewport
 * @param {Object} opts Object containing configuration options for the slick init
 * @param {String} opts.target (optional) the target to try and slick, ex: '.grid-items'
 * @param {String} opts.slide (optional) the caro slide target, ex: 'li.product-tile'
 * @param {Number} opts.unslick (optional) a viewport at which to unslick (disable) the caro. i.e. the slick caro should be active below this viewport. ex: 767
 * @param {Number} opts.showslide (optional) the slide to display initially when the carousel loads. ex: 0
 */
function activateCarousel (opts) {
    var target = opts && opts.target ? opts.target : '.hf-collections-display-tabpanel.selected .hf-search-result-items';
    var slideTarget = opts && opts.slide ? opts.slide : '.hf-grid-tile';
    var unslick = opts && opts.unslick ? opts.unslick : null;
    var showSlide = opts && opts.showslide ? opts.showslide : null;
    var $carouselBlock = $('body').find(target);
    var totalSlides = $carouselBlock.find(slideTarget).length;
    if ($carouselBlock.hasClass('slick-initialized')) {
        deactivateCarousel(opts.target); // force a carousel refresh
    }
    var options = {
        adaptiveHeight: true,
        arrows: true,
        dots: false,
        infinite: true,
        lazyLoad: 'ondemand',
        mobileFirst: true,
        slide: slideTarget,
        slidesToShow: 1
    };
    if (showSlide) {
        options.initialSlide = showSlide;
    }
    $(window).off('resize.giftsnapslick'); // unbind the window resize "unslick"
    if (unslick) {
        // we will handle responsive unslick ourselves. native unslick leaves too many artifacts lying around. and this gives us debounce control.
        options.responsive = undefined;
        $(window).on('resize.giftsnapslick', util.debounce(function () {
            if ($carouselBlock.hasClass('slick-initialized') && util.mediaBreakpointUp(opts.unslick)) {
                deactivateCarousel(); // unslick caro. this custom function unslicks and also removes the lingering slick classes and attributes.
            } else if (!$carouselBlock.hasClass('slick-initialized') && !util.mediaBreakpointUp(opts.unslick)) {
                _slickinit(); // reinit if viewport changes from desktop down to tablet/mobile
            }
        }, 100));

        // now set up the carousel for the first time (if we're in the right viewport)
        if (!$carouselBlock.hasClass('slick-initialized') && !util.mediaBreakpointUp(opts.unslick)) {
            _slickinit();
        }
    } else {
        _slickinit();
    }

    function _slickinit () {
        $carouselBlock.slick(options);
        // Add the item text e.g. "Item 1 of 8"
        $carouselBlock.find(slideTarget).each(function (i) {
            var index = $(this).closest('li.hf-grid-tile').attr('data-index');
            $(this).find('.hf-product-count').text(index + ' of ' + totalSlides);
        });
    }
}

/**
 * @private
 * @function
 * @description close the View Details Modal page takeover
 * @param {Event} e The original triggering event
 */
function openPurchaseViewDetailsModalPage (e) {
    if (e) {
        e.preventDefault();
    }

    if (!util.mediaBreakpointUp(737)) {
        return;
    }

    if (e && $(e.target).closest('.slick-slide').length) {
        // already in a carousel. do nothing on link click.
        return;
    }

    var i = null;
    var dialogTierId = $('.hf-collections-display-tab.selected').attr('data-tier');
    if (e) {
        i = $(e.target).closest('li.hf-grid-tile').data('index') - 1;
    }
    $('body').addClass('collection-modal-page');
    $('.hf-collections-display').attr('aria-live','polite').attr('aria-labelledby', dialogTierId + '-title').attr('aria-describedby', dialogTierId + '-description').find('[aria-hidden="true"]:visible').attr('aria-hidden','false');
    activateCarousel({showslide: i});

    $(window).on('resize.closeVieDetailsModalPage', util.debounce(function () {
        if (!util.mediaBreakpointUp(737)) {
            closeViewDetailsModalPage();
        }
    }));
}

/**
 * @private
 * @function
 * @description close the View Details Modal page takeover
 */
function closeViewDetailsModalPage () {
    $('body').removeClass('collection-modal-page');
    $('.hf-collections-display').removeAttr('aria-role').removeAttr('aria-labelledby').removeAttr('aria-describedby').find('[aria-hidden="false"]:hidden').attr('aria-hidden','true');
    deactivateCarousel();
    activateCarousel({unslick: 737});
    $(window).off('resize.closeVieDetailsModalPage');
}

/**
 * @private
 * @function
 * @description close the Redeem Form Modal page takeover
 */
function closeRedeemWelcomeModal () {
    $('body').removeClass('redeem-welcome-modal-page');
    $('.selectcollection-cta').addClass('redeem');
    // the carousel has already been initialized, but may need a refresh due to lazy load images
    activateCarousel({unslick: 737});
    stickyfooter.makeSticky('.selectgift-cta-sticky-container', '(max-width: 736px)');
}

/**
 * @private
 * @function
 * @description launch the Redeem View Details Modal dialog
 * @param {Event} e The original triggering event
 */
 function openRedeemViewDetailsModalPage (e) {
    if (e) { e.preventDefault(); }
    if ($(e.target).closest('.slick-slide').length) {
        // already in a carousel. do nothing on link click.
        return false;
    }
    dialog.openWithContent({
        content: $('.hf-collections-display').html(),
        options : {
            dialogClass : 'giftsnap-redeem-modal',
            width: 1230,
            maxWidth: 'calc(100% - 60px)',
            modal: true,
            openInCallback: true,
            fluid: true,
            draggable: false,
            resizable: false,
            close: function () {
                $(this).remove();
                activateCarousel({unslick: 737});
            }
        },
        callback: function() {
            var i = null;
            var dialogTierId = $('.hf-collections-display-tab.selected').attr('data-tier');
            if (e && e.target) {
                i = $(e.target).closest('li.hf-grid-tile').data('index') - 1;
            }
            $('.giftsnap-redeem-modal .hf-collections-display').attr('aria-live','polite').attr('aria-labelledby', dialogTierId + '-title').attr('aria-describedby', dialogTierId + '-description').find('[aria-hidden="true"]:visible').attr('aria-hidden','false');
            activateCarousel({target: '.giftsnap-redeem-modal .hf-collections-display-tabpanel.selected .hf-search-result-items', showslide: i});
        }
    });
}

/**
 * @private
 * @function
 * @description launch the Recipients Modal dialog
 */
function openRecipientsModal () {
    dialog.openWithContent({
        content: $('.gift-snap-recipients-modal').html(),
        options : {
            dialogClass : 'gift-snap-recipients-modal',
            width: 1025,
            maxWidth: 1025,
            modal: true,
            openInCallback: true,
            fluid: true,
            draggable: false,
            resizable: false,
            close: function () {
                $(this).remove();
            }
        }
    });
    util.limitCharacters();
    validator.init();
}
/**
 * @private
 * @function
 * @description handle the Recipients Modal form submit
 * @param {Event} e the submit event object
 * @param {form} form the submit form object
 */
 function handleRedeemFormSubmit(e, form) {
    e.preventDefault();
    var data = form.serialize();
    $.ajax({
        type: 'POST',
        url: util.ajaxUrl(form.attr('action')),
        data: data
    }).done(function (response) {
        if (response && response.isGiftSnapValid) {
            window.location.href = response.url;
        } else if (response && !response.success) {
            for (var id in response.errors.FormErrors) {
                var error_el = $('#' + id).addClass('error').removeClass('valid').next('.error');
                if (!error_el || error_el.length === 0) {
                    error_el = $('<span for="' + id + '" generated="true" class="error" style=""></span>');
                    $('#' + id).after(error_el);
                }
                error_el.text(response.errors.FormErrors[id].replace(/\\'/g, "'")).show();
            }
        }
    }).fail(function (textStatus) {
        if (textStatus === "parsererror") {
            window.alert(window.Resources.BAD_RESPONSE);
        } else {
            window.alert(window.Resources.SERVER_CONNECTION_ERROR);
        }
    });
}

/**
 * @private
 * @function
 * @description add to basket
 * @param {Event} e the submit event object
 * @param {string} pid product ID
 */
 function addToBasket(e, pid) {
    e.preventDefault();
    var data = 'Quantity=1&uuid=&cartAction=update&giftsnap=true&pid=' + pid.replace(/#/g, '') + '&code=' + $('#dwfrm_giftsnap_redeem_giftsnapcode').val();
    var a2cUrl = window.Urls.addProduct;
    $.ajax({
        type: 'POST',
        url: util.ajaxUrl(a2cUrl),
        data: data
    })
    .done(function (response) {
        if (response.success) {
            var url = util.appendParamsToUrl(window.Urls.checkoutShow, {multiShipOverride:'false'});
            window.location = util.ajaxUrl(url);
        } else if (response.redirect) {
            window.location = util.ajaxUrl(window.Urls[response.redirect]);
        }
    })
    .fail(function (textStatus) {
        if (textStatus === "parsererror") {
            window.alert(window.Resources.BAD_RESPONSE);
        } else {
            window.alert(window.Resources.SERVER_CONNECTION_ERROR);
        }
    });
}

/**
 * @private
 * @function
 * @description handle the Recipients Modal form submit
 * @param {Event} e the submit event object
 * @param {form} form the submit form object
 */
function handleRecipientFormSubmit(e, form) {
    e.preventDefault();
    var data = form.serialize();
    var tier = $('#dwfrm_giftsnap_purchase_giftsnaptier').val();
    var tealiumData = $('.gift-snap-recipient-cta').data('tealium');
    $.ajax({
        type: 'POST',
        url: util.ajaxUrl(form.attr('action')),
        data: data
    })
        .done(function (response) {
            if (response && response.success) {
                ajax.load({
                    url: window.Urls.minicartGS,
                    data: {lineItemId: response.result.lineItemId, giftCertCount: response.result.giftCertCount},
                    callback: function (response) {
                        dialog.close();
                        minicart.show(response, {
                            asModal: true,
                            lastActive: $('#add-to-cart')
                        });
                        form.find('input,textarea').val('');

                        if (tier && tealiumData && tealiumData[tier]) {
                            tealium.addToCartGiftSnap(tealiumData[tier]);
                        }
                    }
                });
            } else if (response) {
                for (var id in response.errors.FormErrors) {
                    var error_el = form.find('#' + id).addClass('error').removeClass('valid').next('.error');
                    if (!error_el || error_el.length === 0) {
                        error_el = $('<span for="' + id + '" generated="true" class="error" style=""></span>');
                        form.find('#' + id).after(error_el);
                    }
                    error_el.text(response.errors.FormErrors[id].replace(/\\'/g, "'")).show();
                }
            }
        })
        .fail(function (textStatus) {
            if (textStatus === "parsererror") {
                window.alert(window.Resources.BAD_RESPONSE);
            } else {
                window.alert(window.Resources.SERVER_CONNECTION_ERROR);
            }
    });
}

module.exports = giftsnap;
