'use strict';

var util = require('../util');
var floatlabels = require('../float-labels');

//checkout related imports
var singleship = require('./checkout/singleship');
var multiship = require('./checkout/multiship');
var shipping = require('./checkout/shipping');
var billing = require('./checkout/billing');
var review = require('./checkout/review');
var progress = require('../progress');

var isShipping = false,
    isMultiShipping = false,
    isMultiAddress = false,
    isReview = false,
    shippingMethods = [];

window.app.isMultiShipping = isMultiShipping;

/**
 * @function
 * @description updates the order summary based on a possibly recalculated basket after a shipping promotion has been applied
 */
function updateSummary() {

    var shipOnDate = $("input[name='shipOnDate']");

    var url = window.Urls.summaryRefreshURL;

    var params = {
            shipOnDate:shipOnDate.val()
    };

    var url = util.appendParamsToUrl(window.Urls.summaryRefreshURL, params, true);

    var summary = $("#hf-secondary .order-summary-wrapper");
    if(summary.length == 0) { //cart page now been added to dynamic update
        var summary = $("#osummary");
        $(".cart-coupon-code").remove(); //returned summary has apply promotion code button
    }

    // indicate progress
    progress.show(summary);

    // load the updated summary area
    summary.load( url, function () {
        // hide edit shipping method link
        summary.fadeIn("fast");
        summary.find('.hf-checkout-mini-cart .minishipment .header a').hide();
        summary.find('.hf-order-totals-table .order-shipping .label a').hide();
    });
}
function initApplyCoupon() {
    $('body').on('keydown', 'input[name*="_billing_couponCode"]', function (e) {
        e.stopImmediatePropagation();
        if (e.which === 13) {
            e.preventDefault();
            $('#add-coupon').click();
        }
    });
    $('body').on('click', '#add-coupon', function(e) {
        e.preventDefault();

        var val = $("input[name$='_couponCode']").val();
        var cartPage = $('.hf-cart').length;

        var url = util.appendParamsToUrl(window.Urls.addCouponJSON, {
            couponCode: val,
            format: 'ajax',
            pageType: cartPage ? 'cart' : 'billing'
        });

        $.ajax({
            type: 'GET',
            url: url,
            success: function (data) {

                if (data.success && data.status === 'APPLIED') {
                    // cart page
                    //refresh entire page, Product level discount can change the line item pricing
                    $("input[name$='_couponCode']").removeAttr('aria-invalid');
                    window.location.reload();
                    $('#add-coupon').prop('disabled', true);
                }

                if (!data || !data.success || data === '') {
                    // else, coupon failed to apply
                    // display error message
                    if (!data) {
                        // bad, big failure, use defaulted message
                        $('#addCouponError').text("Exception occured while trying to apply coupon.");
                    } else {
                        var message = data.message;
                        $('#addCouponError').text(message);
                    }
                    $("input[name$='_couponCode']").attr('aria-invalid', 'true').attr('aria-describedBy', 'addCouponError');
                    window.setTimeout(function () {
                        $("input[name$='_couponCode']").focus();
                    }, 300);
                }

                if (data && data.status === "NO_APPLICABLE_PROMOTION") {
                    $('#addCouponError').text(data.message);
                }
            }
        });
    });
}

function initRemoveCoupon() {
    $('body').on('click', '.delete-coupon', function(e) {
        e.preventDefault();
        var $el = $(this);
        var couponCode = $el.data('code');
        var sourceCode = $el.attr('sourcecode');
        var type = $el.attr('type');
        var $couponLineWrapper = $el.closest('.cart__discount-lineitem');

        if(sourceCode == 'undefined' || sourceCode == null) {
            sourceCode = false;
        }

        if(type == 'undefined' || type == null) {
            type = "";
        }

        var url = util.appendParamsToUrl(window.Urls.removeCouponJSON, {couponCode:couponCode,sourceCode:sourceCode,format:"ajax"});
        $.ajax({
            type:'GET',
            url:url,
            success: function (res) {
                var cartPage = $('.hf-cart').length;
                if (cartPage) {
                    //refresh entire page
                    window.location.reload();
                } else {
                    //refresh summary
                    updateSummary();
                    if (typeof res === 'string') {
                        $('.billing-promo-wrapper').replaceWith(res);
                    }
                }
            }
        });
    });
}

function removeCouponSourceCode(btn) {

    var formRow = $('#add-coupon').parents('.form-row');

    var errorContainer = formRow.next('.error');
    var msgContainer = formRow.nextAll('.msg');

    errorContainer.html('');
    msgContainer.html('');

    var url = btn.attr('data-removeurl');

    $.getJSON(url, function(data) {

        var fail = false;
        var msg = "";
        if (!data) {
            msg = window.Resources.BAD_RESPONSE;
            fail = true;
        }
        else if (!data.success) {
            msg = data.message;
            fail = true;
        }
        if (fail) {

            if (errorContainer.length) {
                errorContainer.html(msg);
            }
            else {
                errorContainer = $('<div>', { class:"error"});
                errorContainer.html(data.message);
                errorContainer.insertAfter(formRow);
            }

            return;
        }

        if (data.couponerror) {

            if (errorContainer.length) {
                errorContainer.html(data.message);
            }
            else {
                errorContainer = $('<div>', { class:"error"});
                errorContainer.html(data.message);
                errorContainer.insertAfter(formRow);
            }
        }
        else if (data.couponmsg) {

            if (msgContainer.length) {
                msgContainer.html(data.message);
            }
            else {
                msgContainer = $('<div>', {class:"msg"});
                msgContainer.html(data.message);
                msgContainer.insertAfter(formRow);
            }


            var parents = btn.parents('.sourcecode, .couponcode');
            parents.remove();

            updateSummary();

        }

    });

}

/**
 * @function
 * @description Sets a boolean variable (isShipping) to determine the checkout stage
 */
function initializeDom() {
    isShipping = $(".checkout-shipping").length > 0;
    isMultiShipping = $(".checkout-multi-shipping.shipping").length > 0;
    isMultiAddress = $(".checkout-multi-shipping.address").length > 0;
    isReview = $(".hf-order-review").length > 0;
    window.app.isMultiShipping = isMultiShipping;
}

/**
 * @function Initializes the page events depending on the checkout stage (shipping/billing)
 */
function initializeEvents() {
    shipping.addressLoad();
    initApplyCoupon();
    initRemoveCoupon();

    if (isShipping) {
        singleship.init();
        handleRequiredCity();
    } else if (isMultiAddress) {
        multiship.initAddressPage();
        handleRequiredCity();
    } else if (isMultiShipping) {
        multiship.initShippingMethodsPage();
        handleRequiredCity();
    } else if (isReview) {
        review.init();
    } else {
        billing.init();
    }
    //Set initial state of dropdown to error on page load (will be removed once option is picked)
    $('#address .select-address select, .checkoutmultishipping .custom-select select').each(function(){
        if($(this).val() == ""){
            $(this).siblings('.select-styled').addClass('error');
        }
    });
}

function handleRequiredCity() {
    $(document).on('change', '.addressType', function () {
        var type = $(this).val();
        var city = $('input[name$="_city"]');
        if (type !== 'military') {
            if (city) {
                var container = city.parent();
                container.addClass('required');
                if (container.find('label').find('.required-indicator').length === 0) {
                    container.find('label').append('<span class="required-indicator">*</span>');
                }
            }
        } else {
            if (city) {
                var container = city.parent();
                container.removeClass('required');
                container.find('label').find('.required-indicator').remove();
            }
        }
    });
}

var checkout = {
    init : function () {
        initializeDom();
        initializeEvents();
        floatlabels.init();
    },
    updateSummary : updateSummary,
    initApplyCoupon : initApplyCoupon,
    initRemoveCoupon : initRemoveCoupon
};

module.exports = checkout;
