'use strict';

var floatlabels = require('../float-labels');

var orderconfirmation = {
    /**
     * @function
     * @description Binds the events of the floatlabels
     */
    init : function () {
        floatlabels.init();
    }
};

module.exports = orderconfirmation;
