'use strict';
var progress = require('../../progress');

var review = {
    /**
     * @function
     * @description shows gift message box, if shipment is gift
     */
    init: function () {
        $('form.submit-order').on('submit', function () {
            progress.show($('.hf-checkout-order-totals'));
        });
    }
};

module.exports = review;
