/**
 * @function
 * @description Slick-initializes the given carousel wrapper by target class name or id
 * @param {String} cssSelector The css selector of the carousel wrapper element to initialize, ex: '.my-carousel'. Default value is '.recommendation-caro-1x4'.
 */
function init(cssSelector) {
    window.setTimeout(function () {
        var caroSelector = cssSelector || '.recommendation-caro-1x4';
        var $carouselBlock = $(caroSelector);

        $carouselBlock.each(function () {
            if ($carouselBlock.hasClass('slick-initialized')) {
                return;
            }
            $carouselBlock.slick({
                lazyLoad: 'ondemand',
                slidesToShow: 4,
                infinite: true,
                arrows: true,
                responsive: [{
                    breakpoint: 735,
                    settings: {
                        slidesToShow: 1
                    }
                }]
            });
        });
    }, 3000);
}

var recommendationCaros = {
    init: init
};

module.exports = recommendationCaros;