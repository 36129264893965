'use strict';

var ajax = require('./ajax');
// private

var $cache = {};
// end private

var dialog = {
    /**
     * @function
     * @description Appends a dialog to a given container (target)
     * @param {Object} params  params.target can be an id selector or an jquery object
     */
    create : function (params) {
        // options.target can be an id selector or an jquery object
        var target = $(params.target || "#dialog-container");
        var id;
        // if no element found, create one
        if(target.length === 0) {
            if(target.selector && target.selector.charAt(0) === "#") {
                id = target.selector.substr(1);
            }
            target = $("<div>").attr("id", id).addClass("dialog-content").appendTo("body");
        }

        // create the dialog
        $cache.lastactive = params.options.lastactive ? params.options.lastactive : '';
        $cache.container = target;
        $cache.container.dialog($.extend(true, {}, dialog.settings, params.options || {}));
        return $cache.container;
    },
    /**
     * @function
     * @description Opens a dialog using the given url (params.url)
     * @param {Object} params.url should contain the url
     */
    open : function (params) {
        if (!params.url || params.url.length === 0) { return; }

        $cache.container = dialog.create(params);
        // finally load the dialog
        ajax.load({
            target : $cache.container,
            url : params.url,
            callback : function () {
                if (!$cache.container.dialog("isOpen") && params.options.openInCallback) {
                    $cache.container.dialog("open");
                }

                if (params.onLoadCallback) {
                    params.onLoadCallback();
                }
            }
        });

        return $cache.container;
    },
    /**
     * @description populate the dialog with html content, then open it
     **/
    openWithContent: function (params) {
        var content, position, callback;

        $cache.container = dialog.create(params);
        content = params.content || params.html;
        if (!content) { return; }
        $cache.container.empty().html(content);
        if (!$cache.container.dialog('isOpen')) {
            $cache.container.dialog('open');
        }

        callback = (typeof params.callback === 'function') ? params.callback : function () {};
        callback();


    },
    /**
     * @function
     * @description Closes the dialog and triggers the "close" event for the dialog
     */
    close : function () {
        if(!$cache.container) {
            return;
        }
        $cache.container.dialog("close");
    },
    /**
     * @function
     * @description Triggers the "apply" event for the dialog
     */
    triggerApply : function () {
        $(this).trigger("dialogApplied");
    },
    /**
     * @function
     * @description Attaches the given callback function upon dialog "apply" event
     */
    onApply : function (callback) {
        if(callback) {
            $(this).bind("dialogApplied", callback);
        }
    },
    /**
     * @function
     * @description Triggers the "delete" event for the dialog
     */
    triggerDelete : function () {
        $(this).trigger("dialogDeleted");
    },
    /**
     * @function
     * @description Attaches the given callback function upon dialog "delete" event
     * @param {String} The callback function to be called
     */
    onDelete : function (callback) {
        if(callback) {
            $(this).bind("dialogDeleted", callback);
        }
    },
    /**
     * @function
     * @description Submits the dialog form with the given action
     * @param {String} The action which will be triggered upon form submit
     */
    submit : function (action) {
        var form = $cache.container.find("form:first");
        // set the action
        $("<input/>").attr({
            name : action,
            type : "hidden"
        }).appendTo(form);

        // serialize the form and get the post url
        var post = form.serialize();
        var url = form.attr("action");

        // post the data and replace current content with response content
        $.ajax({
            type : "POST",
            url : url,
            data : post,
            dataType : "html",
            success : function (data) {
                $cache.container.html(data);
            },
            failure : function (data) {
                window.alert(window.Resources.SERVER_ERROR);
            }
        });
    },
    isActive: function () {
        return $cache.container && ($cache.container.length > 0) && ($cache.container.children.length > 0);
    },
    isQuickView : function () {
        return $cache.container && $cache.container.find('#QuickViewDialog');
    },
    settings : {
        autoOpen : false,
        resizable : false,
        draggable : false,
        bgiframe : true,
        modal : true,
        height : 'auto',
        width : '800',
        buttons : {},
        title : '',
        position : 'center',
        overlay : {
            opacity : 0.5,
            background : "black"
        },
        /**
         * @function
         * @description The close event
         */
        open : function (event, ui) {
            var $this = $(this);
            var $uiDialog = $this.parent();
            var onOpenCallback = $this.dialog("option",  "onOpenCallback");
            var ariaLabel = $this.dialog("option",  "ariaLabel");

            if (typeof(onOpenCallback) === "function") {
                onOpenCallback();
            }

            $uiDialog.attr('aria-label', $('.ui-dialog-title', $uiDialog).text());
            //ADA: set custom aria label if the option exist
            if (typeof(ariaLabel) === "string") {
                $uiDialog.attr('aria-label', ariaLabel);
            }
            //ADA: set aria-modal=”true” because this ia modal
            $uiDialog.attr('aria-modal', 'true');
        },
        close : function (event, ui) {
            if ($cache.lastactive) {
                $($cache.lastactive).focus();
            }
            $(this).dialog("destroy");
        }
    }
};

module.exports = dialog;
