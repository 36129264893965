'use strict';

var util = require('./util');
var dialog = require('./dialog');

function showModal() {
    var $modal = $('.suggested-products-modal');
    $modal.dialog({
        dialogClass: 'suggested-products-dialog',
        maxWidth: 1070,
        maxHeight: 1056,
        width: '100%',
        modal: true,
        fluid: true,
        draggable: false,
        resizable: false,
        position : 'center',
        open: function () {
            var windowSize = $(window).innerWidth(),
                $slider = $modal.find('.products');
            $modal.focus();
            if (windowSize <= 735) {
                if ($slider.hasClass('slick-initialized')) {
                    $slider.slick('unslick');
                }
                $slider.slick({
                    slidesToShow: 1,
                    arrows: true,
                    lazyLoad: 'ondemand',
                    slide: '.product-tile'
                });
            } else {
                if ($slider.hasClass('slick-initialized')) {
                    $slider.slick('unslick');
                }
            }

            var $closeBar = $('.suggested-products-dialog a.ui-dialog-titlebar-close');
            $closeBar.attr('tabindex', 0);
            $closeBar.on('keydown', function (e) {
                if (e.which === 9) {
                    $('.suggested-products-modal h2').focus();
                }
            });

            $closeBar.on('keyup', function (e) {
                if (e.which === 13) {
                    $modal.dialog('close');
                }
            });

            $('.ui-widget-overlay, .add-to-cart').on('click', function() {
                $modal.dialog('close');
            });
         },
         close: function () {
             $('.suggested-products-modal .product-tile').removeClass('flipped');
             $('.shop-alternatives').focus();
         }
    });
}

function fluidDialog() {
    var $visible = $('.ui-dialog:visible');
    // each open dialog
    $visible.each(function () {
        var $this = $(this);
        var dialogData = $this.find('.ui-dialog-content').data('dialog');
        // if fluid option == true
        if (dialogData.options.fluid) {
            var wWidth = $(window).width();
            // check window width against dialog width
            if (wWidth < (parseInt(dialogData.options.maxWidth) + 50))  {
                // keep dialog from filling entire screen
                $this.css('max-width', '90%');
            } else {
                // fix maxWidth bug
                $this.css('max-width', dialogData.options.maxWidth + 'px');
            }
            //reposition dialog
            dialogData.option('position', dialogData.options.position);
        }
    });
    var windowSize = $(window).innerWidth(),
        $modal = $('.suggested-products-modal:visible'),
        $slider = $modal.find('.products');
    if (windowSize <= 735) {

        if ($slider.hasClass('slick-initialized')) {
            return;
        }
        $slider.slick({
            slidesToShow: 1,
            arrows: true,
            lazyLoad: 'ondemand',
            slide: '.product-tile'
        });
    } else {
        if ($slider.hasClass('slick-initialized')) {
            $slider.slick('unslick');
        }
    }
}

function flipProduct() {
    var $product = $('.suggested-products-modal .product-tile'),
        $details = $product.find('.details');

    $details.click(function(e) {
        e.preventDefault();
        $(this).closest($product).toggleClass('flipped');
        $(this).closest('.suggested-product-details').show();
        if($(this).hasClass('view-details')) {
            $(this).next('.details').focus();
        } else {
            $(this).prev('.details').focus();
        }
    });
}
/**
 * @private
 * @function
 * @description Binds the events of the order, address and payment pages
 */
function initializeEvents() {
    $('.shop-alternatives').click(function (e) {
        e.preventDefault();
        showModal();
    });

    $(document).on('dialogopen', '.ui-dialog', function (event, ui) {
        fluidDialog();
    });
    $(window).resize(function () {
        fluidDialog();
    });

    $('.shop-alternatives').click(function (e) {
        e.preventDefault();
        $('.suggested-products-modal').show().dialog('open');
    });

    flipProduct();
}

var suggestedproducts = {
    /**
     * @function
     * @description Binds the events of the suggested products modal
     */
    init : function () {
        initializeEvents();
    },
    fluidDialog : fluidDialog,
    flipProduct : flipProduct
};

module.exports = suggestedproducts;