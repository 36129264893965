'use strict';

var ajax = require('./ajax');
var util = require('./util');
var minicart = require('./minicart');
var $cache;

function setAddToCartHandler(e) {
    e.preventDefault();
    var form = $(this).closest("form");

    var options = {
        url : util.ajaxUrl(form.attr('action')),
        method : 'POST',
        cache: false,
        contentType : 'application/json',
        data : form.serialize()
    };
    $.ajax(options).done(function (response) {
        if( response.success ) {
            ajax.load({
                url : window.Urls.minicartGC,
                data :{lineItemId : response.result.lineItemId},
                callback : function(response) {
                    minicart.show(response, {
                        lastActive: form
                    });
                    form.find('input,textarea').val('');
                }
            });
        } else {
            form.find('span.error').hide();
            for( var id in response.errors.FormErrors ) {
                var error_el = $('#' + id).addClass('error').removeClass('valid').next('.error');
                if( !error_el || error_el.length === 0 ) {
                    error_el = $('<span for="' + id + '" generated="true" class="error" style=""></span>');
                    $('#' + id).after(error_el);
                }
                error_el.text(response.errors.FormErrors[id].replace(/\\'/g,"'")).show();
            }
            // console.log(JSON.stringify(response.errors));
        }
    }).fail(function (xhr, textStatus) {
        // failed
        if(textStatus === "parsererror") {
            window.alert(window.Resources.BAD_RESPONSE);
        } else {
            window.alert(window.Resources.SERVER_CONNECTION_ERROR);
        }
    });
}

function initializeCache() {
    $cache = {
        addToCart : $("#AddToBasketButton"),
    };
}

function initializeEvents() {
    $cache.addToCart.on('click', setAddToCartHandler);
}

var giftcert = {
    init : function() {
        initializeCache();
        initializeEvents();
    }
};

module.exports = giftcert;
