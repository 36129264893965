'use strict';

var tooltip = {
    /**
     * @function
     * @description Initializes the tooltip-content and layout
     */
    init : function () {

        $('.tooltip').attr('title', 'Tooltip').tooltip({
            position: {
              my: "center top+10",
              at: "center bottom"
            },
            content: function() {
                // add a data attribute of data-layout="some-class" to your tooltip-content container if you want a custom class
                var tooltipClass = "";
                var content = $(this).find('.tooltip-content, .hf-tooltip-content').length > 0 ? $(this).find('.tooltip-content, .hf-tooltip-content').first() : $(this).siblings('.hf-tooltip-content, .tooltip-content').first();

                if (content.data("layout")) {
                    tooltipClass = " class='" + content.data("layout") + "' ";
                    return "<div " + tooltipClass + ">" + content.html() + "</div>";
                } else {
                    return "<div>" + content.html() + "</div>";
                }
            }
        });

        var windowSize = $(window).width();
        if (windowSize < 767) {
            $('.tooltip').click(function() {
                $('#tooltip').toggleClass('tooltipShow');
            });
        } else {
            $('.tooltip').click(function(e) {
                e.preventDefault();
            });
        }
    }
};

module.exports = tooltip;
