'use strict';

var searchsuggest = require('./searchsuggest');
var util = require('./util');
var ajax = require('./ajax');
var validator = require('./validator');
var dialog = require('./dialog');
var tealium = require('./tealium');
var minicart = require('./minicart');
var page = require('./page');
var _ = require('lodash');
var floatlabels = require('./float-labels');

var $cachedDialog = null;
var globalevents = {
    /**
     * @private
     * @function
     * @description Apply dialogify event handler to all elements that match one or more of the specified selectors.
     */
    init: function () {
        $(".hf-mobile-searchbutton").click(function() {
            if ($(".hf-mobile-search").is(":hidden")) {
                $(".mobile-menu, .mobile-sl").hide();
                $(".hf-mobile-search").slideDown();
            }
            else {
                $(".hf-mobile-search").slideUp();
            }
        });

        //If you click outside of a jquery ui dialog, close it.
        $('body').on('click', '.ui-widget-overlay', function(){
            dialog.close();
        });

        $('.click-to-expand').on('click', function() {
            var $wrapper = $(this).parents('.expandable-wrapper');
            var $expandIcon = $wrapper.find('.expand-icon');

            //Toggle aria expanded
            $expandIcon.attr('aria-expanded', function (i, attr) {
                return attr == 'true' ? 'false' : 'true';
            });
            //Toggle icon
            $expandIcon.toggleClass('open');
            //Slide content open/close
            $wrapper.find('.content-to-expand').slideToggle();
        });

        $('.expand-button').on('click', function(e) {
            e.preventDefault();
            var $wrapper = $(this).parents('.expandable-wrapper');

            //Toggle aria expanded
            $(this).attr('aria-expanded', function (i, attr) {
                return attr == 'true' ? 'false' : 'true';
            });
            //Toggle icon
            $(this).toggleClass('open');
            //Slide content open/close
            $wrapper.find('.content-to-expand').slideToggle();
        });

        $('body').on('keydown', 'input[name$="_address1"]', function(e) {
            var $scrollbox = $('#edq-verification-suggestion-box');

            if ((e.keyCode == 38 || e.keyCode == 40) && $scrollbox.children().length > 0 ){
                var $el = $(".edq-global-intuitive-address-suggestion.selected");
                for (var i = 0; i < $scrollbox.children().length; i++){
                    var scrollPosition = $el.position().top;
                    var height = $scrollbox.height() - 1;
                    if (scrollPosition >= height) {
                        if ($scrollbox.children().eq(i).is($el)){
                            scrollPosition = $el.height() * (i - 2);
                            $scrollbox.scrollTop(scrollPosition);
                        }
                    }
                }
            }
            if (e.keyCode == 13) {
                initFloatLabels();
            }
        });

        $("body").on("click", function(e) {
            var $targetInput = $('input[name$="_address1"]');
            var address1Value = ($targetInput.length === 2) ? $targetInput.eq(1).val() : $targetInput.val();
            if (document.hasFocus() && $(document.activeElement).is($targetInput) && address1Value != "") {
                initFloatLabels();
            }
        });

        function initFloatLabels() {
            window.setTimeout(function () {
                floatlabels.init();
            }, 1500);
        }

        var controlKeys = ["8", "13", "46", "45", "36", "35", "38", "37", "40", "39"];

        $("body").on("click", ".dialogify, [data-dlg-options], [data-dlg-action]", setDialogify)
        .on("keydown", "textarea[data-character-limit]", function(e) {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data("character-limit"),
                charsUsed = text.replace(/(\r\n|\n|\r)/g, '').length;

                if ((charsUsed >= charsLimit) && (controlKeys.indexOf(e.which.toString()) < 0)) {
                    e.preventDefault();
                }
        })
        .on("change keypress keyup mouseup", "textarea[data-character-limit]", function(e) {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data("character-limit"),
                charsUsed = text.replace(/(\r\n|\n|\r)/g, '').length,
                charsRemain = charsLimit - charsUsed;

            if(charsRemain < 0) {
                $(this).val( text.slice(0, charsRemain) );
                charsRemain = 0;
            }

            $(this).next('div.char-count').find('.char-remain-count').html(charsRemain);
        });

        $(".account-logout, .logout-button").on("click", function() {
            tealium.userLogout();
        });

        //initialize search suggestions
        searchsuggest.init(window.app.ui.searchContainer, window.Resources.SIMPLE_SEARCH);

        //Trigger character limit calculations on page load in case we've added a saved message
        $('textarea[data-character-limit]').change();

        // print handler
        window.app.ui.printPage.on("click", function () { window.print(); return false; });


        // add show/hide navigation elements
        $('.hf-secondary-navigation .toggle').click(function() {
            $(this).toggleClass('expanded').next('ul').toggle();
        });

        // add generic toggle functionality
        $('.toggle').next('.toggle-content').hide();

        // subscribe email box
        if (window.app.ui.slots.subscribeEmail.length > 0)	{
            window.app.ui.slots.subscribeEmail.focus(function () {
                var input = $(this);
                var val = $.trim(input.val());
                if(val.length > 0 && val !== window.Resources.SUBSCRIBE_EMAIL_DEFAULT) {
                    return; // do not animate when contains non-default value
                }

                input.animate({ color: '#737373'}, 500, 'linear', function () {
                    input.val('').css('color','#333333');
                });
            }).blur(function () {
                var input = $(this);
                var val = $.trim(input.val());
                if(val.length > 0) {
                    return; // do not animate when contains value
                }

                input.val(window.Resources.SUBSCRIBE_EMAIL_DEFAULT)
                       .css('color','#737373')
                       .animate({color: '#333333'}, 500, 'linear');

            });
        }

        //footer phone number
        var phone = window.Preferences.phoneNumber;
        var mobileMenuOverlay = 1;
        $('.phoneNumber').html(phone);

        //mobile menu and footer
        if(window.Preferences.mobileMenuBackgroundOpacity != null) {
            mobileMenuOverlay = window.Preferences.mobileMenuBackgroundOpacity;
        }
        function headerFooterTabs() {
            var slideout = new window.Slideout({
                'panel': document.getElementById('panel'),
                'menu': document.getElementById('menu'),
                'tolerance': 70,
                'padding': 320,
                'touch': false
              });

            var focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
            var firstFocusableElement = $('.hf-mobile-menu').find(focusableElements).first();
            var focusableContent = $('.hf-mobile-menu').find(focusableElements);
            var lastFocusableElement = focusableContent.last();

            document.addEventListener('keydown', function(e) {
                if (e.keyCode === 27) {
                    slideout.close();
                }

                var isTabPressed = e.key === 'Tab' || e.keyCode === 9;

                if (!isTabPressed) {
                    return;
                }

                if (e.shiftKey) {
                    if (document.activeElement === firstFocusableElement[0]) {
                        lastFocusableElement.focus();
                        e.preventDefault();
                    }
                } else {
                    if (document.activeElement === lastFocusableElement[0]) {
                        firstFocusableElement.focus();
                        e.preventDefault();
                    }
                }
            });

            $('.hf-mobile-menubutton').on('click', function() {
              slideout.toggle();
              $('#hf-mobile-menu-close').focus();
              $('.slideout-panel').css('box-shadow','#ddd 0px -7px 7px inset');
              var $div = $("<div>", {id: "menu-overlay"});
              if($('#menu-overlay').length == 0) {
                  $("#panel").prepend($div);
              }
              $('#menu-overlay').css('opacity',mobileMenuOverlay);
            });

            slideout.once('open', slideout._initTouchEvents);
            slideout.on('open', slideout.enableTouch);
            slideout.on('open', function() {
                $('#panel').on('scroll touchmove mousewheel', function (event) {
                    event.preventDefault();
                });
                $('#menu-overlay, #hf-mobile-menu-close').on('click touchstart', function(e) {
                    e.preventDefault();
                    slideout.close();
                });
            });
            slideout.on('close', function() {
                $('#panel').unbind('scroll touchmove mousewheel');
                $('.hf-mobile-menubutton').focus();
            });
            slideout.on('beforeclose', function() {
                $('#menu .mobileMenuImg.closed').show();
                $('#menu .mobileMenuImg.open').hide();
                $('.hf-level-2').slideUp();
                $('#menu-overlay').remove();

            });

            slideout.on('close', slideout.disableTouch);

            $(".hf-level-1").each(function() {
                $("a.hf-level-1").unbind('click').click(function(e) {
                    if ($(this).siblings('.hf-level-2').length == 1) {
                        e.preventDefault();
                        $(this).siblings('.hf-level-2').slideToggle();
                        $(this).children('.mobileMenuImg.open, .mobileMenuImg.closed').toggle();
                        $(this).parent().siblings().children().next().slideUp();
                    } else {
                        $(this).children('.mobileMenuImg').hide();
                    }
                });
            });

            $(".footer-links > h2").each(function () {
                $(this).addClass('mobile-initialized');
                $(this).attr({
                    'aria-expanded': false,
                    'aria-controls': $(this).next('ul').attr('id'),
                    'role': 'button'
                });
            });
        }

        $(window).resize(function() {
            var windowSize = $(window).width();
            var $footerToggle = $(".footer-links > h2");

            if (windowSize > 736 && $footerToggle.first().hasClass('mobile-initialized')) {
                $footerToggle.removeAttr('aria-expanded aria-controls role').removeClass('mobile-initialized open');
                $(".footer-links ul").show();
            } else if (windowSize < 737 && !$footerToggle.first().hasClass('mobile-initialized')) {
                $(".footer-links > h2").each(function () {
                    $(this).addClass('mobile-initialized');
                    $(".footer-links ul").hide();
                    $footerToggle.removeClass('open');
                    $(this).attr({
                        'aria-expanded': false,
                        'aria-controls': $(this).next('ul').attr('id'),
                        'role': 'button'
                    });
                });
            }
        });

        $(".footer-links > h2").on('click', function(e) {
            var windowSize = $(window).width();
            if (windowSize < 737) {
                e.preventDefault();
                var ariaValue =  $(this).attr('aria-expanded');
                if (ariaValue === 'false' || ariaValue === false) {
                    $(this).attr('aria-expanded', 'true');
                } else {
                    $(this).attr('aria-expanded', 'false');
                }
                $(this).next('ul').slideToggle();
                $(this).toggleClass('open');
            }
        });

        var windowSize = $(window).width();
        if (windowSize < 737) {
            $(".footer-links > .footer-heading").each(function () {
                $(this).attr('aria-expanded', false);
            });
            headerFooterTabs();
        } else if(windowSize > 736 && windowSize < 960) {
            $('#hf-navigation .hf-level-1').each(function() {
                $(this).on("mouseover", function () {
                    if($(this).next('.hf-level-2').length) {
                        if($(this).next('.hf-level-2').children().find('.menu-column-2').length > 0 && $(this).next('.hf-level-2').children().find('.menu-column-3').length > 0 && $(this).children().find('.catPromos').length > 0) {
                            $(this).next('.hf-level-2').css({'left': '0', 'padding': '10px 20px 30px 30px'});
                        }
                        var rt = ($(window).width() - ($(this).next('.hf-level-2').offset().left + $(this).next('.hf-level-2').outerWidth()));

                        if(rt < 0) {
                            $(this).next('.hf-level-2').css({'right': '0'});
                        }
                    }
                });
            });
        }else{
            $('#hf-navigation .hf-level-1').each(function() {
                $(this).on("mouseover", function () {
                    if($(this).next('.hf-level-2').length) {
                        if($(this).next('.hf-level-2').children().find('.menu-column-2').length > 0 && $(this).next('.hf-level-2').children().find('.menu-column-3').length > 0 && $(this).children().find('.catPromos').length > 0) {
                            $(this).next('.hf-level-2').css({'left': '0', 'padding': '10px 20px 30px 30px'});
                        }
                        var rt = ($(window).width() - ($(this).next('.hf-level-2').offset().left + $(this).next('.hf-level-2').outerWidth()));

                        if(rt < 100) {
                            $(this).next('.hf-level-2').css({'right': '0'});
                        }
                    }
                });
            });
        }

        if (windowSize < 769 && windowSize > 736) {
            $("ul.hf-menu-category li").each(function() {
                $("a.hf-level-1").unbind('click').click(function(e) {
                    if($(this).parent().children('.hf-menu-category > li > .hf-level-2').length) {
                        e.preventDefault();
                        $(this).toggleClass('opened');
                        $(this).next('.hf-level-2').slideDown();
                        $(this).parent().siblings().children().next().slideUp(function() {
                            $(this).removeClass('opened');
                        });
                        if ($(this).hasClass('opened')) {
                             $(this).unbind('click');
                        }
                    }else{
                        $(this).unbind('click');
                    }
                });
            });
        }

        function gridToggle() {
            $('.hf-grid-toggle').attr('href','+location.href+');
            $('.toggle-grid').click(function(e) {
                e.preventDefault();
                $('.hf-search-result-content').addClass('wide-tiles');
                $('.toggle-grid').addClass('active');
                $('.toggle-list').removeClass('active');
                $('.hf-product-contents').each(function(index, domEle) {
                    var lisize = $(this).children('ul').find('li').size();
                    if(lisize > 8) {
                        $(this).siblings('.hf-view-more').show();
                    }
                });
                return false;
            });
            $('.toggle-list').click(function(e) {
                e.preventDefault();
                $('.hf-search-result-content').removeClass('wide-tiles');
                $('.toggle-list').addClass('active');
                $('.toggle-grid').removeClass('active');
                return false;
            });
            $('.toggle-list').trigger('click');
        }
        gridToggle();

        //Polyfill object-fit and object-position on images on IE
        //Wrapped in try/catch because it returns "Function Expected" on IE depending on the network speed.
        try{
            $(function () {
                window.objectFitImages();
            });
        }catch(e) {
            //This error will happen in IE, doesn't seem to harm functionality
            //Removed logging, to prevent unnecessary messaging
        }

        //Push alias pathname to datalayer
        utag_data.page_url = window.document.location.pathname;

        /* HF-2037: Forces iOS devices to not zoom in upon clicking an input, select, or textarea
         * This is achieved by forcing these html elements to have a font-size of at least 16px.
         */
        if(iOSDevice()) {
            $('body').addClass('ios-device');
        }

        $('body').on('click', '.emailSubscribeCS .closeEmailSubscribe', function(e) {
            e.preventDefault();
            dialog.close();
        });

        //For category banner
        if($('.category-banner').hasClass("variation2")) {
              $(".category-banner.variation2 img").clone().appendTo(".category-banner.variation2");
              $(".category-banner.variation2 img:first").remove();
        }

        if(jQuery('.promo-block1 li').length <= 4) {
            jQuery('.promo-block1').addClass('across4layout');
        }
        if(jQuery('.promo-block1 li').hasClass('across1plus3') == true) {
            jQuery('.promo-block1').addClass('across1plus3');
        }
        $(".category-banner .inner-box").children().each(function() {
              var showChar = 28;
              if($(this).text().length > showChar) {
                  $(this).text($(this).text().substr(0, showChar));
               }
        });
        var $miniCache = {
            dropDown 		: $('.account-dropDown'),
            dropdownBlock	: $('.account-dropDown .hf-level-2')
        };
        function onClose() {
            minicart.close();
            $miniCache.dropdownBlock.slideDown('slow');
        }

        function onOpen(e) {
            if(!$(this).data('hover')) {
                $miniCache.dropdownBlock.slideUp('slow');
            }
        }

        $miniCache.dropDown.hover( onClose, _.debounce(onOpen, 600) ).data('hover', false);
        $miniCache.dropdownBlock.mouseleave(function () {
            $miniCache.dropDown.data('hover', false);
        });
        $miniCache.dropdownBlock.mouseenter(function () {
            $miniCache.dropDown.data('hover', true);
        });

        //back to top function
        var $backTop = $('.back-top');
        $backTop.click(function () {
            $('html, body').animate({scrollTop: 0}, 500);
            $('.skip-to-content').focus();
        });
        $backTop.on('keydown', function (e) {
            if (e.which === 13) {
                e.preventDefault();
                $backTop.click();
            }
        });

        $('body').on('click','.hf-menu-category li.opened .open-menu-button', function() {
            $(this).closest('li').removeClass('opened');
            $(this).closest('li').find('.open-menu-button').attr('aria-expanded', false);
        });

        $('body').on('click','.hf-menu-category li:not(.opened) .open-menu-button', function() {
            $('.hf-menu-category li.opened .open-menu-button').attr('aria-expanded', false);
            $('.hf-menu-category li.opened').removeClass('opened');
            $(this).closest('li').addClass('opened');
            $(this).closest('li').find('.open-menu-button').attr('aria-expanded', true);
        });

        $('body').on('keydown', function (e) {
            if (e.which === 27) {
                $('.hf-menu-category li.opened .open-menu-button').attr('aria-expanded', false);
                $('.hf-menu-category li.opened').removeClass('opened');
            }
        });

        $('body').on('click', '.skip-to-content', function () {
            $('.hf-breadcrumb, #hf-wrapper, #wrapper, #hf-main, #main').first().attr('tabindex', '-1').focus();
        });
    }
};

/**
 * @function
 * @description
 */
function setDialogify(e) {
    e.preventDefault();
    var el = this,
        actionSource = $(this),
        useJson = false,
        dlgAction = $(actionSource).data("dlg-action") || {}, // url, target, isForm
        dlgOptions = $.extend({}, dialog.settings, $(actionSource).data("dlg-options") || {});

    dlgOptions.title = dlgOptions.title || $(actionSource).attr("title") || "";
    useJson = dlgOptions.useJson || false;

    var url = dlgAction.url || // url from data
              (dlgAction.isForm ? $(actionSource).closest("form").attr("action") : null) || // or url from form action if isForm=true
              $(actionSource).attr("href"); // or url from href

    if (!url) { return; }

    var form = jQuery(this).parents('form');
    var method = form.attr("method") || "POST";

    if (el.tagName !== 'A' && dlgAction.isForm && !form.valid()) {
        return;
    }
    // if this is a content link, update url from Page-Show to Page-Include
    if ($(this).hasClass("attributecontentlink")) {
        var uri = util.getUri(url);
        url = window.Urls.pageInclude + uri.query;
    }
    if (method && method.toUpperCase() == "POST") {
         var postData = form.serialize() + "&" + jQuery(this).attr("name") + "=submit";
    } else {
         if (url.indexOf('?') == -1 ) {
          url += '?';
         } else {
          url += '&';
         }
         url += form.serialize();
         url = util.appendParamToURL(url, jQuery(this).attr('name'), "submit");
    }
    if ($cachedDialog != null) {
        $cachedDialog.remove();
    }

    dlgOptions.close = function () {
        actionSource.focus();
        var focusAfterClose = 'focusAfterClose' in dlgOptions ? dlgOptions.focusAfterClose : null;
        if (focusAfterClose) {
            $(focusAfterClose).focus();
        }
        $(this).dialog("destroy");
    };

    $cachedDialog = dialog.create({target:dlgAction.target, options : dlgOptions});

    if (useJson) {
        ajax.getJson({
            url: $(actionSource).attr("href") || $(actionSource).closest("form").attr("action"),
            callback: function (response) {
                if (response) {
                    if (response.success) {
                        page.refresh();
                    } else if (response.html) {
                        $cachedDialog.empty().html(response.html);
                        $cachedDialog.dialog("open");
                        validator.init();
                    }
                }
            },
            data : !$(actionSource).attr("href") ? postData : null,
            type: method
        });
    } else {
        ajax.load({
            url: $(actionSource).attr("href") || $(actionSource).closest("form").attr("action"),
            target: $cachedDialog,
            callback: function () {
                $cachedDialog.dialog("open");
                validator.init();
                if ($(actionSource).hasClass('suppress-h1-heading')) {
                    window.setTimeout(function () {
                        var elemToChange = $cachedDialog.closest('.ui-dialog').find('.ui-dialog-titlebar h1');
                        elemToChange.replaceWith(elemToChange.html());
                        $cachedDialog.closest('.ui-dialog').find('.ui-dialog-title').addClass('title-additional-margin').focus();
                    }, 100);
                }
            },
            data : !$(actionSource).attr("href") ? postData : null
        });
    }

}

/* Detects if navigator platform is a mobile iOS device.
 * @return Boolean - true, if device is an iPad, iPhone, or iPod
 */
function iOSDevice() {
    return !!window.navigator.platform && /iPad|iPhone|iPod/.test(window.navigator.platform);
}

module.exports = globalevents;
