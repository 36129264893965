'use strict';

var validator = require('../validator');
var ajax = require('../ajax');
var giftcert = require('../giftcert');
var shipping = require('./checkout/shipping');
var page = require('../page');
var dialog = require('../dialog');
var util = require('../util');
var gdpr = require('../gdpr');
var tealium = require('../tealium');
var floatlabels = require('../float-labels');
var adyenCheckout = require('../adyen-checkout.js');

var $cache = {};
/**
 * @private
 * @function
 * @description Initializes the events on the address form (apply, cancel, delete)
 * @param {Element} form The form which will be initialized
 */
function initializeAddressForm(form) {

    var form = $("#dwfrm_profile_address");
    //insert timestamp into hidden AddressID field - rest of Avalara functionality requires an addressID
    var nicknameInput = $( "input[name*='addressid']");
    if (nicknameInput.length > 0) {
        //only generate timestamp if there is no existing value
        if (nicknameInput.val().length < 1) {
            nicknameInput.val(new Date().getTime());
        }
    }
    form.find("input[name='format']").remove();

    form.on("click", ".cancel-button, .close-button", function(e) {
        e.preventDefault();
        dialog.close();
    })
    .on("click", ".delete-button", function(e) {
        e.preventDefault();
        if (window.confirm(String.format(window.Resources.CONFIRM_DELETE, window.Resources.TITLE_ADDRESS))) {
            var url = util.appendParamsToUrl(window.Urls.deleteAddress, {AddressID:form.find("#addressid").val(),format:"ajax"});
            $.ajax({
                url: url,
                method: "POST",
                dataType:"json"
            }).done(function(data) {
                if (data.status.toLowerCase() === "ok") {
                    dialog.close();
                    page.refresh();
                }
                else if (data.message.length > 0) {
                    window.alert(data.message);
                    return false;
                }
                else {
                    dialog.close();
                    page.refresh();
                }
            });
        }
    })
    .on('change', "select[id$='_country']", function() {
        util.updateStateOptions(this);
    });

    $('form[name$="profile_address"]').on('submit', function (e) {
        var isValid = util.formCustomValidation($('form[name$="profile_address"]'));
        if (!isValid) {
            e.preventDefault();
            return false;
        }
    });

    validator.init();

    util.AddressTypeChange();

    shipping.initializeAvalaraFunctionality('account');

    floatlabels.init();
    // once page reloaded with invalid form - run validation
    if ($('#runValidationOnInit').length > 0) {
        util.formCustomValidation($('form[name$="profile_address"]'));
    }


    // initialize state drop down
    $('#edit-address-form').on('change', "select[id$='_country']", function() {
        util.updateStateOptions(this);
    });

    //reorder button triggers tealium cartAdd() event
    $('.reorder-button').on('click', function() {
        //for each product line item, trigger the cart add event
        var $shipentBlock = $(this).parents('.order-history-header').siblings('.order-history-items');
        if($shipentBlock.length === 0) {
            $shipentBlock = $('#hf-primary');
        }
        $shipentBlock.find('.tealium-product-data').each(function() {
            var $this = $(this);
            tealium.cartAdd($this.data('tealium'), $this.val());
        });
    });

}

/**
 * @private
 * @function
 * @description Toggles the list of Orders
 */
function toggleFullOrder () {
    $('.order-items')
        .find('li.hidden:first')
            .prev('li')
                .append('<a class="toggle">View All</a>')
                .children('.toggle')
                    .click(function() {
                        $(this).parent().siblings('li.hidden').show();
                        $(this).remove();
                    });
}
/**
 * @private
 * @function
 * @description Binds the events on the address form (edit, create, delete)
 */
function initAddressEvents() {
    var addresses = $("#addresses");
    if (addresses.length === 0) { return; }

    addresses.on("click", "a.address-edit, a.hf-address-create", function(e) {
        e.preventDefault();
        var options = {open: initializeAddressForm};
//			dialog.open({url:this.href, options:options});
        window.location.href = this.href;
    }).on("click", ".delete", function(e) {
        e.preventDefault();
        if (window.confirm(String.format(window.Resources.CONFIRM_DELETE, window.Resources.TITLE_ADDRESS))) {
            $.ajax({
                url: util.appendParamsToUrl($(this).attr("href"), {format:"ajax"}),
                dataType:"json"
            }).done(function(data) {
                if (data.status.toLowerCase() === "ok") {
                    page.redirect(window.Urls.addressesList);
                }
                else if (data.message.length > 0) {
                    window.alert(data.message);
                }
                else {
                    page.refresh();
                }
            });
        }
    });
}
/**
 * @private
 * @function
 * @description Binds the events of the payment methods list (delete card)
 */
function initPaymentEvents() {
    var paymentList = $(".payment-list");
    if (paymentList.length === 0) { return; }

    util.setDeleteConfirmation(paymentList, String.format(window.Resources.CONFIRM_DELETE, window.Resources.TITLE_CREDITCARD));

    $("form[name='payment-remove']").on("submit", function(e) {
        e.preventDefault();
        // override form submission in order to prevent refresh issues
        var button = $(this).find("button.delete");
        $("<input/>").attr({type:"hidden", name:button.attr("name"), value:button.attr("value") || "delete card"}).appendTo($(this));
        var data = $(this).serialize();
        $.ajax({
            type: "POST",
            url: $(this).attr("action"),
            data: data
        })
        .done(function(response) {
            page.redirect(window.Urls.paymentsList);
        });
    });



}
/**
 * @private
 * @function
 * @description Binds the events of the order, address and payment pages
 */
function initializeEvents() {
    toggleFullOrder();
    initializeAddressForm();
    initAddressEvents();
    initPaymentEvents();
    if (window.Preferences.ADYEN_SF_ENABLED)  {
        adyenCheckout.initAccount();
    }

    $('#downloadData').on('click', function(e) {
        e.preventDefault();

        ajax.load({
            url:$(this).attr('href'),
            callback: function(data) {

                var fileName = $(data).filter('#fileName').val();
                var customerData = $(data).filter('#customerData').val();
                util.downloadObjectAsJson(customerData, fileName);//obj, name

            }
        });
    });

    $('#forgetMe').on('click', function(e) {
        e.preventDefault();

        gdpr.gdprDeleteUser();
    });

    /*
     * Change event handler for radio buttons on the My Account landing page.
     * Abilty to toggle agree and disagree to GDPR tracking.
     * */
    $(".privacy .options input:radio.checkbox").on('change', function(e) {
        var $this = $(this),
            agree_consent = "disagree";

        //Set to a string of agree or disagree for signutire of function.
        if($this.val() == "true") {
            agree_consent = "agree";
        }

        //Call function to change consent.
        gdpr.gdpr(agree_consent, false);// Consent/From Dialog
    });

}

var account = {
    /**
     * @function
     * @description Binds the events of the order, address and payment pages
     */
    init : function () {
        initializeEvents();

        giftcert.init();
    }
};

module.exports = account;