'use strict';

var validator = require('./validator');
var ajax = require('./ajax');
var dialog = require('./dialog');
var util = require('./util');

var $cache = {},
    initialized = false;
/**
 * @private
 * @function
 * @description Initializes the events (preview, send, edit, cancel and close) on the send to friend form
 */
function initializeEvents() {
    util.limitCharacters();
    if (initialized) {return; }
    $cache.dialog.on("click", ".preview-button, .send-button, .edit-button", function (e) {
        e.preventDefault();
        $cache.form.validate();
        if (!$cache.form.valid()) {
            return false;
        }
        var requestType = $cache.form.find("#request-type");
        if (requestType.length > 0) {
            requestType.remove();
        }
        $("<input/>").attr({id:"request-type", type:"hidden", name:$(this).attr("name"), value:$(this).attr("value")}).appendTo($cache.form);
        var data = $cache.form.serialize();
        ajax.load({url:$cache.form.attr("action"),
               data: data,
               target: $cache.dialog,
               callback: function() {
                    validator.init();
                    util.limitCharacters();
                    $cache.form = $("#send-to-friend-form");
                    $(".ui-dialog-content").dialog("option", "position", "center");
               }
        });
    })
    .on("click", ".cancel-button, .close-button", function (e) {
        e.preventDefault();
        $cache.dialog.dialog("close");
    });
    initialized = true;
}

var sendtofriend = {
    init : function () {
        $cache = {
            form: $("#send-to-friend-form"),
            dialog: $("#send-to-friend-dialog"),
            pdpForm: $("form.pdpForm")
        };
        initializeEvents();
    },

    /**
     * @function
     * @description
     */
    initializeDialog : function (eventDelegate, eventTarget) {
        $(eventDelegate).on("click", eventTarget, function (e) {
            e.preventDefault();
            var dlg = dialog.create({target:$("#send-to-friend-dialog"), options:{
                width:800,
                height:'auto',
                title:this.title,
                open:function() {
                    sendtofriend.init();
                    validator.init();
                }
            }});

            var data = util.getQueryStringParams($("form.pdpForm").serialize());
            if (data.cartAction) {
                delete data.cartAction;
            }
            var url = util.appendParamsToUrl(this.href, data);
            url = this.protocol + "//" + this.hostname + ((url.charAt(0) === "/") ? url : ("/" + url));
            ajax.load({
                url:util.ajaxUrl(url),
                target:dlg,
                callback: function () {
                    dlg.dialog("open");	 // open after load to ensure dialog is centered
                }
            });
        });
    }
};

module.exports = sendtofriend;
