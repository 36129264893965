'use strict';

var ajax = require('./ajax');
var util = require('./util');

var giftcard = {
    /**
     * @function
     * @description Load details to a given gift certificate
     * @param {String} id The ID of the gift certificate
     * @param {Function} callback A function to called
     */
    checkBalance : function (id, callback) {
        // load gift certificate details
        var url = util.appendParamToURL(window.Urls.giftCardCheckBalance, "giftCertificateID", id);

        ajax.getJson({
            url: url,
            callback: callback
        });
    }
};

module.exports = giftcard;
