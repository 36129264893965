'use strict';

var validator = require('../../validator'),
    ajax = require('../../ajax'),
    util = require('../../util'),
    giftcard = require('../../giftcard'),
    shipping = require('./shipping'),
    addressupdate = require('../address'),
    adyenCheckout = require('../../adyen-checkout.js');

var $cache = {};

/**
 * @function
 * @description loads billing address, Gift Certificates, Coupon and Payment methods
 */
exports.init = function () {
    initializeCache();
    initializeEvents();
    var $payType = $('[name="brandCode"]');
    var $issuer = $('.issuer');
    var selectedPayType = $payType.find(':checked').val();
    $issuer.on('change', function () {
        updatePaymentType($(this).val(), true);
    });
    $("input[name$='_address1']").attr('autocomplete', 'address-line1');
    if (window.Preferences.ADYEN_SF_ENABLED)  {
        adyenCheckout.initBilling();
        $payType.on('change', function() {
            $('#selectedIssuer').val("");
            $issuer.hide();
            $('.checkoutComponent').hide();
            $('#component_' + $(this).val()).show();
                if($(this).siblings( ".issuer").length > 0){
                    $('#selectedIssuer').val($(this).siblings( ".issuer" ).val());
                    $(this).siblings('.issuer').show();
                }
            });
    }

};

/**
* @function
* @description Changes the payment type or issuerId of the selected payment method
* @param {String, Boolean} value of payment type or issuerId and a test value to see which one it is, to which the payment type or issuerId should be changed to
*/
function updatePaymentType(selectedPayType, issuerType) {
    if (issuerType) {
        $('#dwfrm_adyPaydata_issuer').val(selectedPayType);
    } else {
        $('input[name="brandCode"]').removeAttr('checked');
        $('input[value=' + selectedPayType + ']').prop('checked', 'checked');
    }
    // if the payment type has hidden fields reveal it
    $('#component_' + selectedPayType).show();
    //formPrepare.validateForm();
}

/*              Private Functions           */
function removeCouponSourceCode(btn) {
    var formRow = $('#add-coupon').parents('.form-row');
    var errorContainer = formRow.next('.error');
    var msgContainer = formRow.nextAll('.msg');

    errorContainer.html('');
    msgContainer.html('');

    var url = btn.attr('data-removeurl');

    $.getJSON(url, function(data) {

        var fail = false;
        var msg = "";
        if (!data) {
            msg = window.Resources.BAD_RESPONSE;
            fail = true;
        } else if (!data.success) {
            msg = data.message;
            fail = true;
        }
        if (fail) {

            if (errorContainer.length) {
                errorContainer.html(msg);
            } else {
                errorContainer = $('<div>', { class:"error"});
                errorContainer.html(data.message);
                errorContainer.insertAfter(formRow);
            }

            return;
        }

        if (data.couponerror) {

            if (errorContainer.length) {
                errorContainer.html(data.message);
            } else {
                errorContainer = $('<div>', { class:"error"});
                errorContainer.html(data.message);
                errorContainer.insertAfter(formRow);
            }
        } else if (data.couponmsg) {

            if (msgContainer.length) {
                msgContainer.html(data.message);
            } else {
                msgContainer = $('<div>', {class:"msg"});
                msgContainer.html(data.message);
                msgContainer.insertAfter(formRow);
            }


            var parents = btn.parents('.sourcecode, .couponcode');
            parents.remove();

            shipping.updateSummary();

        }
    });
}

function clearBillingFormData () {
    $cache.addressid.val('');
    $cache.addressid.attr('value','').trigger('change');

    $cache.firstName.val('');
    $cache.firstName.attr('value','').trigger('change');

    $cache.lastName.val('');
    $cache.lastName.attr('value','').trigger('change');

    $cache.address1.val('');
    $cache.address1.attr('value','').trigger('change');

    $cache.address2.val('');
    $cache.address2.attr('value','').trigger('change');

    $cache.city.val('');
    $cache.city.attr('value','').trigger('change');

    $cache.postalCode.val('');
    $cache.postalCode.attr('value','').trigger('change');

    $cache.phone.val('');
    $cache.phone.attr('value','').trigger('change');

    $cache.stateCode.val('');
    $cache.stateCode.attr('value','').trigger('change');

    $cache.postOffice.val('');
    $cache.postOffice.attr('value', '').trigger('change');

    $cache.companyName.val('');
    $cache.companyName.attr('value', '').trigger('change');
    //Reset country to it's first option ("United States")
    $cache.countryCode.children(":first").attr("selected", "selected").prop('selected', true).trigger('change');
    //Reset addressType to it's first option ("Resedential")
    $cache.addressType.children(":first").attr("selected", "selected").prop('selected', true).trigger('change');
}

function updateBillingFormDataWithSelectedShipTo (inputObject) {
    var addressObject = $('.select-address-dropdown option:selected');

    if (addressObject.length == 0) {
        // we have a single shipment scenario, grab data from input object
        addressObject = inputObject;
    }

    var firstName = addressObject.attr('data-addressfirstname');
    var lastName = addressObject.attr('data-addresslastname');
    var address1 = addressObject.attr('data-addressaddress1');
    var address2 = addressObject.attr('data-addressaddress2');
    var city = addressObject.attr('data-addresscity');
    var postalCode = addressObject.attr('data-addresspostalcode');
    var phone = addressObject.attr('data-addressphone');
    var countryCode = addressObject.attr('data-addresscountrycode');
    var stateCode = addressObject.attr('data-addressstatecode');
    var addressType = addressObject.attr('data-addresstype');
    var addressID = addressObject.attr('data-addressid');
    var companyName = addressObject.attr('data-addresscompanyname');
    var postalOffice = '';

    if (addressType === 'military') {
        util.updateStateOptions($cache.countryCode, true);
        //PostalOffice is stored as the city on military addresses.
        postalOffice = city;
    }

    $cache.addressType.val(addressType).trigger('change');
    $cache.countryCode.val(countryCode).trigger('change');
    $cache.firstName.attr('value', firstName).val(firstName);
    $cache.lastName.attr('value', lastName).val(lastName);
    $cache.address1.attr('value', address1).val(address1);
    $cache.address2.attr('value', address2).val(address2);
    $cache.city.attr('value', city).val(city);
    $cache.postalCode.attr('value', postalCode).val(postalCode);
    $cache.phone.val(phone);
    $cache.stateCode.val(stateCode);
    $cache.postOffice.val(postalOffice);
    $cache.companyName.attr('value', companyName).val(companyName);
    $cache.addressid.attr('value', addressID).val(addressID);
}

/**
 * @function
 * @description Changes the payment method form depending on the passed paymentMethodID
 * @param {String} paymentMethodID the ID of the payment method, to which the payment method form should be changed to
 */
function changePaymentMethod(paymentMethodID) {
    $(".missing-payment-error-msg").addClass('hidden');
    $cache.paymentMethods.removeClass("payment-method-expanded");
    var pmc = $cache.paymentMethods.filter("#PaymentMethod_" + paymentMethodID);
    if (pmc.length === 0) {
        pmc = $("#PaymentMethod_Custom");
    }
    pmc.addClass("payment-method-expanded");

    // ensure checkbox of payment method is checked
    if ($("#is-" + paymentMethodID).length) {
        $("#is-" + paymentMethodID)[0].checked = true;
    }

    // grey out the non-checked icon block, make sure the checked option is highlighted
    if (paymentMethodID === 'PayPal' || paymentMethodID === 'AdyenComponent') {
        $('.form-row.CREDIT_CARD').addClass('inactive');
        $('.form-row.PayPal').removeClass('inactive');
        $('.form-row.AdyenComponent').removeClass('inactive');
        $('.credit-cards').addClass('visually-hidden');
    } else {
        $('.form-row.CREDIT_CARD').removeClass('inactive');
        $('.form-row.PayPal').addClass('inactive');
        $('.form-row.AdyenComponent').addClass('inactive');
        $('.credit-cards').removeClass('visually-hidden');
        $cache.ccNum.trigger('change');
    }

    if (paymentMethodID === 'Adyen' || paymentMethodID === 'AdyenComponent') {
        updatePaymentType(paymentMethodID, false);
    }


    var bmlForm = $cache.checkoutForm.find("#PaymentMethod_BML");
    bmlForm.find("select[name$='_year']").removeClass("required");
    bmlForm.find("select[name$='_month']").removeClass("required");
    bmlForm.find("select[name$='_day']").removeClass("required");
    bmlForm.find("input[name$='_ssn']").removeClass("required");

    if (paymentMethodID === "BML") {
        var yr = bmlForm.find("select[name$='_year']");
        bmlForm.find("select[name$='_year']").addClass("required");
        bmlForm.find("select[name$='_month']").addClass("required");
        bmlForm.find("select[name$='_day']").addClass("required");
        bmlForm.find("input[name$='_ssn']").addClass("required");
    }
    validator.init();
    ShowHideContinueButton();
}

/**
 * @function
 * @description Fills the Credit Card form with the passed data-parameter and clears the former cvn input
 * @param {Object} data The Credit Card data (holder, type, masked number, expiration month/year)
 */
function setCCFields(data) {
    $cache.ccOwner.val(data.holder).trigger('change');
    $cache.ccType.val(data.type).trigger('change');
    $cache.ccNum.val(data.fullcardNumber).trigger('change');
    $cache.ccNumHide.val(data.fullcardNumber);
    $cache.ccMonth.val(data.expirationMonth).trigger('change');
    $cache.ccYear.val(data.expirationYear).trigger('change');
    /******** code added for cybersource integration ********/
    $cache.ccSubscription.val(data.isSubscription);
    $cache.ccMaskedFourDigit.val(data.maskedFourDigit);
    $cache.ccMaskedFourDigit.parent().show();
    $cache.ccNum.parent().hide();
    /******** end code added for cybersource integration ********/
    $cache.ccCcv.val("").trigger('change');
    //$cache.ccSaveCard.parent().hide();
    var $creditCard = $('#newCard');
    $creditCard.find('[name$="selectedCardID"]').val(data.selectedCardID).trigger('change');
    // remove error messages
    $cache.ccContainer.find(".errormessage")
                      .toggleClass("errormessage")
                      .filter("span").remove();

    $cache.ccContainer.find(".errorlabel").toggleClass("errorlabel");
}

/**
 * @function
 * @description Clears the Credit Card form
 */
function clearCCFields() {
    $cache.ccOwner.val("").trigger('change');
    $cache.ccType.val("").trigger('change');
    $cache.ccNum.val("").trigger('keyup');
    $cache.ccMonth.val("").trigger('change');
    $cache.ccYear.val("").trigger('change');
    $cache.ccSubscription.val("");
    $cache.ccMaskedFourDigit.val("");
    $cache.ccMaskedFourDigit.parent().hide();
    $cache.ccNum.parent().show();
    /******** end code added for cybersource integration ********/
    $cache.ccCcv.val("").trigger('change');
    $cache.ccSaveCard.parent().show();
    // remove error messages
    $cache.ccContainer.find(".errormessage")
                      .toggleClass("errormessage")
                      .filter("span").remove();

    $cache.ccContainer.find(".errorlabel").toggleClass("errorlabel");
}

/**
 * @function
 * @description Updates the credit card form with the attributes of a given card
 * @param {String} cardID the credit card ID of a given card
 */
function populateCreditCardForm(cardID) {
    // load card details
    var url = util.appendParamToURL(window.Urls.billingSelectCC, "creditCardUUID", cardID);
     ajax.getJson({
        url: url,
        callback: function (data) {
            if(!data) {
                window.alert(window.Resources.CC_LOAD_ERROR);
                return false;
            }
            $cache.ccList.data(cardID, data);
            setCCFields(data);
        }
    });
}

function hasEmptyRequired () {
    var $form = $('form[id$="billing"]'),
        $requiredInputs = $('.required', $form).find(':input');


    var requiredValues = $requiredInputs.filter(':visible').map(function () {
        return $(this).val();
    });

    // check to see if any visible, required fields are empty
    for (var i = 0; i < requiredValues.length; i++) {
        if (requiredValues[i] == '')
            {return true;}
    }
    return false;
}

function isValidCVNLength() {
    // returns true if CC is not selected as payment method, else checks for valid CVN length
    if($('#is-CREDIT_CARD').is(":checked") && !window.Preferences.ADYEN_SF_ENABLED) {
        var length = $("input[name$='_cvn']").val().length;
        var isAmericanExpress = ($("input[name$='_creditCard_number']").val().indexOf('3') === 0);
        if((length === 4 && isAmericanExpress) || (length === 3 && !isAmericanExpress)) {
            return true;
        }
        return false;
    }else{
        return true;
    }
}

function ShowHideContinueButton() {
    var noPaymentNeeded = false;
    if ($("#noPaymentNeeded").length > 0 && $(".giftcert-pi").length > 0) {
        noPaymentNeeded = true;
    }
    var paymentMethodId = $cache.paymentMethodId.filter(":checked");
    if (paymentMethodId.length === 0 && !noPaymentNeeded) {
        //determine if gift card was used to pay all the amount?
        $('#save-billing-btn').attr('disabled','disabled');
    // if pay method exists and payment is needed, check for empty required fields & CVN (if CC selected)
    } else if (hasEmptyRequired() || !isValidCVNLength()) {
        $('#save-billing-btn').attr('disabled','disabled');
    } else {
        // no empty required fields and CVN length is valid (or paymethod is not CC)
        $('#save-billing-btn').removeAttr('disabled');
    }
}

/**
 * @function
 * @description returns a boolean result to whether the required billing fields are completed.
 */
function billingDataComplete() {
    if ($cache.firstName.val().length === 0 ||
        $cache.lastName.val().length === 0 ||
        $cache.address1.val().length === 0 ||
        $cache.city.val().length === 0 ||
        $cache.stateCode.val().length === 0 ||
        $cache.postalCode.val().length === 0) {
            return false;
    } else {
        return true;
    }

}

function saveBillingClicked(e) {
    e.preventDefault();

    var $body = $('body');
    var headerHeight = window.isMobileUserAgent ? $('#hf-header').height() * 2.5 : $('.full-header-wrapper').height() * 2.5;

    /*
     * determine if the [Continue] button press should be treated
     * as a PayPal incontext button press or as a button press
     * that will be handled by the pipeline flow (saveBillingAction)
     */
    var paypalselected = false;
    var paymentMethodId = $cache.paymentMethodId.filter(":checked");
    if ((paymentMethodId.length !== 0) && (paymentMethodId.val().toLowerCase() === "paypal")) {
        paypalselected = true;
    }

    if ($cache.ccSaveCard.is(':checked')) {
        $cache.ccSaveCard.val(true);
    }

    var paypalIncontextActive = $cache.ppIncontext.html();

    // determine if the order total was paid using gift cert or a promotion
    if ($("#noPaymentNeeded").length > 0 && $(".giftcert-pi").length > 0) {
        $cache.saveBillingAction.click();
    } else if (paymentMethodId.length === 0) {
        // no payment method was selected,
        // unhide a message asking to fill in required fields
        $(".incontext-error-msg").removeClass('hidden');
        $(".missing-payment-error-msg").removeClass('hidden');

        $('html, body').animate({
            scrollTop: $('.hf-payment-methods').offset().top - $body.offset().top - headerHeight
        }, 500);
        e.stopPropagation();
    } else if (paypalselected && paypalIncontextActive === "true") {
        // check that all required fields have been filled in
        if (billingDataComplete()) {
            // begin the incontext checkout
            $cache.savePayPalInContextAction.click();
        } else {
            // unhide a message asking to fill in required fields
            $(".incontext-error-msg").removeClass('hidden');
        }
    } else {
        var $tokenizationError = $('#tokenization-error');

        // check if tokenization enabled
        if(!window.Preferences.ADYEN_SF_ENABLED && paypalselected == false) {
            var $token = $('#creditCardToken');
            var $form = $('form[id$="billing"]');

            // TODO: script to populate saved token's needs to be created to populate token form field

            // check for token
            var formValid = util.formCustomValidation($form) && $form.valid();
            if ($token.length && $token.val() === '' && $token.parent().is(':visible') && $form.length && formValid) {
                var $creditCard = $('[data-method="CREDIT_CARD"]');
                var requestObj = {};

                // check for auth errors
                if ('error' in window.i4goTrueTokenObj) {
                    $tokenizationError.html(window.i4goTrueTokenObj.error);
                    $('*[name$="_billing_save"]').attr('disabled', 'disabled');

                    $('html, body').animate({
                        scrollTop: $tokenizationError.offset().top - $body.offset().top - headerHeight
                    }, 500);
                    return false;

                } else { // proceed with tokenization
                    // translate cc type into 2 digit code
                    var i4goCardType = '';
                    if ($cache.creditcardblock.find('select[name$="_type"]').val() != null) {
                        switch ($cache.creditcardblock.find('select[name$="_type"]').val()) {
                        case 'Visa':
                            i4goCardType = 'VS';
                            break;

                        case 'Master':
                            i4goCardType = 'MC';
                            break;

                        case 'Discover':
                            i4goCardType = 'NS';
                            break;

                        case 'Amex':
                            i4goCardType = 'AX';
                            break;
                        }
                    } else {
                        $tokenizationError.html(window.Resources.CC_TYPE_INVALID);
                        $('html, body').animate({
                            scrollTop: $tokenizationError.offset().top - $body.offset().top - headerHeight
                        }, 500);
                        return false;
                    }
                    // check for a stored cc, if so load full cc for i4go verification
                    var ccnum = $cache.creditcardblock.find('input[name$="_number"]').val();
                    //Remove whitespace before sending to backend
                    ccnum = ccnum.replace(/\s/g, '');
                    if (ccnum.indexOf('****') == 0) {
                        ccnum = $cache.creditcardblock.find('input[id$="_numberhide"]').val();
                    }

                    // per request from Shift4 certification remove '-' from zip 9
                    var postalcode = $cache.postalCode.val();
                    if (postalcode != null) {
                        postalcode = postalcode.replace('-', '');
                    }
                    var $creditCardMonth = $cache.creditcardblock.find('[name$="_month"]').val();
                    // request object
                    requestObj = {
                        fuseaction: 'api.jsonPostCardEntry',
                        i4go_accessblock: window.i4goTrueTokenObj.accessBlock,
                        i4go_cardtype: i4goCardType,
                        i4go_cardnumber: ccnum,
                        i4go_expirationmonth: $creditCardMonth < 10 ? "0" + $creditCardMonth : $creditCardMonth,
                        i4go_expirationyear: Number("20" + $cache.creditcardblock.find('[name$="_creditCard_year"]').val()),
                        i4go_cvv2code: $cache.creditcardblock.find('input[name$="_cvn"]').val(),
                        i4go_cvv2indicator: '1',    // hard code cvv entered by user
                        i4go_cardholdername: $creditCard.find('input[name$="_owner"]').val(),
                        i4go_postalcode: postalcode,
                        i4go_streetaddress: $cache.address1.val()
                    };

                    // make tokenization call
                    $.post(window.i4goTrueTokenObj.server + '/index.cfm', requestObj, function (data) {

                        if (data.i4go_responsecode !== '1') {
                            //TODO: clean up error message styling
                            $tokenizationError.html(data.i4go_responsetext.replace(/\s?\(\d+\),?/g, ''));
                            $('html, body').animate({
                                scrollTop: $tokenizationError.offset().top - $body.offset().top  - headerHeight
                            }, 500);
                        } else {
                            $token.val(data.i4go_uniqueid);
                            //Remove whitespace before sending to backend
                            var ccnum = $cache.creditcardblock.find('input[name$="_number"]').val();
                            $cache.creditcardblock.find('input[name$="_number"]').val(ccnum.replace(/\s/g, ''));
                            $cache.saveBillingAction.click();

                        }
                    })
                    .error(function () {
                        // obtain a new accessblock and try again
                        var url = window.Urls.getNewAccessBlock;
                        $.ajax({
                            url: url,
                            type: 'GET',
                            async: false,
                            dataType: 'json',
                            success: function (data) {
                                if (data.i4go_responsecode !== '1') {
                                    $tokenizationError.html('We have experienced a communication error, please return to the shipping page, confirm your information and continue on to billing from there.');
                                    $('html, body').animate({
                                        scrollTop: $tokenizationError.offset().top - $body.offset().top - headerHeight
                                    }, 500);
                                } else {
                                    window.i4goTrueTokenObj.server = data.i4go_server;
                                    window.i4goTrueTokenObj.accessBlock = data.i4go_accessblock;

                                    //Remove whitespace before sending to backend
                                    var ccnum = $cache.creditcardblock.find('input[name$="_number"]').val();
                                    $cache.creditcardblock.find('input[name$="_number"]').val(ccnum.replace(/\s/g, ''));

                                    $cache.saveBillingBtn.click();
                                }

                            },
                            failure : function (data) {
                                $tokenizationError.html('We have experienced a communication error, please return to the shipping page, confirm your information and continue on to billing from there.');
                                $('html, body').animate({
                                    scrollTop: $tokenizationError.offset().top - $body.offset().top - headerHeight
                                }, 500);
                            }
                        });

                    });


                }
            }
        } else {
            // tokenization not enabled, continue checkout
            //Remove whitespace before sending to backend
            var ccnum = $cache.creditcardblock.find('input[name$="_number"]').val();
            $cache.creditcardblock.find('input[name$="_number"]').val(ccnum.replace(/\s/g, ''));

            $cache.saveBillingAction.click();
        }

        $('html, body').animate({
            scrollTop: $('form[id$="billing"] .error:visible').first().offset().top - $body.offset().top - headerHeight
        }, 500);

        // prevent form submission
        return false;
    }
}

function initializeEvents() {
    util.initCustomDropdowns(true);

    addressupdate.initStateDropdown();

    $('#is-CREDIT_CARD').prop('checked', true).trigger('click');
    validator.init();
    util.AddressTypeChange();
    window.GRI.militaryAddressExamples();
    util.handleAddressTypeChange('.billing-upper');

    if( !$cache.paymentMethodId ) {return;}

    /*
     * on billing load light up all the applicable payment logos
     */
    var paymentMethodId = $cache.paymentMethodId.filter(":checked");
    if (paymentMethodId.length === 0) {
        $("[id^=creditcard-logo-]").addClass('active').fadeTo(0, 1);
    } else if (paymentMethodId.val().toLowerCase() == "paypal" || paymentMethodId.val() == "AdyenComponent") {
        $("[id^=creditcard-logo-]").removeClass('active').fadeTo(0, 0.25);
    } else {
        $("[id^=creditcard-logo-]").addClass('active').fadeTo(0, 1);
    }


    var $form = $('form[id$="billing"]'),
        $continue = $('[name$="billing_save"]'),
        $requiredInputs = $('.required', $form).find(':input');

    $requiredInputs.on('keyup change', ShowHideContinueButton);
    ShowHideContinueButton();

    $cache.paymentMethodId.on("click", function () {
        var $cvvtooltip = $('.hf-cvn-tip a');

        changePaymentMethod($(this).val());

        if ($cvvtooltip.is(':visible')) {
            $cvvtooltip.off('click').on('click', function (e) {
                e.preventDefault();
            });
        }
    });

    // if returning from the Place Order page then the billing address
    // may not be from one of the shipping address entries, if it
    // doesn't match a shipping entry then show the 'different billing address' area
    if ($('input[name="shippingaddressmatched"]').length === 0 || $('input[name="shippingaddressmatched"]').val() == 'false') {
        // change the checkbox and open the billing address
        $('input[value="true"]').removeAttr('checked');
        $('input[id="differentbilling"]').attr('checked','checked');
        $('.hf-use-billing input').attr('value','false');

        $('.billing-upper').slideDown();
        $('#use-shipping-address-address').hide();
        $('.edit-address').hide();
    } else {
        $('#useAsBillingAddress').trigger('click');
        $('.billing-upper').slideUp();
        $('#use-shipping-address-address').show();
        $('.edit-address').show();
    }

    $('.edit-address').click(function(e) {
        e.preventDefault();
        $('#useAsBillingAddress').trigger('click', ['editBillingAddress']);
        $('.billing-upper').slideDown();
        $('#use-shipping-address-address').hide();
        $('.edit-address').hide();
    });

    $cache.addressDropdown.on("change", function () {
        // only update the form if 'use shipping address' is checked
        if ($('[id="useAsBillingAddress"]').attr('checked') == "checked") {
            //clear defaulted billing address
            clearBillingFormData();

            // update the billing form with the values from the address drop down
            updateBillingFormDataWithSelectedShipTo();
        }
    });


    function useAsBillingAdddressClick(manualTrigger, eventType) {
        var $checkbox = $('#useAsBillingAddress');
        window.setTimeout(function () {
            if($checkbox.is(':checked')) {
                $('input[value="false"]').removeAttr('checked');
                $('.hf-use-billing input').attr('value','true');

                //populate address from selected address in drop down
                updateBillingFormDataWithSelectedShipTo($checkbox);
                $('.billing-upper').slideUp();
                $('#use-shipping-address-address').show();
                $('.edit-address').show();
            } else {
                if (manualTrigger !== true && eventType !== 'editBillingAddress') {
                     clearBillingFormData();
                }
                // For registered user that have a saved address
                if (eventType === 'editBillingAddress' && $('select[name$=_addressList]').length) {
                    $('select[name$=_addressList]').next('.select-styled').next('.select-options').find('li[rel=newAddress]').trigger('click');
                    //populate address from selected address in drop down
                    updateBillingFormDataWithSelectedShipTo($checkbox);
                }
                $('input[value="true"]').removeAttr('checked');
                $('.hf-use-billing input').attr('value','false');
                $('.billing-upper').slideDown();
                $('#use-shipping-address-address').hide();
                $('.edit-address').hide();
            }
            if (!($checkbox.is(':checked') && manualTrigger) && eventType !== 'editBillingAddress') {
                $('select[name$="billing_addressList"]').trigger('change');
            }
            ShowHideContinueButton();
        }, 100);
    }

    $('#useAsBillingAddress').on('click', useAsBillingAdddressClick);

    //run on init
    var manualTrigger = true;
    useAsBillingAdddressClick(manualTrigger);

    //Determines if billing page is using a "useAsBillingAddress" checkbox from the multiship address selection
    var $msBillingAddressEmpty = $('.msBillingAddressEmpty');
    if($msBillingAddressEmpty.length) {
        if($msBillingAddressEmpty.val() == "true") {
            //Clicks different address dropdown
            $('#differentbilling').click();
        }
    }

    $cache.saveBillingBtn.click(saveBillingClicked);

    var ccImageSwap = function() {

        var ccType = "";
        var value = $(this).val();

        if (!value) {
            return;
        }
        if (value.match(/Amex|Visa|Master|Discover/)) {
            ccType = value;
        } else {
            var ccFirstDigit = value[0];

            switch (ccFirstDigit) {

                case "2":
                    ccType = "Master";
                    break;
                case "3":
                    ccType = "Amex";
                    break;
                case "4":
                    ccType = "Visa";
                    break;
                case "5":
                    ccType = "Master";
                    break;
                case "6":
                    ccType = "Discover";
                    break;
            }
        }

        if (ccType != "") {
            $("[id^=creditcard-logo-]").removeClass('active').fadeTo(0, 0.25);
            $cache.ccType.val(ccType);
            $("#creditcard-logo-" + ccType).addClass('active').fadeTo(0, 1);
        } else {
            // no card # entered, light them all up as possible options
            $("[id^=creditcard-logo-]").addClass('active').fadeTo(0, 1);
        }

    };

    $cache.ccNum.on("input", ccImageSwap);

    $cache.ccMaskedFourDigit.on("input", ccImageSwap);

    $cache.ccType.on('change', ccImageSwap);

    clearCCFields();

    $("form").on("change", ".dropdown-billing-country", function(e) {

        var cValue = $(".dropdown-billing-country").val(),
            labelZip = $("form").find("label[for='dwfrm_billing_billingAddress_addressFields_zip'] span").not(".required-indicator");

        if(cValue === 'CA') {
            labelZip.text('Postal Code');
            labelZip.attr('placeholder','Postal Code');
        } else {
            labelZip.text('Zip Code');
            labelZip.attr('placeholder','Zip Code');
        }
    });

    // get selected payment method from payment method form
    var paymentMethodId = $cache.paymentMethodId.filter(":checked");
    if (paymentMethodId.length !== 0) {
        changePaymentMethod(paymentMethodId.val());
    }

    // handle credit card radio button selection
    $('.saved-cc').find('.input-radio').on('click', function() {
        if ($(this).val()) {
            $('.cvn input').val('');
            $('.form-new-card:not(".visually-hidden")').addClass('visually-hidden');
            $('.saved-card').find('.cvn input').removeClass('transparent');
            populateCreditCardForm($(this).val());
            $('.saved-card').show();
        } else {
            $('.cvn input').val('');
            $('.form-new-card').removeClass('visually-hidden');
            $('.form-new-card').find('input, select').val('');
            $cache.ccMaskedFourDigit.parent().hide();
            $cache.ccNum.parent().show();
            $('.saved-card').hide();
        }
    });

    // handle saved card CVV entry
    $('.saved-card').find('.cvn input').on('blur', function() {
        $('.form-new-card').find('.cvn input').val($(this).val());
    });
    $('.form-new-card').find('.cvn input').on('blur', function() {
        $('.saved-card').find('.cvn input').val($(this).val());
    });
    $('.cvn input').on('change', function() {
        ShowHideContinueButton();
    });
    $('#PaymentMethod_CREDIT_CARD input').on('change', function() {
        if($(this).valid()) {
            $(this).removeClass('error');
            $(this).closest('.form-row').removeClass('error');
        }
    });

    // handle delete saved credit card
    $('.delete-card').click(function(e) {
        e.preventDefault();
        var $cardRow = $(this).parent(),
            cardUUID = $cardRow.find('input').val(),
            url = util.appendParamsToUrl(window.Urls.deleteCreditCard, {
                'creditCardUUID' : cardUUID,
                'format' : 'ajax'
            });
        $.ajax(url, {
            success : function() {
                $cardRow.remove();
                var $savedCards = $('#PaymentMethod_CREDIT_CARD > .saved-cc');
                if ($savedCards.length === 0) {
                    var $savedCardSections = $('.saved-card.cvn-block, .add-new-card, .description');
                    $savedCardSections.remove();
                    $('.form-new-card').find('input, select').val('');
                    $('.form-new-card').removeClass('visually-hidden');
                }
            }
        });
    });

    // handle whole form submit (bind click to continue checkout button)
    // append form fields of current payment form to this submit
    // in order to validate the payment method form inputs too

    $cache.save.on('click', function (e) {
        // determine if the order total was paid using gift cert or a promotion
        if ($("#noPaymentNeeded").length > 0 && $(".giftcert-pi").length > 0) {
            // as a safety precaution, uncheck any existing payment methods
            $cache.paymentMethodId.filter(":checked").removeAttr("checked");
            // add selected radio button with gift card payment method
            $("<input/>").attr({
               name: $cache.paymentMethodId.first().attr("name"),
               type: "radio",
               checked: "checked",
               value: window.Constants.PI_METHOD_GIFT_CERTIFICATE
            }).appendTo($cache.checkoutForm);
        }

        var tc = $cache.checkoutForm.find("input[name$='bml_termsandconditions']");
        if ($cache.paymentMethodId.filter(":checked").val() === "BML" && !$cache.checkoutForm.find("input[name$='bml_termsandconditions']")[0].checked) {
            window.alert(window.Resources.BML_AGREE_TO_TERMS);
            return false;
        }

        var error = $('.error:first').not('.adyen-error').offset().top,
            header = $('.full-header-wrapper').height();
        if(error) {
            $('html, body').animate({
                scrollTop: (error - header) - 1
            }, 500);
            return false;
        }

    });

    $cache.gcCheckBalance.on("click", function (e) {
        e.preventDefault();
        $cache.gcCode = $cache.gcCode || $cache.checkoutForm.find("input[name$='_giftCertCode']");
        $cache.balance = $cache.balance || $cache.checkoutForm.find("div.balance");
        if ($cache.gcCode.length === 0 || $cache.gcCode.val().length === 0) {
            $cache.balance.find("span.error").remove();
            var error = $("<span>").addClass("error").attr('id', 'giftcart-error-message').appendTo($cache.balance);
            $('.gift-cert-input').attr('aria-describedby', 'giftcart-error-message');
            $('.gift-cert-input').attr('aria-invalid', 'true');
            window.setTimeout(function () {
                $('.gift-cert-input').focus();
            }, 400);
            error.html(window.Resources.GIFT_CERT_MISSING);
            return;
        }

        giftcard.checkBalance($cache.gcCode.val(), function (data) {
            if (!data || !data.giftCertificate) {
                // error, clear any old balance data first
                $cache.balance.html('');
                var error = $("<span>").addClass("error").attr('id', 'giftcart-error-message').appendTo($cache.balance);
                $('.gift-cert-input').attr('aria-describedby', 'giftcart-error-message');
                $('.gift-cert-input').attr('aria-invalid', 'true');
                window.setTimeout(function () {
                    $('.gift-cert-input').focus();
                }, 400);
                error.html(window.Resources.GIFT_CERT_INVALID);
                return;
            }
            // display details in UI
            $cache.balance.find("span.error").remove();
            $('.gift-cert-input').removeAttr('aria-describedby');
            $('.gift-cert-input').removeAttr('aria-invalid');
            var balance = data.giftCertificate.balance;
            $cache.balance.html(window.Resources.GIFT_CERT_BALANCE + " " + balance);
        });
    });

    $("button.remove-coupon, button#remove-sourcecode").click(function(e) {
        e.preventDefault();
        removeCouponSourceCode($(this));
    });

    $form.find('input, select').on('keydown', function(e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode === 13 && e.target.name.indexOf('_billing_couponCode') < 0) {
            e.preventDefault();
            $continue.click();
        }
    }).on('change', function() {
        $(this).siblings('.form-caption.error-message').remove();
        if ($('select[name$="_addressType"]').val() === 'military') {
            var $city = $('input[name$="_city"]'),
                $postOffice = $(".post-office select"),
                $postText = $postOffice.val();
            if($city.val() == '') {
                $city.val($postText);
            }
        }
    });

    function resetToken() {
        $('#creditCardToken').val('');
        $('#tokenization-error').html('');
    }

    $cache.ccCcv.on('change', function () {
        resetToken();
        $(this).parent().removeClass('error');
    });
    $cache.ccNum.on('change', resetToken);
    $cache.ccMonth.on('change', resetToken);
    $cache.ccYear.on('change', resetToken);

    $('#add-giftcert').on('click', function(e) {
        e.preventDefault();
        $cache.balance = $cache.balance || $cache.checkoutForm.find("div.balance");
        var $this = $(this),
            url = util.appendParamsToUrl(window.Urls.redeemGiftCert, {
                'giftCertCode' : $('.gift-cert-input').val(),
                'format' : 'ajax'
            });
        $.ajax(url, {
            success : function(response, error) {
                if (!response.success) {
                    var error = $cache.balance.find("span.error");
                    if (error.length === 0) {
                        error = $("<span>").addClass("error").attr('id', 'giftcart-error-message').appendTo($cache.balance);
                    }
                    error.html(response.message);
                    $('.gift-cert-input').attr('aria-describedby', 'giftcart-error-message');
                    $('.gift-cert-input').attr('aria-invalid', 'true');
                    window.setTimeout(function () {
                        $('.gift-cert-input').focus();
                    }, 400);
                } else {
                    $('#is-CREDIT_CARD').attr('checked', true);
                    $this.parents('form').submit();
                    $('.gift-cert-input').removeAttr('aria-describedby');
                    $('.gift-cert-input').removeAttr('aria-invalid');
                }
            }
        });
    });

    $cache.ccNum.on('input', function(e) {
        var target = e.target,
        position = target.selectionStart; // Capture initial position of cursor

        //remove non digit values and add hyphens in '1234 1234 1234 1234' format
        this.value = this.value.replace(/[^0-9]/g, '').replace(/\W/gi, '').replace(/(.{4})/g, '$1 ').trim();

        //Delete key was pressed
        if (e.originalEvent && e.originalEvent.inputType == "deleteContentForward") {
            target.selectionEnd = position; // Set the cursor back to the initial position.
        }
    });

    $('.cvn input').on('input', function (e){
        var target = e.target,
        position = target.selectionStart; // Capture initial position of cursor

        //remove non digit values
        this.value = this.value.replace(/[^0-9]/g, '');

        //Delete key was pressed
        if (e.originalEvent && e.originalEvent.inputType == "deleteContentForward") {
            target.selectionEnd = position; // Set the cursor back to the initial position.
        }
    });

    if ($('.saved-cc').length && $('.saved-cc').find('.input-radio:checked').val() != '') {
        populateCreditCardForm($('.saved-cc').find('.input-radio:checked').val());
    }
}

function initializeCache() {
    //Get cache that is shared between checkout pages
    $cache = shipping.getCheckoutCache();

    //Get billing page specific cache
    $cache.addCoupon = $("#add-coupon");
    $cache.addressDropdown = $(".select-address-dropdown");
    $cache.creditcardblock = $('[data-method="CREDIT_CARD"]');
    $cache.email = $cache.checkoutForm.find("input[name$='_emailAddress']");
    $cache.save = $cache.checkoutForm.find("button[name$='_billing_save']");
    $cache.paymentMethods = $cache.checkoutForm.find("div.payment-method");
    $cache.paymentMethodId = $cache.checkoutForm.find("input[name$='_selectedPaymentMethodID']");
    $cache.ccContainer = $("#PaymentMethod_CREDIT_CARD");
    $cache.ccList = $("#creditCardList");
    $cache.ccOwner = $cache.ccContainer.find("input[name$='creditCard_owner']");
    $cache.ccType = $cache.ccContainer.find("select[name$='_type']");
    $cache.ccNum = $cache.ccContainer.find("input[name$='_number']");
    $cache.ccNumHide = $cache.ccContainer.find("input[id$='_numberhide']");
    $cache.ccMonth = $cache.ccContainer.find("[name$='_month']");
    $cache.ccYear = $cache.ccContainer.find("[name$='_year']");
    $cache.ccCcv = $cache.ccContainer.find("input[name$='_cvn']");
    $cache.ccSaveCard = $cache.ccContainer.find("input[name$='_saveCard']");
    $cache.BMLContainer = $("#PaymentMethod_BML");
    $cache.gcCheckBalance = $("#gc-checkbalance");
    $cache.removeCoupon = $(".remove-coupon");
    $cache.couponCode = $("input[name$='_couponCode']"); //need to ensure this get initialized for shipping page too
    $cache.chooseAddress = $('form.address input[name="chooseAddress"]');
    /******* code added for cybersource integrations ********/
    $cache.ccSubscription = $cache.ccContainer.find("input[name$='creditCard_isSubscription']");
    $cache.ccSubscription.val(false);
    $cache.ccMaskedFourDigit = $cache.ccContainer.find("input[name$='creditCard_maskedFourDigit']");
    $cache.ccMaskedFourDigit.parent().hide();
    if($cache.ccMaskedFourDigit.val() == 'undefined' || $cache.ccMaskedFourDigit.val() == "") {
        $cache.ccMaskedFourDigit.parent().hide();
    } else {
        $cache.ccMaskedFourDigit.parent().show();
        $cache.ccNum.parent().hide();
        $cache.ccSubscription.val(true);
    }
    /***********end code added for cybersource integration*******/
    $cache.saveBillingBtn = $('#save-billing-btn');
    $cache.saveBillingAction = $('#save-billing-action');
    $cache.savePayPalInContextAction = $('#billingSubmitButton');
    $cache.ppIncontext = $('#pp_incontext');
}
