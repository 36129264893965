'use strict';

var shipping = require('./pages/checkout/shipping');
var dialog = require('./dialog');

var GRI = {
    dialogs: [],
    billingShipmentToggle : function() {
        $('.toggle-shipment').bind("click", function() {
            if(!($(this).hasClass('expanded'))) {
                $(this).addClass('expanded');
                $(this).parent().siblings('.hf-shipment-content').show();
            } else {
                $(this).removeClass('expanded');
                $(this).parent().siblings('.hf-shipment-content').hide();
            }
            return false;
        });
    },
    militaryAddressExamples : function() {
        $('.military_address_content').hide();
        $('.military_address_link a').bind("click", function() {
            $(this).parent().next('.military_address_content').toggle();

            if($(this).parent().next('.military_address_content').is(":visible")) {
                $(this).text('Hide Military Address Examples');
            } else {
                $(this).text('Show Military Address Examples');
            }

            return false;
        });
    },
    shippingMethods : {
        maxMonths : 6,

        init : function() {

            function refocusElementAfterDelay(element, delay) {
                var buttonClass = element.classList[0];
                if (buttonClass) {
                    window.setTimeout(function() {
                        $('.' + buttonClass).focus();
                    }, delay);
                }
            }

            //ADA: refocuses button clicked (next/prev) after a month change. jquery ui destroys the clicked element
            //so it loses focus.
            $(window.document).on('click', '.ui-datepicker-next, .ui-datepicker-prev', function (e) {
                e.preventDefault();
                refocusElementAfterDelay(e.target, 0);
            });

            this.datepickers = $("div[id^='choose-date']");
            this.datepickers
            .each(function(i) {
                var datepicker = $(this);

                var shipmentUuid = datepicker.data('shipmentuuid');
                var shipmentId = datepicker.data('shipmentid');
                var disabledDays = datepicker.data('disableddays');
                var mindays = parseInt(datepicker.data('mindays')) || 5;
                var defaultdate = datepicker.data('defaultdate');

                var altFieldId = datepicker.data('altfieldid');
                altFieldId = '#' + altFieldId;

                function noWeekendsOrHolidays(date) {
                    //check if day is disabled
                    var string = $.datepicker.formatDate('mm-dd-yy', date);
                    return [disabledDays.indexOf(string) == -1];
                }

                function resetBoxes() {
                    $('#' + shipmentId + '_52').parent().removeClass('border');
                    $('#' + shipmentId + '_01').parent().removeClass('border');
                    $('#' + shipmentId + '_UPS-XP').parent().removeClass('border');
                    $('#' + shipmentId + '_64C').parent().removeClass('border');
                    $('#' + shipmentId + '_UPS-ST').parent().removeClass('border');

                    $('#' + shipmentId + '_52').attr('active', '');
                    $('#' + shipmentId + '_01').attr('active', '');
                    $('#' + shipmentId + '_UPS-XP').attr('active', '');
                    $('#' + shipmentId + '_64C').attr('active', '');
                    $('#' + shipmentId + '_UPS-ST').attr('active', '');
                }

                function triggerOnSelectForCurrentDay() {
                    var currentFocus = window.document.activeElement;
                    window.setTimeout(function() {
                        datepicker.find('.ui-datepicker-current-day').trigger('click');
                        refocusElementAfterDelay(currentFocus, 0);
                        GRI.accessibilityFunctions();
                    }, 0);
                }

                function onSelect(dateText, inst) {
                    resetBoxes();

                    //clear range
                    $('.datepicker-range, .datepicker-range-last, datepicker-range-first').removeClass('datepicker-range, datepicker-range-last, datepicker-range-first');

                    //only standard shipping is valid for range
                    var $standardShipping = $('#' + shipmentId + '_64S');
                    $standardShipping.parent().addClass('border');
                    $standardShipping.attr('active', '1');

                    //set estimated arrival date
                    var formattedDate = $.datepicker.formatDate('M d', new Date(dateText));

                    $standardShipping.siblings('.estimated-arrival').html(window.Resources.ARRIVES_FUTURE_DELIVERY + ' ' + formattedDate);

                    //display shipping estimate range
                    window.setTimeout(function() {
                        datepicker.datepicker("setDate", dateText);
                        var range = $standardShipping.attr('size') || 3;

                        //Mark last day of range (the day that was selected)
                        var $selectedDay = datepicker.find('.ui-datepicker-current-day').addClass('datepicker-range-last');
                        var $currentDayInRange = $selectedDay;

                        //Wipe previous 'arrives on ..' text
                        datepicker.find('.arrives-text').removeClass('arrives-text');

                        //Set 'arrives on ..' text
                        var $dayBeforeTheSelectedDay = $selectedDay.prev();
                        if ($dayBeforeTheSelectedDay.hasClass('ui-datepicker-week-end')) {
                            //Show 'arrives on..' text above the selected day
                            $selectedDay.addClass('arrives-text');
                        } else {
                            //Show 'arrives on..' text above the previous day
                            $dayBeforeTheSelectedDay.addClass('arrives-text');
                        }
                        datepicker.find('.arrives-text a, .arrives-text span').attr('data-content', window.Resources.GRI_FOD_ONORBEFORE + formattedDate);

                        //Mark middle of range (i starts at 2 because first/last days are marked separatly)
                        for (var i = 2; i < range; i++) {
                           $currentDayInRange = getPrevBusinessDay($currentDayInRange);
                           $currentDayInRange.addClass('datepicker-range');
                        }

                        //Mark first day of range
                        $currentDayInRange = getPrevBusinessDay($currentDayInRange);
                        $currentDayInRange.addClass('datepicker-range-first');

                        //ADA: focus this element, because jquery ui loses focus here
                        $selectedDay.find('a').focus();
                    }, 0);

                    function getPrevBusinessDay($currentDay) {
                        var $daysBeforeThis = $currentDay.prev();
                        var $prevBusinessDay = $daysBeforeThis;
                        //If the day before the current day is a weekend, we need to extend the next business day
                        if ($daysBeforeThis.hasClass('ui-datepicker-week-end')) {
                            //Highlight Sunday
                            $daysBeforeThis.addClass('datepicker-range');
                            //Find previous row's Friday and highlight Saturday
                            $prevBusinessDay = $currentDay.parent().prev().children().last().addClass('datepicker-range').prev();
                        }

                        return $prevBusinessDay;
                    }
                    GRI.accessibilityFunctions();
                }

                var d = shipping.getDate3pm();

                var j = d.getDate() + 1;
                d.setDate(j);
                var string = $.datepicker.formatDate('mm-dd-yy', d);

                while(disabledDays.indexOf(string) >= 0) {
                    j = d.getDate();
                    d.setDate(j + 1);
                    string = $.datepicker.formatDate('mm-dd-yy', d);
                }

                var datepickerConfig = {
                        altField : altFieldId,
                        beforeShowDay: noWeekendsOrHolidays,
                        onChangeMonthYear: triggerOnSelectForCurrentDay,
                        onSelect: onSelect,
                        dayNamesMin: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"], //To show dayNamesShort values like: Mon Tues..
                        minDate: '+' + 1 + 'd',
                        maxDate: '+' + GRI.shippingMethods.maxMonths + 'm'
                };

                datepicker.datepicker(datepickerConfig);
                datepicker.datepicker("setDate", d); //set the default date to null
                onSelect(d);


                //configure the date selector dialog opener
                var dialogOpenerClass = '.select-shipping-date-' + shipmentId;
                var selectOpenerClass = '.show-shipping-details-' + shipmentId;
                var selectShippingContainerClass = 'body';
                var shipMethodId = "input[id^='" + shipmentId + "_'][active='1']";
                GRI.shippingMethods.dialogOpener(dialogOpenerClass, selectOpenerClass, selectShippingContainerClass, altFieldId, shipMethodId);
            });

        },
        dialogOpener : function(dialogOpenerClass, selectOpenerClass, selectShippingContainerClass, altFieldId, shipMethodId) {
            $(dialogOpenerClass).on('click', function(e) {
                var shipmentId = $(altFieldId).data('shipmentid');
                var selected = '';
                var $this = $(this);
                Date.prototype.addDays = function(days) {
                    this.setDate(this.getDate() + days);
                    return this;
                };
                $('.modal-form-row input').removeAttr('active');
                $('.modal-form-row').removeClass('border');

                var dialogOptions = {
                    target : selectOpenerClass,
                    options : {
                        height : 'auto',
                        width : 800,
                        dialogClass : 'select-date dialog-center-title',
                        title : window.Resources.GRI_FOD_TITLE,
                        onOpenCallback : function() {
                            $('.slideout-panel').css('position','inherit');
                        },
                        close : function() {
                            $('.slideout-panel').css('position','relative');
                            $this.closest('.shipping-method-list').find('input.input-radio:checked').focus();
                        },
                        buttons : {
                            "save" : function() {
                                $('#' + shipmentId + '_64S').attr('active', '1').parent().addClass('border');
                                var shipMethodDWId = $(shipMethodId).val();
                                var $selectDateField = $(altFieldId);
                                var $selectedShippingtMethodsList = $selectDateField.parents('.shipping-method-list');
                                var $selectedDiv = $selectDateField.parents('.shipping-boxes').first();

                                //Add border and radio check to this shipping method
                                var $singleShipEl = $selectedShippingtMethodsList.find(".select-shipping-container input[id^='shipping-method-']");

                                if ($singleShipEl.length > 0) {
                                    $singleShipEl.prop('checked', true);
                                } else {
                                    $selectedShippingtMethodsList.find('input[id*="_64S"]').prop('checked', true);
                                }

                                GRI.shippingMethods.selectShippingMethod(altFieldId, shipMethodId, $selectedDiv);

                                //single ship
                                $selectedShippingtMethodsList.find('.shipdate-delay-message').hide();
                                //multi ship
                                $selectedShippingtMethodsList.parents('.hf-shipment').find('.multi-shipdate-delay-message').hide();
                                $selectedShippingtMethodsList.children().find('.shipping-boxes.border').removeClass('border');
                                $selectDateField.parent().addClass('border');

                                // set estimated shipping date
                                var img = $('<div>').append($selectDateField.siblings('.select-shipping-date').find('img')).html();
                                var date = $('.hasDatepicker[data-altfieldid="' + altFieldId.slice(1) + '"]').datepicker('getDate');
                                var arrivalDate = $.datepicker.formatDate('mm/dd/yy', date);
                                var displayDate = $.datepicker.formatDate('M d', date);
                                $selectDateField.siblings('.select-shipping-date').html(img + window.Resources.ARRIVES_FUTURE_DELIVERY + arrivalDate);

                                $('div[data-shipmentid="' + shipmentId + '"]').find('.select-shipping-date .estimated-arrival').attr('data-shipondate', arrivalDate).text(window.Resources.ARRIVES_FUTURE_DELIVERY + ' ' + displayDate);

                                $(this).dialog("close");
                            }
                        }
                    }
                };

                for (var i = 0; i < GRI.dialogs.length; i++) {
                    var tmp = GRI.dialogs.pop();
                    tmp.dialog('destroy');
                }
                var dlg = dialog.create(dialogOptions);
                $('.select-date').appendTo(selectShippingContainerClass).position({
                    my : 'center',
                    at : 'center',
                    of : window,
                    collision : 'fit'
                });
                dlg.dialog("open");
                GRI.dialogs.push(dlg);
                GRI.accessibilityFunctions();
            });
        },

        selectShippingMethod : function(altFieldId, shipMethodId, element) {
            var shipMethodDWId = $(shipMethodId).val();
            //read current date selected
            var shipOnDate = $(altFieldId).val();
            var shipmentId = $(altFieldId).data('shipmentid');
            var address1 = $(altFieldId).data('addressaddress1');
            var address2 = $(altFieldId).data('addressaddress2');
            var city = $(altFieldId).data('addresscity');
            var postalCode = $(altFieldId).data('addresspostalcode');
            var countryCode = $(altFieldId).data('addresscountrycode');
            var stateCode = $(altFieldId).data('addressstatecode');
            var addressType = $('.addressType').val();
            shipping.selectCustomShippingMethod(shipOnDate, shipmentId, shipMethodDWId, address1, address2, city, postalCode, countryCode, stateCode, addressType, element);
        }
    },
    updateProductOptions : function() {
        $('.custom-options .option-custom').each(function() {
            var optionID = $(this).attr('id');
            var selectedOptionValue = $(this).children('.selected-option-value').html();
            $('.product-options select[id="' + optionID + '"]').children('option[value="' + selectedOptionValue + '"]').attr('selected','selected');
        });
    },
    accessibilityFunctions : function() {
        window.setTimeout(function () {
            $('.ui-datepicker-prev, .ui-datepicker-next, .ui-datepicker-calendar [data-handler="selectDay"] a').attr('role','button');
            $('.ui-datepicker-calendar thead th').attr('scope','col');

            $('.ui-datepicker').each(function () {
                if ($(this).siblings('p.sr-only').length === 0) {
                    var srText = '<p class="sr-only">' + window.Resources.DATEPICKER_SR_ADA + '</p>';
                    $(this).prepend(srText);
                }
            });

            $('.ui-datepicker-prev').on("keydown", function (e) {
                if (e.which === 9) {
                    $('.ui-datepicker-title span').focus();
                } else if (e.which === 13) {
                    $(this).trigger('click');
                }
            });

            $('.ui-datepicker-next').on("keydown", function (e) {
                if (e.which === 9) {
                    $('.ui-state-active').focus();
                } else if (e.which === 13) {
                    $(this).trigger('click');
                }
            });
        }, 0);
    }
};

module.exports = GRI;
