'use strict';

var minicart = require('../minicart');
var sendtofriend = require('../sendtofriend');
var tealium = require('../tealium');
var progress = require('../progress');
var dialog = require('../dialog');
var util = require('../util');
var ajax = require('../ajax');
var suggestedproducts = require('../suggestedproducts');
var bopis = require('../bopis');

var $cache;

function loadYoutubePopup () {
    $('.hf-pdp-youtube').magnificPopup({
          type: 'iframe',
          mainClass: 'mfp-fade',
          removalDelay: 160,
          preloader: false,

          fixedContentPos: false});
    $('.popup-youtube').magnificPopup({
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,
        fixedContentPos: false
    });
}

window.setTimeout(function() {
    $('<span>/ 5</span>').appendTo('#hf-pdpReviews .bv-secondary-rating-summary-rating');
    $('#hf-pdpReviews .bv-secondary-rating-summary-rating').text(function (i, text) {
        return text.replace(/ /g, '');
    });
    $(".bv-dropdown-target").remove();
}, 5000);


function recommendedProductsCarousel () {

    // HF-522-3 put in a 3 second delay on the PDP recommended products from PI
    // carousel to allow the PI products to load before assigning them to the carousel
    // HF-649 moved the carouselBlock initialization into the 3 second delay to account
    // for moving the title block and hf-cfcarousel tags into the content slot rendering template
    // resulting in the hf-cfcarousel existing inside cq_recomm_slot block, needed to wait
    // for that block to load before looking for the #hf-cfcarousel.
    window.setTimeout(function () {
        var carouselBlock;
        if ($("[id^='cq_recomm_slot']").length > 0) {
            carouselBlock = $("#hf-pdpMain [id^='cq_recomm_slot'] #hf-cfcarousel");
        } else {
            carouselBlock = $("#hf-pdpMain #hf-cfcarousel");
        }

        carouselBlock.slick({
            lazyLoad: 'ondemand',
            slidesToShow: 3,
            infinite: false,
            responsive: [{
                breakpoint: 959,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 735,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                    arrows: true,
                    appendDots: $('#hf-pdpMain .hf-pdp-recommendations-slider-nav'),
                    appendArrows: $('#hf-pdpMain .hf-pdp-recommendations-slider-nav')
                }
            }]
        });
    }, 3000);
}

function initPdpSectionCarousels () {
    window.setTimeout(function () {
        var $carouselBlock = $('.pdp-section-carousel');

        $carouselBlock.each(function () {
            if($carouselBlock.hasClass('slick-initialized')){
                return;
            }
            $carouselBlock.slick({
                lazyLoad: 'ondemand',
                slidesToShow: 4,
                infinite: true,
                arrows: true,
                responsive: [{
                    breakpoint: 735,
                    settings: {
                        slidesToShow: 1
                    }
                }]
            });
        });
    }, 3000);
}

function initUpsellerSwatchCarousel () {
	var $carouselBlock = $('.upseller-variations .swatches');
	var doShowSelected = false;
	var activeIndex = 1;

	if (!$carouselBlock.length || $carouselBlock.hasClass('slick-initialized')) {
		return;
	}

	if ($carouselBlock.find('li.selected').length) {
		doShowSelected = true;
		activeIndex = $carouselBlock.find('li').index($carouselBlock.find('li.selected'));
	}

	$carouselBlock.slick({
		centerMode: true,
		centerPadding: '30%',
	  });

	if (doShowSelected) {
		$carouselBlock.slick('slickGoTo', activeIndex, true);
	}
}

function updateUpsellerProductName () {
	var $carouselBlock = $('.upseller-variations');
	if (!$carouselBlock.length) {
		return;
	}
	$('h1.product-name').html($('#product-content h2.visually-hidden').data('name'));
}

function updateVariationTabs (anchor) {
	progress.show($cache.pdpMain);

	ajax.load({
		url: anchor.data('tabs'),
		callback : function (data) {
			$('.hf-product-tabs').remove();
			$('.product-detail').append(data);
			productContentInitTabs();
			progress.hide();
		}
	});
}

function productContentInitTabs () {
    var focusedTabIndex = 0;

    var tablist = $('.hf-tabs-menu');
    var tabs = $('.hf-product-tabs-header');
    var tabpanels = $('.tab-content');

    tabs.first().attr('aria-selected', true).attr('tabindex', 0);
    tabpanels.first().removeClass('d-none');
    tabpanels.first().attr('tabindex', 0);

    // Focus previous/next tabs using Left and Right arrow keys
    tablist.on('keydown', function(e) {
        switch(e.key) {
            case 'ArrowLeft':
                focusPreviousTab();
                break;

            case 'ArrowRight':
                focusNextTab();
                break;

            case 'Home':  // optional
                e.preventDefault();  // prevent page scroll
                focusFirstTab();
                break;

            case 'End':  // optional
                e.preventDefault();  // prevent page scroll
                focusLastTab();
                break;
        }
    });

    // Activate tabs when they are clicked (also handles Enter and Space keys)
    tabs.each(function() {
        $(this).on('click', function(e) {
            var nextTabIndex = tabs.index(e.target);
            selectTab(nextTabIndex);
        });
    });

    /**
     Tab selection
     */
    function focusPreviousTab() {
        if(focusedTabIndex > 0) {
            focusedTabIndex -= 1;
        } else {
            focusedTabIndex = tabs.length - 1;
        }

        tabs[focusedTabIndex].focus();
    }

    function focusNextTab() {
        if(focusedTabIndex < tabs.length - 1) {
            focusedTabIndex += 1;
        } else {
            focusedTabIndex = 0;
        }

        tabs[focusedTabIndex].focus();
    }

    function focusFirstTab() {
        focusedTabIndex = 0;
        tabs[focusedTabIndex].focus();
    }

    function focusLastTab() {
        focusedTabIndex = tabs.length - 1;
        tabs[focusedTabIndex].focus();
    }

    function selectTab(index) {
        // Deactivate all tabs
        tabs.each(function() {
            $(this).attr('aria-selected', false);
            $(this).attr('tabindex', -1);
        });

        // Activate only the requested tab, and set focus to it
        $(tabs[index]).attr('aria-selected', true);
        $(tabs[index]).attr('tabindex', 0);
        $(tabs[index]).focus();
        focusedTabIndex = index;

        // Hide all tabpanels
        tabpanels.each(function() {
            $(this).addClass('d-none');
            $(this).removeAttr('tabindex');
        });

        // Show only the tabpanel for the requested tab
        var nextTabpanel = $('#' + tabs[index].getAttribute('aria-controls'));
        nextTabpanel.removeClass('d-none');
        nextTabpanel.attr('tabindex', 0);
    }
}

function promoDetails () {
    $('.promo-details').click(function (e) {
        e.preventDefault();
        $(this).toggleClass('opened').next('.promo-details-content').toggle();
    });
}
/**
 * @function
 * @description sets up item count number in input box
 */
function changeItemCountOnClick () {
    var $increment = $("img[id^='hf-increment']");
    var $decrement = $("img[id^='hf-decrement']");
    //var $inputBox   = $("#Quantity");
    $decrement.on('click', function () {
        var $this = $(this);
        var $inputBox = $this.siblings('input[name="Quantity"]');
        var currentVal = $inputBox.val();
        if(isNaN(currentVal)) { currentVal = 1;}
        else {currentVal = parseInt(currentVal);}
        if(currentVal > 0) {
            $inputBox.val(currentVal - 1);
        }
        $inputBox.change();
    });
    $increment.on('click', function () {
        var $this = $(this);
        var $inputBox = $this.siblings('input[name="Quantity"]');
        var currentVal = $inputBox.val();
        if(isNaN(currentVal)) { currentVal = 1;}
        else {currentVal = parseInt(currentVal);}
        $inputBox.val(currentVal + 1);
        $inputBox.change();
    });
}

/**
 * @function
 * @description Slides the product options up and down on checkbox check event
 */

function giftOptionsSlide () {
    var $expandOrReduceElement = $("#hf-pdpGiftOptions");
    var $slideArea = $("#hf-product-option-container");

    $expandOrReduceElement.on('click', function () {
        var $this = $(this);
        if($this.hasClass('toggleShow')) {
            $this.toggleClass('toggleShow');
            $slideArea.slideUp(500);
        }else {
            $this.toggleClass('toggleShow');
            $slideArea.slideDown(500);
        }

        $('#hf-product-option-containerWrapper').slick('setPosition');
    });
}
/**
 * @private
 * @function
 * @description Loads product's navigation on the product detail page
 */
function loadProductNavigation() {
    var pidInput = $cache.pdpForm.find("input[name='pid']").last();
    var navContainer = $("#product-nav-container");
    // if no hash exists, or no pid exists, or nav container does not exist, return
    if (window.location.hash.length <= 1 || pidInput.length === 0 || navContainer.length === 0) {
        return;
    }

    var pid = pidInput.val();
    var hashParams = window.location.hash.substr(1);
    if (hashParams.indexOf("pid=" + pid) < 0) {
        hashParams += "&pid=" + pid;
    }

    var url = window.Urls.productNav + (window.Urls.productNav.indexOf("?") < 0 ? "?" : "&") + hashParams;
    ajax.load({url:url, target: navContainer});
}

/**
 * @function
 * @description Sets the main image attributes and the href for the surrounding <a> tag
 * @param {Object} atts Simple object with url, alt, title and hires properties
 */
function setMainImage(atts) {
    var imgZoom = $cache.pdpMain.find("a.main-image");
    if (imgZoom.length > 0 && atts.hires && atts.hires != '' && atts.hires != 'null') {
        imgZoom.attr("href", atts.hires);
    }

    imgZoom.find("img.primary-image").attr({
        "src" : atts.url,
        "alt" : atts.alt,
        "title" : atts.title
    });
}

/**
 * @function
 * @description helper function for swapping main image on swatch hover
 * @param {Element} element DOM element with custom data-lgimg attribute
 */
function swapImage(element) {
    var lgImg = $(element).data("lgimg");

    var newImg = $.extend({}, lgImg);
    var imgZoom = $cache.pdpMain.find("a.main-image");
    var mainImage = imgZoom.find("img.primary-image");
    // store current image info
    lgImg.hires = imgZoom.attr("href");
    lgImg.url = mainImage.attr("src");
    lgImg.alt = mainImage.attr("alt");
    lgImg.title = mainImage.attr("title");
    // reset element's lgimg data attribute
    $(element).data(lgImg);
    // set the main image
    setMainImage(newImg);
}


/**
 * @function
 * @description Enables the zoom viewer on the product detail page
 */
function loadZoom() {
    //disabled 5/27/2016 Kunle HF-20, was causing zoom functionality to be bypassed. Instead, just check the browser width.
    //if(quickview.isActive() || !window.zoomViewerEnabled) { return; }
    var browserWidth = jQuery(window).width();


    //zoom properties
    var options = {
        zoomType: 'innerzoom',
        alwaysOn : 0, // setting to 1 will load load high res images on page load
        zoomWidth : 575,
        zoomHeight : 349,
        position:'right',
        preloadImages: 0, // setting to 1 will load load high res images on page load
        xOffset: 30,
        yOffset:0,
        showEffect : 'fadein',
        hideEffect: 'fadeout'
    };

    // Added to prevent empty hires zoom feature (if images don't exist)
    var mainImage = $cache.pdpMain.find(".main-image");

        //5/27/2016 Kunle HF-20 Zoom for Mobile.
        //using magnific popup js library to implement a popup box when you click the main product image on the PDP.
        //Should only be in effect when the screen is <= 767 pixels, or mobile sized.
        if(!$("#carouselContainer").hasClass("slick-initialized")) {
            $("#carouselContainer").slick({
                lazyLoad: 'ondemand',
                dots: true,
                arrows: true,
                appendDots: $('#hf-pdpMain .hf-pdp-slider-nav'),
                appendArrows: $('#hf-pdpMain .hf-pdp-slider-nav')
            });
        }
        $("#carouselContainer").slick('setPosition');


        $('.product-image.main-image').magnificPopup({delegate: 'img' , type: 'image', closeOnContentClick: true, zoom: { enabled: true },
         callbacks: {
             //necessary hacking in order to get the popup image to show.
             elementParse: function() {
              //set the img src of the created popup to be the one found in magnificPopups search, so the image is found.
              for (var x = 0; x < 10; x ++) {
                  if (this.items[x].el && this.items[x].el[0] && this.items[x].el[0].src) {
                      this.items[x].src = this.items[x].el[0].src;
                      break;
                  }
              }

             },
             //when the popup is opened/loaded adjust css so that the close button is more visible and
             //the img is larger and the aspec ratio stays the same (for the most part)
             open: function () {
                 $('.mfp-img').css({'max-height': 'none','height': '100vw', 'width': '100vw'});
                 $(".mfp-close").css({'color': 'black', 'font-size': '40px', 'padding-right': '15px'});
             },
             resize: function() {
                 //unfortunately magnificPopup update the size of the popup everytime you scroll, this will maintain the
                 //css changes maed above (in terms of size);
                 $('.mfp-img').css({'max-height': 'none', 'height':'100vw', 'width': '100vw'});
             }
        },
        gallery: {
            enabled: false
        },
        image: {
            titleSrc: "alt"
        },
        disableOn: function () {
            if ($(window).width() > 735 ) {
                return false;
            }
            return true;
        }
    });


    var hiresImageSrc = mainImage.attr("data-href");
    if( hiresImageSrc && hiresImageSrc != '' && hiresImageSrc.indexOf('noimagelarge') < 0 ) {
        mainImage.removeData("jqzoom").jqzoom(options);
    }
}
/**
 * @function
 * @description replaces the images in the image container. for example when a different color was clicked.
 */
function replaceImages() {
    var newImages = $("#update-images");
    var imageContainer = $cache.pdpMain.find("div.product-image-container");

    imageContainer.html(newImages.html());
    newImages.remove();
    setMainImageLink();

	altImages();
}
/**
 * @function
 * @description Adds css class (image-zoom) to the main product image in order to activate the zoom viewer on the product detail page.
 */
function setMainImageLink() {
    if (dialog.isActive() && dialog.isQuickView() || window.isMobileUserAgent) {
        $cache.pdpMain.find("a.main-image").removeAttr("href");
    }
    else {
        $cache.pdpMain.find("a.main-image").addClass("image-zoom");
    }
}
/**
 * @function
 * @description Removes css class (image-zoom) from the main product image in order to deactivate the zoom viewer on the product detail page.
 */
function removeImageZoom() {
    $cache.pdpMain.find("a.main-image").removeClass("image-zoom");
}

function altImages() {
    var $thumb = $('#thumbnails').find('li.thumb');
    if ($thumb.length > 2) {
        var carouselBlock = $('#hf-pdpMain #thumbnails ul');
        carouselBlock.slick({
            lazyLoad: 'ondemand',
            slidesToShow: 2,
            slide: '.thumb',
            infinite: false,
            variableWidth: true,
            responsive: [{
                breakpoint: 1440,
                settings: {
                    variableWidth: false
                }
            }]
        });
    } else {
        $thumb.addClass('static');
    }
}

/**
 * @function
 * @description Updates quantity form name and custom drop down function call
 */
function qtyUpdate() {
    var $qtySelect = $("select[name$=_quantity]"),
        $qtySelectOption = $qtySelect.find($('option'));
    $qtySelect.attr('name','Quantity');

    $qtySelectOption.each(function() {
        var text = $(this).text(),
            value = $(this).val();
        text = parseInt(text);
        value = parseInt(value);
        $(this).attr({
            label: text,
            value: value
          }).text(text);
    });
    util.initCustomDropdowns(true);
}

/**
 * @function
 * @description Functionality for selecting states for products with wine
 */
function wineStates() {
    var $wineStateWrapper = $('.wine-state-wrapper');
    var checkBox = document.getElementById('dwfrm_product_agecheck');

    if ($wineStateWrapper.length) {
        //Disable the add-to-cart button if there is a wine shipping restriction
        var $addToCart = $('#add-to-cart');
        var $wineStateButton = $wineStateWrapper.find('.wine-state-button');
        var $wineStateMessage = $('.wine-state-message');

        //If there is a wine shipping restriction, trim the states selector to only include valid states
        var restrictedStates = $('.wine-resricted-states').text().split(',');
        $('.wine-state-wrapper .select-option').each(function() {
            if (restrictedStates.indexOf($(this).attr('label')) > -1) {
                $(this).remove();
            }
        });

        //If addToCart button was disabled on page load
        if($addToCart.prop("disabled")) {
            //Also disable wineStateButton
            $wineStateButton.prop("disabled", true);
        }else{ // removed .can-deliver visible check as we are not showing that message now.
            if ($('.wine-state-select option:selected').val() != '' && checkBox.checked) {
                $addToCart.prop("disabled", false);
            } else {
                $addToCart.prop("disabled", true);
            }
        }
        $('.wine-state-select').on('change', function() {
                var data = $('.wine-state-button').data('restricted-states'),
                selected = $('.wine-state-select option:selected').text(),
                selectedValue = $('.wine-state-select').val();

            //Always disable on submit (incase they send blank - the default message)
            $addToCart.prop("disabled", true);

            if(typeof(data) !== 'undefined' && selected.length && !$('button#add-to-cart').hasClass('add-to-cart-disabled')) {
                //if the selected option is not within the list of restricted states
                //var $goodNewsElements = $('.vis-toggle-1');
                //var $badNewsElements = $('.vis-toggle-2');
                var $suggestedProducts = $('.suggested-products');
                //Show the current message
                //$wineStateMessage.show();

                if(data.indexOf(selected) === -1 && checkBox.checked) {
                    //enable the add-to-cart button and toggle visibilty of corresponding elements
                    $addToCart.prop("disabled", false);
                    /*$badNewsElements.hide();
                    $goodNewsElements.show();
                    $suggestedProducts.hide();*/
                }else{
                    //disable the add-to-cart button
                    $addToCart.prop("disabled", true);
                    /*$goodNewsElements.hide();
                    $badNewsElements.show();
                    $('.see-shipping').css('display','block');
                    $suggestedProducts.show();*/
                }
                //Inject state value chosen into our message
                $('.chosen-state').text(selectedValue);
            }
        });
        $(checkBox).on('click', function() {
            if (checkBox.checked && $('.wine-state-select option:selected').val() != ''){
                $addToCart.prop("disabled", false);
            } else {
                $addToCart.prop("disabled", true);
            }
        });
/*            $wineStateMessage.find('.see-shipping').on('click', function() {
                var offset = 0;
                offset = $('.full-header-wrapper').height() + 90;
                $('.hf-product-tabs-header a[data-tab="tab5"]').trigger('click');
                if($('.stickyHeader').length) {
                    offset = $('.full-header-wrapper').height() + 12;
                }
                $('html, body').animate({
                    scrollTop: $('.hf-product-tabs-header a[data-tab="tab5"]').offset().top - offset
                }, 1000);
            });
        }*/
    }
}

/**
 * @function
 * @description Functionality for adding a signature ribbon modal
 */
function signatureRibbonModal() {
    var $modal = $('#add-ribbon'),
        $addToCart = $('button.add-to-cart-option'),
        $optionRadio     = $('.option-radio'),
        $productOption = $('.hf-optionsText'),
        $selectBox = $('.product-option.input-select'),
        $overlay = $('.ui-widget-overlay'),
        $close = $('.ui-dialog-titlebar-close'),
        $optionAdded = $('.product-option-added');


    $modal.dialog({
        dialogClass: 'add-ribbon-container',
        maxWidth: 690,
        maxHeight: 557,
        width: 'auto',
        modal: true,
        fluid: true,
        draggable: false,
        resizable: false,
        position : 'center',
        open : function () {
            $(this).closest('.ui-dialog').attr({
                'role' : 'region',
                'aria-label' : 'Select Gift Option Dialog'
            });

        }
    });

    $addToCart.off('click').on('click', function() {
        $optionAdded.val(1);
        $modal.dialog('close');
        $('#add-to-cart').click();
        $selectBox.val('None');
        $optionAdded.val(0);
    });

    $overlay.on('click', function() {
        $modal.dialog('close');
        $selectBox.val('None');
        $optionAdded.val(0);
    });

    $close.on('click', function() {
        $selectBox.val('None');
        $optionAdded.val(0);
    });

    $close.keyup(function (e) {
        if (e.which == 13){
            $close.click();
        }
    });

    $productOption.off('keypress').on('keypress', function (e) {
        if (e.which == 13){
            $(this).find('label').click();
            $optionRadio.trigger('change');
        }
    });

    $productOption.each(function(){
        var optionID = $(this).data('optionname');
        if($(this).hasClass('hf-activeOption')) {
            $selectBox.val(optionID);
        }
    });

    $optionRadio.off('change').on('change', function(e) {
        var optionID = $(this).parent().data('optionname');
        e.stopImmediatePropagation();
        if($(this).parent().hasClass('hf-activeOption')) {
            $productOption.removeClass('hf-activeOption');
            $selectBox.val('None');
        } else {
            $productOption.removeClass('hf-activeOption');
            $(this).parent().addClass('hf-activeOption');
            $selectBox.val(optionID);
        }
        $selectBox.trigger('change');
    });
}
/**
 * @private
 * @function
 * @description Initializes the DOM of the product detail page (images, reviews, recommendation and product-navigation).
 */
function initializeDom() {
    $cache.pdpMain.find('div.product-detail .product-tabs').tabs();
    if($('#pwrwritediv').length > 0) {
        var options = $.extend(true, {}, dialog.settings, {
            autoOpen : true,
            height : 750,
            width : 650,
            dialogClass : 'writereview',
            title : 'Product Review',
            resizable : false
        });

        dialog.create({
            target : window.app.ui.reviewsContainer,
            options : options
        });
    }
    qtyUpdate();
    loadProductNavigation();
    setMainImageLink();
    changeItemCountOnClick();
    giftOptionsSlide();
    productContentInitTabs();
    recommendedProductsCarousel();
    initPdpSectionCarousels();
	initUpsellerSwatchCarousel();
    loadYoutubePopup();
    promoDetails();
    altImages();
    wineStates();

    if ($cache.productSetList.length > 0) {
        var unavailable = $cache.productSetList.find("form").find("button.add-to-cart[disabled]");
        if (unavailable.length > 0) {
            $cache.addAllToCart.attr("disabled", "disabled");
            $cache.addToCart.attr("disabled", "disabled"); // this may be a bundle

        }
    }
    $(window).on('resize', function() {
        $('.zoomWrapper, .zoomWrapperImage').width($('.primary-image').width());
        $('.zoomWrapper, .zoomWrapperImage').height($('.primary-image').height());
    });

    $cache.pdpMain.on("click", ".add-ribbon", function (e) {
        e.preventDefault();
        signatureRibbonModal();
    });
}
/**
 * @private
 * @function
 * @description Initializes the cache on the product detail page.
 */
function initializeCache() {
    $cache = {
        productId : $("#pid"),
        pdpMain : $("#hf-pdpMain"),
        productContent : $("#product-content"),
        thumbnails : $("#thumbnails"),
        bonusProductGrid : $(".bonusproductgrid"),
        imageContainer : $(".product-primary-image"),
        productSetList : $("#product-set-list"),
        addToCart : $("#add-to-cart"),
        addAllToCart : $("#add-all-to-cart")
    };
    $cache.detailContent = $cache.pdpMain.find("div.detail-content");
    $cache.pdpForm = $cache.pdpMain.find("form.pdpForm");
    $cache.swatches = $cache.pdpMain.find("ul.swatches");
    $cache.mainImageAnchor = $cache.imageZoom = $cache.imageContainer.find("a.main-image");
    $cache.mainImage = $cache.mainImageAnchor.find("img.primary-image");
}

/**
 * @private
 * @function
 * @description Initializes events on the product detail page for the following elements:<br/>
 * <p>availability message</p>
 * <p>add to cart functionality</p>
 * <p>images and swatches</p>
 * <p>variation selection</p>
 * <p>option selection</p>
 * <p>send to friend functionality</p>
 */
function initializeEvents() {
    // add or update shopping cart line item
    product.initAddToCart();
    $cache.pdpMain.on("change keyup", "form.pdpForm select[name=Quantity]", function (e) {
        var availabilityContainer = $cache.pdpMain.find("div.availability");
        product.getAvailability(
            $("#pid").val(),
            $(this).val(),
            function (data) {
                if (!data) {
                    $cache.addToCart.removeAttr("disabled");
                    availabilityContainer.find(".availability-qty-available").html();
                    availabilityContainer.find(".availability-msg").show();
                    wineStates();
                    return;
                }else{
                    var avMsg = null;
                    var avRoot = availabilityContainer.find(".availability-msg").html('');
                    var variations = $('.product-variations');
                    var variantSelected = variations.length > 0 ? variations.find('.attribute .select').length > 0 : true;

                    // Look through levels ... if msg is not empty, then create span el
                    if (Number(data.ats) >= Number(data.statusQuantity)) {
                        if (variantSelected) { // removed .can-deliver visible check as we are not showing that message now.
                            $cache.addToCart.removeAttr('disabled');
                        }

                        $('.stock-status.above-limit').hide();
                        // if new quantity is below in-stock threshold or is below ATS and the preorder date is in the past
                        // show In-Stock messaging
                        if (data.levels.IN_STOCK >= Number(data.statusQuantity) || new Date() > new Date(data.inStockDate)) {
                            $('.stock-status.preorder').hide();
                            $('.stock-status.instock').show();
                        } else {
                            // check for some in-stock
                            if (data.levels.IN_STOCK > 0) {
                                var preorderQty = Number(data.statusQuantity) - data.levels.IN_STOCK;
                                $('.stock-status.preorder .preorder-count').text(preorderQty.toString());
                            }
                            $('.stock-status.instock').hide();
                            $('.stock-status.preorder').show();
                        }
                    }
                    if( data.levels.IN_STOCK > 0 ) {
                        if (data.isAvailable && !$cache.addToCart.hasClass('out-of-stock') && variantSelected) {
                            $cache.addToCart.removeAttr('disabled');
                        }

                        avMsg = avRoot.find(".in-stock-msg");
                        if (avMsg.length === 0) {
                            avMsg = $("<p/>").addClass("in-stock-msg").appendTo(avRoot);
                        }
                        if( data.levels.PREORDER == 0 && data.levels.BACKORDER == 0 && data.levels.NOT_AVAILABLE == 0 ) {
                            // Just in stock
                            avMsg.text(window.Resources.IN_STOCK);
                        } else {
                            // In stock with conditions ...
                            avMsg.text(data.inStockMsg);
                        }
                    }
                    if( data.levels.PREORDER > 0 ) {
                        avMsg = avRoot.find(".preorder-msg");
                        if (avMsg.length === 0) {
                            avMsg = $("<p/>").addClass("preorder-msg").appendTo(avRoot);
                        }
                        if( data.levels.IN_STOCK == 0 && data.levels.BACKORDER == 0 && data.levels.NOT_AVAILABLE == 0 ) {
                            // Just in stock
                            avMsg.text(window.Resources.PREORDER);
                        } else {
                            avMsg.text(data.preOrderMsg);
                        }
                    }
                    if( data.levels.BACKORDER > 0 ) {
                        avMsg = avRoot.find(".backorder-msg");
                        if (avMsg.length === 0) {
                            avMsg = $("<p/>").addClass("backorder-msg").appendTo(avRoot);
                        }
                        if( data.levels.IN_STOCK == 0 && data.levels.PREORDER == 0 && data.levels.NOT_AVAILABLE == 0 ) {
                            // Just in stock
                            avMsg.text(window.Resources.BACKORDER);
                        } else {
                            avMsg.text(data.backOrderMsg);
                        }
                    }
                    if( data.inStockDate != '' ) {
                        avMsg = avRoot.find(".in-stock-date-msg");
                        if (avMsg.length === 0) {
                            avMsg = $("<p/>").addClass("in-stock-date-msg").appendTo(avRoot);
                        }
                        avMsg.text(String.format(window.Resources.IN_STOCK_DATE,data.inStockDate));
                    }
                    if( data.levels.NOT_AVAILABLE > 0 ) {
                        avMsg = avRoot.find(".not-available-msg");
                        if (avMsg.length === 0) {
                            avMsg = $("<p/>").addClass("not-available-msg").appendTo(avRoot);
                        }
                        if( data.levels.PREORDER == 0 && data.levels.BACKORDER == 0 && data.levels.IN_STOCK == 0 ) {
                            avMsg.text(window.Resources.NOT_AVAILABLE);
                        } else {
                            avMsg.text(window.Resources.REMAIN_NOT_AVAILABLE);
                        }
                    }
                    wineStates();
                    return;
                }
                $cache.addToCart.attr("disabled", "disabled");
                availabilityContainer.find(".availability-msg").hide();
                var avQtyMsg = availabilityContainer.find(".availability-qty-available");
                if (avQtyMsg.length === 0) {
                    avQtyMsg = $("<span/>").addClass("availability-qty-available").appendTo(availabilityContainer);
                }
                avQtyMsg.text(data.inStockMsg).show();

                var avQtyMsg = availabilityContainer.find(".availability-qty-available");
                if (avQtyMsg.length === 0) {
                    avQtyMsg = $("<span/>").addClass("availability-qty-available").appendTo(availabilityContainer);
                }
                avQtyMsg.text(data.backorderMsg).show();
            });

    });

    // Add to Wishlist and Add to Gift Registry links behaviors
    $cache.pdpMain.on("click", "a.wl-action", function (e) {
        e.preventDefault();

        var data = util.getQueryStringParams($("form.pdpForm").serialize());
        if (data.cartAction) {
            delete data.cartAction;
        }
        var url = util.appendParamsToUrl(this.href, data);
        url = this.protocol + "//" + this.hostname + ((url.charAt(0) === "/") ? url : ("/" + url));
        window.location.href = url;
    });

    $cache.pdpMain.on("hover", "ul.Color a.swatchanchor", function () {
        swapImage(this);
    });
    // productthumbnail.onclick()
    $cache.pdpMain.on("click", "img.productthumbnail", function (e) {
        e.preventDefault();
        var lgImg = $(this).data("lgimg");

        // switch indicator
        $cache.pdpMain.find("div.product-thumbnails li.selected").removeClass("selected");
        $(this).closest("li").addClass("selected");

        setMainImage(lgImg);
        // load zoom if not quick view
        if( lgImg.hires != '' && lgImg.hires.indexOf('noimagelarge') < 0 ) {
            setMainImageLink();
            loadZoom();
        } else {
            removeImageZoom();
        }
    });

    //ribbon types
    // HF-33 6/6/2016 by Kunle:
    // New function that will grab img src stored in hidden divs when the user selects an ribbon from the option
    // dropdown and change the primary-image to the one associated with the option.
    // Rendering template: productimages.isml

    //HF-33 6/16/2016 by Kunle:
    //Updated: using hidden input tags instead of hidden divs w/ data attributes
    //this fixes the issue related to the fact that some product options have spaces and special characters which
    //iterferes with using those values as classes in the hidden divs.
    //now there are hidden input fields, the name being the product option value and the value being the url of the hi-res
    //image relating to the product option.

    // HF-411 11/4/2016 by Kunle:
    // Updated: Removed mobile specific conditional. Now there are two 'main image' divs that control the primary image experience
    // of the mobile and

    $cache.pdpMain.on("change", "[name$=_ribbonType], [name$=_giftTag]", function (e) {
        //get value of the dropdown on change
        var ribbonVal = $(this).val();
        var mobileCarousel = $cache.pdpMain.find("#carouselContainer");
        //get the hidden input field with the corrosponding name
        var hiddenDiv = $cache.pdpMain.find("[name='" + ribbonVal + "']");
        //if no hidden input fields are found (which would contain the src url for new primary img), dont do anything
        if (hiddenDiv.length < 1) { return;}

        //if found, get the value of the hidden input field (value is set in productimages.isml) and use the name and value
        //as the src and alt of the primary image respectively.
        var hiddenDivURL = hiddenDiv.val();

            //-->replace the main image with the one chosen in the ribbon option box.
            //for mobile, if an image has already updated in the carousel remove that image before updating the carousel with another
            if ($cache.pdpMain.find(".ribbonItem").length > 0) {
                mobileCarousel.slick('slickRemove', mobileCarousel.find('.slick-slide:not(".slick-cloned")').length - 1);
            }
            //Add the mobile image and slide to it in the carousel
            mobileCarousel.slick('slickAdd', "<img class='primary-image ribbonItem' src='" + hiddenDivURL + "'/>");
            mobileCarousel.slick('slickGoTo', mobileCarousel.find('slick-slide').length - 1);
            var imgZoom = $cache.pdpMain.find("a.main-image.hf-desktopImage");
            var options = {
                    zoomType: 'innerzoom',
                    alwaysOn : 0, // setting to 1 will load load high res images on page load
                    zoomWidth : 575,
                    zoomHeight : 349,
                    position:'right',
                    preloadImages: 0, // setting to 1 will load load high res images on page load
                    xOffset: 30,
                    yOffset:0,
                    showEffect : 'fadein',
                    hideEffect: 'fadeout'
                };
            imgZoom.attr("href", hiddenDivURL);
            imgZoom.find("img.primary-image").attr({
              "src" : hiddenDivURL,
              "alt" : ribbonVal,
              "title" : ribbonVal
            });
            //reinitialiize jqzoom so that it will the hoverzoom will still work after we've changed the image.
            imgZoom.removeData("jqzoom").jqzoom(options);

            //reinitializes magnific popup for newly added slide which is being used on the mobile site
            $(".ribbonItem").magnificPopup({type: 'image', closeOnContentClick: true, zoom: { enabled: true },
                 callbacks: {
                     //necessary hacking in order to get the popup image to show.
                     elementParse: function() {
                      //set the img src of the created popup to be the one found in magnificPopups search, so the image is found.
                      for (var x = 0; x < 10; x ++) {
                          if (this.items[x].el && this.items[x].el[0] && this.items[x].el[0].src) {
                              this.items[x].src = this.items[x].el[0].src;
                              break;
                          }
                      }

                     },
                     //when the popup is opened/loaded adjust css so that the close button is more visible and
                     //the img is larger and the aspec ratio stays the same (for the most part)
                     open: function () {
                         $('.mfp-img').css({'max-height': 'none','height': '100vw', 'width': '100vw'});
                         $(".mfp-close").css({'color': 'black', 'font-size': '40px', 'padding-right': '15px'});
                     },
                     resize: function() {
                         //unfortunately magnificPopup update the size of the popup everytime you scroll, this will maintain the
                         //css changes maed above (in terms of size);
                         $('.mfp-img').css({'max-height': 'none', 'height':'100vw', 'width': '100vw'});
                     }
                },
                gallery: {
                    enabled: false
                },
                image: {
                    titleSrc: "alt"
                }
            });
    });

    // dropdown variations
    $cache.pdpMain.on("change", ".product-options select", function (e) {
        var salesPrice = $cache.pdpMain.find("div.product-add-to-cart .price-sales");

        var selectedItem = $(this).children().filter(":selected").first();
        var combinedPrice = selectedItem.data("combined");
        salesPrice.text(combinedPrice);
    });

    // prevent default behavior of thumbnail link and add this Button
    $cache.pdpMain.on("click", "li.unselectable a", false);

    // handle drop down variation attribute value selection event
    $cache.pdpMain.on("change", ".variation-select", function(e) {
        if ($(this).val().length === 0) {return;}
        var qty = $cache.pdpForm.find("select[name$=_quantity]").first().val(),
            listid = $cache.pdpForm.find("input[name='productlistid']").first().val(),
            productSet = $(this).closest('.subProduct'),
            params = {
                Quantity : isNaN(qty) ? "1" : qty,
                format : "ajax"
            };
        if( listid ) {params.productlistid = listid;}
        var target = (productSet.length > 0 && productSet.children.length > 0) ? productSet : $cache.productContent;
        var url = util.appendParamsToUrl($(this).val(), params);
        progress.show($cache.pdpMain);

        ajax.load({
            url: url,
            callback : function (data) {
                target.html(data);
                product.initAddToCart();
                changeItemCountOnClick();
                promoDetails();
                $("update-images").remove();
                giftOptionsSlide();
            }
        });
    });

    // swatch anchor onclick()
    $cache.pdpMain.on("click", "div.product-detail a[href].swatchanchor", function (e) {
        e.preventDefault();

        var el = $(this);
        if( el.parents('li').hasClass('unselectable') || (el.hasClass('upseller-swatchanchor') && el.parent('li').hasClass('selected'))) {
			return;
		}

        var swapMainImage = el.closest("ul.swatches").hasClass("Color") || el.hasClass('upseller-swatchanchor');
		var isUpsellerVariation = el.hasClass('upseller-swatchanchor');

        var anchor = el,
            qty = $cache.pdpForm.find("select[id$=_quantity]").first().val(),
            listid = $cache.pdpForm.find("input[name='productlistid']").first().val(),
            productSet = $(anchor).closest('.subProduct'),
            params = {
                Quantity : isNaN(qty) ? "1" : qty
            };
        if( listid ) {params.productlistid = listid;}

        var target = (productSet.length > 0 && productSet.children.length > 0) ? productSet : $cache.productContent;
        var url = util.appendParamsToUrl(this.href, params);
        progress.show($cache.pdpMain);

        ajax.load({
            url: url,
            callback : function (data) {
                target.html(data);
                product.initAddToCart();
                if (swapMainImage || isUpsellerVariation) {
                    replaceImages();
                }
                changeItemCountOnClick();
                promoDetails();
                giftOptionsSlide();
                qtyUpdate();
				if (target.find('.bopis-selector').length) {
					bopis.init();
				}
				if (isUpsellerVariation) {
					initUpsellerSwatchCarousel();
					updateUpsellerProductName();
					updateVariationTabs(el);
					wineStates();
				}
                $cache.pdpMain.on("click", ".add-ribbon", function (e) {
                    e.preventDefault();
                    signatureRibbonModal();
                });
            }
        });
    });

    $cache.productSetList.on("click", "div.product-set-item li a[href].swatchanchor", function (e) {
        e.preventDefault();
        // get the querystring from the anchor element
        var params = util.getQueryStringParams(this.search);
        var psItem = $(this).closest(".product-set-item");

        // set quantity to value from form
        var qty = psItem.find("form").find("select[name$=_quantity]").first().val();
        params.Quantity = isNaN(qty) ? "1" : qty;

        var url = window.Urls.getSetItem + "?" + $.param(params);

        // get container
        var ic = $(this).closest(".product-set-item");
        ic.load(url, function () {
            progress.hide();
            if ($cache.productSetList.find("button.add-to-cart[disabled]").length > 0) {
                $cache.addAllToCart.attr("disabled","disabled");
                $cache.addToCart.attr("disabled","disabled"); // this may be a bundle
            }
            else {
                $cache.addAllToCart.removeAttr("disabled");
                $cache.addToCart.removeAttr("disabled"); // this may be a bundle
            }

            product.initAddToCart(ic);
        });
    });

    $cache.addAllToCart.on("click", function (e) {
        e.preventDefault();
        var psForms = $cache.productSetList.find("form").toArray(),
            miniCartHtml = "",
            addProductUrl = util.ajaxUrl(window.Urls.addProduct);

        // add items to cart
        function addItems() {
            var form = $(psForms.shift());
            var itemid = form.find("input[name='pid']").val();

            $.post(addProductUrl, form.serialize())
            /*$.ajax({
                method: "POST",
                dataType : "html",
                url: addProductUrl,
                data: form.serialize()
            })*/
            .done(function (response) {
                // success
                miniCartHtml = response;
            })
            .fail(function (xhr, textStatus) {
                // failed
                var msg = window.Resources.ADD_TO_CART_FAIL;
                $.validator.format(msg, itemid);
                if(textStatus === "parsererror") {
                    msg += "\n" + window.Resources.BAD_RESPONSE;
                } else {
                    msg += "\n" + window.Resources.SERVER_CONNECTION_ERROR;
                }
                window.alert(msg);
            })
            .always(function () {
                if (psForms.length > 0) {
                    addItems();
                }
                else {
                    dialog.close();
                    minicart.show(miniCartHtml, {
                        lastActive: $cache.addAllToCart
                    });
                }
            });
        }
        addItems();
        return false;
    });
    sendtofriend.initializeDialog($cache.pdpMain, "a.send-to-friend");

    $cache.pdpMain.find("button.add-to-cart[disabled]").attr('title', $cache.pdpMain.find(".availability-msg").html() );

    suggestedproducts.init();

    $cache.pdpMain.on("click", ".add-ribbon", function (e) {
        e.preventDefault();
        signatureRibbonModal();
    });

    bopis.init();
}
/**
 * @private
 * @function
 * @description Event handler to handle the add to cart event
 */
function setAddToCartHandler(e) {
    e.preventDefault();
    var form = $(this).closest("form");
    var qty = form.find("select[name=Quantity]");
    var isSubItem = $(this).hasClass("sub-product-item");
    if(qty.length === 0 || isNaN(qty.val()) || parseInt(qty.val(), 10) === 0) {
        qty.val("1");
    }

    var $gridTile =  $('.clicked');
    var gridIndexOnCLP = null;

    var $selectedProductOption = $('input.option-radio:checked').parent();
    var $productOptionAdded = Number($('.product-option-added').val());
    var optionData;

    var $addToCart = $('#add-to-cart');
    var productData = $addToCart.data('tealium');
    productData.product_quantity = [parseInt(qty.val(), 10).toFixed(2)];

    /* Generate and send tealium information */
    if($gridTile.length) {
        gridIndexOnCLP = "" + $gridTile.data('clp-grid-index');
    }

    if($productOptionAdded > 0 && $selectedProductOption.length) {
        optionData = $selectedProductOption.data('option-tealium');
        productData = {
            category_name : productData.category_name,
            product_id : [productData.product_id[0], optionData.product_id],
            product_name : [productData.product_name[0], optionData.product_name],
            product_original_price : [productData.product_original_price[0], optionData.product_original_price],
            product_unit_price : [productData.product_unit_price[0], optionData.product_unit_price],
            product_discount : [productData.product_discount[0], optionData.product_discount],
            product_quantity : [qty.val() + ".00", qty.val() + ".00"],
            product_sku : [productData.product_sku[0], optionData.product_sku],
            product_category : [productData.product_category[0], optionData.product_category],
        };
    }else if($('.hf-optionsText').length) { //if this product has the ability to have a ProductOption
        productData = {
            category_name : productData.category_name,
            product_id : [productData.product_id[0], "None"],
            product_name : [productData.product_name[0], "None"],
            product_original_price : [productData.product_original_price[0], "0.00"],
            product_unit_price : [productData.product_unit_price[0], "0.00"],
            product_discount : [productData.product_discount[0], "0.00"],
            product_quantity : [qty.val() + ".00", qty.val() + ".00"],
            product_sku : [productData.product_sku[0], "None"],
            product_category : [productData.product_category[0], "None"],
        };
    }

    /* Check inventory */
    var $instock = form.parent().find('.instock-quantity');
    var $preorder = form.parent().find('.preorder-quantity');
    var $preorderQuantityDesired = form.parent().find('.stock-status.preorder span');
    if($instock.length) {
        var stockQty = parseInt($instock.val());
        var preorderQty = parseInt($preorder.val());
        var desiredQty = parseInt(qty.val());
        var maxPurchaseQuantity = stockQty + preorderQty;
        var isPerpetual = $instock.data('isperpetual');

        if(!isPerpetual) {
            if(maxPurchaseQuantity < desiredQty) {
                $('.stock-status').hide();
                $addToCart.prop("disabled", true);
                if(maxPurchaseQuantity > 0) {
                    $('.stock-status.above-limit').show();

                    $('select[name$=_quantity]').on('change', function(event) {
                        //if quantity drops to what is purchasable
                        if($(this).val() <= maxPurchaseQuantity) {
                            $addToCart.prop("disabled", false);
                            //Remove listener
                            $(this).off('change', event);
                        }

                    });

                }

                //Disable the button if there is nothing left to purchase, set button to "out of stock"
                if(maxPurchaseQuantity === 0) {
                    $('.stock-status').hide();
                    $addToCart.addClass('out-of-stock');
                    $addToCart.text(window.Resources.OUT_OF_STOCK);
                    $addToCart.prop("disabled", true);
                }
                return false;
            }else{
                if (stockQty >= desiredQty) {
                    $instock.val(stockQty - desiredQty);
                    $('.stock-status').hide();
                    $('.stock-status.instock').show();
                } else {
                    var difference = desiredQty - stockQty;
                    $instock.val(0);
                    stockQty = 0;
                    preorderQty = preorderQty - difference;
                    $preorder.val(preorderQty);
                }
                $('.max-purchase-quantity').text(maxPurchaseQuantity);
            }

            //Disable the button if there is nothing left to purchase, set button to "out of stock"
            if(maxPurchaseQuantity === 0) {
                $('.stock-status').hide();
                $addToCart.text(window.Resources.OUT_OF_STOCK);
                $addToCart.prop("disabled", true);
            }
        }
    }


    /* Add product to minicart and basket */
    var data = form.serialize();
    var pliUUIDToReplace = $('.pliUUIDToUpdate').text();
    if(typeof pliUUIDToReplace !== 'undefined' && pliUUIDToReplace.length > 1) {
        data += "&uuid=" + encodeURIComponent(pliUUIDToReplace);
    }

    var url = util.ajaxUrl(window.Urls.addProduct);
    var realPID = null;
    data.split("&").map(function(item) {
        if (item.indexOf('pid') > -1) {
            realPID = item.split('=')[1];
        }
    });

    var bopisSelection = false;

    if ($('.bopis-selector').length > 0) {
        bopisSelection = $('[name^="bopis-radio"]:checked').val();
    }

    if (bopisSelection) {
        data += "&bopisSelection=" + bopisSelection;
        if (bopisSelection === 'instore') {
            if ($('#add-to-cart-storeid').length > 0) {
                data += "&storeid=" + $('#add-to-cart-storeid').val();
            }
            if ($('#add-to-cart-zipcode').length > 0) {
                data += "&zipcode=" + $('#add-to-cart-zipcode').val();
            }
            if ($('#add-to-cart-inventorylistid').length > 0) {
                data += "&inventorylistid=" + $('#add-to-cart-inventorylistid').val();
            }
        }
    }
    $.post(url, data, function (response) {
        tealium.cartAdd(productData, null, gridIndexOnCLP);
        if (window.pageContext.ns === "cart") {
            window.location.reload();
        } else {
            minicart.show(response, {
                asModal: true,
                pid: realPID,
                lastActive: $addToCart
            });
        }
    });
    if (dialog) {
        dialog.triggerDelete();
    }

}

var product = {
    init : function () {
        initializeCache();
        initializeDom();
        initializeEvents();
    },
    readReviews : function() {
        $('.product-tabs').tabs('select','#tab4');
        $('body').scrollTop($('#tab4').offset().top);
    },
    /**
     * @function
     * @description Gets the availability to given product and quantity
     */
    getAvailability : function (pid, quantity, callback) {
        ajax.getJson({
            url: util.appendParamsToUrl(window.Urls.getAvailability, {pid:pid, Quantity:quantity}),
            callback: callback
        });
    },
    /**
     * @function
     * @description Binds the click event to a given target for the add-to-cart handling
     * @param {Element} target The target on which an add to cart event-handler will be set
     */
    initAddToCart : function (target) {
        if (target) {
            target.on("click", ".add-to-cart", setAddToCartHandler);
        }
        else {
            $(".add-to-cart").on("click", setAddToCartHandler);
        }
    }
};

module.exports = product;
