'use strict';

var storefront = require('./storefront');
var product = require('./product');
var quickview = require('../quickview');
var progress = require('../progress');
var producttile = require('../producttile');
var productcompare = require('../productcompare');
var util = require('../util');
var lazyload = require('../lazyload');
var recCaro = require('../recommendation-caro');

var search = {
    init : function () {
        restoreScrollPosition();
        if($('.pfaelzer').length > 0 || $('.home-slider-slides .slide').length > 0) {
            storefront.init();
            return;
        }
        $cache = {
            main : $("#hf-main"),
            items : $("#hf-search-result-items")
        };
        $cache.content = $cache.main.find(".hf-search-result-content");
        recCaro.init();
        productcompare.init();
        updateProductListing(false);
        if (window.Clientcache.LISTING_INFINITE_SCROLL) {
            $('.view-more-btn').click(function () {
                var $placeholder = $('.infinite-scroll-placeholder');
                window.sessionStorage.scrollCount = 0;
                loadSearchPage($placeholder, true);
                $(window).on('scroll', initInfiniteScroll);
                $(this).attr('aria-expanded','true').removeClass('show').hide();
            });
        }
        producttile.init();
        initializeEvents();
        storefront.readMore($('.hf-rm-seo'));
        filters();
        narrowBy();
        $(window).resize(function() {
            filters();
            narrowBy();
        });
    }
};

var restoreScrollPosition = function() {

    if ('scrollRestoration' in window.history) {
        window.history.scrollRestoration = 'manual';
    }
    var searchParams = util.getQueryStringParams(window.location.href);
    if (!('position' in searchParams)) {
        return false;
    }

    var elementIndex = searchParams['position'];
    if (elementIndex) {
        var $element = $('.hf-grid-tile[data-index="' + elementIndex + '"]'),
            offset = $('#hf-header').innerHeight();
        window.setTimeout(function () {
            $("html,body").animate({
                scrollTop: $element.offset().top - (offset * 2)
            }, 1000);
        }, 1000);
    }
};

var $cache = {};
/**
 * @private
 * @function
 * @description CYOB landing pg
 */
$('.popup-youtube').magnificPopup({
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,
    fixedContentPos: false
});

$('.cyob-steps').insertAfter('.cyob-content .secondary.top');
$('.cyob-content img.step1').appendTo('.cyob-steps .cyob1');
$('.cyob-content img.step2').appendTo('.cyob-steps .cyob2');
$('.cyob-content img.step3').appendTo('.cyob-steps .cyob3');
$('#cyob .cyob-content img').show();

var $containerModal = $('.cyob-modal.cyob-containers');
var $itemsModal = $('.cyob-modal.cyob-items');

function initDialogs() {
    $containerModal.dialog({
        autoOpen : false,
        modal: true,
        height : 760,
        width : 960,
        dialogClass : 'cyob-modal-containers',
        close: function( event, ui ) {
            $('body').css('overflow','auto');
        }
    });

    $itemsModal.dialog({
        autoOpen : false,
        modal: true,
        height : 760,
        width : 960,
        dialogClass : 'cyob-modal-items',
        close: function( event, ui ) {
            $('body').css('overflow','auto');
        }
    });
}

function viewContainers() {
    $('a.cyob-view-containers').click(function(e) {
        e.preventDefault();
        $containerModal.dialog('open');
        noScroll();
        cyobCarousel();
        $('.cyob-modal-containers').scrollTop(0);
    });
    $('a.see-items').click(function() {
        $itemsModal.dialog('open');
        $('.slick-slider').slick('setPosition');
        $('.cyob-modal-items').scrollTop(0);
        $containerModal.dialog('close');
        noScroll();
    });
}

function viewItems() {
    $('a.cyob-view-items').click(function(e) {
        e.preventDefault();
        noScroll();
        $itemsModal.dialog('open');
        cyobCarousel();
        $('.cyob-modal-items').scrollTop(0);
    });
    $('a.see-containers').click(function() {
        $containerModal.dialog('open');
        $('.slick-slider').slick('setPosition');
        $('.cyob-modal-containers').scrollTop(0);
        $itemsModal.dialog('close');
        noScroll();
    });
}

function cyobCarousel() {
    if ($(window).width() < 737) {
        $('.cyob-carousel').each(function(key, item) {
            var sliderIdName = 'slider' + key;
            this.id = sliderIdName;
            var sliderId = '#' + sliderIdName;
            var hasSlick = $(sliderId).hasClass('slick-initialized');
            if(hasSlick == false && sliderId.length) {
                $(sliderId).slick({
                    lazyLoad: 'ondemand',
                    dots: true,
                    arrows:true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    appendArrows: $(this).parent().find('.slider-nav'),
                    appendDots: $(this).parent().find('.slider-nav .sl-buttons')
                });
            }
        });
    }
}

function noScroll() {
    if ($(window).width() < 1024) {
        $('body').css('overflow','hidden');
    }
}


initDialogs();
viewContainers();
viewItems();
toggleSearch();


/**
 * @private
 * @function
 * @description replaces breadcrumbs, lefthand nav and product listing with ajax and puts a loading indicator over the product listing
 */
function updateProductListing(isHashChange, isSortChange) {
    var hash = encodeURI(decodeURI(window.location.hash));
    if(hash === '#hf-results-content' || hash === '#hf-results-products' || hash === '') { return; }

    var refineUrl = null;
    if (hash.length > 0) {
        refineUrl = window.location.pathname + "?" + hash.substr(1);
        $('.filters').addClass('filtered');
    }
    else if (isHashChange) {
        refineUrl = window.location.href;
    }

    if (!refineUrl) { return; }
    var url = util.appendParamToURL(refineUrl, "format", "ajax");

    progress.show();
    $cache.main.load(url, function () {
        lazyload.reinit();
        productcompare.init();
        producttile.init();
        filters();
        narrowBy();
        progress.hide();
        if (window.Clientcache.LISTING_INFINITE_SCROLL) {
            jQuery(document).trigger('grid-update');
            $('.view-more-btn').click(function () {
                var $placeholder = $('.infinite-scroll-placeholder'),
                    $endResults = $('.result-hit-content.end');
                $('.result-hit-content.show').removeClass('show');
                window.sessionStorage.scrollCount = 0;
                loadSearchPage($placeholder, true);
                $(window).on('scroll', initInfiniteScroll);
                $(this).attr('aria-expanded','true').removeClass('show').hide();

                if($endResults.length > 1) {
                    $endResults.not(':first').remove();
                }
                $endResults.show();
            });
        }
        if (hash.length > 0) {
            $('.filters').addClass('filtered');
        }

        if (isSortChange) {
            window.isSortChange = false;
            $('#grid-sort-header').focus();
        }
    });
}
/**
 * @private
 * @function
 * @description
 */
function initInfiniteScroll() {

    // getting the hidden div, which is the placeholder for the next page
    var loadingPlaceHolder = jQuery('.infinite-scroll-placeholder[data-loading-state="unloaded"]');

    if (loadingPlaceHolder.length == 1 && util.elementInViewport(loadingPlaceHolder.get(0), 250)) {
        loadSearchPage(loadingPlaceHolder);
    }
}
function loadSearchPage(loadingPlaceHolder, buttonClick) {
    // get url hidden in DOM
    var gridUrl = loadingPlaceHolder.attr('data-grid-url'),
        $endResults = $('.result-hit-content.end');

    // switch state to 'loading'
    // - switches state, so the above selector is only matching once
    // - shows loading indicator
    loadingPlaceHolder.attr('data-loading-state', 'loading');
    loadingPlaceHolder.addClass('infinite-scroll-loading');
    $endResults.hide();


    // named wrapper function, which can either be called, if cache is hit, or ajax repsonse is received
    var fillEndlessScrollChunk = function (html) {
        loadingPlaceHolder.removeClass('infinite-scroll-loading');
        loadingPlaceHolder.attr('data-loading-state', 'loaded');
        loadingPlaceHolder.parent('.hf-search-result-content').append(html);
        loadingPlaceHolder.siblings('.view-btn-wrapper').find('.view-more-btn').removeClass('show');
        loadingPlaceHolder.siblings('.result-hit-content.show').removeClass('show');
        jQuery(document).trigger('grid-update');
        lazyload.reinit();
        if($endResults.length > 1) {
            $endResults.not(':first').remove();
        }
        $endResults.show();
    };

    if (window.Clientcache.LISTING_INFINITE_SCROLL && 'sessionStorage' in window && window.sessionStorage["scroll-cache_" + gridUrl]) {
        // if we hit the cache
        fillEndlessScrollChunk(window.sessionStorage["scroll-cache_" + gridUrl]);

        if (buttonClick) {
            $('.hf-search-result-items').last().find('.hf-thumb-link').first().focus();
        }
    } else {
        // else do query via ajax
        $.ajax({
            type: 'GET',
            dataType: 'html',
            url: gridUrl,
            success: function (response) {
                // put response into cache
                try {
                    window.sessionStorage["scroll-cache_" + gridUrl] = response;
                } catch (e) {
                    // nothing to catch in case of out of memory of session storage
                    // it will fall back to load via ajax
                }
                // update UI
                fillEndlessScrollChunk(response);

                if (buttonClick) {
                    $('.hf-search-result-items').last().find('.hf-thumb-link').first().focus();
                }
            }
        });
    }
}
/**
 * @private
 * @function
 * @description Search toggle function
 */
function toggleSearch() {
	var $searchToggle = $('.header-search-toggle'),
		$searchExpand = $('.header-search-expand'),
		$searchClose = $('.header-search-close'),
        $searchInput = $('#q'),
        $searchPlaceholder = $searchInput.attr('placeholder');

	if($(window).width() <= 736) {
        $searchInput.attr('placeholder', 'Search');
	} else {
        $searchInput.attr('placeholder', $searchPlaceholder);
	}

    $searchToggle.on('click', function () {
        $searchExpand.toggle(500);
        $searchInput.focus();
    });
	$searchClose.on('click', function (e) {
        e.preventDefault();
        $searchExpand.hide(500);
        $searchToggle.focus();
	});
	$searchClose.keypress(function (e) {
        if (e.which == 13) {
            $searchClose.click();
        }
    });

    var focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

    var firstFocusableElement = $searchExpand.find(focusableElements).first();
    var focusableContent = $searchExpand.find(focusableElements);
    var lastFocusableElement = focusableContent.last();


    document.addEventListener('keydown', function(e) {
        var isTabPressed = e.key === 'Tab' || e.keyCode === 9;

        if (!isTabPressed) {
            return;
        }

        if (e.shiftKey) {
            if (document.activeElement === firstFocusableElement[0]) {
                lastFocusableElement.focus();
                e.preventDefault();
            }
        } else {
            if (document.activeElement === lastFocusableElement[0]) {
                firstFocusableElement.focus();
                e.preventDefault();
            }
        }
    });
}

$("body").on('click', '.hf-name-link, .hf-thumb-link', function(e){
    e.preventDefault();
    var element = $(this);
    restorePosition(element);
    window.location.href = $(this).attr('href');
});

function restorePosition(element) {
    var clickedElementIndex = element.parents('li').attr('data-index');
    var url = util.removeParamFromURL(window.location.href, 'start');
    url = util.removeParamFromURL(url, 'sz');
    url = util.removeParamFromURL(url, 'position');
    url = util.removeParamFromURL(url, 'source');
    var size = window.Preferences.PRODUCTS_PER_PAGE;
    var currentIndex = parseInt(clickedElementIndex, 10);
    size = currentIndex > size ?  (Math.ceil(currentIndex / size) * size) : size;
    url = util.appendParamsToUrl(url, {'start': 0, 'sz': size, 'position': clickedElementIndex, 'source': 'pdp'});
    window.history.replaceState(null, null, url);
}

/**
 * @private
 * @function
 * @description Filter functions
 */
function filters() {
    var $blockToMove = $('#filter-block');
    var $mobileHook = $('#filter-hook-mobile');
    var $desktopHook = $('#filter-hook-mobile');
    if (window.innerWidth < 737 && $('#filter-hook-mobile #filter-block').length === 0) {
        $mobileHook.append($blockToMove);
    } else if (window.innerWidth > 736 && $('#filter-hook-desktop #filter-block').length === 0) {
        $desktopHook.append($blockToMove);
    }
    var $filterToggle = $('.filter-by-toggle'),
        $filters = $('.filter-by'),
        $closeFilters = $('.close-filters'),
        $refinement = $('.hf-refinement'),
        $refinementToggle = $refinement.children('legend'),
        $container = $('#hf-secondary'),
        $narrowByToggle = $('.narrow-by-toggle'),
        $categories = $('.category-refine'),
        $clearFilters = $('.clear-refinement'),
        $refinedValue = $('.refined-value'),
        $refinementLink = $('li.selected .refinement-link');

    $refinement.each(function () {
        var $list = $(this).children('ul'),
            $items = $list.children('li');
    });

    $filterToggle.off('click').on('click', function(){
        if($narrowByToggle.hasClass('opened')) {
            $narrowByToggle.removeClass('opened').attr('aria-expanded','false');
            $container.removeClass('categories-opened');
            $categories.slideUp();
        }
        $(this).toggleClass('opened').attr('aria-expanded',$(this).attr('aria-expanded') === 'true' ? 'false' : 'true');
        $filters.slideToggle();

        if($(this).hasClass('opened')) {
            window.sessionStorage.filtersOpen = true;
        } else {
            window.sessionStorage.filtersOpen = false;
        }
    });

    $closeFilters.off('click').on('click', function(){
        window.sessionStorage.filtersOpen = false;
        $filterToggle.removeClass('opened').attr('aria-expanded','false');
        if ($(window).width() < 736) {
            $container.removeClass('filters-opened');
        }
        $filters.slideUp();
        $filterToggle.focus();
    });

    $clearFilters.on('click', function(){
        window.sessionStorage.filtersOpen = false;
        window.sessionStorage.location = window.location.protocol + '//' + window.location.hostname + window.location.pathname;
    });
    $clearFilters.keypress(function (e) {
        if (e.which == 13){
            $clearFilters.click();
        }
    });

    if(window.sessionStorage.filtersOpen == 'true' && window.sessionStorage.location == window.location.href) {
        $filterToggle.addClass('opened no-animation').attr('aria-expanded','true');
        $filters.show();
        if (window.sessionStorage.lastClickedID) {
            $('#' + window.sessionStorage.lastClickedID).focus();
        }
        window.setTimeout(function () {
            $filterToggle.removeClass('no-animation');
        }, 500);
    }

    if($refinedValue.length <= 1) {
        $refinedValue.on('click', function(){
            window.sessionStorage.filtersOpen = false;
        });
    }
    if(window.sessionStorage.filtersOpen == 'true') {
        $refinement.each(function () {
            var $list = $(this).children('ul'),
                $items = $list.children('li');

            if ($items.hasClass('selected')) {
                $filterToggle.addClass('opened no-animation').attr('aria-expanded','true');
                $filters.show();
                if (window.sessionStorage.lastClickedID) {
                    $('#' + window.sessionStorage.lastClickedID).focus();
                }
                window.setTimeout(function () {
                    $filterToggle.removeClass('no-animation');
                }, 500);
                return;
            }
        });
    }

    if (window.innerWidth < 737) {
        $refinementToggle.unbind('click').click(function(){
            if(!$(this).hasClass('expanded')) {
                $refinementToggle.removeClass('expanded').siblings('ul').slideUp();
                $(this).addClass('expanded').siblings('ul').slideDown();
            } else {
                $(this).removeClass('expanded').siblings('ul').slideUp();
            }
        });
    } else {
        $refinementToggle.unbind('click').removeClass('expanded').siblings('ul').show();
    }

    $cache.main.on('click', '.hf-refinement button', function (e) {
        if ($(this).parent().hasClass('selected')) {
            $(this).parent().removeClass('selected');
        }
        $(this).parent().toggleClass('selected');
    });

    $refinementLink.on('click', function () {
        var $links = $refinementLink.length;
        if($links == 1) {
            window.sessionStorage.filtersOpen == 'false';
        }
    });
}

/**
 * @private
 * @function
 * @description Narrow by category functions
 */
function narrowBy() {
    var $narrowByToggle = $('.narrow-by-toggle'),
        $categories = $('.category-refine'),
        $categoryList = $('#category-level-1'),
        $categorySecondaryList = $('#category-level-2'),
        $listLength = $categoryList.children('li').length,
        $listLengthSecondary = $categorySecondaryList.children('li').length,
        $container = $('#hf-secondary');

    if ($(window).width() > 736) {
        if ($listLength == 1 && $listLengthSecondary > 1) {
            $categorySecondaryList.addClass('secondary-categories');
        }

        if($listLength > 6 && $listLength <= 9) {
            $categoryList.css('height','90');
        } else if ($listLength > 10 || $listLengthSecondary > 10) {
            $categoryList.css('height','100');
            $categorySecondaryList.css('height','100');
        }
    } else {
        $categoryList.css('height','auto');
        $categorySecondaryList.css('height','auto');
    }

    $narrowByToggle.off('click').on('click', function(e) {
        e.stopPropagation();
        if($('.filter-by-toggle').hasClass('opened')) {
            $('.close-filters').trigger('click');
        }
        $(this).toggleClass('opened').attr('aria-expanded',$(this).attr('aria-expanded') === 'true' ? 'false' : 'true');
        $container.toggleClass('categories-opened');
        $categories.slideToggle();
    });
}

/**
 * @private
 * @function
 * @description Initializes events for the following elements:<br/>
 * <p>refinement blocks</p>
 * <p>updating grid: refinements, pagination, breadcrumb</p>
 * <p>item click</p>
 * <p>sorting changes</p>
 */
function initializeEvents() {

    // compare checked
    $cache.main.on("click", "input[type='checkbox'].compare-check", function (e) {
        var cb = $(this);
        var tile = cb.closest(".hf-product-tile");

        var func = this.checked ? productcompare.addProduct : productcompare.removeProduct;
        var itemImg = tile.find("div.hf-product-image a img").first();
        func({
            itemid : tile.attr("data-itemid"),
            uuid : tile[0].id,
            img : itemImg,
            cb : cb
        });

    });

    // handle events for updating grid
    $cache.main.on("click", ".filters .hf-refinement button, .hf-pagination a, .breadcrumb-refinement-value a, .selected-refinements button, .selected-refinements a", function (e) {
        var $this = $(this);
        if($this.parent().hasClass("unselectable")) { return; }
        var catparent = $this.parents('.hf-category-refinement');
        var folderparent = $this.parents('.hf-folder-refinement');

        //if the anchor tag is uunderneath a div with the class names & , prevent the double encoding of the url
        //else handle the encoding for the url
        if(catparent.length > 0 || folderparent.length > 0 ) {

            return true;
        } else {
            e.preventDefault();
            e.stopPropagation();
            if ($this.parent().hasClass('selected')) {
                $this.parent().removeClass('selected');
            }
            $this.parent().toggleClass('selected');
            var uri = util.getUri(this);
            if ($this.data('href')) {
                window.sessionStorage.lastClickedID = $this.attr('id');
            } else {
                window.sessionStorage.lastClickedID = null;
            }
            if (uri.query.length > 1 ) {
                window.location.hash = encodeURI(decodeURI(uri.query.substring(1)));
            } else {
                var link;
                if ($this.data('href')) {
                    link = $this.data('href');
                } else {
                    link = this.href;
                }
                window.location.href = link;
            }
            if ($this.parents(".hf-pagination").length) {
                window.scrollTo(0,0);
            }
            return false;
        }
    });

    // handle events item click. append params.
    $cache.main.on("click", ".hf-product-tile a:not('#hfquickviewbutton')", function (e) {
        var a = $(this);
        // get current page refinement values
        var wl = window.location;

        var qsParams = (wl.search.length > 1) ? util.getQueryStringParams(wl.search.substr(1)) : {};
        var hashParams = (wl.hash.length > 1) ? util.getQueryStringParams(wl.hash.substr(1)) : {};

        // merge hash params with querystring params
        var params = $.extend(hashParams, qsParams);
        if (!params.start) {
            params.start = 0;
        }
        // get the index of the selected item and save as start parameter
        var tile = a.closest(".hf-product-tile");
        var idx = tile.data("idx") ? +tile.data("idx") : 0;

        // convert params.start to integer and add index
        params.start = (+params.start) + (idx + 1);
        // set the hash and allow normal action to continue
        a[0].hash = $.param(params);
    });

    // handle sorting change
    $cache.main.on("change", ".hf-sort-by select", function (e) {
        var refineUrl = $(this).find('option:selected').val();
        var uri = util.getUri(refineUrl);
        window.location.hash = uri.query.substr(1);
        window.isSortChange = true;
        return false;
    })
    .on("change", ".hf-items-per-page select", function (e) {
        var refineUrl = $(this).find('option:selected').val();
        if (refineUrl == "INFINITE_SCROLL") {
            jQuery('html').addClass('infinite-scroll');
            jQuery('html').removeClass('disable-infinite-scroll');
        } else {
            jQuery('html').addClass('disable-infinite-scroll');
            jQuery('html').removeClass('infinite-scroll');
            var uri = util.getUri(refineUrl);
            window.location.hash = uri.query.substr(1);
        }
        return false;
    });

    // handle hash change
    $(window).hashchange(function () {
        var isSortChangeVal = window.isSortChange ? window.isSortChange : false;
        updateProductListing(true, isSortChangeVal);
    });
}

module.exports = search;
