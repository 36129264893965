'use strict';

var lazyImages;
const configIntersection = {rootMargin: '100px 0px 100px 0px'};//Config for intersection Observer.  Increasing the bounds of the Intersection.
//Must disbale the linter because it does not know what an Observer is
/* eslint-disable */
const observerImageLazy = new IntersectionObserver(function(entries, self){
    entries.forEach(function(entry) {
        if (entry.isIntersecting === true) {
            // Stop watching and load the image
            self.unobserve(entry.target);
            preloadImage(entry.target);
        }
    });
}, configIntersection);

/* eslint-ensable */
function initializeObservers() {
    initializeLazyLoad();
}

//Used for any reinits of lazy load such as filters on a grid page.
function reInitializeObservers() {
    observerImageLazy.disconnect();
    initializeLazyLoad();
}

function initializeLazyLoad() {
    lazyImages = document.querySelectorAll('[data-lazy], [data-srcset]');

    [].forEach.call(lazyImages, function(image, index) {
        observerImageLazy.observe(image, index);
    });
}

function preloadImage(entry) {
    const src = entry.getAttribute('data-lazy');
    const srcset = entry.getAttribute('data-srcset');
    if (!src && !srcset) { return; }//If we have no src or srcset, we have no image, return.
    if (src) {
        entry.setAttribute('src', src);
        entry.onload = function() {
            entry.removeAttribute('data-lazy');
        };
    } else if (srcset) {
        entry.setAttribute('srcset', srcset);
        entry.onload = function() {
            entry.removeAttribute('data-srcset');
        };
    }

    //If slick slider is being used, this cleans it up
    if (entry.hasAttribute('data-lazy')) {
        entry.removeAttribute('data-lazy');
    }
}

exports.init = function () {
    initializeObservers();
}
exports.reinit = function(){
    reInitializeObservers();
}