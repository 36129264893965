/**
* Temp save address object in customer's browser
*
* @module cartridge/js/localStorageAddress
*/

'use strict';

var util = require('./util');

var localStorageAddress = {
	isEnabled: function () {
		return typeof(window.Storage) !== 'undefined';
	},
	shippingType: {
		save: function () {
			if (localStorageAddress.isEnabled()) {
				if ($('input[name$="_shipToSingleAddress"]').prop('checked')) {
					window.localStorage.setItem('addressType', 'single');
				} else if ($('input[name$="_shipToMultipleAddresses"]').prop('checked')) {
					window.localStorage.setItem('addressType', 'multi');
				}
			}
		},
		restore: function () {
			if (localStorageAddress.isEnabled()) {
				var addressType = window.localStorage.getItem('addressType');
				if (addressType) {
					if (addressType == 'single') {
						$('input[name$="_shipToSingleAddress"]').prop('checked', true).attr('checked', 'checked').trigger('change');
						$('input[name$="_shipToMultipleAddresses"]').prop('checked', false).removeAttr('checked').trigger('change');
					} else {
						$('input[name$="_shipToMultipleAddresses"]').prop('checked', true).attr('checked', 'checked').trigger('change');
						$('input[name$="_shipToSingleAddress"]').prop('checked', false).removeAttr('checked').trigger('change');
					}
					window.localStorage.removeItem('addressType');
				}
			}
		}
	},
	email: {
		save: function () {
			if (localStorageAddress.isEnabled()) {
				var email = $('input[name$="email_emailAddress"]').val();
				window.localStorage.setItem('shippingEmail', email);
				localStorageAddress.shippingType.save();
			}
		},
		restore: function () {
			if (localStorageAddress.isEnabled()) {
				var email = window.localStorage.getItem('shippingEmail');
				if (email) {
					$('input[name$="email_emailAddress"]').val(email);
					window.localStorage.removeItem('shippingEmail');
				}
			}
		}
	},
	shippingAddress: {
		save: function (isMulti) {
			if (localStorageAddress.isEnabled()) {
                var selector = '.checkout-shipping';
                if (isMulti) {
                    selector = '.ship-to-single-wrapper';
                }
				var formData = $(selector).find('input,select').not('input[name="csrf_token"]').serialize();
				window.localStorage.setItem('shippingAddress', formData);
				localStorageAddress.email.save();
			}
		},
		restore: function (isMulti) {
			if (localStorageAddress.isEnabled()) {
				var formData = window.localStorage.getItem('shippingAddress');
				if (formData !== null) {
                    var selector = '.checkout-shipping';
                    if (isMulti) {
                        selector = '.ship-to-single-wrapper';
                    }
					$(selector).deserialize(formData);
					util.AddressTypeChange();
					$(selector).deserialize(formData);
					window.localStorage.removeItem('shippingAddress');
				}
				localStorageAddress.email.restore();
			}
		}
	},
	multiShip: {
		save: function () {
			if (localStorageAddress.isEnabled()) {
				var data = [];
				localStorageAddress.email.save();
				$('.checkout-row').each(function () {
					var row = $(this);
					var selects = row.find('select.select-address');
					selects.each(function () {
                        var select = $(this);
                        var selectedOption = select.find('option:selected').data();
                        if (selectedOption && selectedOption.address && selectedOption.address.UUID) {
                            var selectedAddress = selectedOption.address.UUID;
                            var obj = {
                                liID: select.data('unique'),
                                addrID: selectedAddress
                            };
                            data.push(obj);
                        }
					});
				});
				if (data.length > 0) {
					window.localStorage.setItem('multiShipAddresses', JSON.stringify(data));
				}
				if ($('input[name$="_shipToSingleAddress"]').prop('checked')) {
                    localStorageAddress.shippingAddress.save(true);
				}
			}
		},
		restore: function () {
			if (localStorageAddress.isEnabled()) {
				var savedData = window.localStorage.getItem('multiShipAddresses');
				var needCustomInputsReinit = false;
				if (savedData) {
					var data = JSON.parse(savedData);
					for (var i = 0; i < data.length; i++) {
						var obj = data[i];
						var select = $('select[data-unique="' + obj.liID + '"]');
						if (select.length > 0) {
                            var option = select.find('option[value="' + obj.addrID + '"]');
                            if (option.length > 0) {
                                option.prop('selected', true).attr('selected', 'selected');
                                needCustomInputsReinit = true;
                            }
						}
					}
					if (needCustomInputsReinit) {
						util.initCustomDropdowns(true);
					}
					window.localStorage.removeItem('multiShipAddresses');
				}
				localStorageAddress.email.restore();
				if (window.localStorage.getItem('shippingAddress')) {
                    localStorageAddress.shippingAddress.restore(true);
				}
			}
		}
	}
};

module.exports = localStorageAddress;
