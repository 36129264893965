'use strict';
// jQuery extensions

module.exports = function () {
    // params
    // toggleClass - required
    // triggerSelector - optional. the selector for the element that triggers the event handler. defaults to the child elements of the list.
    // eventName - optional. defaults to 'click'
    $.fn.toggledList = function (options) {
        if (!options.toggleClass) { return this; }

        var list = this;
        function handleToggle(e) {
            e.preventDefault();
            var classTarget = options.triggerSelector ? $(this).parent() : $(this);
            classTarget.toggleClass(options.toggleClass);
            // execute callback if exists
            if (options.callback) { options.callback(); }
        }

        return list.on(options.eventName || "click", options.triggerSelector || list.children(), handleToggle);
    };

    $.fn.syncHeight = function () {
        function sortHeight(a, b) {
            return $(a).height() - $(b).height();
        }

        var arr = $.makeArray(this);
        arr.sort(sortHeight);
        return this.height($(arr[arr.length - 1]).height());
    };

    $.fn.clearValidation = function(){var v = $(this).validate();$('[name]',this).each(function(){v.successList.push(this);v.showErrors();});v.resetForm();v.reset();};

    //Javascript Array Class extensions for indexOf and remove
    if(!Array.prototype.indexOf) {
        Array.prototype.indexOf = function(what, i) {
            i = i || 0;
            var L = this.length;
            while (i < L) {
                if(this[i] === what) {return i;}
                ++i;
            }
            return -1;
        };
    }
    Array.prototype.remove = function() {
        var what, a = arguments, L = a.length, ax;
        while (L && this.length) {
            what = a[--L];
            while ((ax = this.indexOf(what)) !== -1) {
                this.splice(ax, 1);
            }
        }
        return this;
    };
};
