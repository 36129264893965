'use strict';

var stickynav = {
    init: function () {
        initializeDOM();
        initializeObservers();
    }
};

function initializeDOM () {
    if ( window.pageType != 'checkout') {
        //Used to track when the mobile nav needs to go sticky using the observers API
        var $navElement = $('.full-header-wrapper');
        $navElement.after('<div id="tracker"></div>');
    }
}

function initializeObservers() {
    /*eslint-disable */
    //Linter does not know observers.
    const trackerElm = document.getElementById('tracker');

    if (trackerElm === null) {
        return;
    }
    const config = {
        rootMargin: '0px 0px 100px 0px'
    };

    const observerStickyMenu = new IntersectionObserver(function(entries, self){
        entries.forEach(function(entry) {
            if (entry.isIntersecting === false) {
                document.documentElement.style.setProperty('--header-height-full', document.querySelector('.full-header-wrapper').offsetHeight + 'px');
                $('body').addClass('stickyHeader');
                $('.back-top').fadeIn();
            } else {
                $('body').removeClass('stickyHeader');
                $('.back-top').fadeOut();
            }
        });
    }, config);
    //Call observer
    observerStickyMenu.observe(trackerElm);
    /*eslint-enable */
}

module.exports = stickynav;