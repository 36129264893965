'use strict';

var util = require('./util');

var tealium = {
    itemToReplaceData : {},
    getProductAddLocation : function () {
        var result;
        switch(window.pageContext.ns) {
            case 'search': //Adding via the quick view modal on the CLP
                result = "quick view";
                break;
            case 'product':
                result = "product detail";
                break;
            case 'cart':
                if($('.ui-dialog:visible').length || $('.tealium-remove-event').length) {
                    result = "cart restriction replace";
                    break;
                }
                result = "cart";
                break;
            case 'checkout':
                //Added when there was no restriction error message
                if($('div.shipping-restrictions-msg:visible').length) {
                    result = "shipping restriction replace";
                    break;
                }
                result = "checkout";
                break;
            case 'account':
                result = "re-order";
                break;
        }
        return result;
    },
    getGridPositonPLP : function () {
        var queryStringParams = util.getQueryStringParams(window.document.location.search);
        if(queryStringParams && typeof queryStringParams.gridindex !== typeof undefined) {
            return encodeURIComponent(queryStringParams.gridindex);
        }
        return 'Error, no PLP position set';
    },
    cartAdd :  function(data, addedQuantity, gridIndexCLP) {
        var addLocation = tealium.getProductAddLocation();
        var dataObj;

        var productData;
        if(typeof(data) !== 'undefined' && data != null) {
            productData = data;
            if(typeof data.product_discount !== undefined) {
                productData.product_discount = data.product_discount;
            }
        }

        if(typeof(addedQuantity) !== 'undefined' && addedQuantity != null) {
            productData.product_quantity = [];
            for(var i = 0; i < productData.product_id.length; i++) {
                productData.product_quantity.push(addedQuantity);
            }
        }

        dataObj = {
            "tealium_event"           : "cart_add",
            "category_name"           : 'category_name' in productData && productData.category_name !== null ? productData.category_name.toString() : '',
            "customer_id"             : 'customer_id' in utag_data && utag_data.customer_id !== null ? utag_data.customer_id.toString() : '',
            "customer_email"          : 'customer_email' in utag_data && utag_data.customer_email !== null ? utag_data.customer_email.toString() : '',
            "page_name"               : 'page_name' in utag_data && utag_data.page_name !== null ? utag_data.page_name.toString() : '',
            "page_type"               : 'page_type' in utag_data && utag_data.page_type !== null ? utag_data.page_type.toString() : '',
            "page_url"                : 'page_url' in utag_data && utag_data.page_url !== null ? utag_data.page_url : '',
            "product_id"              : 'product_id' in productData && productData.product_id !== null ? productData.product_id : '',
            "product_name"            : 'product_name' in productData && productData.product_name !== null ? productData.product_name : '',
            "product_quantity"        : 'product_quantity' in productData && productData.product_quantity !== null ? productData.product_quantity : '',
            "product_sku"             : 'product_sku' in productData && productData.product_sku !== null ? productData.product_sku : '',
            "product_unit_price"      : 'product_unit_price' in productData && productData.product_unit_price !== null ? productData.product_unit_price : '',
            "product_original_price"  : 'product_original_price' in productData && productData.product_original_price !== null ? productData.product_original_price : '',
            "product_category"        : 'product_category' in productData && productData.product_category !== null ? productData.product_category : '',
            "product_add_location"    : addLocation,
            "product_type"            : productData && productData.product_type !== null ? productData.product_type : 'product',
            "product_is_default"      : productData && productData.product_is_default ? '1' : '0',
            "product_impression_id"      : productData && productData.product_impression_id ? productData.product_impression_id.toString() : '',
            "product_impression_name"      : productData && productData.product_impression_name ? productData.product_impression_name.toString() : '',
            "product_impression_unitprice"      : productData && productData.product_impression_unitprice ? productData.product_impression_unitprice.toString() : ''
        };

        if('product_discount' in productData && productData.product_discount !== null && productData.product_discount !== undefined) {
            dataObj.product_discount = productData.product_discount;
        }
        var plpPosition = (gridIndexCLP !== null) ? gridIndexCLP : this.getGridPositonPLP();
        if(plpPosition !== null) {
            dataObj.plp_position  = plpPosition;
        }

        utag.link(tealium.removeEmptyValues(dataObj));
    },
    cartRemove:  function(data, removedQuantity) {
        var addLocation = tealium.getProductAddLocation();
        var productData;
        if(typeof(data) !== 'undefined' && data != null) {
            productData = data;
        }

        if(typeof(removedQuantity) !== 'undefined' && removedQuantity != null) {
            productData.product_quantity = [];
            for(var i = 0; i < productData.product_id.length; i++) {
                productData.product_quantity.push(removedQuantity);
            }
        }
        var dataObj = {
            "tealium_event"              : "cart_remove",
            "customer_id"             : utag_data.customer_id,
            "customer_email"          : utag_data.customer_email,
            "page_name"               : utag_data.page_name,
            "page_type"               : utag_data.page_type,
            "page_url"                : utag_data.page_url,
            "product_id"              : productData.product_id,
            "product_name"            : productData.product_name,
            "product_quantity"        : productData.product_quantity,
            "product_sku"             : productData.product_sku,
            "product_unit_price"      : productData.product_unit_price,
            "product_original_price"  : productData.product_original_price,
            "product_category"        : 'product_category' in productData && productData.product_category !== null ? productData.product_category : '',
            "product_remove_location" : addLocation,
            "product_type"            : productData && productData.product_type !== null ? productData.product_type : 'product',
            "product_is_default"      : productData && productData.product_is_default ? '1' : '0',
            "product_impression_id"      : productData && productData.product_impression_id ? productData.product_impression_id.toString() : '',
            "product_impression_name"      : productData && productData.product_impression_name ? productData.product_impression_name.toString() : '',
            "product_impression_unitprice"      : productData && productData.product_impression_unitprice ? productData.product_impression_unitprice.toString() : ''
        };

        if('product_discount' in productData && productData.product_discount !== null && productData.product_discount !== undefined) {
            dataObj.product_discount = productData.product_discount;
        }
        utag.link(tealium.removeEmptyValues(dataObj));

        if($('.cart-row').length === 1 && productData.product_quantity >= $('.tealium-initial-quantity').val()) {
            tealium.cartEmpty();
        }
    },
    cartEmpty:  function() {
        var dataObj = {
            "tealium_event"              : "cart_empty",
            "customer_id"             : utag_data.customer_id,
            "customer_email"          : utag_data.customer_email,
            "page_name"               : utag_data.page_name,
            "page_type"               : utag_data.page_type,
            "page_url"                : utag_data.page_url
        };
        utag.link(tealium.removeEmptyValues(dataObj));
    },
    startCheckout:  function() {
        var dataObj = {
            "tealium_event"              : "start_checkout",
            "customer_id"             : utag_data.customer_id,
            "customer_email"          : utag_data.customer_email,
            "page_url"                : utag_data.page_url,
            "cart_merchandise_total"  : utag_data.cart_merchandise_total,
            "cart_product_id"         : utag_data.cart_product_id,
            "cart_product_name"       : utag_data.cart_product_name,
            "cart_product_price"      : utag_data.cart_product_price,
            "cart_product_quantity"   : utag_data.cart_product_quantity,
            "cart_total_items"        : utag_data.cart_total_items
        };
        utag.link(tealium.removeEmptyValues(dataObj));
    },
    userLogin:  function() {
        var dataObj = {
            "tealium_event"              : "user_login",
            "customer_id"             : utag_data.customer_id,
            "customer_email"          : utag_data.customer_email,
            "page_name"               : utag_data.page_name,
            "page_type"               : utag_data.page_type,
            "page_url"                : utag_data.page_url
        };
        utag.link(tealium.removeEmptyValues(dataObj));
    },
    userLogout:  function() {
        var dataObj = {
            "tealium_event"              : "user_logout",
            "customer_id"             : utag_data.customer_id,
            "customer_email"          : utag_data.customer_email,
            "page_name"               : utag_data.page_name,
            "page_type"               : utag_data.page_type,
            "page_url"                : utag_data.page_url
        };
        utag.link(tealium.removeEmptyValues(dataObj));
    },
    userRegister:  function() {
        var dataObj = {
            "tealium_event"              : "user_register",
            "customer_id"             : utag_data.customer_id,
            "customer_email"          : utag_data.customer_email,
            "page_name"               : utag_data.page_name,
            "page_type"               : utag_data.page_type,
            "page_url"                : utag_data.page_url
        };
        utag.link(tealium.removeEmptyValues(dataObj));
    },
    emailSignup:  function(givenEmail) {
        var customerEmail = typeof givenEmail !== undefined ? givenEmail : utag_data.customer_email;
        var dataObj = {
            "tealium_event"              : "email_signup",
            "customer_id"             : utag_data.customer_id,
            "customer_email"          : customerEmail,
            "page_name"               : utag_data.page_name,
            "page_type"               : utag_data.page_type,
            "page_url"                : utag_data.page_url
        };
        utag.link(tealium.removeEmptyValues(dataObj));
    },
    productView:  function(gridIndexCLP) {
        var viewType = (window.pageContext.ns === "product") ? 'product detail' : 'quick view';
        //Index of product within the CLP grid
        var plpPosition = (gridIndexCLP !== null) ? gridIndexCLP : this.getGridPositonPLP();

        var $addToCart = $('#add-to-cart');
        var productData = $addToCart.data('tealium');

        if($('.hf-optionsText').length) { //if this product has the ability to have a ProductOption
            productData = {
                category_name : productData.category_name,
                product_id : [productData.product_id[0], "None"],
                product_name : [productData.product_name[0], "None"],
                product_original_price : [productData.product_original_price[0], "0.00"],
                product_unit_price : [productData.product_unit_price[0], "0.00"],
                product_discount : [productData.product_discount[0], "0.00"],
                product_sku : [productData.product_sku[0], "None"],
                product_category : [productData.product_category[0], "None"],
                product_type : [productData.product_type[0], "None"],
                product_is_default : [productData.product_is_default[0], "0"],
                product_impression_id : [productData.product_impression_id[0], "0"],
                product_impression_name : [productData.product_impression_name[0], "0"],
                product_impression_unitprice : [productData.product_impression_unitprice[0], "0"]
            };
        }

        var dataObj = {
            "tealium_event"              : "product_view",
            "customer_id"             : utag_data.customer_id,
            "customer_email"          : utag_data.customer_email,
            "page_name"               : utag_data.page_name,
            "page_type"               : utag_data.page_type,
            "page_url"                : utag_data.page_url,
            "category_name"           : utag_data.category_name,
            "plp_position"            : plpPosition,
            "product_id"              : productData.product_id,
            "product_name"            : productData.product_name,
            "product_original_price"  : productData.product_original_price,
            "product_sku"             : productData.product_sku,
            "product_unit_price"      : productData.product_unit_price,
            "product_category"        : productData.product_category,
            "product_discount"        : productData.product_discount,
            "product_view_type"       : viewType,
            "product_type"            : productData.product_type,
            "product_is_default"      : productData.product_is_default,
            "product_impression_id"      : productData && productData.product_impression_id ? productData.product_impression_id.toString() : '',
            "product_impression_name"      : productData && productData.product_impression_name ? productData.product_impression_name.toString() : '',
            "product_impression_unitprice"      : productData && productData.product_impression_unitprice ? productData.product_impression_unitprice.toString() : ''
        };
        utag.link(tealium.removeEmptyValues(dataObj));
    },
    requestCatalog:  function() {
        var customerData = $('.tealium-customer-data').data('tealium');
        var dataObj = {
            "tealium_event"              : "request_catalog",
            "customer_id"             : utag_data.customer_id,
            "customer_email"          : customerData.customer_email,
            "customer_country"        : customerData.customer_country,
            "customer_postal_code"    : customerData.customer_postal_code,
            "customer_state"          : customerData.customer_state,
            "page_name"               : utag_data.page_name,
            "page_type"               : utag_data.page_type,
            "page_url"                : utag_data.page_url
        };
        utag.link(tealium.removeEmptyValues(dataObj));
    },
    orderError:  function() {
        var dataObj = {
            "tealium_event"            : "order_error",
            "order_error_message"   : utag_data.order_error_message,
            "order_payment_types"   : utag_data.order_payment_types,
            "order_id"              : utag_data.order_id,
            "order_subtotal"        : utag_data.order_subtotal,
            "order_tax"             : utag_data.order_tax,
            "order_total"           : utag_data.order_total,
            "order_type"            : utag_data.order_type,
            "customer_id"           : utag_data.customer_id,
            "customer_email"        : utag_data.customer_email,
            "customer_country"      : utag_data.customer_country,
            "customer_postal_code"  : utag_data.customer_postal_code,
            "customer_state"        : utag_data.customer_state,
            "page_name"             : utag_data.page_name,
            "page_type"             : utag_data.page_type,
            "page_url"              : utag_data.page_url
        };
        utag.link(tealium.removeEmptyValues(dataObj));
    },
    applicationError:  function() {
        var dataObj = {
            "tealium_event"              : "application_error",
            "order_subtotal"          : utag_data.order_subtotal,
            "order_tax"               : utag_data.order_tax,
            "order_total"             : utag_data.order_total,
            "order_type"              : utag_data.order_type,
            "customer_id"             : utag_data.customer_id,
            "customer_email"          : utag_data.customer_email,
            "page_name"               : utag_data.page_name,
            "page_type"               : utag_data.page_type,
            "page_url"                : utag_data.page_url
        };
        utag.link(tealium.removeEmptyValues(dataObj));
    },
    purchaseComplete:  function() {
        var dataObj = {
            "tealium_event"                    : "purchase_complete",
            "coupon_code"                   : utag_data.coupon_code,
            "customer_country"              : utag_data.customer_country,
            "customer_email"                : utag_data.customer_email,
            "customer_state"                : utag_data.customer_state,
            "src_code"                      : utag_data.src_code,
            "customer_id"                   : utag_data.customer_id,
            "dw_campaign"                   : utag_data.dw_campaign,
            "dw_customer_groups"            : utag_data.dw_customer_groups,
            "dw_src"                        : utag_data.dw_src,
            "dw_src_group"                  : utag_data.dw_src_group,
            "dw_test_id"                    : utag_data.dw_test_id,
            "dw_test_segment"               : utag_data.dw_test_segment,
            "giftsnap_redemption"           : utag_data.giftsnap_redemption,
            "order_subtotal"                : utag_data.order_subtotal,
            "order_tax"                     : utag_data.order_tax,
            "order_total"                   : utag_data.order_total,
            "page_name"                     : utag_data.page_name,
            "page_type"                     : utag_data.page_type,
            "page_url"                      : utag_data.page_url,
            "product_discount"              : utag_data.product_discount,
            "product_id"                    : utag_data.product_id,
            "product_name"                  : utag_data.product_name,
            "product_quantity"              : utag_data.product_quantity,
            "product_original_price"        : utag_data.product_original_price,
            "product_unit_price"            : utag_data.product_unit_price,
            "product_net_total"             : utag_data.product_net_total,
            "user_anonymous"                : utag_data.user_anonymous,
            "user_authenticated"            : utag_data.user_authenticated,
            "user_geo_country"              : utag_data.user_geo_country,
            "user_geo_state"                : utag_data.user_geo_state,
            "user_registered"               : utag_data.user_registered,
            "user_emidac"                   : utag_data.user_emidac,
            "user_lastPrc"                  : utag_data.user_lastPrc,
            "user_firstPrc"                 : utag_data.user_firstPrc,
            "user_totalPrc"                 : utag_data.user_totalPrc,
            "user_totalVal"                 : utag_data.user_totalVal,
            "order_currency"                : utag_data.order_currency,
            "order_discounts_total"         : utag_data.order_discounts_total,
            "order_id"                      : utag_data.order_id,
            "order_merchandise_discount"    : utag_data.order_merchandise_discount,
            "order_merchandise_subtotal"    : utag_data.order_merchandise_subtotal,
            "order_merchandise_net"         : utag_data.order_merchandise_net,
            "order_merchandise_tax"         : utag_data.order_merchandise_tax,
            "order_merchandise_total"       : utag_data.order_merchandise_total,
            "order_payment_types"           : utag_data.order_payment_types,
            "shipment_count"                : utag_data.shipment_count,
            "shipment_discount"             : utag_data.shipment_discount,
            "shipment_ids"                  : utag_data.shipment_ids,
            "shipment_method_display"       : utag_data.shipment_method_display,
            "shipment_methods"              : utag_data.shipment_methods,
            "shipment_subtotal"             : utag_data.shipment_subtotal,
            "shipment_net"                  : utag_data.shipment_net,
            "shipment_tax"                  : utag_data.shipment_tax,
            "shipment_total"                : utag_data.shipment_total,
            "order_shipping_subtotal"       : utag_data.order_shipping_subtotal,
            "order_shipping_discount"       : utag_data.order_shipping_discount,
            "order_shipping_tax"            : utag_data.order_shipping_tax,
            "order_shipping_total"          : utag_data.order_shipping_total,
            "order_level_discount"          : utag_data.order_level_discount,
            "order_type"                    : utag_data.order_type,
            "fod_date_value"                : utag_data.fod_date_value,
            "shipment_countries"            : utag_data.shipment_countries,
            "shipment_gift_message"         : utag_data.shipment_gift_message,
            "shipment_postal_codes"         : utag_data.shipment_postal_codes,
            "shipment_states"               : utag_data.shipment_states
        };
        utag.link(tealium.removeEmptyValues(dataObj));
    },
    restrictionError:  function(productData, addressData) {
        var dataObj = {
            "tealium_event"              : "restriction_error",
            "order_subtotal"          : utag_data.order_subtotal,
            "order_tax"               : utag_data.order_tax,
            "order_total"             : utag_data.order_total,
            "order_type"              : utag_data.order_type,
            "customer_id"             : utag_data.customer_id,
            "customer_email"          : utag_data.customer_email,
            "product_id"              : productData.product_id,
            "product_name"            : productData.product_name,
            "page_name"               : utag_data.page_name,
            "page_type"               : utag_data.page_type,
            "page_url"                : utag_data.page_url,
            "shipment_countries"      : addressData.shipment_countries,
            "shipment_gift_message"   : utag_data.shipment_gift_message,
            "shipment_postal_codes"   : addressData.shipment_postal_codes,
            "shipment_states"         : addressData.shipment_states
        };
        utag.link(tealium.removeEmptyValues(dataObj));
    },
    cartUpsellView:  function(productData, quantity) {
        var dataObj = {
            "tealium_event"              : "incart_upsell_view",
            "cart_merchandise_total"  : utag_data.cart_merchandise_total,
            "cart_product_id"         : utag_data.cart_product_id,
            "cart_product_name"       : utag_data.cart_product_name,
            "cart_product_price"      : utag_data.cart_product_price,
            "cart_product_quantity"   : utag_data.cart_product_quantity,
            "cart_total_items"        : utag_data.cart_total_items,
            "customer_id"             : utag_data.customer_id,
            "customer_email"          : utag_data.customer_email,
            "page_name"               : utag_data.page_name,
            "page_type"               : utag_data.page_type,
            "page_url"                : utag_data.page_url,
            "product_discount"        : productData.product_discount,
            "product_id"              : productData.product_id,
            "product_name"            : productData.product_name,
            "product_quantity"        : quantity,
            "product_original_price"  : productData.product_original_price,
            "product_unit_price"      : productData.product_unit_price
        };
        utag.link(tealium.removeEmptyValues(dataObj));
    },
    updateCart: function($cartRow) {
        var $tealiumObj = $cartRow.find('.tealium-initial-quantity');
        var initalQuantity = $tealiumObj.val();
        var updatedQuantity = $cartRow.find('.input-text.positivenumber').val();
        var difference = updatedQuantity - initalQuantity;
        var productData = $tealiumObj.data('tealium');
        if(difference > 0) {
            tealium.cartAdd(productData, difference + ".00");
        }else if(difference < 0) {
            tealium.cartRemove(productData, (difference * -1) + ".00");
        }
    },
    addToCartGiftSnap: function (data) {
        tealium.cartAdd(data, 1);
    },
    /* Check each hidden input in the DOM and run the corresponding event.*/
    init: function() {
        var emailCount = 0; //HF-3251 ensure that email signup is only tracked once
        $('.tealium-event-name').each(function() {
            switch($(this).val()) {
                case 'add-to-cart':
                    break;
                case 'remove-from-cart':
                    break;
                case 'catalog-success':
                    tealium.requestCatalog();
                    break;
                case 'general-error':
                    tealium.applicationError();
                    break;
                case 'purchase-complete':
                    tealium.purchaseComplete();
                    break;
                case 'email-signup':
                    //HF-3251 ensure that email signup is only tracked once
                    if (emailCount == 0) {
                        tealium.emailSignup($(this).data('email'));
                    }
                    emailCount++;
                    break;
                case 'register-success':
                    tealium.userRegister();
                    break;
                default:
                    console.log('Unkown tealium event detected.');
                    break;
            }
        });
        if(typeof utag_data.order_error_message !== 'undefined' && utag_data.order_error_message !== null) {
            tealium.orderError();
        }
    },
    removeEmptyValues: function(object) {
        //Iterator over the data object's properties and remove undefined or empty values
        for (var key in object) {
            if (object.hasOwnProperty(key)) {
                if(object[key] == undefined || object[key] === null || object[key].length === 0) {
                    delete object[key];
                }
            }
        }

        return object;
    }
};

module.exports = tealium;