'use strict';

var globalevents = require('./globalevents'),
    util = require('./util'),
    minicart = require('./minicart'),
    stickynav = require('./stickynav'),
    stickyfooter = require('./stickyfooter'),
    subscribeemail = require('./subscribeemail'),
    tealium = require('./tealium'),
    page = require('./page'),
    tooltip = require('./tooltip'),
    lazyload = require('./lazyload'),
    gdpr = require('./gdpr'),
    b2b = require('./b2b'),
    tls = require('./tls'),
    validator = require('./validator');

// if jQuery has not been loaded, load from google cdn
if (!window.jQuery) {
    var s = document.createElement('script');
    s.setAttribute('src', 'https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js');
    s.setAttribute('type', 'text/javascript');
    document.getElementsByTagName('head')[0].appendChild(s);
}

require('./jquery-ext')();

/**
 * @private
 * @function
 * @description Adds class ('js') to html for css targeting and loads js specific styles.
 */
function initializeDom() {
    // add class to html for css targeting
    $('html').addClass('js');
    if (window.Clientcache.LISTING_INFINITE_SCROLL) {
        $('html').addClass('infinite-scroll');
    }
    // load js specific styles
    util.limitCharacters();

    gdpr.gdprDialog();

    $(document).on('keydown', function (e) {
        var $headerSearch = $('.header-search-expand:visible');
        var $headerSearchIcon = $('.header-search-toggle');
        if (e.keyCode === 27 && $headerSearch.length) { // ESC
            $headerSearch.find('.header-search-close').trigger('click');
            $headerSearchIcon.focus();
        }
    });
}

/**
 * @private
 * @function
 * @description Cache dom elements which are being accessed multiple times.<br/>app.ui holds globally available elements.
 */
function initUiCache() {
    window.app.ui = {
        searchContainer : $("#navigation .header-search"),
        printPage       : $("a.print-page"),
        reviewsContainer: $("#pwrwritediv"),
        main            : $("#hf-main"),
        primary         : $("#hf-primary"),
        secondary       : $("#hf-secondary"),
        // elements found in content slots
        slots : {
            subscribeEmail : $(".subscribe-email")
        }
    };
}
/**
 * @private
 * @function
 * @description thi function will initilize all the slick carousel available on the page having class "slick-carousel"
 */
function initSlickCarousel() {
    var slickSelector = $('.slick-carousel-ul');
    if (slickSelector.length > 0 ) {
        slickSelector.each(function() {
            $(this).slick({
                slidesToShow: 4,
                slidesToScroll: 4,
                arrows: false,
                dots: true,
                responsive: [{
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        infinite: true,
                        dots: true
                    }
                }]
            });
        });
    }
}
/**
 * @private
 * @function
 * @description this function will init main hero carouse on cat-info-showcase
 */
function initHeroCatLanding () {
    var $mainHeroCarousel = $('.cat-info-showcase .home-slider-slides');
    if ($mainHeroCarousel.length > 0 && !$mainHeroCarousel.hasClass('slick-initialized')) {
        $mainHeroCarousel.slick({
            lazyLoad: 'ondemand',
            arrows: false,
            dots: true,
            slide: ".home-slider-slides div",
            mobileFirst: true
        });
    }
}

var pages = {
    account: require('./pages/account'),
    cart: require('./pages/cart'),
    checkout: require('./pages/checkout'),
    compare: require('./pages/compare'),
    giftsnap: require('./pages/giftsnap'),
    product: require('./pages/product'),
    registry: require('./pages/registry'),
    requestnewsletter: require('./pages/requestnewsletter'),
    search: require('./pages/search'),
    storefront: require('./pages/storefront'),
    wishlist: require('./pages/wishlist'),
    storelocator: require('./pages/storelocator'),
    orderconfirmation: require('./pages/orderconfirmation')
};

window.GRI = require('./gri');

var app = {
    init: function () {
        if (document.cookie.length === 0) {
            $('<div/>').addClass('browser-compatibility-alert').append($('<p/>').addClass('browser-error').html(window.Resources.COOKIES_DISABLED)).appendTo('#browser-check');
        }

        // init global cache
        initUiCache();

        // init global dom elements
        initializeDom();

        // init global events
        globalevents.init();
        b2b.init();

        // init specific global components
        stickynav.init();
        stickyfooter.init();
        tooltip.init();
        minicart.init();
        validator.init();
        subscribeemail.init();
        lazyload.init();
        initSlickCarousel();
        initHeroCatLanding();

        // execute page specific initializations
        $.extend(page, window.pageContext);
        var ns = page.ns;
        if (ns && pages[ns] && pages[ns].init) {
            pages[ns].init();
        }

        // Check TLS status if indicated by site preference
        if (window.Preferences.CHECK_TLS === true) {
            tls.getUserAgent();
        }
    }
};

//general extension functions
(function () {
    String.format = function () {
        var s = arguments[0];
        var i, len = arguments.length - 1;
        for (i = 0; i < len; i++) {
            var reg = new RegExp('\\{' + i + '\\}', 'gm');
            s = s.replace(reg, arguments[i + 1]);
        }
        return s;
    };
})();

// initialize app
$(document).ready(function () {
    app.init();
    $(window).on('load', function() {
        tealium.init();
    });
});
