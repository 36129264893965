'use strict';

var util = require('./util');

var page = {
    title : "",
    type : "",
    setContext : function (o) {
        $.extend(page, o);
    },
    params : util.getQueryStringParams(window.location.search.substr(1)),
    redirect : function(newURL) {
        var t = window.setTimeout("window.location.href='" + newURL + "'",0);
    },
    refresh : function() {
        var t = window.setTimeout("window.location.assign(window.location.href);",500);

    }
};

module.exports = page;
