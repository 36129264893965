'use strict';

var validator = require('./validator');
var util = require('./util');
var dialog = require('./dialog');

function initGetInTouchPage() {
    if ($('.get-in-touch-page-content').length > 0) {
        $('.get-in-touch-submit').attr('type', 'submit');
        var $modalForm = $('.get-in-touch-modal-wrapper');

        $modalForm.on('submit', function(e) {
            e.preventDefault();
            if ($modalForm.valid()) {
                var lookingFor = $modalForm.find("[name$='lookingfor'] :selected").text();
                var prefContact = $modalForm.find("[name$='prefcontact'] :selected").text();
                var email = $modalForm.find("[name$='getintouch_email']").val();
                var companyName = $modalForm.find("[name$='getintouch_company']").val();
                var firstName = $modalForm.find("[name$='getintouch_firstname']").val();
                var lastName = $modalForm.find("[name$='getintouch_lastname']").val();
                var phone = $modalForm.find("[name$='getintouch_phone']").val();
                var comments = $modalForm.find("[name$='getintouch_comments']").val();

                $.ajax({
                    url: window.Urls.getInTouchSubmit,
                    dataType: 'json',
                    type: 'POST',
                    data: {
                        lookingfor: lookingFor,
                        prefcontact: prefContact,
                        email: email,
                        companyname: companyName,
                        firstname: firstName,
                        lastname: lastName,
                        phone: phone,
                        comments: comments,
                    },
                    success: function (response) {
                        if (typeof (response) === 'undefined') {
                            return;
                        }

                        $('.get-in-touch-page-content').addClass('display-none');
                        $('#get-in-touch-page-success').removeClass('display-none');
                        $(window).scrollTop(0);
                    }
                });
            }
        });
    }
}

var b2b = {
    init: function () {
        var isB2B = false;
        catlandingBestsellers();
        b2bCarousel();
        b2bBusinessGifts();
        initB2BPageGetInTouch();
        initGetInTouchPage();

        $(window).resize(function() {
            b2bCarousel();
            if(isB2B) {
                fluidDialog();
            }
        });

        function initB2BPageGetInTouch() {
            //If we're on the B2B corporate gift page
            if ($('.business-occasion-content').length !== 0) {
                // catch dialog if opened within a viewport smaller than the dialog width
                $(document).on("dialogopen", ".ui-dialog", function (event, ui) {
                    fluidDialog();
                });

                isB2B = true;
            }
            if ($('.get-in-touch-content').length > 0) {
                //The get-in-touch content slot occurs twice, so events will need to be attached to duplicate elements as well.
                $('.get-in-touch-content .contact-us-link, .get-in-touch-content .contact-us-image').each(function() {
                    $(this).on('click', function() {
                        showGetInTouchModal();
                    });
                });
            }
        }

        function fluidDialog() {
            var $visible = $(".ui-dialog:visible");
            // each open dialog
            $visible.each(function () {
                var $this = $(this);
                var dialogData = $this.find(".ui-dialog-content").data("dialog");

                // if fluid option == true
                if (dialogData.options.fluid) {
                    var wWidth = $(window).width();
                    // check window width against dialog width
                    if (wWidth < (parseInt(dialogData.options.maxWidth) + 50))  {
                        // keep dialog from filling entire screen
                        $this.css("max-width", "90%");
                    } else {
                        // fix maxWidth bug
                        $this.css("max-width", dialogData.options.maxWidth + "px");
                    }
                    //reposition dialog
                    dialogData.option("position", dialogData.options.position);
                }
            });

        }
        // for category landing page.
        function catlandingChar() {
            $(".product-carousel #hf-clbestcarousel").slick({
                slidesToShow: 2,
                lazyLoad: 'ondemand',
                responsive: [{
                    breakpoint: 959,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 735,
                    settings: {
                        slidesToShow: 1,
                        dots: true,
                        arrows: true,
                        appendDots: $('.product-carousel .slider-nav'),
                        appendArrows: $('.product-carousel .slider-nav')
                    }
                }]
            });
        }
        function catlandingBestsellers() {
            $(".cat-landing-bestseller #hf-bestsellerscarousel").slick({
                slidesToShow: 2,
                lazyLoad: 'ondemand',
                responsive: [{
                    breakpoint: 959,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 735,
                    settings: {
                        slidesToShow: 1,
                        dots: true,
                        arrows: true,
                        slide: '.hf-grid-tile',
                        appendDots: $('.cat-landing-bestseller .slider-nav'),
                        appendArrows: $('.cat-landing-bestseller .slider-nav')
                    }
                }]
            });
        }

        /* Show the getInTouch modal on the b2b page  */
        function showGetInTouchModal() {

            showModal();

            function showModal() {
                var getInTouchURL = window.Urls.getInTouchURL;
                $(document).ready(function() {
                    dialog.open({
                        url: getInTouchURL,
                        options : {
                            dialogClass : 'get-in-touch-dialog',
                            width: 400,
                            maxWidth: 400,
                            modal: true,
                            openInCallback: true,
                            fluid: true,
                            draggable: false,
                            resizable: false,
                            close: function () {
                                $(this).remove();
                            }
                        },
                        onLoadCallback : function () {
                            util.limitCharacters();
                            $('.ui-widget-overlay').on('click', function () {
                                $(this).siblings('.ui-dialog').find('.ui-dialog-content').dialog('close');
                            });
                            initSubmitEvent();
                            validator.init();
                        }
                    });
                    $(".get-in-touch-dialog .ui-dialog-title").html('');
                });


                function initSubmitEvent() {
                    var $submitButton = $('.get-in-touch-submit');
                    $submitButton.on('click', function(e) {
                        e.preventDefault();
                        var $modalWrapper = $('.get-in-touch-modal-wrapper');

                        if($modalWrapper.valid()) {
                            var $modal = $('.get-in-touch-modal-wrapper');
                            var lookingFor = $modal.find("[name$='lookingfor'] :selected").text();
                            var prefContact = $modal.find("[name$='prefcontact'] :selected").text();
                            var email = $modal.find("[name$='getintouch_email']").val();
                            var companyName = $modal.find("[name$='getintouch_company']").val();
                            var firstName = $modal.find("[name$='getintouch_firstname']").val();
                            var lastName = $modal.find("[name$='getintouch_lastname']").val();
                            var phone = $modal.find("[name$='getintouch_phone']").val();
                            var comments = $modal.find("[name$='getintouch_comments']").val();

                            $.ajax({
                                url: window.Urls.getInTouchSubmit,
                                dataType: 'json',
                                type: 'POST',
                                data: {
                                    lookingfor: lookingFor,
                                    prefcontact: prefContact,
                                    email: email,
                                    companyname: companyName,
                                    firstname: firstName,
                                    lastname: lastName,
                                    phone: phone,
                                    comments: comments,
                                },
                                success: function(response) {
                                    var status;
                                    if(typeof(response) === 'undefined') {
                                        status = 'failed';
                                    }else{
                                        status = response['success'];
                                    }

                                    if (status == 'failed') {
                                        return;
                                    }
                                    //Display content asset (ID=b2b-formsuccess)
                                    $modalWrapper.html($('.get-in-touch-success').html());

                                    //Recenter (vertically)
                                    window.scrollTo(0, $modalWrapper.offset().top - $modalWrapper.height());
                                }
                            });
                        }
                    });
                }

            }
        }

        function catlandingSliderQuote () {
            $(".cat-landing-slider-quote #hf-clquatecarousel").slick({
                lazyLoad: 'ondemand',
                slidesToShow: 1,
                dots: true,
                arrows: true,
                slide: '.slide',
                appendDots: $('.cat-landing-slider-quote #hf-clquatecarousel').next('.slider-nav'),
                appendArrows: $('.cat-landing-slider-quote #hf-clquatecarousel').next('.slider-nav')
            });
        }
        function b2bCarousel() {
            var windowSize = $(window).innerWidth();
            if (windowSize < 737) {

                if(! $('.b2b-occasion-section').closest('.b2b-slider-occasion').length ) {
                    $(".b2b-occasion-section").prependTo('.b2b-slider-occasion');
                }
                if($('.b2b-slider-occasion').hasClass('slick-initialized')) {
                    $('.b2b-slider-occasion').slick('unslick');
                }
                $('.b2b-slider-occasion').slick({
                    lazyLoad: 'ondemand',
                    slidesToShow: 1,
                    dots: true,
                    arrows: true,
                    slide: '.b2b-occasion-section',
                    appendDots: $('.business-occasion-content .slider-nav2'),
                    appendArrows: $('.business-occasion-content .slider-nav2')
                });
            }else{
                if($('.b2b-slider-occasion').hasClass('slick-initialized')) {
                    $('.b2b-slider-occasion').slick('unslick');
                    $('.b2b-occasion-section').eq(0).prependTo('.b2b-occasion-left');
                    $('.left.b2b-occasion-section').eq(0).appendTo('.b2b-occasion-left-bottom');
                    $('.right.b2b-occasion-section').eq(0).appendTo('.b2b-occasion-left-bottom');
                    $('.left.b2b-occasion-section').eq(1).appendTo('.b2b-occasion-right-top');
                    $('.right.b2b-occasion-section').eq(1).appendTo('.b2b-occasion-right-top');
                    $('.b2b-occasion-section').eq(5).appendTo('.b2b-occasion-right');
                }
            }

        }
        function b2bBusinessGifts() {
            $("#hf-business-gifts").slick({
                lazyLoad: 'ondemand',
                slidesToShow: 3,
                dots: true,
                arrows: true,
                slide: '.hf-grid-tile',
                appendDots: $('.business-gifts .slider-nav1'),
                appendArrows: $('.business-gifts .slider-nav1'),
                responsive: [{
                    breakpoint: 959,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 736,
                    settings: {
                        slidesToShow: 1
                    }
                }]
            });
        }
        var $checkbestsellers = $('#hf-bestsellerscarousel');
        if ($checkbestsellers.length && $(window).width() < 735) {
            catlandingBestsellers();
        }
        var $checkchar = $('#hf-clbestcarousel');
        if ($checkchar.length) {
            catlandingChar();
        }
        var $checkslidequate = $('#hf-clquatecarousel');
        if ($checkslidequate.length) {
            catlandingSliderQuote();
        }
    }
};

module.exports = b2b;
