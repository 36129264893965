'use strict';

var util = require('./util');
var ajax = require('./ajax');
var dialog = require('./dialog');
var emailsignup = require('./emailsignup');

var GDPR = {
    gdpr : function (consent, fromDialog) {
        const agree_consent = consent;
        const url = util.appendParamToURL(window.Urls.gdprAccept, "trackingFlag", agree_consent);

        document.cookie = "gdpr_consent_cookie=" + agree_consent + "; path=/;SameSite=Lax";
        document.cookie = "gdpr_consent_timestamp=" + new Date() + "; path=/;SameSite=Lax";

        if (agree_consent === "agree") {
            //Make the call to the controller to set consent in a cookie and session, if the user is logged in set it on the profile in the controller.
            ajax.load({
                url:url,
                callback: function() {
                    //We are coming in from the blank page (User previouly declined)
                    if($('#BlankPage').val()) {
                        window.location.href = window.Urls.homeShow;
                    }else{
                        if(fromDialog) {
                            dialog.close();
                            //Call for determain if we need to show the email sign up.
                            emailsignup.init();
                        }
                    }
                }
            });
        } else {
            //If the user does not agree
            ajax.load({
                url:url,
                callback: function() {
                    window.location.href = window.Urls.gdprBlankPage;
                }
            });
        }
    },
    gdprDialog : function () {
        var showDialog = false;
        //If the cookie is not set to agree, show the dialog.
        if(util.getCookie('gdpr_consent_cookie') != "agree") {
            showDialog = true;
        }

        //If you are logged in and have agreed, do not show the dialog.
        if(window.User.GDPRConsent == "agree") {
            showDialog = false;
        }
        //If the user has not agreed to this before, figure out if we need to show based on country.
        if(showDialog) {
            /*
             * To test without using a proxy add  + ', usa|us' to the end of GDPR_country_list.  This will add US into the country list for local testing.
             * const GDPR_country_list = window.Preferences.COOKIE_COUNTRY_LIST.toLowerCase() + ', usa|us';
             */
            const GDPR_country_list = window.Preferences.COOKIE_COUNTRY_LIST.toLowerCase();
            const country_code = (window.SessionAttributes && window.SessionAttributes.COUNTRY_CODE ? window.SessionAttributes.COUNTRY_CODE.toLowerCase() : null);//Set in controller Resources-LoadTransient
            const search_value = "|" + country_code;
            const is_GDPR_country = GDPR_country_list.indexOf(search_value);
            const GDPR_template_url = window.Urls.gdprTemplate;

            //If this is a country that needs consent load the dialog.
            if(is_GDPR_country != -1) {
                //GDPR dialog load
                dialog.open({
                    url:GDPR_template_url,
                    options : {
                        dialogClass : 'gdpr_consent',
                        width: 675,
                        draggable: false,
                        resizable: false,
                        openInCallback: true,
                        open: function(event, ui) {
                            GDPR_events(event, ui);
                        },
                        closeOnEscape: false,
                    }
                });
            }else{
                //Call for determain if we need to show the email sign up.
                emailsignup.init();
            }
        }else{
            //Call for determain if we need to show the email sign up.
            emailsignup.init();
        }

        /*
         * Function to call GDPR ajax agree/disagree call
         * */
        function GDPR_events(event, ui) {
            $(".gdpr_consent .ui-dialog-title").html('');
            $(".ui-dialog-titlebar-close", ui.dialog | ui).hide();
            $('#consentWrapper').find('.consent_cta').on('click', function(e) {
                e.preventDefault();

                var $this = $(this);
                const agree_consent = $this.attr('data-consent');

                GDPR.gdpr(agree_consent, true);

            });
            $('#disagree-link').on('click', function(e) {
                e.preventDefault();
                $('input#disagree').trigger('click');
            });

        }
    },
    gdprDeleteUser : function () {
        const template_url = window.Urls.gdprDeleteUserDialog;
        dialog.open({
            url:template_url,
            options : {
                dialogClass : 'gdpr_forget',
                width: 575,
                draggable: false,
                resizable: false,
                title: window.Resources.GDPR_FORGET_TITLE,
                openInCallback: true,
                open: function(event, ui) {
                    gdprForgetEvents(event, ui);
                },
                closeOnEscape: false,
            }
        });

        function gdprForgetEvents(event, ui) {
            $('.forget_cta').on('click', function(e) {
                e.preventDefault();
                var $this = $(this);

                if($this.attr('id') == "ForgetmeClose") {
                    dialog.close();
                    return;
                }

                var url = $this.attr('href');
                //If the user does not agree
                ajax.load({
                    url:url,
                    callback: function() {
                        window.location.href = window.Urls.homeShow;
                    }
                });
            });
        }
    }
};

module.exports = GDPR;