'use strict';

var product = require('./pages/product');
var tealium = require('./tealium');
var util = require('./util');
var ajax = require('./ajax');
var dialog = require('./dialog');
var progress = require('./progress');
var tooltip = require('./tooltip');

var $cache = {};
/**
 * @function
 * @description Binds a 'click'-event to the quick view button
 */
function bindQvButton() {
    $(document).on('click', '.hfquickviewbutton', function (e) {
        e.preventDefault();

        $('.clicked').removeClass('clicked');
        $(this).closest('.hf-grid-tile').addClass('clicked');
        var strippedUrl = $(this).attr("href");
        var currentProtocol = window.location.protocol + "//";
        var gridIndexOnCLP = "" + $(this).closest('.hf-grid-tile').data('clp-grid-index');
        strippedUrl = strippedUrl.replace(/.*?:\/\//g, currentProtocol);
        quickview.show({
            url : strippedUrl,
            source : "quickview",
            gridIndex : gridIndexOnCLP
        });
    });
}

var quickview = {
    initButton : bindQvButton,

    init : function () {
        if ($cache.quickView) {
            return $cache.quickView;
        }

        $cache.quickView = $("<div/>").attr("id", "QuickViewDialog").appendTo(document.body);
        return $cache.quickView;
    },
    // show quick view dialog and send request to the server to get the product
    // options.source - source of the dialog i.e. search/cart
    // options.url - product url
    /**
     * @function
     * @description
     */
    show : function (options) {
        options.target = quickview.init();
        options.callback = function () {
            dialog.create({
                target : $cache.quickView,
                options : {
                    height : 'auto',
                    width : 1024,
                    dialogClass : 'quickview',
                    title : 'quickview',
                    resizable : false,
                    position : 'center',
                    open : function () {
                        progress.hide();
                        product.init();
                        tooltip.init();
                        $(this).children().find('.product-name').focus();
                        var $close = $('.ui-dialog-titlebar-close, .ui-widget-overlay');
                        $close.off('keydown').on('keydown', function (e) {
                            e.stopImmediatePropagation();

                            if (e.which === 13) {
                                e.preventDefault();
                                quickview.close();
                            }

                            var isTabPressed = e.key === 'Tab' || e.keyCode === 9;

                            if (isTabPressed) {
                                if (e.shiftKey) {
                                    $('.show-full a').focus();
                                    e.preventDefault();
                                } else {
                                    $('#hf-pdpMain .product-name').focus();
                                    e.preventDefault();
                                }
                            }
                        });
                        var $uiDialog = $(this).parent();
                        $uiDialog.attr('aria-modal', 'true');
                        $uiDialog.attr('aria-label', $('.ui-dialog-title', $uiDialog).text());
                    },
                    close : function(){
                        $('#hfquickviewbutton').addClass('visible');
                        $('#hfquickviewbutton').focus();
                    }
                }
            });
            tealium.productView(options.gridIndex);
            $cache.quickView.dialog('open');
            $("#carouselContainer").slick('setPosition');
        };
        quickview.getProductView(options);

        return $cache.quickView;
    },
    /**
     * @function
     * @description Loads a product into a given container div
     * @param {Object} options An object with the following properties:</br>
     * <p>containerId - id of the container div, if empty then global app.containerId is used</p>
     * <p>source - source string e.g. search, cart etc.</p>
     * <p>label - label for the add to cart button, default is Add to Cart</p>
     * <p>url - url to get the product</p>
     * <p>id - id of the product to get, is optional only used when url is empty</p>
     */
    getProductView: function (options) {
        var target = options.target || quickview.init();
        var source = options.source || "";
        var productListID = options.productlistid || "";


        var productUrl = options.url || util.appendParamToURL(window.Urls.getProductUrl, "pid", options.id);
        if(productListID.length > 0) {
            productUrl = util.appendParamToURL(productUrl, "productlistid", productListID);
        }
        // show small loading image
        //progress.show(app.ui.primary);
        ajax.load({
            target : target,
            url : productUrl,
            data : options.data || "",
            // replace with callback passed in by options
            callback : options.callback || product.init
        });
    },
    // close the quick view dialog
    close : function () {
        if($cache.quickView) {
            $cache.quickView.dialog('close').empty();
            return $cache.quickView;
        }
    },
    exists : function () {
        return $cache.quickView && ($cache.quickView.length > 0);
    },
    isActive : function () {
        return $cache.quickView && ($cache.quickView.length > 0) && ($cache.quickView.children.length > 0);
    },
    container : $cache.quickView
};

module.exports = quickview;
