/* global setTimeout */
'use strict';

var util = require('../util');

var addressupdate = {
    initStateDropdown: function () {
        var countryObj = $("select[id$='_country']");
        if (countryObj != null) {
            var isMilitary = $('select[name$="_addressType"]').val() == 'military';
            util.updateStateOptions(countryObj, isMilitary);
        }
    },
    
    updateAddressTypeValue: function(data, multiForm, elmIndex, $cache) {
        var addressType = multiForm ? $($cache.addressType[elmIndex]) : $cache.addressType;
        var stateCode = multiForm ? $($cache.stateCode[elmIndex]) : $cache.stateCode;
        var postOffice = $($cache.postOffice[elmIndex]);
        var companyName  = multiForm ? $($cache.companyName[elmIndex]) : $cache.companyName;

        if (data) {
            stateCode.val(data.stateCode);
            if(data.addressType === 'military') {
                $(addressType).val("military");
                postOffice.val(data.city);
            } else if (data.addressType === 'business') {
                $(addressType).val("business");
                companyName.val(data.companyName);
            } else {
                $(addressType).val("residential");
            }
            addressType.trigger("change");
        } else if ($('#guestAddressType').val()){
            $(addressType).val($('#guestAddressType').val());
            addressType.trigger("change");
        }
        return addressType;
    }
};

module.exports = addressupdate;