'use strict';

var sendtofriend = require('../sendtofriend');
var product = require('./product');
var quickview = require('../quickview');
var util = require('../util');
var $cache = {};
/**
 * @private
 * @function
 * @description Binds the send to friend and address changed events to the wishlist page
 */
function initializeEvents() {
    sendtofriend.initializeDialog("div.list-table-header", ".send-to-friend");
    $cache.editAddress.on('change', function () {
        window.location.href = util.appendParamToURL(window.Urls.wishlistAddress, "AddressID", $(this).val());
    });
    $cache.wishlistTable.on("click", ".item-details a", function (e) {
        e.preventDefault();
        quickview.show({
            url : e.target.href,
            source : "wishlist"
        });
    });
}

var wishlist = {
    /**
     * @function
     * @description Binds events to the wishlist page
     */
    init : function () {
        $cache.editAddress = $('#editAddress');
        $cache.wishlistTable = $('.pt_wish-list .item-list');
        product.initAddToCart();
        initializeEvents();

    }
};

module.exports = wishlist;