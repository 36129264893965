/* global setTimeout */
'use strict';

var checkout = require('./checkout');
var singleship = require('./checkout/singleship');
var multiship = require('./checkout/multiship');
var bonusproductsview = require('../bonusproductsview');
var util = require('../util');
var page = require('../page');
var dialog = require('../dialog');
var quickview = require('../quickview');
var tealium = require('../tealium');
var floatlabels = require('../float-labels');
var suggestedProducts = require('../suggestedproducts');
var bopis = require('../bopis');
var $cache = {};
/**
 * @private
 * @function
 * @description Updates the cart with new data
 * @param {Object} postdata An Object representing the the new or uptodate data
 * @param {Object} A callback function to be called
 */
function donateSlot() {
    jQuery(".donation_addtocart button.addtocart").click(function(e) {
        e.preventDefault();
        var productid = jQuery(this).siblings("#donate-amount").val();
        var url = util.appendParamsToUrl(window.Urls.addProduct, {pid:productid});
        var tealiumProductData = $('#donate-amount :selected').data('tealium');
        $('.sc-header h1').focus();
        jQuery.ajax({
            type : 'POST',
            dataType : 'html',
            cache	: false,
            contentType : 'application/json',
            url : url,
            success: function() {
                tealium.cartAdd(tealiumProductData, null, null);
            },
            complete : function() {
                $('.update-cart').trigger('click');
            }
        });
    });
    jQuery(".donation_addtocart .nothanks").click(function(e) {
        e.preventDefault();
        dialog.close();
    });
}
function recommendedProductsCarousel () {
    // HF-649 moved the carouselBlock initialization into a 3 second delay to account
    // for moving the title block and hf-cfcarousel tags into the content slot rendering template
    // resulting in the hf-cfcarousel existing inside a cq_recomm_slot block. We needed to wait
    // for that block to load before looking for the #hf-cfcarousel.  This was done to add
    // support for a 'Recommendation' content type to the Cart carousel page.
    window.setTimeout(function () {
        $(".hf-cart #hf-cfcarousel").slick({
            lazyLoad: 'ondemand',
            slidesToShow: 3,
            infinite: false,
            responsive: [{
                breakpoint: 959,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                    arrows: true,
                    appendDots: $('.hf-cart .hf-cart-recommendations-slider-nav'),
                    appendArrows: $('.hf-cart .hf-cart-recommendations-slider-nav')
                }
            }]
        });
    }, 3000);
}

function updateCart(postdata, callback) {
    var url = util.ajaxUrl(window.Urls.addProduct);
    $.post(url, postdata, callback || cart.refresh);
}

function suggestedProductsCart() {
    $('.suggested-products-modal').each(function() {
        var origContainer = $(this).parent();
        var $modal = $(this);
        $modal.dialog({
            autoOpen: false,
            dialogClass: 'suggested-products-dialog',
            maxWidth: 1070,
            maxHeight: 1056,
            width: '100%',
            height: 'auto',
            modal: true,
            fluid: true,
            draggable: false,
            resizable: false,
            position : 'center',
            open : function () {
                suggestedProducts.fluidDialog();
                suggestedProducts.flipProduct();
                $(window).resize(function () {
                    suggestedProducts.fluidDialog();
                });
                var windowSize = $(window).innerWidth(),
                    $slider = $modal.find('.products');

                if (windowSize <= 735) {
                    if ($slider.hasClass('slick-initialized')) {
                        $slider.slick('unslick');
                    }
                    $slider.slick({
                        lazyLoad: 'ondemand',
                        slidesToShow: 1,
                        arrows: true,
                        slide: '.product-tile'
                    });
                } else {
                    if ($slider.hasClass('slick-initialized')) {
                        $slider.slick('unslick');
                    }
                }
                var $closeBar = $('.suggested-products-dialog a.ui-dialog-titlebar-close');
                $closeBar.attr('tabindex', 0);
                $closeBar.on('keydown', function (e) {
                    if (e.which === 9) {
                        $('.suggested-products-modal h2').focus();
                    }
                });

                $closeBar.on('keyup', function (e) {
                    if (e.which === 13) {
                        $modal.dialog('close');
                        origContainer.find('button.replace').focus();
                    }
                });
                $('.ui-widget-overlay, .add-to-cart').on('click', function() {
                    $modal.dialog('close');
                    origContainer.find('button.replace').focus();
                });
                $modal.focus();
                $('.pdp-action').hide();
            }
        }).parent().appendTo(origContainer);
    });

    $('.suggested-products-modal').find('button.select').click(function(e) {
        e.preventDefault();
        var url = util.ajaxUrl(window.Urls.addProduct);
        var $this = $(this);
        var productLineItemId = $this.closest('.cart-product-row').attr('data-line-item-id');
        var productId = $this.prev('.hf-product-tile').attr('data-itemid');
        var quantity = $this.closest('.cart-product-row').find('select[name$=_quantity] option:selected').text();
        var removePLI = $this.attr('data-replacepli');
        var data = {
            uuid: productLineItemId,
            pid: productId,
            Quantity: quantity,
            removePLI: removePLI
        };
        //Get tealium data and trigger cartAdd event
        var $clickedButtonSet = $('.tealium-clicked');
        var productData = $this.data('tealium');
        var initialQuantity = $clickedButtonSet.parents('.item-row-details').find('.tealium-initial-quantity').val();
        tealium.cartAdd(productData, initialQuantity + ".00");
        $(this).closest('.suggested-products-modal').dialog('close');
        $.post(url, data, page.refresh);
    });

    $('.out-of-stock-actions > button.replace').click(function(e) {
        e.preventDefault();
        var $el = $(this);
        $('.tealium-clicked').removeClass('tealium-clicked');
        $el.parent().addClass('tealium-clicked');

        var $suggestedProductsModal = $('body').find('.suggested-products-modal[data-pid="' + $el.parent().data().pid + '"]');
        $suggestedProductsModal.dialog('open');

        // overlay was being appended to the body and causing z-index rules not to apply
        // reappending it here fixes that issue
        $('.ui-widget-overlay').appendTo('#cart-table');
        //Closes if you click outside the modal
        $('.ui-widget-overlay, .close-on-continue').on('click', function () {
            $('.ui-dialog').find('.ui-dialog-content').dialog('close');
        });
    });

    $('.out-of-stock-actions > button.remove').click(function(e) {
        e.preventDefault();

        $('.tealium-clicked').removeClass('tealium-clicked');
        $(this).parent().addClass('tealium-clicked');
        $(this).addClass('tealium-remove-event');
        //Get tealium data and trigger cartRemove event
        var $clickedButtonSet = $('.tealium-clicked');
        var productData = $clickedButtonSet.data('tealium');
        var initialQuantity = $clickedButtonSet.parents('.item-row-details').find('.tealium-initial-quantity').val();

        tealium.cartRemove(productData, initialQuantity + ".00");

        $(this).closest('.cart-product-row').find('button[name$=_deleteProduct]').addClass('clicked').click();
    });

    $('.gl-rec-contents, .hf-view-more').on('click', function () {
        $(this).parent().find('.gl-rec-contents-desc').toggle();
    });

    setTimeout(function () {
        $('.suggested-products-modal').each(function () {
            $('body').append($(this).parent());
        });
    }, 150);
}

/**
 * @private
 * @function
 * @description Cache initialization of the cart page
 */
function initializeCache() {
    $cache = {
        couponCode : $("form input[name$='_couponCode']")
    };
}
function isMobile() {
    try{ document.createEvent("TouchEvent"); return true; }
    catch(e){ return false; }
}
/**
 * @private
 * @function
 * @description Binds events to the cart page (edit item's details, bonus item's actions, coupon code entry )
 */
function initializeEvents() {
    floatlabels.init();
    donateSlot();
    recommendedProductsCarousel();

    $('.shipment-wrapper').on("click", ".item-edit-details a", function (e) {
        if (!isMobile()) {
            e.preventDefault();
            quickview.show({
                url : e.target.href,
                source : "cart"
            });
        }
    })
    .on("click", ".bonus-item-actions a", function (e) {
        e.preventDefault();
        bonusproductsview.show(this.href);
    });

    // override enter key for coupon code entry
    $cache.couponCode.on("keydown", function (e) {
        if (e.which === 13 && $(this).val().length !== 0) {
            $('#add-coupon').trigger('click');
            return false;
        }
    });


    $(function() {
        $('.cart-actions button').click(function(e) {
            tealium.startCheckout();
            var url = window.Urls.checkoutShow;
            // since we are bypassing the Cart formHandler we need to flag
            // a multiship button press so COCustomer-Start knows which
            // direction to send the customer
            if (e.currentTarget.className.indexOf('trigger-multi-ship') !== -1) {
                url = util.appendParamsToUrl(url, {multiShipOverride:'true'});
            } else {
                url = util.appendParamsToUrl(url, {multiShipOverride:'false'});
            }

            window.location = util.ajaxUrl(url);

        });
    });

    var windowSize = $(window).width();
    if (windowSize < 767) {
        $(".shipping-restrictions").each(function() {
            var stock = $(this).closest('.product-list-item .availability-and-actions');
            $(this).show().insertBefore(stock);
        });
    }
    if(windowSize < 481) { //centers the buttons in mobile view
        $('.bonus-item-actions').each(function() {
            //find the bonus container
            var $bnsCntr = $(this).parents('.bonus-container');
            $(this).insertAfter($bnsCntr);
        });
    }else{
        //$('.bonus-item-actions').appendTo('.bonus-info');
    }

}

function rebuildCartTable() {
    if ($(window).width() < 768 && $('.mobile-moved').length < 1) {
        $('.product-description').each(function () {
            $(this).children().addClass('mobile-moved');
            $(this).children().insertAfter($(this));
            $('.cart-left [role]').removeAttr('role');
        });
        $('.product-description').hide();

    } else if ($(window).width() >= 768 && ('.mobile-moved').length > 0) {
        $('.cart-product-row').each(function () {
           $(this).find('.mobile-moved').appendTo($(this).find('.product-description'));
        });
        $('.product-description').show();
        $('.mobile-moved').removeClass('mobile-moved');
        $('.cart-left [data-role]').each(function () {
           $(this).attr('role', $(this).data('role'));
        });
    }
}

var cart = {
    /**
     * @function
     * @description Adds new item to the cart
     * @param {Object} postdata An Object representing the the new or uptodate data
     * @param {Object} A callback function to be called
     */
    add : function (postdata, callback) {
        updateCart(postdata, callback);
    },
    /**
     * @function
     * @description Hook for removing item from the cart
     *
     */
    remove : function () {
        return;
    },
    /**
     * @function
     * @description Updates the cart with new data
     * @param {Object} postdata An Object representing the the new or uptodate data
     * @param {Object} A callback function to be called
     */
    update : function (postdata, callback) {
        updateCart(postdata, callback);
    },
    /**
     * @function
     * @description Refreshes the cart without posting
     */
    refresh : function () {
        // refresh without posting
        page.refresh();
    },
    /**
     * @function
     * @description Initializes the functionality on the cart
     */
    init : function () {
        // edit shopping cart line item

        singleship.donateDialogLoad();


        $('.cart-product-row').each(function() {
            $('.item-user-actions button').click(function() {
                $('.input-name').val($(this).parents('.cart-product-row').find('.name a').text());
            });
        });

        $('.quantity-field').on('change', function(){
            var $initialQty = $(this).parent().find('.tealium-initial-quantity');
            if ($(this).find('[name$="_quantity"]').val() > $initialQty.val()) {
                tealium.cartAdd($initialQty.data('tealium'), $(this).find('[name$="_quantity"]').val() - $initialQty.val(), null);
            }
            $('.update-cart').trigger('click');
        });

        $(".remove-confirmation").each(function (index) {
            var $this = $(this);
            var origContainer = $this.parent();
            $this.dialog({
                autoOpen: false,
                height: 150,
                width: 500,
                modal: true,
                dialogClass: 'removeConf',
                buttons: {
                    'Yes, Remove it' : function() {
                        $(this).dialog('close');
                        $('.clicked').trigger('click');
                        var $cartRow = $('.clicked').parents('.cart-product-row');
                        var $buttonBar = $('.tealium-clicked');
                        if ($buttonBar.length) {
                            tealium.cartRemove($buttonBar.data('tealium'), ["1.00"]);
                        } else {
                            var $tealiumInput = $cartRow.find('.tealium-initial-quantity');
                            tealium.cartRemove($tealiumInput.data('tealium'), $tealiumInput.val() + ".00");
                        }

                    },
                    'Cancel' : function() {
                        $(this).dialog('close');
                        $('.remove-product').each(function() {
                            $(this).removeClass('clicked');
                        });
                    }
                },
                create: function() {
                    $(this).closest('div.ui-dialog').find('.ui-dialog-titlebar-close').click(function() {
                        $('.remove-product').each(function() {
                            $(this).removeClass('clicked');
                        });
                   });
                },
                open: function() {
                    $('.slideout-panel').css('position','static');
                    $(this).parent().attr('aria-modal', 'true');
                },
                close: function() {
                    $('.slideout-panel').css('position','relative');
                    $this.closest('.cart-product-row').find('.remove-product').focus();
                    $(this).parent().removeAttr('aria-modal');
                    setTimeout(function () {
                        $('.remove-product').each(function() {
                            $(this).removeClass('clicked');
                        });
                    }, 300);
                }
            }).parent().appendTo(origContainer);
        });

        $('.remove-product').click(function (e) {
            if ($(this).hasClass('clicked')) {
                return true;
            } else {
                $(this).addClass('clicked').next().children(".remove-confirmation").dialog("open");
                e.preventDefault();
            }
        });

        rebuildCartTable();

        var timeout;
        window.addEventListener('resize', function () {
            window.clearTimeout(timeout);
            timeout = window.setTimeout(rebuildCartTable, 100);
        });

        multiship.initShippingRestrictions();
        checkout.initApplyCoupon();
        checkout.initRemoveCoupon();

        suggestedProductsCart();

        initializeCache();
        initializeEvents();
        bopis.init();
    }
};

module.exports = cart;
