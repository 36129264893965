'use strict';

var ajax = require('./ajax');
var util = require('./util');

var $cache = {},
    _currentCategory = "",
    _isClearing = false,
    MAX_ACTIVE = 6,
    CI_PREFIX = "ci-";

/**
 * @private
 * @function
 * @description Verifies the number of elements in the compare container and updates it with sequential classes for ui targeting
 */
function refreshContainer() {
    if (_isClearing) { return; }

    var ac = $cache.compareContainer.find(".active").length;

    if (ac < 2) {
        $cache.compareButton.attr("disabled", "disabled");
    }
    else {
        $cache.compareButton.removeAttr("disabled");
    }

    // update list with sequential classes for ui targeting
    var compareItems = $cache.compareContainer.find('.compare-item');
    for( var i = 0; i < compareItems.length; i++ ) {
        compareItems.removeClass('compare-item-' + i);
        $(compareItems[i]).addClass('compare-item-' + i);
    }

    $cache.compareContainer.toggle(ac > 0);

}
/**
 * @private
 * @function
 * @description Adds an item to the compare container and refreshes it
 */
function addToList(data) {
    // get the first compare-item not currently active
    var item = $cache.compareContainer.find(".compare-item").not(".active").first();
    if (item.length === 0) { return; } // safety only

    // if already added somehow, return
    if ($("#" + CI_PREFIX + data.uuid).length > 0) {
        return;
    }
    // set as active item
    item.addClass("active")
        .attr("id", CI_PREFIX + data.uuid)
        .attr("data-itemid", data.itemid);

    // replace the item image
    var itemImg = item.children("img.compareproduct").first();
    itemImg.attr({src : $(data.img).attr("src"), alt : $(data.img).attr("alt")});

    // refresh container state
    refreshContainer();

    var tile = $("#" + data.uuid);
    if (tile.length === 0) { return; }

    // ensure that the associated checkbox is checked
    tile.find(".compare-check")[0].checked = true;
}
/**
 * @private
 * @function
 * description Removes an item from the compare container and refreshes it
 */
function removeFromList(uuid) {
    var item = $("#" + CI_PREFIX + uuid);
    if (item.length === 0) { return; }

    // replace the item image
    var itemImg = item.children("img.compareproduct").first();
    itemImg.attr({src : window.Urls.compareEmptyImage, alt : window.Resources.EMPTY_IMG_ALT});

    // remove class, data and id from item
    item.removeClass("active")
        .removeAttr("id")
        .removeAttr("data-itemid")
        .attr("data-itemid", "");

    // use clone to prevent image flash when removing item from list
    var cloneItem = item.clone();
    item.remove();
    cloneItem.appendTo($cache.comparePanel);
    refreshContainer();
    // ensure that the associated checkbox is not checked
    var tile = $("#" + uuid);
    if (tile.length === 0 ) { return; }

    tile.find(".compare-check")[0].checked = false;
}
/**
 * @private
 * @function
 * description Initializes the cache of compare container
 */
function initializeCache() {
    $cache = {
        primaryContent : $("#primary"),
        compareContainer : $("#compare-items"),
        compareButton : $("#compare-items-button"),
        clearButton : $("#clear-compared-items"),
        comparePanel : $("#compare-items-panel")
    };
}
/**
 * @private
 * @function
 * @description Initializes the DOM-Object of the compare container
 */
function initializeDom() {
    _currentCategory = $cache.compareContainer.data("category") || "";
    var active = $cache.compareContainer.find(".compare-item").filter(".active");
    active.each(function () {
        var uuid = this.id.substr(CI_PREFIX.length);
        var tile = $("#" + uuid);
        if (tile.length === 0 ) { return; }

        tile.find(".compare-check")[0].checked = true;
    });
    // set container state
    refreshContainer();
}
/**
 * @private
 * @function
 * @description Initializes the events on the compare container
 */
function initializeEvents() {
    // add event to buttons to remove products
    $cache.primaryContent.on("click", ".compare-item-remove", function (e, async) {
        var item = $(this).closest(".compare-item");
        var uuid = item[0].id.substr(CI_PREFIX.length);
        var tile = $("#" + uuid);
        var args = {
            itemid : item.attr("data-itemid"),
            uuid : uuid,
            cb :  tile.length === 0 ? null : tile.find(".compare-check"),
            async : async
        };
        productcompare.removeProduct(args);
        refreshContainer();
    });

    // Button to go to compare page
    $cache.primaryContent.on("click", "#compare-items-button", function () {
        window.location.href = util.appendParamToURL(window.Urls.compareShow, "category", _currentCategory);
    });

    // Button to clear all compared items
    $cache.primaryContent.on("click", "#clear-compared-items", function () {
        _isClearing = true;
        $cache.compareContainer.hide()
                               .find(".active .compare-item-remove")
                               .trigger("click", [false]);
        _isClearing = false;

    });
}


var productcompare = {
    /**
     * @function
     * @description Cache, events and initialization
     */
    init : function () {
        initializeCache();
        initializeDom();
        initializeEvents();
    },
    initCache : initializeCache,
    /**
     * @function
     * @description Adds product to the compare table
     */
    addProduct : function (args) {
        var items = $cache.compareContainer.find(".compare-item");
        var cb = $(args.cb);
        var ac = items.filter(".active").length;
        if(ac === MAX_ACTIVE) {
            if(!window.alert(window.Resources.COMPARE_CONFIRMATION)) {
                cb[0].checked = false;
                return;
            }

            // remove product using id
            var item = items.first();

            // safety check only. should never occur.
            if (item[0].id.indexOf(CI_PREFIX) !== 0) {
                cb[0].checked = false;
                window.alert(window.Resources.COMPARE_ADD_FAIL);
                return;
            }
            var uuid = item[0].id.substr(CI_PREFIX.length);
            productcompare.removeProduct({
                itemid: item.attr("data-itemid"),
                uuid: uuid,
                cb: $("#" + uuid).find(".compare-check")
            });
        }

        ajax.getJson({
            url : window.Urls.compareAdd,
            data : { 'pid' : args.itemid, 'category' : _currentCategory },
            callback : function (response) {
                if (!response || !response.success) {
                    // response failed. uncheck the checkbox return
                    cb.checked = false;
                    window.alert(window.Resources.COMPARE_ADD_FAIL);
                    return;
                }

                // item successfully stored in session, now add to list...
                addToList(args);
            }
        });
    },
    /**
     * @function
     * @description Removes product from the compare table
     */
    removeProduct : function (args) {
        if (!args.itemid) { return; }
        var cb = args.cb ? $(args.cb) : null;
        ajax.getJson({
            url : window.Urls.compareRemove,
            data : { 'pid' : args.itemid, 'category' : _currentCategory },
            async: args.async,
            callback : function (response) {
                if (!response || !response.success) {
                    // response failed. uncheck the checkbox return
                    if (cb && cb.length > 0) { cb[0].checked = true; }
                    window.alert(window.Resources.COMPARE_REMOVE_FAIL);
                    return;
                }

                // item successfully removed session, now remove from to list...
                removeFromList(args.uuid);
            }
        });
    }
};

module.exports = productcompare;
