'use strict';

var quickview = require('./quickview');
var $cache = {};

/**
 * @function
 * @description Initializes the DOM of the Product Detail Page
 */
function initializeDom() {
    $(window).on('load', producttile.grid);
}

/**
 * @private
 * @function
 * @description Initializes events on the hf-product-tile for the following elements:<br/>
 * <p>swatches</p>
 * <p>thumbnails</p>
 */
function initializeEvents() {
    quickview.initButton();

    $cache.container.on("mouseleave", ".hf-swatch-list", function(e) {
        // Restore current thumb image
        var tile = $(this).closest(".hf-grid-tile");
        var thumb = tile.find(".hf-product-image a.hf-thumb-link img").filter(":first");
        var data = thumb.data("current");
        thumb.attr({
            src : data.src,
            alt : data.alt,
            title : data.title
        });
    });
    $cache.container.on("click", ".hf-swatch-list a.swatch", function (e) {
        e.preventDefault();
        if ($(this).hasClass("selected")) { return; }

        var tile = $(this).closest(".grid-tile");
        $(this).closest(".hf-swatch-list").find(".swatch.selected").removeClass("selected");
        $(this).addClass("selected");
        tile.find("a.hf-thumb-link").attr("href", $(this).attr("href"));
        tile.find("a.hf-name-link").attr("href", $(this).attr("href"));

        var swatchImg = $(this).children("img").filter(":first");
        var data = swatchImg.data("thumb");
        var thumb = tile.find(".hf-product-image a.hf-thumb-link img").filter(":first");
        var currentAtts = {
            src : data.src,
            alt : data.alt,
            title : data.title
        };
        thumb.attr(currentAtts);
        thumb.data("current", currentAtts);
    }).on("mouseenter", ".hf-swatch-list a.swatch", function (e) {
        //if ($(this).hasClass("selected")) { return; }

        // get current thumb details
        var tile = $(this).closest(".hf-grid-tile");
        var thumb = tile.find(".hf-product-image a.hf-thumb-link img").filter(":first");
        var swatchImg = $(this).children("img").filter(":first");
        var data = swatchImg.data("thumb");
        var current = thumb.data('current');

        // If this is the first time, then record the current img
        if(!current) {
            thumb.data('current',{src:thumb[0].src, alt:thumb[0].alt, title:thumb[0].title});
        }

        // Set the tile image to the values provided on the swatch data attributes
        thumb.attr({
            src : data.src,
            alt : data.alt,
            title : data.title
        });

        //swatchImg.data("thumb", currentAtts);
    });

    //"Read reviews" text that is off-screen needs to be removed from review stars so screen reader doesn't think it's a link
    window.setTimeout(function() {
        $('.bv-off-screen').each(function(){
            $(this).text(function (i, text) {
                return text.replace('Read reviews.', '');
            });
        });
    },1000);
}


var producttile = {
    /**
     * @function
     * @description Cache, events and initialization
     */
    init : function () {
        $cache = {
            container : $(".tiles-container"),
            productInfo  : $('.hf-product-info')
        };
        initializeEvents();
        initializeDom();
    },
    grid : function() {
        var tiles = $cache.container.find(".hf-product-tile");
        if (tiles.length === 0) { return; }

        $cache.container.find(".hf-grid-tile ").each(function() {
            $(this).find('.hf-product-contents ul li').hide().filter(':lt(8)').show();
            $('.hf-view-less').click(function () {
                $(this).siblings('.hf-product-contents').find(' ul li').hide().filter(':lt(8)').show();
            });
        });

        $cache.productInfo.on('click', '.hf-view', function () {
            $(this).closest('.hf-view-more').toggle();
            $(this).closest('.hf-view-less').toggle();

            if($(this).closest('.hf-view-more').is(":visible")) {
                $(this).siblings('.hf-product-contents').find(' ul li:hidden').show();
                $(this).closest('.hf-view-more').hide();
                $(this).parent().children('.hf-view-less').show();
            }else if($(this).parent().find('.hf-view-less').is(":visible")) {
                $(this).siblings('.hf-product-contents ul li').hide().filter(':lt(8)').show();
                $(this).parent().find('.hf-view-more').show();
                $(this).parent().find('.hf-view-less').hide();
            }
        });

        $('.hf-view-more').click(function () {
            $(this).closest('div.hf-product-contents').find('.hf-view-less').toggle();
            $(this).toggle();
        });
        $('.hf-view-less').click(function () {
            $(this).closest('div.hf-product-contents').find('.hf-view-more').toggle();
            $(this).siblings('.hf-product-contents ul li').hide().filter(':lt(8)').show();
            $(this).toggle();
        });
    }
};

module.exports = producttile;
