/* global setTimeout */
'use strict';

var validator = require('./validator');

// sub namespace util.* contains utility functions
var util = {
    /**
     * @function
     * @param {Function*} func
     * @param {Number} time
     */
    debounce: function (func, time) {
        var time = time || 100; // 100 by default if no param
        var timer;
        return function (event) {
            if (timer) {
                window.clearTimeout(timer);
            }
            timer = window.setTimeout(func, time, event);
        };
    },

    fillAddressFields: function (address, $form) {
        for (var field in address) {
            if (field === 'UUID' && $('input[name="editAddressUUID"]').length > 0) {
                $('input[name="editAddressUUID"]').val(address[field]);
                continue;
            }
            if (field === 'ID' || field === 'UUID' || field === 'key') {
                continue;
            }
            // if the key in address object ends with 'Code', remove that suffix
            // keys that ends with 'Code' are postalCode, stateCode and countryCode
            $form.find('[name$="' + field.replace('Code', '') + '"]').val(address[field]).trigger('change');
            // update the state fields
            if (field === 'countryCode') {
                $form.find('[name$="country"]').trigger('change');
                // retrigger state selection after country has changed
                // this results in duplication of the state code, but is a necessary evil
                // for now because sometimes countryCode comes after stateCode
                $form.find('[name$="state"]').val(address.stateCode);
            }
        }
    },
    handleRestrictions: function () {
        $('.checkout-row').each(function () {
            var item = $(this);
            var addrs = item.find('select[name$="_addressList"]');
            addrs.each(function () {
                var addr = $(this);
                var selectedOption = addr.find('option:selected');
                if (selectedOption && selectedOption.length > 0) {
                    var optionData = selectedOption.data('address');
                    if (optionData && optionData.custom && optionData.custom.hasShippingRestriction === true) {
                        item.addClass('shipping-restriction');
                    }
                }
            });
        });
    },
    /**
     * @function
     * @description trims a prefix from a given string, this can be used to trim
     * a certain prefix from DOM element IDs for further processing on the ID
     */
    trimPrefix : function (str, prefix) {
        return str.substring(prefix.length);
    },
    /**
     * @function
     * @description Appends a character to the left side of a numeric string (normally ' ')
     * @param {String} str the original string
     * @param {String} padChar the character which will be appended to the original string
     * @param {Number} len the length of the end string
     */
    padLeft : function (str, padChar, len) {
        var digs = len || 10;
        var s = str.toString();
        var dif = digs - s.length;
        while(dif > 0) {
            s = padChar + s;
            dif--;
        }
        return s;
    },

    /**
     * Manually check fields for "null" string
     * @param {Object} form - jquery form selector
     */
    formCustomValidation: function (form) {
        var formValid = true;
        if (form && form.length > 0) {
            form.find('input.required:visible').each(function () {
                var input = $(this);
                // check for null as string
                if(input.val() === 'null' || $.trim(input.val()) === '') {
                    // reset value and run validation
                    input.val('');
                    input.valid();
                    formValid = false;
                }
            });
            //check billing custom address list, which is hidden by default, but not needed when "Same as billing" is checked
            var useAsBilling = $('#useAsBillingAddress');
            if(useAsBilling.length > 0 && (useAsBilling.prop('checked') != true && $('#dwfrm_billing_addressList').length > 0)) {
                var input = $('#dwfrm_billing_addressList');
                if(input.val() === 'null' || $.trim(input.val()) == '') {
                    // reset value and run validation
                    input.val('');
                    input.valid();
                    formValid = false;
                }
            }
            //separate check for null value for non-required address2 field
            var addr2 = form.find('input[name$="address2"]');
            if (addr2 && addr2.length > 0) {
                if ($.trim(addr2.val()) === 'null') {
                    addr2.val('');
                }
            }
            //avalara modal address2 field
            var addr2 = form.find('input[name$="add2"]');
            if (addr2 && addr2.length > 0) {
                if ($.trim(addr2.val()) === 'null') {
                    addr2.val('');
                }
            }
        }
        return formValid;
    },

    /**
     * Determine if custom address object contain null values as string
     * @param {Object} addr - address to check
     * @returns {boolean} isValid - if address object is valid
     */
    addressObjectValidation: function (addr) {
        var isValid = true;
        if (addr) {
            Object.keys(addr).forEach(function (key) {
                if (addr[key] === 'null') {
                    isValid = false;
                }
            });
        }
        return isValid;
    },

    /**
     * @function
     * @description appends the parameter with the given name and value to the given url and returns the changed url
     * @param {String} url the url to which the parameter will be added
     * @param {String} name the name of the parameter
     * @param {String} value the value of the parameter
     */
    appendParamToURL : function (url, name, value) {
        var c = "?";
        if(url.indexOf(c) !== -1 || url.indexOf('#') !== -1) {
            c = "&";
        }
        return url + c + name + "=" + encodeURIComponent(value);
    },
    /**
     * @function
     * @description
     * @param {String}
     * @param {String}
     */
    elementInViewport: function (el, offsetToTop) {
        var top = el.offsetTop,
            left = el.offsetLeft,
            width = el.offsetWidth,
            height = el.offsetHeight;

        while (el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
            left += el.offsetLeft;
        }

        if (typeof(offsetToTop) !== 'undefined') {
            top -= offsetToTop;
        }

        return (
                top < (window.pageYOffset + window.innerHeight) &&
                left < (window.pageXOffset + window.innerWidth) &&
                (top + height) > window.pageYOffset &&
                (left + width) > window.pageXOffset
        );
    },
    /**
     * @function
     * @description appends the parameters to the given url and returns the changed url
     * @param {String} url the url to which the parameters will be added
     * @param {Object} params
     */
    appendParamsToUrl: function (url, params) {
        var _url = url;
        $.each(params, function (name, value) {
            _url = this.appendParamToURL(_url, name, value);
        }.bind(this));
        return _url;
    },
    /**
     * @function
     * @description removes the parameter with the given name from the given url and returns the changed url
     * @param {String} url the url from which the parameter will be removed
     * @param {String} name the name of the parameter
     */
    removeParamFromURL : function (url, parameter) {
        var urlparts = url.split('?');

        if (urlparts.length >= 2) {
            var urlBase = urlparts.shift();
            var queryString = urlparts.join("?");

            var prefix = encodeURIComponent(parameter) + '=';
            var pars = queryString.split(/[&;]/g);
            var i = pars.length;
            while (0 < i--) {
                if(pars[i].lastIndexOf(prefix, 0) !== -1) {
                    pars.splice(i, 1);
                }
            }
            url = urlBase + '?' + pars.join('&');
        }
        return url;
    },

    /**
     * @function
     * @description Returns the static url for a specific relative path
     * @param {String} path the relative path
     */
    staticUrl : function (path) {
        if(!path || $.trim(path).length === 0) {
            return window.Urls.staticPath;
        }

        return window.Urls.staticPath + (path.charAt(0) === "/" ? path.substr(1) : path );
    },
    /**
     * @function
     * @description Appends the parameter 'format=ajax' to a given path
     * @param {String} path the relative path
     */
    ajaxUrl : function (path) {
        return util.appendParamToURL(path, "format", "ajax");
    },

    /**
     * @function
     * @description
     * @param {String} url
     */
    toAbsoluteUrl : function (url) {
        if (url.indexOf("http") !== 0 && url.charAt(0) !== "/") {
            url = "/" + url;
        }
        return url;
    },
    /**
     * @function
     * @description Loads css dynamically from given urls
     * @param {Array} urls Array of urls from which css will be dynamically loaded.
     */
    loadDynamicCss : function (urls) {
        var i, len = urls.length;
        for(i = 0; i < len; i++) {
            util.loadedCssFiles.push(util.loadCssFile(urls[i]));
        }
    },

    /**
     * @function
     * @description Loads css file dynamically from given url
     * @param {String} url The url from which css file will be dynamically loaded.
     */
    loadCssFile : function (url) {
        return $("<link/>").appendTo($("head")).attr({
            type : "text/css",
            rel : "stylesheet"
        }).attr("href", url); // for i.e. <9, href must be added after link has been appended to head
    },
    // array to keep track of the dynamically loaded CSS files
    loadedCssFiles : [],

    /**
     * @function
     * @description Removes all css files which were dynamically loaded
     */
    clearDynamicCss : function () {
        var i = util.loadedCssFiles.length;
        while(0 > i--) {
            $(util.loadedCssFiles[i]).remove();
        }
        util.loadedCssFiles = [];
    },
    /**
     * @function
     * @description Extracts all parameters from a given query string into an object
     * @param {String} qs The query string from which the parameters will be extracted
     */
    getQueryStringParams : function (qs) {
        if(!qs || qs.length === 0) { return {}; }

        var params = {}, unescapedQS = unescape(qs);
        // Use the String::replace method to iterate over each
        // name-value pair in the string.
        unescapedQS.replace( new RegExp( "([^?=&]+)(=([^&]*))?", "g" ),
                    function ( $0, $1, $2, $3 ) {	params[ $1 ] = $3; }
        );
        return params;
    },
    /**
     * @function
     * @description Returns an URI-Object from a given element with the following properties:<br/>
     * <p>protocol</p>
     * <p>host</p>
     * <p>hostname</p>
     * <p>port</p>
     * <p>path</p>
     * <p>query</p>
     * <p>queryParams</p>
     * <p>hash</p>
     * <p>url</p>
     * <p>urlWithQuery</p>
     * @param {Object} o The HTML-Element
     */
    getUri : function (o) {
        var a;
        if (o.tagName && $(o).attr("href")) {
            a = o;
        } else if (typeof o === "string") {
            a = document.createElement("a");
            a.href = o;
        } else if ($(o).data("href")) {
            a = document.createElement("a");
            a.href = $(o).data("href");
        } else {
            return null;
        }

        return {
            protocol : a.protocol, //http:
            host : a.host, //www.myexample.com
            hostname : a.hostname, //www.myexample.com'
            port : a.port, //:80
            path : a.pathname, // /sub1/sub2
            query : a.search, // ?param1=val1&param2=val2
            queryParams : a.search.length > 1 ? util.getQueryStringParams(a.search.substr(1)) : {},
            hash : a.hash, // #OU812,5150
            url : a.protocol + "//" + a.host + a.pathname,
            urlWithQuery : a.protocol + "//" + a.host + a.port + a.pathname + a.search
        };
    },
    /**
     * @function
     * @description Appends a form-element with given arguments to a body-element and submits it
     * @param {Object} args The arguments which will be attached to the form-element:<br/>
     * <p>url</p>
     * <p>fields - an Object containing the query-string parameters</p>
     */
    postForm : function (args) {
        var form = $("<form>").attr({action:args.url,method:"post"}).appendTo("body");
        var p;
        for (p in args.fields) {
            $("<input>").attr({name:p,value:args.fields[p]}).appendTo(form);
        }
        form.submit();
    },
    /**
     * @function
     * @description  Returns a JSON-Structure of a specific key-value pair from a given resource bundle
     * @param {String} key The key in a given Resource bundle
     * @param {String} bundleName The resource bundle name
     * @param {Object} A callback function to be called
     */
    getMessage : function (key, bundleName, callback) {
        if (!callback || !key || key.length === 0) {
            return;
        }
        var params = {key:key};
        if (bundleName && bundleName.length === 0) {
            params.bn = bundleName;
        }
        var url = util.appendParamsToUrl(window.Urls.appResources, params);
        $.getJSON(url, callback);
    },
    /**
     * @function
     * @description Updates the states options to a given country
     * @param {String} countrySelect The selected country
     */
    updateStateOptions : function(countrySelect, isMilitary) {

        isMilitary = isMilitary || false;
        var country = $(countrySelect);
        var countryKey = isMilitary ? 'military' : country.val();

        if ($('.checkout-shipping.address').closest('form').hasClass('giftsnappurchase')) {
            $('.checkout-shipping.address').closest('form').validate().resetForm();
        }

        var form = country.closest("form");
        if (country.length === 0 || !window.app.countries[countryKey]) {
             return;
        }
        var stateField = country.data("stateField") ? country.data("stateField") : form.find("select[name$='_state']");

        var stateFieldVal = stateField.val();

        if (stateField.length === 0) {
            return;
        }

        var c = window.app.countries[countryKey];
        var arrHtml = [];

        // sets the correct class, so proper validation happens (alternates between 'state', 'province' and 'region')
        stateField.removeClass('province region state').addClass(c.label.toLowerCase());

        var label = stateField.siblings('label');
        var isRequired = stateField.attr('required');
        var requiredMarker = isRequired ? ' *' : '';
        var stateLabelText = window.Resources.STATE + requiredMarker;
        var provinceLabelText = window.Resources.PROVINCE + requiredMarker;

        if (countryKey === 'US' || countryKey === 'military') {
            label.text(stateLabelText);
        } else if (countryKey === 'CA') {
            label.text(provinceLabelText);
        }

        var s;
        for (s in c.regions) {
            arrHtml.push('<option value="' + s + '">' + c.regions[s] + '</option>');
        }
        // generate the empty option item and add to stateSelect
        var o1 = $('<option class="select-option" label="Select..." value=""><!-- Empty --></option>');
        stateField.html(arrHtml.join("")).removeAttr("disabled").children().first().before(o1);

        form.validate().resetForm();
        form.find('[aria-describedby]').removeAttr('aria-describedby');
        form.find('[aria-invalid]').removeAttr('aria-invalid');
        stateField.val(stateFieldVal).trigger("change");
    },

    AddressTypeChange : function($givenAddressForm) {

        var adddressForms = typeof($givenAddressForm) === 'undefined' ? $("form.address") : $givenAddressForm;
        var isCustomerAddressForm = adddressForms.length == 1 && adddressForms[0].id == 'dwfrm_profile_address';

        // set state change on country field if on account address page
        /*if (isCustomerAddressForm) {
            adddressForms.find("select[name$='_country']").on("change", function(e) {
                util.updateStateOptions(this);
            });
        }*/

        function floatHalfWidthFields() {
            //Use js to mark which half width input fields should float to the right
            $('.form-row.half-width.float-right').removeClass('float-right'); //remove previous layout
            $('.form-row.half-width:not(.hide-row):odd').addClass('float-right');
        }

        function hideRequiredField($element) {
            $element.removeClass('required').parent().removeClass('required').hide().addClass('hide-row');
        }

        function showRequiredField($element) {
            $element.addClass('required').parent().addClass('required').show().removeClass('hide-row');
        }

        adddressForms.each(function(i) {
            var form = $(this);
            var addressType = form.find('.addressType');
            var companyName = form.find('input[id$="_companyName"]').parent();
            var countrySelect = form.find('.dropdown-country-select');
            var stateSelect = form.find('.dropdown-state-select');
            var militaryContent = form.find('.hf-military_address_link_contentasset');
            var militaryCountry = form.find('.hf-military-address-country');
            var labelState = form.find("label[for$='_state'] span").not(".required-indicator");
            var postOffice = form.find('.post-office select');
            var cityInput = form.find('input[id$="_city"]');
            var phone = form.find('input[id$="_phone"]');
            var zip = form.find('input.zipcode');

            if(addressType.val() != 'business') {
                companyName.hide().addClass('hide-row');
            }
            if(!(addressType.length)) {
                companyName.show().removeClass('hide-row');
            }

            form.submit(function(e) {
                countrySelect.removeAttr('disabled');
            });

            form.on("change", ".addressType", function() {
                var addressType = $(this).val();
                form.find('#address .error-message').remove(); //remove all errors from current address form
                if (addressType === 'residential') {
                    countrySelect.removeClass('country-disabled').removeAttr("disabled").trigger('change').parents('.form-row').removeClass('country-disabled').addClass('half-width');
                    militaryContent.hide().addClass('hide-row');
                    companyName.children('input').val('');
                    companyName.hide().addClass('hide-row');
                    hideRequiredField(postOffice);
                    showRequiredField(cityInput);
                    militaryCountry.hide().addClass('hide-row');
                    util.updateStateOptions(countrySelect[0], false);
                    phone.parents('.form-row').removeClass('half-width');
                    zip.parents('.form-row').insertAfter(cityInput.parents('.form-row'));
                } else if (addressType === 'business') {
                    countrySelect.removeClass('country-disabled').removeAttr("disabled").trigger('change');
                    militaryContent.hide().addClass('hide-row');
                    companyName.show().removeClass('hide-row');
                    hideRequiredField(postOffice);
                    showRequiredField(cityInput);
                    militaryCountry.hide().addClass('hide-row');
                    util.updateStateOptions(countrySelect[0], false);
                } else if (addressType === 'military') {
                    $('.military_address_content').hide().addClass('hide-row');
                    $('.military_address_link a').off().on('click', function() {
                        $(this).parent().next('.military_address_content').toggle();

                        if($(this).parent().next('.military_address_content').is(":visible")) {
                            $(this).text('Hide Military Address Examples');
                        } else {
                            $(this).text('Show Military Address Examples');
                        }

                        return false;
                    });
                    countrySelect.val('US').addClass('country-disabled').removeClass('error').attr("disabled", "disabled").trigger('change').parents('.form-row').addClass('country-disabled').removeClass('half-width');
                    militaryCountry.show().removeClass('hide-row');
                    companyName.children('input').val('');
                    companyName.hide().addClass('hide-row');
                    labelState.text('Region');
                    militaryContent.show().removeClass('hide-row');
                    postOffice.parent().css('display','inline-block');
                    showRequiredField(postOffice);
                    util.updateStateOptions(countrySelect[0], true);
                    stateSelect.trigger('change');
                    hideRequiredField(cityInput);
                    phone.parents('.form-row').addClass('half-width');
                    zip.parents('.form-row').insertBefore(phone.parents('.form-row'));
                }
                floatHalfWidthFields();
            });

            var selectedState = stateSelect.val();

            if(addressType.val() == 'military' && selectedState) {
                stateSelect.val(selectedState);
                stateSelect.trigger('change');
            }

            addressType.trigger('change');
        });

        function formatPhoneInput (e) {
            var target = e.target,
            position = target.selectionStart; // Capture initial position of cursor

            //remove non digit values and add hyphens in 123-123-1234 format
            this.value = this.value.replace(/[^0-9]/g, '').replace(/^([0-9]{3})([0-9]+)/, "$1-$2").replace(/^([0-9]{3}-)([0-9]{3})([0-9]+)/, "$1$2-$3");

            //Delete key was pressed
            if (e.originalEvent && e.originalEvent.inputType == "deleteContentForward") {
                target.selectionEnd = position; // Set the cursor back to the initial position.
            }
        }

        $('.phone').off('input change', formatPhoneInput).on('input change', formatPhoneInput);

    },

    /**
     * @function
     * @description Updates the number of the remaining character
     * based on the character limit in a text area
     * @param {Object} msg a resource message identifier object, e.g. window.Resource.MY_RESOURCE_MSG
     */
    limitCharacters : function () {
        $('form').find('textarea[data-character-limit]').each(function() {
            var characterLimit = $(this).data("character-limit");
            $(this).attr('aria-describedby', $(this).attr('name') + '_char_limit');

            var charCountHtml = '<span class="char-remain-count"  id="' + $(this).attr('name') + '_char_limit">' + characterLimit + '</span><span class="sr-only">' + window.Resources.CHARACTER_COUNT_ADA + '</span>';
            var charCountContainer = $(this).next('div.char-count');
            if (charCountContainer.length === 0) {
                charCountContainer = $('<div class="char-count" aria-live="polite" aria-atomic="true"/>').insertAfter($(this));
            }
            charCountContainer.html(charCountHtml);
            // trigger the keydown event so that any existing character data is calculated
            $(this).change();
        });
    },
    /**
     * @function
     * @description Binds the onclick-event to a delete button on a given container,
     * which opens a confirmation box with a given message
     * @param {String} container The name of element to which the function will be bind
     * @param {String} message The message the will be shown upon a click
     */
    setDeleteConfirmation : function(container, message) {
        $(container).on("click", ".delete", function(e) {
            return window.confirm(message);
        });
    },
    /**
     * @function
     * @description Scrolls a browser window to a given x point
     * @param {String} The x coordinate
     */
    scrollBrowser : function (xLocation) {
        $('html, body').animate({ scrollTop: xLocation }, 500);
    },
    getCookie : function (name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length == 2) {
            return parts.pop().split(";").shift();
        }
    },
    downloadObjectAsJson : function (exportObj, exportName) {
        //This function appends a anchor on the page, sets its attrs to allow to downloading of a file, the removes it from the DOM
        var dataStr = "data:text/json;charset=utf-8," + exportObj;
        var downloadAnchorNode = document.createElement('a');
        var exportName = (exportName.indexOf('.json') != -1 ? exportName : exportName + ".json");

        // IE10+ : (has Blob, but not a[download] or URL)
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
            var blob = new window.Blob([exportObj], {type:  "text/plain;charset=utf-8;"});
            window.navigator.msSaveBlob(blob, exportName);
        }else{
            downloadAnchorNode.setAttribute("href",     dataStr);
            downloadAnchorNode.setAttribute("download", exportName);
            document.body.appendChild(downloadAnchorNode); // required for firefox
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        }
    },
    /**
     * @private
     * @function
     * @description Scrolls to the position of the last previously loaded page in the window.history
     */
    restoreScrollPosition: function () {
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }
        var pageParams = util.getQueryStringParams(window.location.href);
        if (!('position' in pageParams)) {
            return false;
        }

        var positionFromTop = pageParams['position'];
        if (positionFromTop) {
            window.setTimeout(function () {
                $("html, body").animate({
                    scrollTop: positionFromTop
                }, 1000);
            }, 0);
        }
    },
    /**
     * @function
     * @description Generates a "dropdown" menu based on a select HTML element, if it has the class '.custom-dropdown'
     *  Used on the shipping address page's address dropdown menus.
     */
    initCustomDropdowns : function (reinitialization) {
        $('select.custom-dropdown').each(function(index, elem) {
            var $selectElement = $(this),
                $selectNext = $selectElement.next(),
                $selectExists = $selectNext.hasClass('select-styled'),
                $selectOptions = $selectElement.closest('.select-options'),
                address = null,
                addressName = '',
                addressHasRestriction = false,
                restrictionMessageHTML = '',
                $option = null,
                optionTextHTML,
                orginalOptionText,
                isSingleShipSelect = $selectElement.parents('.inc-address-list').length > 0,
                isBillingSelect = $selectElement.parents('.billing').length > 0;

            if (reinitialization) {
                var numberOfOptions = $selectElement.children('option').length;

                if($selectExists) {
                    return;
                }

                if (!$selectElement.hasClass('select-hidden')) {
                    $selectElement.addClass('select-hidden');
                }

                if(!$selectElement.parent().hasClass('custom-select')) {
                    $selectElement.wrap('<div class="custom-select"></div>');
                }

                $selectElement.after('<button class="select-styled" tabindex="0" aria-expanded="false" aria-controls="select-options_' + index + '"></button>');

                var $styledSelect = $selectElement.next('button.select-styled');
                $styledSelect.text($selectElement.children('option').eq(0).text());

                var $list = $('<ol />', {
                    'class': 'select-options',
                    'id': 'select-options_' + index
                }).insertAfter($styledSelect);

                for (var i = 0; i < numberOfOptions; i++) {
                    $option = $selectElement.children('option').eq(i);
                    address = $option.data('address');
                    addressName = '';
                    addressHasRestriction = false;
                    restrictionMessageHTML = '';

                    var useRestriction = !isSingleShipSelect && !isBillingSelect;

                    if (address) {
                        if (address.ID) {
                            addressName = '(' + address.ID + ') ';
                        } else if (address.custom && address.custom.name) {
                            addressName = '(' + address.custom.name + ') ';
                        }
                        if (address.custom) {
                            addressHasRestriction = address.custom.hasShippingRestriction;
                        }
                        if (addressHasRestriction && useRestriction) {
                            restrictionMessageHTML = '<span class="option-restriction-message">' + window.Resources.RESTRICTED_ADDRESS + '</span>';
                        }
                    }

                    optionTextHTML = '<span class="option-text">' + $option.text() + '</span>';

                    $('<li />', {
                        html: optionTextHTML + restrictionMessageHTML,
                        class: addressHasRestriction && useRestriction ? 'restricted-option' : 'not-restricted',
                        rel: $option.val(),
                        tabindex: '0'
                    }).appendTo($list);
                }

                var $listItems = $list.children('li.not-restricted');
                var $restrictedItems = $list.children('li.restricted-option');

                //Events
                var enterKeyTriggersClick = function(e) {
                    if (e.which == 13 && !$(this).parents('.form-row').hasClass('completed')) {
                        $(this).click();
                    }
                };

                var restrictedItemClicked = function (e) {
                    e.stopPropagation();
                };

                var listItemClicked = function(e) {
                    e.stopPropagation();
                    orginalOptionText = $selectElement.find('option[value="' + $(this).attr('rel') + '"]').text();
                    $styledSelect.text(orginalOptionText).removeClass('active').attr('aria-expanded','false');
                    $selectElement.val($(this).attr('rel')).prop('selected', true);
                    $selectElement.trigger('change');
                    $list.hide();
                };

                $styledSelect.on('click', function(e) {
                    e.preventDefault();
                    e.stopPropagation();
                    $('div.select-styled.active').not(this).each(function(){
                        $(this).removeClass('active').attr('aria-expanded','false').next('ol.select-options').hide();
                    });
                    $(this).toggleClass('active').attr('aria-expanded', $(this).attr('aria-expanded') === 'true' ? 'false' : 'true').next('ol.select-options').toggle();
                });

                $restrictedItems.click(restrictedItemClicked);
                $listItems.click(listItemClicked);
                $listItems.keypress(enterKeyTriggersClick);

                $(document).click(function() {
                    $styledSelect.removeClass('active').attr('aria-expanded','false');
                    $list.hide();
                });

                $('.select-options li').each(function() {
                    var liOption = $(this).attr('rel');
                    if (liOption == $selectElement.find('option:selected').val()) {
                        $(this).addClass('selected');
                        orginalOptionText = $selectElement.find('option[value="' + liOption + '"]').text();
                        $styledSelect.text(orginalOptionText);
                        return;
                    }
                });

                //Hide dropdown list by default
                $styledSelect.text(orginalOptionText).attr('aria-expanded','false').next('ol.select-options').hide();
            }
        });
    },
    handleAddressTypeChange: function (selector) {
        var $form = $(selector);


        $form.on('change', "select[id$='_country']", function() {
            util.updateStateOptions(this, $('select[name$="_addressType"]').val() == 'military');
        });

        util.updateStateOptions($("select[id$='_country']"), $('select[name$="_addressType"]').val() == 'military');
        validator.init();
        util.AddressTypeChange($form);

        // select address from list
        $('select[name$="_addressList"]', $form).on('change', function () {
            util.updateStateOptions($("select[id$='_country']"), $('select[name$="_addressType"]').val() == 'military');
            validator.init();

            var selected = $(this).children(':selected').first();
            var selectedAddress = $(selected).data('address');
            if (!selectedAddress) { return; }

            var addressType = 'addressType' in selectedAddress ? selectedAddress.addressType : selectedAddress.custom.addressType;

            util.fillAddressFields(selectedAddress, $form);

            $form.find('select[name*="_addressType"]').val(addressType);
            $('.addressType').trigger('change');

            setTimeout(function() {
                var postOffice = $form.find("select[name*='_addressFields_postOffce']");
                var state = $form.find("select[name*='_addressFields_states_state']");
                var company = $form.find("select[name*='_addressFields_companyName']");

                if (addressType === 'military') {
                    postOffice.val(selectedAddress['city']).change();
                    state.val(selectedAddress['stateCode']).change();
                } else if (addressType === 'residential') {
                    state.val(selectedAddress['stateCode']).change();
                } else if (addressType === 'business') {
                    company.val(selectedAddress['companyName']);
                    state.val(selectedAddress['stateCode']).change();
                }
            }, 100);
        });
    },

    /**
     * Executes a callback function when the user has stopped resizing the screen.
     * @function
     * @param   {function}  callback
     * @example util.smartResize(function() { do stuff }
     */
    smartResize: function (callback) {
        // Eventlistener
        window.addEventListener('resize', util.debounce(callback, 100));
    },

    /**
     * @function
     * @desc Generates a min-width matchMedia media query based on the given params
     * @param {Number} size - Breakpoint to use for the media query
     * @example if (util.mediaBreakpointUp(737)) { do stuff }
     */
    mediaBreakpointUp: function (size) {
        const mediaQuery = window.matchMedia('(min-width: ' + size + 'px)');
        return mediaQuery.matches;
    }
};

module.exports = util;
