'use strict';

var dialog = require('./dialog');
var progress = require('./progress');
var validator = require('./validator');
var util = require('./util');

function trapModalFocus($modal, closeAction) {

    $modal.addClass('trapped');
    var focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
    var firstFocusableElement = $modal.find(focusableElements).first();
    var lastFocusableElement = $modal.find(focusableElements).last();

    document.addEventListener('keydown', function(e) {
        if (e.keyCode === 27) {
            if ($modal.hasClass('active')) {
                closeAction();
            }
        }

        var isTabPressed = e.key === 'Tab' || e.keyCode === 9;

        if (!isTabPressed) {
            return;
        }

        if (e.shiftKey) {
            if (document.activeElement === firstFocusableElement[0]) {
                lastFocusableElement.focus();
                e.preventDefault();
            }
        } else {
            if (document.activeElement === lastFocusableElement[0]) {
                firstFocusableElement.focus();
                e.preventDefault();
            }
        }
    });
}

function initStoreDetailsToggle() {
    $('body').off('click', '.bopis-show-details').on('click', '.bopis-show-details', function (e) {
        e.preventDefault();
        var $tooltip = $(this).siblings('.store-details-tooltip');

        $tooltip.addClass('active');
        $tooltip.attr('aria-expanded', true);
        $tooltip.find('.store-details-tooltip-close').focus();
        if (!$tooltip.hasClass('trapped')) {
            trapModalFocus($tooltip, function () { $tooltip.removeClass('active'); $tooltip.siblings('.bopis-show-details').focus(); });
        }
    });

    $('body').off('click', '.store-details-tooltip-close').on('click', '.store-details-tooltip-close', function (e) {
        e.preventDefault();
        var $tooltip = $(this).closest('.store-details-tooltip');
        $tooltip.removeClass('active');
        $tooltip.attr('aria-expanded', false);
        $tooltip.siblings('.bopis-show-details').focus();
    });
}

function openModal($lastactive, pid, zipCode, pliUUID) {
    progress.show('.bopis-selector');
    var url = util.appendParamsToUrl(window.Urls.getStoreModal, { pid: pid });
    if (zipCode) {
        url = util.appendParamsToUrl(url, { zipCode: zipCode });
    }
    if (pliUUID) {
        url = util.appendParamsToUrl(url, { pliUUID: pliUUID });
    }
    dialog.open({
        url: url,
        options: {
            dialogClass: 'bopis-store-select-modal',
            title: window.Resources.SELECT_STORE,
            width: 690,
            openInCallback: true,
            open: function () {
                validator.initForm($('#bopisSearchForm'));
                $('.bopis-selector .loader').remove();

                $(this).parent().attr('aria-modal', 'true');
                $(this).parent().attr('aria-label', $('.ui-dialog-title', $(this).parent()).text());
            },
            close: function () {
                $('.bopis-store-select-modal, .dialog-content').remove();
                $lastactive.focus();
            }
        }
    });
}

function toggleDisableAddToCartPDP() {
    if ($('#hf-pdpMain').length > 0) {
        var disableCTA = false;
        if ($('[id^="bopis-in-store"]:checked').siblings('label').find('.bopis-availability').hasClass('not-available')) {
            disableCTA = true;
        }

        if (disableCTA) {
            $('#add-to-cart').addClass('bopis-disabled').attr('disabled', 'disabled');
        } else if (!disableCTA && $('#add-to-cart').hasClass('bopis-disabled')) {
            $('#add-to-cart').removeClass('bopis-disabled').removeAttr('disabled');
        }
    }
}

function toggleDisableCartCTA() {
    // Check pickup in store availability
    if ($('.hf-cart').length > 0) {
        var disableCTA = false;
        $('[id^="bopis-in-store"]:checked').each(function () {
            if ($(this).siblings('label').find('.bopis-availability').hasClass('not-available')) {
                disableCTA = true;
            }
        });

        if (disableCTA) {
            $('.cart-cta').addClass('bopis-disabled').attr('disabled', 'disabled');
        } else if (!disableCTA && $('.cart-cta').hasClass('bopis-disabled')) {
            $('.cart-cta').removeClass('bopis-disabled').removeAttr('disabled');
        }
    }
}

function initStoreSelectModal() {
    // Removes css overay preventing selection before JS loads
    $('.bopis-selector').addClass('enabled');

    toggleDisableCartCTA();
    toggleDisableAddToCartPDP();

    $('body').off('click', '.not-selected:checked').on('click', '.not-selected:checked', function () {
        if ($(this).closest('.bopis-selector').data('preferred-store') === false) {
            var pid = $(this).closest('.bopis-selector').data('pid');
            openModal($(this), pid);
        }
    });

    $('body').off('change', '[name^="bopis-radio"]').on('change', '[name^="bopis-radio"]', function (e) {
        if ($(this).val() === 'instore' && $(this).closest('.bopis-selector').data('preferred-store') === false && $("#hf-pdpMain").length > 0) {
            var $this = $(this);
            var pid = $(this).closest('.bopis-selector').data('pid');
            openModal($(this), pid);
            window.setTimeout(function () {
                $this.addClass('not-selected');
            }, 200);
            $('.pdp-inventory-wrapper').addClass('d-none');
        } else if ($(this).val() === 'instore' && $(".hf-cart").length > 0) {
            progress.show();
            var pid = $(this).closest('.bopis-selector').data('pid');
            var pliUUID = $(this).data('pliuuid');
            var url = util.appendParamsToUrl(window.Urls.addStoreFromSession, { pliUUID: pliUUID });
            $(this).closest('.cart-pli-row').find('.availability-and-actions').addClass('d-none');
            $.ajax({
                url : url,
                dataType : 'json',
                success : function (data) {
                    if (data.success === true) {

                        window.location.reload();
                    } else if (data.success === false && data.openmodal === true) {
                        openModal($(this), pid, null, pliUUID); // no store data in session - open modal
                    } else {
                        window.location.reload();
                    }
                },
                failure : function (data) {
                    window.alert(window.Resources.SERVER_ERROR);
                    progress.hide();
                }
            });
        }

        if ($(".hf-cart").length > 0 && $(this).val() === 'ship') {
            var thisSelector = $(this).closest('.bopis-selector');
            var pliUUID = $(this).data('pliuuid');
            var url = util.appendParamsToUrl(window.Urls.removeStore, { pliUUID: pliUUID });
            progress.show(thisSelector);
            if ($(this).closest('.cart-pli-row').find('.availability-and-actions .is-in-stock').length === 0) {
                $(this).closest('.cart-pli-row').find('.availability-and-actions').removeClass('d-none');
            }
            $.ajax({
                url : url,
                dataType : 'json',
                success : function (data) {
                    if (data.success) {
                        progress.hide();
                    } else {
                        window.location.reload();
                    }
                },
                failure : function (data) {
                    window.alert(window.Resources.SERVER_ERROR);
                    progress.hide();
                }
            });
        } else if ($(this).val() === 'ship') {
            window.setTimeout(function () {
                $('[name^="bopis-radio"][value="instore"]').removeClass('not-selected');
            }, 200);
            $('.pdp-inventory-wrapper').removeClass('d-none');
        }

        $(this).closest('.bopis-selector').toggleClass('instore', $(this).val() === 'instore');

        toggleDisableCartCTA();
        toggleDisableAddToCartPDP();
    });

    $('body').off('click', '.bopis-select-store-modal-toggle').on('click', '.bopis-select-store-modal-toggle', function (e) {
        e.preventDefault();
        var pid = $(this).closest('.bopis-selector').data('pid');
        var zipCode = $(this).closest('.bopis-selector').data('zip');
        openModal($(this), pid, zipCode);
    });

    $('body').off('submit', '#bopisSearchForm').on('submit', '#bopisSearchForm', function(e) {
        e.preventDefault();
        var bopisSearchForm = $(this);
        var bopisContainer = $(this).parent();
        var bopisSearchResults = bopisContainer.find('.bopis-nearest-stores');
        var trimmedValue = $('#bopis-zip').val().trim();
        $('#bopis-zip').val(trimmedValue);

        if (bopisSearchForm.valid()) {
            var post = bopisSearchForm.serialize();
            var url = bopisSearchForm.attr('action');

            progress.show(bopisSearchForm);

            $.ajax({
                url : url,
                data : post,
                dataType : 'html',
                success : function (data) {
                    bopisSearchResults.html(data);
                    bopisSearchResults.show();
                    progress.hide();
                },
                failure : function (data) {
                    window.alert(window.Resources.SERVER_ERROR);
                    progress.hide();
                }
            });
        }
    });

    $('body').off('click', '.bopisSelectStore').on('click', '.bopisSelectStore', function(e) {
        var currentStore = $(this);
        e.preventDefault();
        var url = currentStore.attr('data-href');

        $.ajax({
            url : url,
            dataType : 'html',
            success : function (data) {
                dialog.close();

                if ($('.hf-cart').length > 0) {
                    progress.show();
                    window.location.reload();
                } else {
                    var container = $('.bopis-container');
                    container.html(data);
                    if ($('.bopis-selector').data('preferred-store') === true) {
                        $('[name^="bopis-radio"]').last().prop('checked', true);
                    }
                    $('.bopis-selector').addClass('enabled');
                    trapModalFocus($('.store-details-tooltip'), function () {
                        $('.store-details-tooltip').removeClass('active');
                        $('.store-details-tooltip').siblings('.bopis-show-details').focus();
                    });

                    toggleDisableAddToCartPDP();
                }
            },
            failure : function (data) {
                window.alert(window.Resources.SERVER_ERROR);
            }
        });
    });

    $('body').off('click', '.modal-store-details-toggle').on('click', '.modal-store-details-toggle', function() {
        var $toggledElement = $(this).siblings('.modal-store-details');

        $(this).toggleClass('active');
        $toggledElement.toggleClass('active');

        $toggledElement.attr('aria-expanded', $toggledElement.hasClass('active') ? true : false);
    });
}

module.exports = {
    init : function () {
        initStoreDetailsToggle();
        initStoreSelectModal();
    }
};

