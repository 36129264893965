'use strict';

var bonusproductsview = require('./bonusproductsview');
var util = require('./util');

//provides functionality around the mini cart
var $cache = {},
    initialized = false;

var timer = {
    id: null,
    clear: function () {
        if (timer.id) {
            window.clearTimeout(timer.id);
            delete timer.id;
        }
    },
    start: function (duration) {
        timer.id = window.setTimeout(minicart.close, duration);
    }
};

var minicart = {
    url: "", // during page loading, the Demandware URL is stored here

    /**
     * @function
     * @description Cache initializations and event binding to the mimcart
     */
    init: function () {
        $cache.minicart = $("#hf-mini-cart");
        $cache.mcMobile = $('.hf-mobile-cartbutton');
        $cache.mcTotal = $cache.minicart.find(".hf-mini-cart-total");
        $cache.mcContent = $cache.minicart.find(".hf-mini-cart-content");
        $cache.mcClose = $cache.minicart.find(".hf-mini-cart-close");
        $cache.mcProductList = $cache.minicart.find(".hf-mini-cart-products");
        $cache.mcProducts = $cache.mcProductList.children(".hf-mini-cart-product");

        //var collapsed = $cache.mcProductList.children().not(":first").addClass("collapsed");  /* Having this commented out prevents the collapsed state of product on mini cart */

        // bind events to the cart total link at the top right corner
        if (window.isMobileUserAgent) {
            minicart.updateMobileMinicartQuantity();
        }

        var $body = $('body');

        $body.on('keydown', function (e) {
            if (e.which === 27) {
                minicart.close();
            }
        });
        $body.on('click touch', '.hf-mini-cart-close, #overlay', minicart.close);
        $body.on('keydown', '.hf-mini-cart-close', function (e) {
            e.stopImmediatePropagation();
            e.preventDefault();

            if (e.which === 13) {
                minicart.close();
            } else if (e.which === 9) {
                var $el = $(this);
                $el.blur();
                if ($el.hasClass('keep-shopping')) {
                    $cache.mcContent.find('.just-added').focus();
                } else {
                    $cache.mcProducts.focus();
                }
            }
        });

        /* Having the below commented out prevents the collapse toggle from being displayed on minicart
        $cache.mcProducts.append('<div class="mini-cart-toggler">&nbsp;</div>');

        $cache.mcProductList.toggledList({toggleClass : "collapsed", triggerSelector:".mini-cart-toggler", eventName:"click"});
        */

        $(window).on('resize', function () {
            if ($cache.mcContent.hasClass('as-modal')) {
                minicart.centerModal();
            }
        });

        initialized = true;
    },
    /**
     * @function
     * @description Shows the given content in the mini cart
     * @param {String} A HTML string with the content which will be shown
     */
    show: function (html, options) {
        $cache.minicart.html(html);
        minicart.init();

        //ADA: set aria-modal=”true” because this ia modal
        $cache.mcContent.attr('aria-modal', 'true');
        $cache.mcContent.attr('role', 'modal');
        $cache.mcContent.attr('aria-label', $('.just-added', $cache.mcContent).text());

        if (options.asModal) {
            minicart.showAsModal(options.pid);
        } else {
            minicart.slide();
        }

        if (options.lastActive) {
            $cache.lastactive = options.lastActive;
        }

        $('.hf-mini-cart-content .just-added').focus();

        window.setTimeout(function () {
            bonusproductsview.loadBonusOption();
        }, 1000);
    },
    /** @function
     *  @description Initialize mouse/touch events for minicart
     *  @param {String} A string for the events to initialize the minicart dropdown to open on
     */
    eventsSetUp: function (events) {
        $('body').on('click touch', '.hf-mini-cart-close, #overlay', minicart.close);
    },
    centerModal: function () {
        var topOffset = (window.innerHeight / 2) - ($cache.mcContent.height() / 2);
        var leftOffset = (window.innerWidth / 2) - ($cache.mcContent.width() / 2);
        $cache.mcContent.css('top', topOffset + 'px');
        $cache.mcContent.css('left', leftOffset + 'px');
    },
    /**
     * @function
     * @description Slides down and show the contents of the mini cart
     */
    slide: function () {
        if (!initialized) {
            minicart.init();
        }

        if (minicart.suppressSlideDown && minicart.suppressSlideDown()) {
            return;
        }

        timer.clear();


        $("#overlay").css('display', 'block');
        // show the item
        $cache.mcContent.slideDown('slow');
        $cache.mcClose.slideDown('slow');
        // after a time out automatically close it
        timer.start(3000);
    },
    slideStayOpen: function () {
        if (!initialized) {
            minicart.init();
        }

        if (minicart.suppressSlideDown && minicart.suppressSlideDown()) {
            return;
        }

        timer.clear();

        // show the item
        $cache.mcContent.slideDown('slow');
        $cache.mcClose.slideDown('slow');
        if ($cache.mcContent.is(':visible')) {
            $("#overlay").css('display', 'block');
        }
    },
    showAsModal: function (pid) {
        $cache.mcContent.addClass('as-modal');

        $("#overlay").css('display', 'block');
        // show the item
        $cache.mcContent.show();
        $cache.mcClose.show();

        minicart.centerModal();

        if (pid) {
            // show added product
            if (Array.isArray(pid)) {
                pid.forEach(function (val) {
                    $cache.mcContent.find('[data-id="' + val + '"]').first().addClass('show');
                });
            } else {
                $cache.mcContent.find('[data-id="' + pid + '"]').first().addClass('show');
            }
        }
    },
    /**
     * @function
     * @description Closes the mini cart with given delay
     * @param {Number} delay The delay in milliseconds
     */
    close: function (delay) {
        timer.clear();
        $cache.mcContent.slideUp();
        $cache.mcClose.slideUp();

        if ($cache.mcContent.hasClass('as-modal')) {
            $cache.mcContent.removeClass('as-modal');
            $cache.mcContent.removeAttr('style');
        }

        $("#overlay").css('display', 'none');

        if ($cache.lastactive) {
            $cache.lastactive.focus();
            $cache.lastactive = null;
        }
    },
    /**
     * @function
     * @description Hook which can be replaced by individual pages/page types (e.g. cart)
     */
    suppressSlideDown: function () {
        return false;
    },

    updateMobileMinicartQuantity: function () {
        var $minicartQuantity = $('.hf-numberViewer');
        var $mobileMinicartQuantity = $('.hf-mobile-mini-cart-qty');
        if ($minicartQuantity.length) {
            $mobileMinicartQuantity.first().html($minicartQuantity.first().html());
        }
    }

};

module.exports = minicart;