'use strict';

var tealium = require('../tealium');

var $cache = {};

function initializeEvents() {
    $cache.subscribeButton.on('click', function(event) {
        event.preventDefault();
        //Clear error message text
        $cache.subscribeMessage.text('');
        if ( validateEmail( $cache.subscribeEmail.val() ) ) {
            //If firstname / last name are empty
            if(!$cache.subscribe.valid()) {
                return;
            }
            var action = $cache.subscribe.attr('action');
            var email = $cache.subscribeEmail.val();
            var firstName = $cache.subscribe.find("[name$=firstname]").val();
            var lastName = $cache.subscribe.find("[name$=lastname]").val();
            $.ajax({
                dataType : "json",
                url: action,
                data: {
                    'email': email,
                    'firstname': firstName,
                    'lastname': lastName
                }
            })
            .done(function (response) {
                var success = response.success;
                var message = response.message;
                var newClass = (response.success) ? 'success' : 'failed';
                $cache.subscribeMessage.removeClass().addClass(newClass).show().text(response.message);
                if (success) {
                    tealium.emailSignup(email);
                    $cache.subscribeEmail.attr('aria-invalid', 'false');
                } else {
                    $cache.subscribeEmail.attr('aria-invalid', 'true');
                    $cache.subscribeEmail.focus();
                }
            })
            .fail(function (xhr, textStatus) {
                // failed
                if(textStatus === "parsererror") {
                    window.alert(window.Resources.BAD_RESPONSE);
                } else {
                    window.alert(window.Resources.SERVER_CONNECTION_ERROR);
                }
            });
        } else {
            $cache.subscribeMessage.show().text(window.Resources.INVALID_EMAIL);
            $cache.subscribeEmail.attr('aria-invalid', 'true');
            $cache.subscribeEmail.focus();
        }
    });
}

function initializeCache() {
    $cache = {
        subscribe: $(".newsletter-form")
    };
    $cache.subscribeEmail = $cache.subscribe.find(".newsletter-email");
    $cache.subscribeButton = $cache.subscribe.find(".newsletter-submit").first();
    $cache.subscribeMessage = $cache.subscribe.find(".newsletter-signup-message");
}

function validateEmail(email) {
    var filter = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    if (filter.test(email)) {
         return true;
     } else {
        return false;
    }
}

var requestnewsletter = {
    init : function () {
        initializeCache();
        initializeEvents();
    }
};

module.exports = requestnewsletter;
