
/* global setTimeout */
'use strict';

var ajax = require('../../ajax');
var util = require('../../util');
var dialog = require('../../dialog');
var validator = require('../../validator');
var progress = require('../../progress');
var tooltip = require('../../tooltip');
var tealium = require('../../tealium');
var suggestedProducts = require('../../suggestedproducts');
var lazyload = require('../../lazyload');
var addressupdate = require('../address');

var $cache = {},
    shippingMethods = [];

var shipping = {
    initForm: function(skipChangeTrigger) {
      util.handleAddressTypeChange('.inc-address-list, .ship-to-single-wrapper');
      if (!skipChangeTrigger) {
          $('select[name$="_addressList"]', $('.inc-address-list, .ship-to-single-wrapper')).trigger('change');
      }
      util.handleRestrictions();
      $("input[name$='_address1']").attr('autocomplete', 'address-line1');
    },
    /**
     * @function
     * @description suggested products modal
     */
    suggestedProductsCheckout: function() {
        $('#ShippingRestrictionViewDialog .suggested-products, #ShippingRestrictionViewDialog .suggested-products-modal').show();
        dialog.create({
            target : '#ShippingRestrictionViewDialog',
            options : {
                dialogClass: 'suggested-products-dialog checkout-suggested-products',
                maxWidth: 1070,
                maxHeight: 1056,
                width: 'auto',
                modal: true,
                fluid: true,
                draggable: false,
                resizable: false,
                position : 'center',
                open : function () {
                    lazyload.reinit();
                    suggestedProducts.fluidDialog();
                    suggestedProducts.flipProduct();
                    $(window).resize(function () {
                        suggestedProducts.fluidDialog();
                    });
                    progress.hide();
                    $('.pdp-action').hide();
                    $('.ui-widget-overlay').css('position','fixed');
                    var $closeBar = $('.suggested-products-dialog a.ui-dialog-titlebar-close');
                    $closeBar.attr('tabindex', 0);
                    $closeBar.on('keyup' ,function (e) {
                        if (e.keyCode === 13) {
                            $('.suggested-products-dialog.checkout-suggested-products').dialog('close');
                        }
                    });
                    $('.ui-widget-overlay, .add-to-cart').on('click', function() {
                        $('.suggested-products-modal').dialog('close');
                    });
                },
                close: function () {
                    $('.ui-widget-overlay').css('position','absolute');
                    $('.button-replace').focus();
                }
            }
        });
    },

    //initialize the js events on the address form within the Avalara modal. must be re-initialized on each modal load.
    initializeAvalaraAddressForm: function($target, onSuccess, onError, onEdit, pageType) {
        var $forms = $(document).find('.edq-edit-address-form');

        $forms.each(function () {
            var form = $(this);
            form.on('click', '.submit-button', function (e) {
                e.preventDefault();
                var isValid = util.formCustomValidation(form);
                if (!isValid) {
                    return false;
                }
                var applyName = $(this).attr('name');
                var options = {
                    url: form.attr('action') + '?format=ajax',
                    data: form.serialize() + '&' + applyName + '=x',
                    type: 'POST'
                };
                $.ajax(options).done(function (data) {
                    if (typeof(data) !== 'string') {
                        if (data.success) {
                            shipping.acceptAndSubmit(data.address, onSuccess, pageType);
                        } else {
                            acceptAndError(data.address, onError, pageType);
                        }
                    } else {
                        //address was, again, unverified. reload this modal and re-init.
                        $target.html(data);

                        addressupdate.initStateDropdown();

                        shipping.initializeAvalaraAddressForm($target, onSuccess, onError, onEdit, pageType);
                    }
                });
            });
        });

        // change block to view editable address
        $target.find('.oldAddress .button').click(function () {
            $target.find('.oldAddress').slideToggle('slow');
            $target.find('.newAddress').slideToggle('slow');

        });
        //Edit address in AVS modal, submit
        $target.find('.addField.submit-button').click(function () {
            if ($target.find('form').length > 0) {
                if (!util.formCustomValidation($target.find('form'))) {
                    return false;
                }
                if (!$target.find('form').valid()) {
                    return false;
                }
            }
            $target.find('.oldAddress').slideToggle('slow');
            $target.find('.newAddress').slideToggle('slow');
            onEdit();
        });

        $target.find('.showHide').click(function () {
            $target.find('.picklist').slideToggle('slow');
        });
    },

    doCustomShipSelect: function(params, element) {
        // attempt to set shipping method
        var url = util.appendParamsToUrl(window.Urls.selectShippingMethodsList, params, true);

         ajax.getJson({
            url: url,
            callback: function (data) {

                if(!data || !data.shippingMethodID) {
                    console.log("Couldn't select shipping method.");
                    return false;
                }
                // display promotion in UI and update the summary section,
                // if some promotions were applied
                $(".shippingpromotions").empty();

                // update price and dates for multi ship
                if (element) {
                    var input = element.find('input');
                    var datesDiv = element.find('.estimated-arrival');
                    if (input.val() == data.shippingMethodID) {
                        if (!datesDiv.attr('data-shipondate')) {
                            if (data.specificArrival) {
                                datesDiv.attr('data-shipondate', data.specificArrival);
                            }
                            if (data.earliestArrivalDate) {
                                datesDiv.attr('data-earliestarrival', data.earliestArrivalDate);
                            }
                            if (data.latestArrivalDate) {
                                datesDiv.attr('data-latestarrival', data.latestArrivalDate);
                            }

                            if (data.specificArrival) {
                                var textToInsert = window.Resources.DATE_SPECIFIC + ' ' + data.specificArrival;
                                datesDiv.text(textToInsert);
                            } else if (data.earliestArrivalDate == data.latestArrivalDate) {
                                var textToInsert = window.Resources.DATE_LATEST + ' ' + data.latestArrivalDate;
                                datesDiv.text(textToInsert);
                            } else {
                                var textToInsert = window.Resources.DATE_RANGE + ' ' + data.earliestArrivalDate + ' - ' + data.latestArrivalDate;
                                datesDiv.text(textToInsert);
                            }
                        }

                        if (data.salePrice) {
                            element.find('.discount-shipping').text(data.salePrice);
                            element.find('.standard-shipping').addClass('strikethrough');
                        } else {
                            element.find('.discount-shipping').empty();
                            element.find('.standard-shipping').removeClass('strikethrough');
                        }
                        if (data.standardPrice) {
                            element.find('.standard-shipping').text(data.standardPrice);
                        }
                    }
                }

                shipping.updateSummary();
                progress.hide();
                tooltip.init();
            }
        });
    },

    /**
     * @function
     * @description selects the custom shipping method
     * @param
     */
    selectCustomShippingMethod: function(shipOnDate, shipmentId, shippingMethodId, address1, address2, city, postalCode, countryCode, stateCode, addressType, elememt) {
        //highlight the box on the page that represents this shipment and method
        var params = {
                countryCode:countryCode,
                stateCode:stateCode,
                postalCode:postalCode,
                city:city,
                shippingMethodID:shippingMethodId,
                shipOnDate: shipOnDate,
                shipmentId: shipmentId,
                addressType: addressType
            };

        shipping.doCustomShipSelect(params, elememt);
    },

    getDate3pm: function($shipmentList, cutoffHour) {
        var d = new Date();
        if(typeof $shipmentList !== 'undefined') {
            var $instockdate = $shipmentList.find('.furthest-product-instockdate');
            if($instockdate.length) {
                d = new Date( $instockdate.val() );
                return d;
            }
        }
        if (!cutoffHour) {
            cutoffHour = 15;
        }
        var utc = d.getTime() + (d.getTimezoneOffset() * 60000);
        var offset = -5; //eastern time offset
        var nd = new Date(utc + (3600000 * offset));

        var h = nd.getHours();

        if (h >= cutoffHour) {
            nd.setDate(nd.getDate() + 1);
            return nd;
        } else {
            return nd;
        }
    },

    ignoreRestriction: false,

    /**
     * @function initializeAvalaraFunctionality
     *
     * @description Initializes Avatax events depending on the forms needing address validation.
     *
     * @param {String} formorigin, indicates the selectors and urls for the different pages
     * used for address verification.
     *
     * @param {object} useParentModal Instance of a dialog object (defined in dialog.js) of the parent modal,
     * alread existing in the page, which should be populated with the Avatax AJAX response (as necessary). If
     * this is undefined or null, the functionality is used: a new modal will be created for this response.
     */
    initializeAvalaraFunctionality : function (formorigin, useParentModal) {

        var addressCheckClass = 'shipping-address-check-avalara',
            avalaraAJAXurl,
            $modalElement,
            $addressForm,
            $saveAddressBtn,
            avalaraSubmitStatus = false,
            useCleanedAddressOnVerified = true,
            useFrontEndValidation = false,
            useShippingRestrictions = false,
            onSuccess,
            onError,
            onEdit,
            originalAddressID = '';

        avalaraSubmitStatus = false;

        //insert timestamp into hidden AddressID field - rest of Avalara functionality requires an addressID
        var nicknameInput = $( "input[name*='addressid']");
        if (nicknameInput.length > 0) {
            //only generate timestamp if there is no existing value
            if (nicknameInput.val().length < 1) {
                nicknameInput.val(new Date().getTime());
            }
        }
        if (formorigin == 'singleshipping') {
            //SINGLE SHIPPING
            $addressForm = $('form[id$="_shippingAddress"]');
            $saveAddressBtn = $('button[name$="_shippingAddress_save"]');
            avalaraAJAXurl = window.Urls.avalaraValidateShippingAddress;
            useFrontEndValidation = true;
            useShippingRestrictions = true;
            if ($('input[name="editAddressUUID"]').length > 0) {
                originalAddressID = $('input[name="editAddressUUID"]').val();
            }
        } else if (formorigin == 'account') {
            // ACCOUNT ADDRESS
            // note different form id and save address button callout for refresh
            $addressForm = $('form[id$="address-form"]');
            $saveAddressBtn = $('button[class$="apply-button"]');
            avalaraAJAXurl = window.Urls.avalaraValidateAccountAddress;
            originalAddressID = $('input[id$="originalAddressID"]').val();
            useFrontEndValidation = true;
        } else if (formorigin == 'multisingleship') {
            $addressForm = $('form.checkout-multi-shipping');
            $saveAddressBtn = $('button[name$="_shippingAddress_savetoallqlis"]');
            avalaraAJAXurl = window.Urls.avalaraValidateShippingAddress;
            if ($('input[name="editAddressUUID"]').length > 0) {
                originalAddressID = $('input[name="editAddressUUID"]').val();
            }
            useFrontEndValidation = true;
            useShippingRestrictions = true;
        }


        if (!!useParentModal && useParentModal.exists && useParentModal.isActive) {
            $modalElement = useParentModal.$container;
        } else {
            //create modal and init dialog settings
            window.setTimeout(function() {
                $modalElement = $('<div></div>').attr('id','address-verification');
                $modalElement.dialog({
                    autoOpen: false,
                    resizable: true,
                    modal: true,
                    width: '500',
                    height: 'auto',
                    title: '<h1>' + window.Resources.ADDRESS_VERIFY_TITLE + '</h1>',
                    dialogClass: 'address-verification',
                    close: function(){
                        $('.' + addressCheckClass).focus();
                    },
                    open: function(){
                       $('a.ui-dialog-titlebar-close').attr('tabindex', 0).focus();
                    }

                });
                $('.address-verification').attr('aria-modal',true);
                $('.address-verification').attr('aria-label','address-verification');
            },2000);
        }

        $saveAddressBtn.addClass(addressCheckClass);

        onSuccess = function () {
            //Adjust to page jump from shippingMethodList changing
            var $continueButton = $('.hf-continue');
            if($continueButton.length) {
                $('html, body').scrollTop( ($continueButton.offset().top + 75) - ( $(window).height() - $continueButton.height() ) );
            }

            //close modal and finally submit the page's edited address form
            $modalElement.dialog('close');
            $saveAddressBtn.removeClass(addressCheckClass);
            var isValid = util.formCustomValidation($addressForm);
            if (isValid) {
                $saveAddressBtn.click();
            }
            $modalElement.html('');
        };

        onError = function () {
            // close modal but don't trigger button
            $modalElement.dialog('close');
            $modalElement.html('');
        };

        onEdit = function () {
            shipping.fillInDataFromAVSEdit($addressForm, $modalElement.find('.newAddress'));
        };

        $('select[name$="_singleshipping_addressList"]').on('change', function () {
            shipping.ignoreRestriction = true;
        });

        //Initial validation via AJAX
        $(document.body).off('click', '.' + addressCheckClass).on('click', '.' + addressCheckClass, function (e) {
            //don't do any AVS actions if the form is in error state
            if($('#address .select-address .select-styled').hasClass('error')){
                var $body = $('body');
                var headerHeight = window.isMobileUserAgent ? $('#hf-header').height() * 1.7 : $('.full-header-wrapper').height();
                $('html, body').animate({
                    scrollTop: (($('#address .error').offset().top - headerHeight))
                }, 500);
                return false;
            }
            // prevent additional avalara modal
            if (formorigin == 'multisingleship') {
                if ($('input[name$="_shipToMultipleAddresses"]').prop('checked')) {
                    return false;
                }
            }

            util.formCustomValidation($addressForm);
            if (!$addressForm.valid()) {
                var $body = $('body');
                var headerHeight = window.isMobileUserAgent ? $('#hf-header').height() * 1.7 : $('.full-header-wrapper').height();

                $('html, body').animate({
                    scrollTop: $('.pt_checkout .error:visible').first().offset().top - $body.offset().top - headerHeight
                }, 500);
                window.setTimeout(function () {
                    $('.pt_checkout .error:visible').first().focus();
                }, 500);

                return false;
            }
            // strip down to get the formlistitem index as i0, i1, etc.
            if (shipping.ignoreRestriction == false) {
                if(useFrontEndValidation && !$addressForm.valid() || useShippingRestrictions && $('.shipping-restrictions-msg').is(':visible')) {
                    var error = $('.error:first').offset().top,
                        header = $('.full-header-wrapper').height();
                    $('html, body').animate({
                        scrollTop: error - header
                    }, 500);
                    return false;
                }
            }

            var formindex = e.currentTarget.name;
            if (formindex != null) {
                formindex = formindex.replace('_saveShipment', '');
                formindex = formindex.replace('dwfrm_multishipping_shippingOptions_shipments_', '');
            } else {
                formindex = "";
            }
            // strip off the button name so we have a basis for updating the form fields in acceptAndSubmit() below
            var formid = e.currentTarget.name;
            if (formid != null) {
                formid = formid.replace('_saveShipment', '');
            }

            // The shipmentid is used in place of the passed in parameter map value when coming back in to
            // edit a previously entered multishipment address
            var shipmentid = formindex;
            if (shipmentid != null) {
                shipmentid = shipmentid.replace('i', '');
                var shipNum = parseInt(shipmentid, 10);
                if (isNaN(shipNum)) {
                    shipmentid = "0";
                } else {
                    shipNum = shipNum + 1;
                    shipmentid = shipNum.toString();
                }
            }
            var addressId = $addressForm.find("input[name$='_addressid']");
            if (addressId.length > 0) {
                addressId.val(addressId.val().trim());
            }

            if (formorigin == 'singleshipping' || formorigin == 'multisingleship') {
                if ($('input[name="editAddressUUID"]').length > 0) {
                    originalAddressID = $('input[name="editAddressUUID"]').val();
                }
            }

            var encodedOriginalAddressID = '';
            if (originalAddressID.length > 0 && originalAddressID !== 'null') {
                encodedOriginalAddressID = encodeURIComponent(originalAddressID);
            }

            var edqmetadata = $('input[name$="_address1"]').attr('edq-metadata');
            var experianAddress = false;
            if (edqmetadata !== undefined) {
                experianAddress = true;
            }
            $.ajax({
                type: 'POST',
                data: $addressForm.serialize() + '&format=ajax&formid=' + formid + '&formindex=' + formindex + '&shipmentid=' + shipmentid + '&originalAddressID=' + encodedOriginalAddressID + '&experianAddress=' + experianAddress,
                url: avalaraAJAXurl
            })
            .done(function (response) {
                if ($('.' + addressCheckClass).hasClass('giftsnap')) {
                    window.location = window.Urls.billing;
                } else if (typeof(response) === 'string') {
                    var $target;
                    //Address is not valid, so provide modal recommendations
                    $target = $modalElement;
                    $target.html(response);
                    $target.dialog('open');
                    addressupdate.initStateDropdown();

                    shipping.initializeAvalaraAddressForm($target, onSuccess, onError, onEdit, formorigin);
                    validator.init();
                } else if (response.success) {
                    //Address is valid or accepted as is by customer.
                    avalaraSubmitStatus = true;
                    if (useCleanedAddressOnVerified) {
                        shipping.acceptAndSubmit(response.address, onSuccess, formorigin);
                    } else {
                        onSuccess();
                    }
                } else if (response.success == false) {
                    if (response.error) {
                        window.alert(response.error);
                    } else {
                        window.alert('Error occurred while verifying your address.');
                    }

                }
            });

            if (avalaraSubmitStatus === false) {
                return false;
            }
        });

        $(document).click(function(e) {
            if ($(e.target).is($(document.getElementsByClassName('ui-widget-overlay')))) {
                $modalElement.dialog('close');
             }
        });

    },

    fillInDataFromAVSEdit: function ($addressForm, $avsAddressBlock) {
         $addressForm.find('input[name$="_address1"]').val($avsAddressBlock.find('input[name$="_addForm_add1"]').val());
         $addressForm.find('input[name$="_address2"]').val($avsAddressBlock.find('input[name$="_addForm_add2"]').val());
         $addressForm.find('input[name$="_city"]').val($avsAddressBlock.find('input[name$="_addForm_city"]').val());
         $addressForm.find('input[name$="_state"]').val($avsAddressBlock.find('input[name$="_addForm_state"]').val());
         $addressForm.find('input[name$="_zip"]').val($avsAddressBlock.find('input[name$="_addForm_zip"]').val());
    },
    /**
     * @function
     * @description updates the order summary based on a possibly recalculated basket after a shipping promotion has been applied
     */
    updateSummary: function() {
        var shipOnDate = $("input[name='shipOnDate']");
        if (!shipOnDate || shipOnDate.length === 0) {
            shipOnDate = $('.shipping-method-radio:checked').parent().find('.shipping-info').find('.estimated-arrival').data('shipondate');
        } else {
            shipOnDate = shipOnDate.val();
        }

        var url = window.Urls.summaryRefreshURL;

        var params = {
            shipOnDate:shipOnDate
        };

        var url = util.appendParamsToUrl(window.Urls.summaryRefreshURL, params, true);

        var summary = $("#hf-secondary .order-summary-wrapper");
        if(summary.length == 0) { //cart page now been added to dynamic update
            summary = $("#osummary");
            $(".cart-coupon-code").remove(); //returned summary has apply promotion code button
        }

        // load the updated summary area
        summary.load( url, function () {
            // hide edit shipping method link
            summary.find('.hf-checkout-mini-cart .minishipment .header a').hide();
            summary.find('.hf-order-totals-table .order-shipping .label a').hide();
        });
    },

    /**
     * @function
     * @description Make an AJAX request to the server to retrieve the list of applicable shipping methods
     * based on the merchandise in the cart and the currently entered shipping address
     * (the address may be only partially entered).  If the list of applicable shipping methods
     * has changed because new address information has been entered, then issue another AJAX
     * request which updates the currently selected shipping method (if needed) and also updates
     * the UI.
     */
    updateShippingMethodList: function(elmIndex, callBackAfterUpdatingList) {
        var refreshAll = elmIndex === undefined;

        elmIndex = elmIndex ? elmIndex : 0;

        if (!$cache.shippingMethodList || $cache.shippingMethodList.length === 0) { return; }

        var shippingMethodList = refreshAll ? $cache.shippingMethodList : $($cache.shippingMethodList[elmIndex]);

        if (shippingMethodList.length > 1) {
            shippingMethodList.each(function(i) {
                shipping.updateShippingMethodList(i);
            });
        } else {

            var url = getShippingMethodURL(window.Urls.shippingMethodsJSON, shippingMethodList, elmIndex);

             ajax.getJson({
                url: url,
                callback: function (data) {
                    if (!data) {
                        window.alert("Couldn't get list of applicable shipping methods.");
                        return false;
                    }

                    // if the data array is empty then unhide the shipping restriction block
                    var shippingMethodList = $cache.shippingMethodList;
                    var formblock = shippingMethodList.parents('form.address');
                    var container = formblock.find('div.hf-restrictions-checkout');
                    var msg = formblock.find('div.shipping-restrictions-msg');

                    if (msg.length == 0) {
                        msg = container.find(":hidden");
                    }

                    if (msg.length) {
                        if (data.length === 0) {
                            msg.removeClass('hidden');
                        } else {
                            msg.addClass('hidden');
                        }
                    }

                    // We need to update the UI.  The list has changed.
                    // Cache the array of returned shipping methods.
                    shippingMethods[elmIndex] = data;

                    var smlUrl = getShippingMethodURL(window.Urls.shippingMethodsList, shippingMethodList, elmIndex);

                    // indicate progress
                    progress.show(shippingMethodList);

                    // load the shipping method form
                    shippingMethodList.load(smlUrl, function () {
                        shippingMethodList.fadeIn("fast");

                        // update the summary
                        progress.hide();
                        tooltip.init();
                        shipping.updateSummary();

                        if (!$cache.shippingMethodList.find('.form-row').children().children('input').is(':checked')) {
                            var array = [];

                            $('.shipping-boxes label span').each(function () {
                                var shippingNum = $(this).text().replace(/[^\d\.]/g, '');
                                array.push(shippingNum);
                            });

                            var minShip = Math.min.apply(Math, array);

                            $('.shipping-boxes label span').each(function () {
                                var shippingCost = $(this).text().replace(/[^\d\.]/g, '');
                                if (shippingCost == minShip) {
                                    $(this).parent().next().find('input.input-radio').attr("checked", "checked");
                                }
                            });
                        }

                        var selectedShip = $cache.shippingMethodList.find('.form-row.shipping-boxes').not('.border');
                        var FODisSet = false;
                        if ($(this).find('.select-shipping-container.border').length) {
                            FODisSet = true;
                        }

                        var $shippingCheckboxes = $cache.shippingMethodList.find('input.input-radio');

                        $(selectedShip).each(function () {
                            var $this = $(this);
                            var checkedInput = $this.find('input:checked');
                            var initialShippingId = checkedInput.attr('value');
                            if ($this.children().children('input:checked')) {
                                var $formRow = checkedInput.parents().parents('.form-row');
                                var latestArrival = $formRow.find('.estimated-arrival').data('latestarrival');
                                $formRow.addClass('border');
                                $formRow.closest('fieldset').find('input[name$="latestArrival"]').val(latestArrival);
                            }

                            //On load, If it is a 'specific' day shipping method (ex: delivery by Christmas)
                            if (!FODisSet || typeof (initialShippingId) !== 'undefined' && initialShippingId.indexOf('64M') == -1) {
                                $cache.shippingMethodList.find('.shipdate-delay-message').show();
                            }

                            $this.on('click', function (e) {
                                $this.find('input:radio').prop('checked', 'checked');
                                $this.find('input:radio').trigger('change');
                            });
                        });

                        var selectedCheckbox = $cache.shippingMethodList.find('input.input-radio:checked');
                        var lastSelectedCheckbox = selectedCheckbox;

                        $shippingCheckboxes.off().on('change', function (e) {
                            if (selectedCheckbox.val() === $(this).val()) {
                                return;
                            }

                            selectedCheckbox = $(this);

                            var $shippingWrapper = $(this).closest('.shipping-boxes');

                            //find the selected shipping id
                            //if the shipping id is not custom.  custom can only be clicked
                            //when modal is saved
                            var shippingId = $(this).val();
                            if (shippingId != "null" && shippingId != '64S') {
                                var shipOnDate = $shippingWrapper.find('.estimated-arrival').data('shipondate') || '';
                                selectShippingMethod(shippingId, shipOnDate, shippingMethodList, elmIndex);
                            } else {
                                lastSelectedCheckbox.prop('checked', 'checked');
                                lastSelectedCheckbox.focus();
                                lastSelectedCheckbox.trigger('change');
                                return false;
                            }

                            //If it is a 'specific' day shipping method (ex: delivery by Christmas)
                            if (shippingId.indexOf('64M') != -1) {
                                $cache.shippingMethodList.find('.shipdate-delay-message').hide();
                            } else {
                                $cache.shippingMethodList.find('.shipdate-delay-message').show();
                            }

                            $('#hf-shipping-method-list fieldset > .shipping-boxes.border, .select-shipping-container.border').removeClass('border');
                            var latestArrival = $shippingWrapper.find('.estimated-arrival').data('latestarrival');
                            $shippingWrapper.addClass('border');
                            $shippingWrapper.closest('fieldset').find('input[name$="latestArrival"]').val(latestArrival);

                            lastSelectedCheckbox = $(this);
                        });

                        if (typeof callBackAfterUpdatingList === 'function') {
                            callBackAfterUpdatingList();
                        }
                        resizeFutureDateShipMethod();
                    });
                }
            });
        }
    },

    giftMessageCheckboxClick: function(e, isSingleShipping, shipmentID) {
        if ($(e.target).prop('checked') == false) {
            return;
        }

        var url = isSingleShipping ? window.Urls.giftMessageDialog : window.Urls.giftMessageDialogMultiship;
        e.preventDefault();

        var dialogOptions = {
            "width": 910,
            "dialogClass": "gift-message-modal",
            "modal" : true,
            "title": window.Resources.GM_DIALOG_TITLE,
            close: function () {
                $('input[type="checkbox"][name$="_isGift"]').focus();
            },
        };

        $cache.giftMessageDialog = dialog.create({
            options: dialogOptions
        });

        ajax.load({
            url: url,
            target: $cache.giftMessageDialog,
            callback: function () {
                $cache.giftMessageDialog.dialog("open");
                util.limitCharacters();
                validator.init();
                $('body').on('click', '.gift-message-cta', function (e) {
                    shipping.saveGiftMessageRequest($(this), $cache.giftMessageDialog);
                });

            },
            data: {shipmentID: shipmentID}
        });
    },

    saveGiftMessageRequest: function(actionSource, currentDialog) {
        var url = $(actionSource).closest("form").attr("action") || $(actionSource).attr("href") || null;

        if (!url) { return; }

        var form = actionSource.parents('form'),
            postData = form.serialize() + "&" + actionSource.attr("name") + "=submit";

        if (currentDialog) {
            progress.show(currentDialog);
        }

        ajax.getJson({
            url: url,
            callback: function (response) {
                if (currentDialog) {
                    progress.hide(currentDialog);
                    currentDialog.dialog('close');
                    $('.shipment-is-gift input[type="checkbox"]').focus();
                }
            },
            data : postData,
            type: 'POST'
        });
    },

    /**
     * @function
     * @description Selects the first address from the list of addresses
     */
    addressLoad: function() {
        // select address from list
        var menu = $cache.addressList;
        var multiForm = menu.attr('data-shipmentloopcount');
        var elmIndex = (typeof multiForm !== typeof undefined && multiForm !== false) ? menu.data('shipmentloopcount') - 1 : 0;
        var selected = $(".hf-select-address select option:selected");
        var data = $(selected).data("address");
        var countryCode  = multiForm ? $($cache.countryCode[elmIndex]) : $cache.countryCode;
        var addressType = addressupdate.updateAddressTypeValue(data, multiForm, elmIndex, $cache);

        $cache.addressList.on("change", function () {
            var menu = $(this);
            var multiForm = menu.attr('data-shipmentloopcount');
            var $singleAddressForm = menu.parents('.inc-address-list');
            var elmIndex = (typeof multiForm !== typeof undefined && multiForm !== false) ? menu.data('shipmentloopcount') - 1 : 0;
            var selected = menu.children(":selected").first();
            var data = $(selected).data("address");
            var addressList = $("#dwfrm_singleshipping_addressList");
            var billingAddressList = $('#dwfrm_billing_addressList');

            //if shipping or billing list was just given a value, show the rest of the fields and hide the instructions
            if ((typeof addressList.val() != 'undefined' && addressList.val() != '') || (typeof billingAddressList.val() != 'undefined' && billingAddressList.val() != '')) {
                $('#address').removeClass('hidden-fields');
                //insert timestamp into hidden AddressID field - rest of Avalara functionality requires an addressID
                if ($("#address input[name*='addressid']").val().length < 1) {
                    $("#address input[name*='addressid']").val(new Date().getTime());
                }
                $('#address .select-address .select-styled, .checkoutmultishipping .custom-select .select-styled').removeClass('error');
            } else {
                $('#address').addClass('hidden-fields');
                $('#address .select-address .select-styled, .checkoutmultishipping .custom-select .select-styled').addClass('error');

            }

            if (selected.val() === 'newAddress' || !data) {
                $singleAddressForm.find('.input-select').not(menu).find('option:first-child').prop('selected', true).trigger('change'); //reset dropdowns
                $singleAddressForm.find('.input-text').not("#address input[name*='addressid']").val('').trigger('change'); //clear fields
                if ($singleAddressForm.find('input[name="editAddressUUID"]').length > 0) {
                    $singleAddressForm.find('input[name="editAddressUUID"]').val('');
                }
                $singleAddressForm.clearValidation(); //reset validation to original state
            } else {
                var p;
                var addressname = data.ID ? data.ID : data.custom.name;
                if (addressname) {
                    $cache.addressid.val(addressname);
                }
                $("#address input[name$='_address2']").val('').removeClass('float-label').prev('label').removeClass('float-label');
                var needRefreshShipments = false;

                for (p in data) {
                    if ($cache[p] && data[p]) {
                        var cachedField = multiForm ? $($cache[p][elmIndex]) : $cache[p];
                        cachedField.val(data[p].replace("^","'")).trigger('blur');
                        // special handling for countrycode => stateCode combo
                        var countryCode  = multiForm ? $($cache.countryCode[elmIndex]) : $cache.countryCode;
                        var addressType = addressupdate.updateAddressTypeValue(data, multiForm, elmIndex, $cache);

                        if (cachedField.attr('id') === addressType.attr('id')) {
                            addressType.trigger("change");
                        } else if (cachedField.attr('id') === countryCode.attr('id')) {
                            util.updateStateOptions(cachedField, data.addressType === 'military');
                        }
                    }
                }

                // re-validate the form
                if ($('.billing-address-section').length < 1) {
                    $cache.checkoutForm.validate().form();
                } else {
                    $('.inc-address-list').validate().form();
                }

            }
        });

        function isShippingFormEmpty() {
            var isEmpty = true;
            if ($('input[name$="_address1"]').val() != '' &&
                $('input[name$="_city"]').val() != '' &&
                $('input[name$="_zip"]').val() != '' &&
                $('select[name$="_state"]').val() != '') {
                isEmpty = false;
            }
            return isEmpty;
        }

        // update state options in case the country changes
        $cache.countryCode.on("change", function () {
            util.updateStateOptions(this, addressType.val() === 'military');
        });

        if(countryCode.val() == 'CA') {
            $cache.countryCode.trigger('change');
        }

        util.AddressTypeChange();

        $(document).on('click', '#editProduct', function(e) {
            e.preventDefault();
            editReplaceProduct(this);
        });

        function checkEmail() {
            if($cache.email.val() != '' && !$cache.email.hasClass('error')) {
                $cache.email.closest('.form-row').addClass('verified-email');
            } else {
                if($cache.email.closest('.form-row').hasClass('verified-email')){
                    $cache.email.closest('.form-row').removeClass('verified-email');
                }
            }
        }
        checkEmail();
        $cache.email.on('blur', function() {
            checkEmail();
        });
        resizeFutureDateShipMethod();
    },

    acceptAndSubmit: function (address, callback, pageType) {
        // Populate the parent page's form fields in case they were updated through one or more iterations of the modal,
        // or the useCleanedAddressOnVerified option is set to true.
        if (address) {
            $('input[name$="_address1"]').val($.trim(address.add1));
            $('input[name$="_address2"]').val($.trim(address.add2));
            $('input[name$="_city"]').val($.trim(address.city));
            $('input[name$="_postOffce"]').val($.trim(address.city));
            $('select[name$="_state"]').val($.trim(address.state));
    
            var testpostal = $('input[name$="_postal"]');
            if (testpostal.length > 0) {
                $('input[name$="_postal"]').val($.trim(address.postal).toUpperCase());
            } else {
                // assume the field is labelled zip
                $('input[name$="_zip"]').val($.trim(address.postal).toUpperCase());
            }
        }
        var isValid = util.formCustomValidation($('.edq-edit-address-form'));
        if (!isValid) {
            return false;
        }
    
        if (typeof callback === 'function') {
            if(typeof(pageType) === 'string' && pageType == 'singleshipping') {
                shipping.updateShippingMethodList(null, callback);
            }else{
                callback();
            }
        }
    },

    /**
     * @function
     * @description Returns the cache shared throughout the checkout process (single ship, multiship address, multiship shippingmethods, billing)
     */
    getCheckoutCache: function() {
        var $cache = {};
        $cache.giftMessageDialog = null;
        $cache.email = $("input[name$='email_emailAddress']");
        $cache.checkoutForm = $("form.address");
        $cache.addressid = $cache.checkoutForm.find("input[id$='_addressid']");
        $cache.addressType = $cache.checkoutForm.find("select[id$='_addressType']");
        $cache.companyName = $cache.checkoutForm.find("input[name$='_companyName']");
        $cache.firstName = $cache.checkoutForm.find("input[name$='_firstName']");
        $cache.lastName = $cache.checkoutForm.find("input[name$='_lastName']");
        $cache.address1 = $cache.checkoutForm.find("input[name$='_address1']");
        $cache.address2 = $cache.checkoutForm.find("input[name$='_address2']");
        $cache.city = $cache.checkoutForm.find("input[name$='_city']");
        $cache.postOffice = $cache.checkoutForm.find("select[name$='_postOffce']");
        $cache.postalCode = $cache.checkoutForm.find("input[name$='_zip']");
        $cache.phone = $cache.checkoutForm.find("input[name$='_phone']");
        $cache.countryCode = $cache.checkoutForm.find("select[id$='_country']");
        $cache.stateCode = $cache.checkoutForm.find("select[id$='_state']");
        return $cache;
    }
};

/*               Private Functions               */
//Shipping module only cache
function initializeCache() {
    //Get cache that is shared between checkout flow
    $cache = shipping.getCheckoutCache();

    //Add Shipping.js specific cache selectors here
    $cache.addressList = $cache.checkoutForm.find(".hf-select-address select[id$='_addressList']");
    $cache.shippingMethodList = $("div[id^='hf-shipping-method-list']");
}

function acceptAndError (address, callback, pageType) {
    // Populate the parent page's form fields in case they were updated through one or more iterations of the modal,
    // then trigger an error message for a bad address
    if (address.multiship == 'true') {
        $('input[name$="' + address.formid + '_addressFields_address1"]').val($.trim(address.add1));
        $('input[name$="' + address.formid + '_addressFields_address2"]').val($.trim(address.add2));
        $('input[name$="' + address.formid + '_addressFields_city"]').val($.trim(address.city));
        $('input[name$="' + address.formid + '_addressFields_zip"]').val($.trim(address.postal).toUpperCase());
        $('select[name$="' + address.formid + '_addressFields_states_state"]').val($.trim(address.state)).trigger('change');
    } else {
        $('input[name$="_address1"]').val($.trim(address.add1));
        $('input[name$="_address2"]').val($.trim(address.add2));
        $('input[name$="_city"]').val($.trim(address.city));
        $('input[name$="_zip"]').val($.trim(address.postal).toUpperCase());
        $('select[name$="_state"]').val($.trim(address.state)).trigger('change'); //trigger just in case it's already a select element with events
    }
    // show the error for address change impacting shipping method choice.
    $('div.error-form').removeClass('hidden');

    if (typeof callback === 'function') {
        if(typeof(pageType) === 'string' && pageType == 'singleshipping') {
            shipping.updateShippingMethodList(null, callback);
        }else{
            callback();
        }
    }
}

/**
 * @function
 * @description Helper method which constructs a URL for an AJAX request using the
 * entered address information as URL request parameters.
 */
function getShippingMethodURL(url, shippingMethodList, elmIndex) {

    elmIndex = elmIndex ? elmIndex : 0;

    var countryCode = $($cache.countryCode[elmIndex]);
    var stateCode = $($cache.stateCode[elmIndex]);
    var postalCode = $($cache.postalCode[elmIndex]);
    var city = $($cache.city[elmIndex]);
    var address1 = $($cache.address1[elmIndex]);
    var address2 = $($cache.address2[elmIndex]);
    var postOffice = $($cache.postOffice[elmIndex]);
    var addressType = $($cache.addressType[elmIndex]);

    // HF-1034 added logic to capture post office for city for military addresses
    var cityValueToUse = city.val();
    if (postOffice.val() && !city.val()) {
        cityValueToUse = postOffice.val();
    }

    var params = {
        countryCode:countryCode.val(),
        stateCode:stateCode.val(),
        postalCode:postalCode.val(),
        city:cityValueToUse,
        address1:address1.val(),
        address2:address2.val(),
        addressType:addressType.val()
    };

    var shipmentId = shippingMethodList.data('shipmentid');

    if (typeof shipmentId !== typeof undefined && shipmentId !== false) {
        params.shipmentId = shippingMethodList.data('shipmentid');
        params.shipmentLoopCount = shippingMethodList.data('shipmentloopcount');
    }

    var newUrl = util.appendParamsToUrl(url, params, true);

    return newUrl;
}

/**
 * @function
 * @description selects a shipping method for the default shipment and updates the summary section on the right hand side
 * @param
 */
function selectShippingMethod(shippingMethodID, shipOnDate, shippingMethodList, elmIndex) {

    elmIndex = elmIndex ? elmIndex : 0;

    var countryCode = $($cache.countryCode[elmIndex]);
    var stateCode = $($cache.stateCode[elmIndex]);
    var postalCode = $($cache.postalCode[elmIndex]);
    var city = $($cache.city[elmIndex]);
    var address1 = $($cache.address1[elmIndex]);
    var address2 = $($cache.address2[elmIndex]);
    var postOffice = $($cache.postOffice[elmIndex]);
    var addressType = $($cache.addressType[elmIndex]);

    // nothing entered
    if(!shippingMethodID) {
        return;
    }

    var cityValueToUse = city.val() || postOffice.val();

    var params = {
        countryCode:countryCode.val(),
        stateCode:stateCode.val(),
        postalCode:postalCode.val(),
        address1:address1.val(),
        address2:address2.val(),
        city:cityValueToUse,
        shippingMethodID:shippingMethodID,
        shipmentId: shipmentId,
        shipOnDate: shipOnDate,
        addressType: addressType.val()
    };

    var shipmentId = shippingMethodList.data('shipmentid');

    if (typeof shipmentId !== typeof undefined && shipmentId !== false) {
        params.shipmentId = shippingMethodList.data('shipmentid');
    }

    shipping.doCustomShipSelect(params);
    resizeFutureDateShipMethod();
}

function editReplaceProduct(link) {
    var jqlink = $(link);
    var pid = jqlink.attr('pid');
    var plitemid = jqlink.attr('plitemid');
    var plid = jqlink.attr('plid');
    var shipid = jqlink.attr('shipid');
    var data = {pid : pid, plitemid : plitemid, plid : plid, shipid : shipid};
    var postData = $('form[id$="shippingAddress"]').serialize();
    postData += "&pid=" + pid + "&plitemid= " + plitemid + "&plid=" + plid + "&shipid=" + shipid;
    var tealiumReplacementData = jqlink.data('tealium-replacement');

    $.ajax({
        method: 'GET',
        url: window.Urls.editProduct,
        data: postData,
        dataType: 'json'
    }).done( function(response, textStatus, request) {
        response = jQuery.parseJSON(response);
        updateOnReplace();
        tealium.cartAdd(tealiumReplacementData, null);
    }).fail( function() {
        updateOnReplace();
        tealium.cartAdd(tealiumReplacementData, null);
    });
}

function updateOnReplace() {
    var $miniCartContent = $('.hf-mini-cart-content');
    //Clear title
    $('#ui-dialog-title-ShippingRestrictionViewDialog').html('');
    //Replace modal with sucess message
    $('#ShippingRestrictionViewDialog .recommendations').hide();
    $('.replace-success-asset').show();

    //Re center
    $cache.shippingrestrictiondialogview.dialog('option', 'position', 'center');

    shipping.updateShippingMethodList();
    shipping.updateSummary();
    $miniCartContent.load(window.location + ' .hf-mini-cart-content > *');
    resizeFutureDateShipMethod();
}

function resizeFutureDateShipMethod() {
    if($('.select-shipping-date').height() > 65 && !$('.select-shipping-container-me').hasClass('addHeight')) {
        $('.select-shipping-container-me').addClass('addHeight');
    }
}

initializeCache();

module.exports = shipping;
