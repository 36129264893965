'use strict';

var _ = require('lodash');
var $cache = {};
var storefront = {
    init : function () {
        $cache = {
            wrapper      : $('#hf-wrapper'),
            homeSlider   : $('.home-slider-slides'),
            homeMarketingContent: $('.home-marketing-content-slides'),
            secondaryHomeMarketSlider: $('.home-marketing-content-2 .market-body'),
            SliderImgs   : $('.home-slider img.desktop'),
            mSliderImgs  : $('.home-slider img.mobile'),
            productInfo  : $('.hf-product-info'),
            topCarousel  : $('.hf-featured-content #hf-main'),
            blogSlider   : $('.hf-blog-content'),
            blogSocial   : $('.hf-content.social'),
            timelineSlider: $('.hf-timeline-container')
        };

        storefront.readMore($('.hf-rm-seo'));

        var homeSliderSpeed = 3000;
        if(window.Preferences.homeSliderSpeed != null) {
            homeSliderSpeed = window.Preferences.homeSliderSpeed;
        }

        function homeMarketingContent() {
            var width = window.innerWidth;

            if(width >= 2610){
                if($cache.homeMarketingContent.hasClass('slick-initialized')){
                    $cache.homeMarketingContent.slick('unslick', 0);
                }
                $cache.homeMarketingContent.find('.slide').css('display','inline-block');
            } else {
                if(!$cache.homeMarketingContent.hasClass('slick-initialized')){
                    $cache.homeMarketingContent.slick({
                        arrows: true,
                        appendArrows: $('.home-marketing-arrows-nav'),
                        dots: false,
                        slide: ".home-marketing-content-slides div",
                        mobileFirst: true,
                        lazyLoad: 'ondemand',
                        slidesToShow: 1,
                        centerMode: true,
                        variableWidth: true,
                        responsive: [
                            {
                               breakpoint: 2610,
                               settings: "unslick"
                            }
                         ]
                    });
                    $cache.homeMarketingContent.find('.slide').css('display','inline-block');
                    $cache.homeMarketingContent.slick('slickGoTo', 0);
                }
                
                if(!$cache.secondaryHomeMarketSlider.hasClass('slick-initialized')){
                    $cache.secondaryHomeMarketSlider.slick({
                        arrows: true,
                        dots: false,
                        slide: ".market-slide",
                        mobileFirst: true,
                        lazyLoad: 'ondemand',
                        slidesToShow: 1,
                        infinite: false,
                        accessibility: true,
                        responsive: [
                            {
                              breakpoint: window.Constants.MOBILE_BREAKPOINT,
                              settings: "unslick"
                            }
                         ]
                    });
                    window.setTimeout(function(){
                        $cache.secondaryHomeMarketSlider.find('[tabindex="-1"]').removeAttr('tabindex');
                    }, 0);
                }
            }
        }

        homeMarketingContent();

        function homeMainSlider() {
            if(!$cache.homeSlider.hasClass('slick-initialized')){
                $cache.homeSlider.slick({
                    lazyLoad: 'ondemand',
                    arrows: false,
                    dots: true,
                    slide: ".home-slider-slides div",
                    mobileFirst: true,
                    instructionsText: window.Resources.STORE_CAROUSEL_ADA
                });
            }

            $('a.home-slider-link').each(function() {
                $(this).insertBefore($(this).parent());
            });
        }
        
        homeMainSlider();
        
        function sliderImgs() {
            if ($(window).width() <= 736) {
                $cache.SliderImgs.hide();
                $cache.mSliderImgs.css('display', 'block');
            }else {
                $cache.mSliderImgs.hide();
                $cache.SliderImgs.css('display', 'block');
            }
        }

        sliderImgs();

        $cache.wrapper.on("click", "ul.tabs li", function (e) {
            e.preventDefault();
            var tab_id = $(this).attr('data-tab');
            $('ul.tabs li').removeClass('current');
            $('.tab-content').removeClass('current');
            $(this).addClass('current');
            $("#" + tab_id).addClass('current');
            $('.tab-content').resize();
            tabCarousel();
        });

        tabCarousel();
        function tabCarousel() {
            $('.tab-content.current .tab-carousel').each(function() {
                $(this).not('.slick-initialized').slick({
                    lazyLoad: 'ondemand',
                    arrows: true,
                    slidesToShow: 3,
                    responsive: [
                     {
                       breakpoint: 737,
                       settings: {
                           dots: true,
                           appendDots: $('.tab-content.current .slider-nav'),
                           appendArrows: $('.tab-content.current .slider-nav'),
                           slidesToShow: 1,
                           slidesToScroll: 1
                       }
                     },
                     {
                       breakpoint: 769,
                       settings: {
                           slidesToShow: 2,
                           slidesToScroll: 1
                       }
                     }
                   ]
                });
            });
        }

        favsCarousel();
        function favsCarousel() {
            $('.favorites-carousel').each(function() {
              if ($(this).find('.slide').length > 2) {
                  $('.favorites-carousel .tab-carousel').each(function() {
                        $(this).slick({
                            lazyLoad: 'ondemand',
                            arrows: true,
                            slidesToShow: 2,
                            responsive: [
                             {
                               breakpoint: 737,
                               settings: {
                                   dots: true,
                                   appendDots: $('.favorites-content .sl-buttons'),
                                   appendArrows: $('.favorites-content .slider-nav'),
                                   slidesToShow: 1,
                                   slidesToScroll: 1
                               }
                             }
                           ]
                        });
                    });
              }else{
                  $('.slide').addClass('one').show();
              }
            });
        }

        anniCarousel();
        function anniCarousel() {
            $('.anni-tab-content .tab-carousel').each(function() {
                $(this).slick({
                    lazyLoad: 'ondemand',
                    arrows: true,
                    slidesToShow: 3,
                    responsive: [
                     {
                       breakpoint: 737,
                       settings: {
                           dots: true,
                           appendDots: $('.anni-tab-content .sl-buttons'),
                           appendArrows: $('.anni-tab-content .slider-nav'),
                           slidesToShow: 1,
                           slidesToScroll: 1
                       }
                     },
                     {
                       breakpoint: 769,
                       settings: {
                           slidesToShow: 2,
                           slidesToScroll: 1
                       }
                     }
                   ]
                });
            });
        }

        $cache.productInfo.on('click', '.hf-view', function () {
            var el = $(this).parent().children('div.hf-product-contents'),
            curHeight = el.height(),
            autoHeight = el.css('height', 'auto').height(),
            fixedHeight = el.css('height', '105px').height();

            $(this).closest('.hf-view-more').toggle();
            $(this).closest('.hf-view-less').toggle();

            if($(this).closest('.hf-view-more').is(":visible")) {
                el.height(curHeight).animate({height: autoHeight}, 800);
                $(this).closest('.hf-view-more').hide();
                $(this).parent().children('.hf-view-less').show();
            }else if($(this).parent().find('.hf-view-less').is(":visible")) {
                el.height(curHeight).animate({height: fixedHeight}, 800);
                $(this).parent().find('.hf-view-more').show();
                $(this).parent().find('.hf-view-less').hide();
            }
        });

        $('.hf-view-more').click(function () {
            $(this).closest('ul').find('.hf-view-less').toggle();
            $(this).toggle();
        });
        $('.hf-view-less').click(function () {
            $(this).closest('ul').find('.hf-view-more').toggle();
            $(this).toggle();
        });

        function blogSlider() {
            $cache.blogSocial.remove();
            if($('.hf-content').length > 2 && !$cache.blogSlider.hasClass('slick-initialized')) {
                $cache.blogSlider.slick({
                    lazyLoad: 'ondemand',
                    slide: ".hf-content",
                    dots: false,
                    arrows: true,
                    appendDots: $('.slider-nav2'),
                    appendArrows: $('.slider-nav2'),
                    slidesToShow: 1,
                    slidesToScroll: 1
                });
            }
        }

        function timelineSliderInit() {
            if(!$cache.timelineSlider.hasClass('slick-initialized')) {
                $cache.timelineSlider.slick({
                    lazyLoad: 'ondemand',
                    slide: ".hf-timeline-container a",
                    dots: true,
                    arrows: true,
                    appendDots: $('.timeline-dots'),
                    appendArrows: $('.timeline-dots'),
                    slidesToShow: 1,
                    slidesToScroll: 1
                });
            }
        }

        if ($(window).width() < 737) {
            $('.hf-shop-all').text('Shop All');
            $('.hf-content.social').remove();
            blogSlider();
            tabCarousel();
            timelineSliderInit();
            $(".home-slider-text").addClass("center");
        }

        function slicker() {
            if ($(window).width() < 737) {
                blogSlider();
                timelineSliderInit();
            }else if ($(window).width() > 737) {
                if($('.hf-blog-content').hasClass('slick-initialized')) {
                    $cache.blogSlider.slick('unslick');
                }
                if($cache.timelineSlider.hasClass('slick-initialized')) {
                    $cache.timelineSlider.slick('unslick');
                }
            }
        }

        homeProductRecs();
        function homeProductRecs() {
            window.setTimeout(function () {
                var $homeProductRecs = $('.home-product-recommendations-carousel'),
                    $slide = $homeProductRecs.find('.slide');
                $homeProductRecs.slick({
                    lazyLoad: 'ondemand',
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    slide: '.slide',
                    arrows: true,
                    dots: true,
                    responsive: [{
                        breakpoint: 735,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }]
                });
                $slide.show();
            }, 3000);
        }

        $(window).resize(function() {
            sliderImgs();
            slicker();
            homeMarketingContent();
        });
    },
/**
 * @private
 * @function
 * @description Read More function
 */

readMore: function(obj) {
    var $content = (obj),
        $more = $content.next('.read-more');
    if($content.children($('div[itemprop="headline"]')).length) {
        $more.show();
    }
    $more.unbind('click').click(function() {
       $content.toggleClass('expanded');
       window.setTimeout(function () {
           $more.toggleClass('expanded');
       }, 100);
    });
    $more.keypress(function (e) {
        if (e.which == 13){
            $more.click();
        }
    });
}
};



module.exports = storefront;