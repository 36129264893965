'use strict';

var producttile = require('../producttile');
var product = require('./product');
var quickview = require('../quickview');
var ajax = require('../ajax');
var page = require('../page');

var $cache = {};
/**
 * @private
 * @function
 * @description Initializes the cache on the compare table
 */
function initializeCache() {
    $cache = {
        compareTable : $("#compare-table"),
        categoryList : $("#compare-category-list")
    };
}
/**
 * @private
 * @function
 * @description Initializes the DOM on the product tile
 */
function initializeDom() {
    producttile.init();
}
/**
 * @private
 * @function
 * @description Binds the click events to the remove-link and quick-view button
 */
function initializeEvents() {
    $cache.compareTable.on("click", ".remove-link", function (e) {
        e.preventDefault();
        ajax.getJson({
            url : this.href,
            callback : function (response) {
                page.refresh();
            }
        });
    })
    .on("click", ".open-quick-view", function (e) {
        e.preventDefault();
        var form = $(this).closest("form");
        quickview.show({
            url:form.attr("action"),
            source:"quickview",
            data:form.serialize()
        });
    });

    $cache.categoryList.on("change", function () {
        window.location = '?category=' + $(this).val();
//			$(this).closest("form").submit();
    });
}

var compare = {
    /**
     * @function
     * @description Initializing of Cache, DOM and events
     */
    init : function () {
        initializeCache();
        initializeDom();
        initializeEvents();
        product.initAddToCart();
    }
};

module.exports = compare;